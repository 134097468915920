import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Table from '@components/Table';
import { useTranslation } from 'react-i18next';
import PadlockStatusColumn from '@src/components/PadlockStatusColumn';
import AdminActions from '@src/components/AdminActions';
import useSwr from '@src/hooks/useSwr';
import { dateFormat } from '@src/utils/formatters';
import BannerModal from './BannerModal/index';
import {
  Tooltip,
  Button,
  Dropdown,
  Input,
  Col,
  Select,
  Image,
  message,
} from 'antd';
import {
  PlusCircleOutlined,
  EyeOutlined,
  LinkOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import useSearchParams from '@src/hooks/useSearchParams';
import { Label } from '@components/ui';
import { Filters, FiltersCard } from '@src/components/Filters/index';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const Banners = () => {
  const { t } = useTranslation('banners');

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [modalVisible, setModalVisible] = useState(false);
  const [description, setDescription] = useState();
  const [descriptionValue, setDescriptionValue] = useState();
  const [active, setActive] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [bannerToEdit, setBannerEdit] = useState({});
  const [isEdition, setIsEdition] = useState(false);
  const [viewBannerImg, setViewBannerImg] = useState();

  const { data, mutate } = useSwr('/service-proxy/banner', {
    description,
    active,
    page: pagination?.current - 1,
    linesPerPage: pagination.pageSize,
  });

  const deleteBanner = useCallback(
    bannerId => apiMiddleware.delete(`/service-proxy/banner/${bannerId}`),
    [],
  );

  const changeStatus = useCallback(
    async (bannerId, active) => {
      const actionType = active ? 'enabling' : 'disabling';
      try {
        await apiMiddleware.put('/service-proxy/banner/active', null, {
          params: {
            active,
            bannerId,
          },
        });
        message.success(t(`msgs.success-when-${actionType}`));
      } catch {
        message.error(t(`msgs.error-when-${actionType}`));
      }
    },
    [t],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'description',
        setState: setDescription,
        inTheFilters: true,
      },
      {
        name: 'active',
        setState: setActive,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setDescriptionValue(description);
  }, [description]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      total: data?.totalElements,
    }));
  }, [description, active, data?.totalElements]);

  const mutateStatus = useCallback(
    (bannerId, active) => {
      mutate({
        ...data,
        content: data?.content?.map(banner =>
          banner.id === bannerId ? { ...banner, active } : banner,
        ),
      });
    },
    [data, mutate],
  );

  const mutateDelete = useCallback(
    bannerId => {
      mutate({
        ...data,
        content: data?.content?.filter(banner => banner.id !== bannerId),
      });
    },
    [data, mutate],
  );

  const mutateCreate = useCallback(
    newBanner => {
      if (data) {
        mutate({
          ...data,
          content: [newBanner, ...data.content],
        });
      }
    },
    [data, mutate],
  );

  const mutateUpdate = useCallback(
    updatedBanner => {
      if (data) {
        mutate({
          ...data,
          content: data?.content?.map(banner =>
            banner.id === updatedBanner.id ? updatedBanner : banner,
          ),
        });
      }
    },
    [data, mutate],
  );

  const handleActions = useCallback(
    async (type, payload) => {
      if (type === 'DELETE') {
        try {
          await deleteBanner(payload);
          mutateDelete(payload);
          message.success(t('msgs.success-when-deleting'));
        } catch {
          message.error(t('msgs.error-when-deleting'));
        }
      }
    },
    [deleteBanner, mutateDelete, t],
  );

  const viewItems = useCallback(
    (fileName, fileDetailName, link) => {
      const hasLink = link && link !== '';

      const handleViewLink = (fileName, buttonText, hasLink, link) => (
        <Button
          type="link"
          onClick={() => {
            if (hasLink) {
              window.open(link, '_blank', 'noreferrer');
            } else {
              setViewBannerImg({
                visible: true,
                imgSrc: `/banners/${fileName}`,
              });
            }
          }}
          icon={hasLink ? <LinkOutlined /> : <FileImageOutlined />}
        >
          {buttonText}
        </Button>
      );

      return [
        {
          key: 'main',
          label: handleViewLink(fileName, t('buttons.main')),
        },
        {
          key: hasLink ? 'link' : 'details',
          label: handleViewLink(
            fileDetailName,
            t(`buttons.${hasLink ? 'link' : 'details'}`),
            hasLink,
            link,
          ),
        },
      ];
    },
    [t],
  );

  const columns = useMemo(
    () => [
      { title: t('columns.description'), dataIndex: 'description' },
      {
        title: t('columns.status.title'),
        dataIndex: 'active',
        render: (active, record) => (
          <PadlockStatusColumn
            isOpen={active}
            openText={t('columns.status.able')}
            closedText={t('columns.status.disabled')}
            openActionTooltip={t('columns.status.enable')}
            closedActionTooltip={t('columns.status.disable')}
            onAction={() =>
              changeStatus(record.id, !active).then(() => mutateStatus(!active))
            }
          />
        ),
      },
      {
        title: t('columns.createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: t('columns.createdBy'),
        dataIndex: 'createdBy',
      },
      {
        title: t('columns.actions'),
        dataIndex: 'actions',
        align: 'center',
        render: (value, record) => (
          <AdminActions
            alertDelete={t('msgs.delete')}
            entityToExclude={record.description}
            extraActions={
              <Tooltip title={t('buttons.view')}>
                {record?.fileDetailName ||
                (record?.link && record?.link !== '') ? (
                  <Dropdown
                    menu={{
                      items: viewItems(
                        record?.fileName,
                        record?.fileDetailName,
                        record?.link,
                      ),
                    }}
                  >
                    <Button size="large" type="link" style={{ color: 'gray' }}>
                      <EyeOutlined />
                    </Button>
                  </Dropdown>
                ) : (
                  <Button
                    size="large"
                    type="link"
                    style={{ color: 'gray' }}
                    onClick={() =>
                      setViewBannerImg({
                        visible: true,
                        imgSrc: `/banners/${record?.fileName}`,
                      })
                    }
                  >
                    <EyeOutlined />
                  </Button>
                )}
              </Tooltip>
            }
            onDelete={() => handleActions('DELETE', record.id)}
            onEdit={() => {
              setBannerEdit(record);
              setIsEdition(true);
              setModalVisible(true);
            }}
          />
        ),
      },
    ],
    [changeStatus, handleActions, viewItems, mutateStatus, t],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('columns.description'),
        item: (
          <Search
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('columns.status.title'),
        item: (
          <Select
            style={{ width: '100%' }}
            onChange={value => handleSetSearchParams({ active: value })}
            options={[
              {
                label: t('columns.status.able'),
                value: 'true',
              },
              {
                label: t('columns.status.disabled'),
                value: 'false',
              },
            ]}
            value={active}
            allowClear
          />
        ),
      },
    ],
    [active, descriptionValue, handleSetSearchParams, t],
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleOnCloseModal = useCallback(() => {
    setModalVisible(false);
    setBannerEdit({});
    setIsEdition(false);
  }, []);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  return (
    <>
      <BannerModal
        visible={modalVisible}
        onClose={handleOnCloseModal}
        mutate={banner =>
          isEdition ? mutateUpdate(banner) : mutateCreate(banner)
        }
        isEdition={isEdition}
        bannerToEdit={bannerToEdit}
      />
      {viewBannerImg?.visible && (
        <Image
          src={viewBannerImg?.imgSrc}
          height={0}
          preview={{
            visible: viewBannerImg?.visible,
            onVisibleChange: visible => setViewBannerImg({ visible }),
          }}
        />
      )}
      <Table
        columns={columns}
        data={data?.content}
        loading={!data}
        title={
          <>
            {t('title')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        tableKey="banners"
        extraActions={
          <Tooltip placement="top" title={t('modal.title-new')}>
            <Button
              icon={<PlusCircleOutlined />}
              size="large"
              type="link"
              onClick={() => setModalVisible(true)}
            />
          </Tooltip>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default Banners;
