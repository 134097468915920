import styled from 'styled-components';

export const PrettyJSONContainer = styled.div`
  position: relative;

  .ant-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
