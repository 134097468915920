import { dateFormat, formatCurrency } from '@src/utils/formatters';
import React from 'react';
import { Space, Card, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const HistoricCard = ({ historicData, index }) => {
  const { t: translate } = useTranslation(['sms-send']);

  return (
    <Card
      title={historicData?.description}
      extra={
        <Tag
          color={
            index === 0 ? 'green' : historicData.deletedAt ? 'red' : 'orange'
          }
        >
          {index === 0
            ? translate('hire.created')
            : historicData.deletedAt
            ? translate('hire.exclude')
            : translate('hire.edited')}
        </Tag>
      }
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <p style={{ margin: 0, fontSize: '0.8rem' }}>
            {historicData.deletedBy
              ? historicData.deletedBy
              : historicData?.createdBy}
          </p>
          <p
            style={{
              margin: '4px 0',
              fontWeight: 'bold',
              opacity: '0.8',
            }}
          >
            {historicData.deletedAt
              ? dateFormat(historicData?.deletedAt)
              : dateFormat(historicData?.createdAt)}
          </p>
        </div>
        <Space direction="vertical">
          <p
            style={{
              margin: 0,
              fontSize: '1.1rem',
              opacity: '0.9',
            }}
          >
            {formatCurrency(historicData?.price)}
          </p>
          {historicData?.type !== 'PAY_PER_USE' && (
            <p
              style={{
                margin: 0,
                fontSize: '1.1rem',
                opacity: '0.9',
              }}
            >
              {historicData?.franchise} SMS
            </p>
          )}
        </Space>
      </div>
    </Card>
  );
};

export default HistoricCard;
