export const cleanObject = obj => {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const isEmpty = obj => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const removeAttrFromAList = (list, toDelete) =>
  list?.filter(attr => !toDelete.includes(attr));

export const deleteAttrInObj = (obj, toDelete) => {
  const newObj = obj && { ...obj };
  if (newObj && toDelete) {
    toDelete.forEach(attrName => {
      delete newObj[attrName];
    });
  }

  return newObj;
};

export const getSearchParam = (search, paramsNames) => {
  let params = {};
  paramsNames?.map(name => {
    params = {
      ...params,
      [name]: new URLSearchParams(search).get(name),
    };
  });
  params = cleanObject(params);
  return isEmpty(params) ? undefined : params;
};

export const manageSearchParams = (navigate, params) => {
  const { pathname } = location;
  const cleanParametersObject = cleanObject(params);

  if (isEmpty(cleanParametersObject)) {
    navigate(
      {
        pathname,
        search: '',
      },
      { replace: true },
    );
  } else {
    navigate(
      {
        pathname,
        search: '?' + new URLSearchParams(cleanParametersObject).toString(),
      },
      { replace: true },
    );
  }
};
