import useCustomers from '@src/hooks/useCustomers';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SessionContext from './SessionContext';
import rolesConstants from '@src/utils/rolesConstants';
import { SWRConfig } from 'swr';
import { changeAntdTheme } from 'dynamic-antd-theme';
import apiMiddleware from '@src/services/apiMiddleware';
import useSwr from '@src/hooks/useSwr';
import useUserPreferences from '@src/hooks/useUserPreferences';
import KeycloakContext from '../KeycloakContext/KeycloakContext';
import { useLocation } from 'react-router-dom';

const SessionProvider = ({ children }) => {
  const { hostname } = window.location;

  const [userRoles, setUserRoles] = useState();
  const [logoImgName, setLogoImgName] = useState('');
  const [mainColor, setMainColor] = useState('#5867dd');
  const [whatsapp, setWhatsapp] = useState();
  const [whatsappMessage, setWhatsappMessage] = useState();
  const [mainCustomerInContext, setMainCustomerInContext] = useState();
  const [customersSwitch, setCustomersSwitch] = useState();
  const [isContextSwitch, setIsContextSwitch] = useState(false);
  const [userLogged, setUserLogged] = useState();
  const [enableHomePage, setEnableHomePage] = useState();
  const { keycloak } = useContext(KeycloakContext);
  const { pathname } = useLocation();
  const isPageHome = pathname === '/management';

  const hasCustomersRoles = useMemo(() => {
    let hasRole = false;
    [rolesConstants.MANAGE_CUSTOMERS, rolesConstants.VIEW_CUSTOMERS].forEach(
      role => {
        if (userRoles?.portal?.includes(role)) {
          hasRole = true;
        }
      },
    );

    return hasRole;
  }, [userRoles]);

  const isInAnotherContext = useMemo(
    () => mainCustomerInContext?.id !== userLogged?.customerId,
    [mainCustomerInContext?.id, userLogged?.customerId],
  );

  const handleMutateCustomerSwitch = useCallback(
    (customer, type = 'CREATE' | 'DELETE') => {
      if (isInAnotherContext) {
        return;
      }
      setCustomersSwitch(oldCustomersSwitch => ({
        ...oldCustomersSwitch,
        customersOptions:
          type === 'CREATE'
            ? [
                ...oldCustomersSwitch.customersOptions,
                { ...customer, id: Number(customer.id) },
              ]
            : oldCustomersSwitch.customersOptions.filter(
                cst => cst.id !== customer.id,
              ),
      }));
    },
    [isInAnotherContext],
  );

  const hasRoles = useMemo(() => userRoles !== undefined, [userRoles]);

  useEffect(() => {
    if (keycloak) {
      setUserLogged(keycloak?.idTokenParsed);
    }
  }, [keycloak]);

  const userPreferences = useUserPreferences(userLogged?.user_id);

  useEffect(() => {
    setLogoImgName(hostname);
  }, [hostname]);

  useEffect(() => {
    if (mainColor) {
      changeAntdTheme(mainColor);
    }
  }, [mainColor]);

  useEffect(() => {
    if (hasRoles) {
      const violetColor = '#5867dd';
      const isNotValidString = str => str === '' || !str || str === '#FFF';
      apiMiddleware
        .get('/service-proxy/broker/theme', {
          params: {
            domain:
              hostname === 'localhost'
                ? 'allcom.iot.algartelecom.com.br'
                : hostname,
          },
        })
        .then(({ data }) => {
          const color = isNotValidString(data?.mainColor)
            ? violetColor
            : data?.mainColor;
          setMainColor(color);
          const treatedNullValue = value =>
            value !== null ? value : undefined;
          setWhatsapp(treatedNullValue(data?.whatsapp));
          setWhatsappMessage(treatedNullValue(data?.whatsappMessage));
        })
        .catch(() => {
          setMainColor(violetColor);
        });
    }
  }, [hostname, hasRoles]);

  const { ...customer } = useCustomers(hasCustomersRoles, hasRoles);

  const isBroker = useMemo(
    () =>
      customer?.customerLogged && customer?.customerLogged?.type === 'BROKER',
    [customer?.customerLogged],
  );

  const isAllcom = useMemo(() => {
    if (customer?.customerLogged) {
      const allcomId = 10000;
      const customerId = customer.customerLogged.id;
      const parentId = customer.customerLogged.parentId;
      return customerId === allcomId || parentId === allcomId;
    }
  }, [customer?.customerLogged]);

  const isHostAllcom = useMemo(() => {
    const allcomHosts = [
      // TESTS
      // 'localhost',
      // 'allcom.iot',
      // HOM
      'allcom.iot.algartelecom.com.br',
      // PRD
      'allcom.algartelecom.com.br',
      'allmanager.com.br',
    ];
    return allcomHosts.some(defaultHost => hostname.includes(defaultHost));
  }, [hostname]);

  const { data: bannersData, error: bannersError } = useSwr(
    hasRoles && isPageHome ? '/service-proxy/banner/active' : null,
    null,
    {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  );

  useEffect(() => {
    const hasBanners = bannersData?.banners?.length > 0;
    const hasParentBanners = bannersData?.parentBanners?.length > 0;
    const isBrokerInOtherHost = isBroker && !isHostAllcom;

    if (isAllcom && isHostAllcom) {
      setEnableHomePage(true);
    } else if (bannersData && isBroker !== undefined) {
      if (isBrokerInOtherHost && !hasBanners) {
        setEnableHomePage(false);
      } else {
        if (bannersError || (!hasBanners && !hasParentBanners)) {
          setEnableHomePage(false);
        } else if (!bannersError && (hasBanners || hasParentBanners)) {
          setEnableHomePage(true);
        }
      }
    }
  }, [
    bannersData,
    bannersError,
    isAllcom,
    isBroker,
    isHostAllcom,
    setEnableHomePage,
  ]);

  const providerValues = useMemo(
    () => ({
      userRoles,
      setUserRoles,
      logoImgName,
      isAllcom,
      mainColor,
      setMainColor,
      customer,
      mainCustomerInContext,
      setMainCustomerInContext,
      whatsapp,
      setWhatsapp,
      whatsappMessage,
      setWhatsappMessage,
      hasCustomersRoles,
      customersSwitch,
      setCustomersSwitch,
      isContextSwitch,
      setIsContextSwitch,
      handleMutateCustomerSwitch,
      enableHomePage,
      setEnableHomePage,
      bannersData,
      bannersError,
      isHostAllcom,
      isBroker,
      userPreferences,
    }),
    [
      userRoles,
      logoImgName,
      isAllcom,
      mainColor,
      customer,
      mainCustomerInContext,
      whatsapp,
      whatsappMessage,
      hasCustomersRoles,
      customersSwitch,
      isContextSwitch,
      handleMutateCustomerSwitch,
      enableHomePage,
      bannersData,
      bannersError,
      isHostAllcom,
      isBroker,
      userPreferences,
    ],
  );

  return (
    <SWRConfig value={{ provider: () => new Map() }}>
      <SessionContext.Provider value={providerValues}>
        {children}
      </SessionContext.Provider>
    </SWRConfig>
  );
};

export default SessionProvider;
