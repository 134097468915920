import React, { useState, useEffect, useMemo } from 'react';
import { notification } from 'antd';

import { useTranslation } from 'react-i18next';

import useSwr from '@hooks/useSwr';
import apiMiddleware from '@src/services/apiMiddleware';

export const useDeviceFormatter = (id, showContracted) => {
  const { t } = useTranslation('inventory-detailed');

  const [note, setNote] = useState(t('default-note'));

  const { data: device, mutate: mutateDevice } = useSwr(
    id ? `/service-proxy/inventory/MOT/details` : null,
    {
      showContracted,
      id,
    },
    {
      method: 'post',
    },
  );

  const { data: dataConsumption, error: errorConsumption } = useSwr(null);

  const handleUpdateNickname = async value => {
    if (value !== t('default-nickname')) {
      try {
        await apiMiddleware.put(
          `/service-proxy/inventory/MOT/${device?.msisdn}/nickname?nickname=${value}`,
        );
        mutateDevice({
          ...device,
          nickname: value,
        });
      } catch {
        notification.error({
          message: t('error'),
          description: t('errors.update-nickname'),
        });
      }
    }
  };

  useEffect(() => {
    if (device?.observation) {
      setNote(
        device.observation.trim() !== ''
          ? device?.observation
          : t('default-note'),
      );
    }
  }, [device?.observation, t]);

  const handleUpdateObservation = async value => {
    if (value !== t('default-note')) {
      try {
        await apiMiddleware.put(
          `/service-proxy/inventory/MOT/${device?.msisdn}/observation?observation=${value}`,
        );
        if (device && device?.observation) {
          device.observation = value;
        }
        mutateDevice(device, false);

        setNote(value);
      } catch {
        notification.error({
          message: t('error'),
          description: t('errors.update-note'),
        });
      }
    }
  };

  const consumptionFormatted = useMemo(() => {
    const consumption = [];
    if (dataConsumption?.results) {
      dataConsumption?.results[0].series[0].values.forEach(values => {
        values.forEach((value, index) => {
          if (index === 0) {
            return;
          }
          if (dataConsumption.results) {
            consumption.push({
              name: dataConsumption.results[0].series[0].columns[index],
              traffic: value || 0,
              date: `${new Date(values[0]).getDate()}/${
                new Date(values[0]).getMonth() + 1
              }`,
            });
          }
        });
      });
    }
    return consumption;
  }, [dataConsumption]);

  const locationFormatted = useMemo(() => {
    const city = device?.city;
    const state = device?.state;
    if (city && state) {
      return `${city}, ${state}`;
    }
    if (city) {
      return city;
    }
    if (state) {
      return state;
    }
    return '-';
  }, [device?.city, device?.state]);

  const activationDateFormatted = useMemo(() => {
    if (device?.activationDate) {
      const date = new Date(device?.activationDate);
      return date.toLocaleString();
    }
    return '';
  }, [device?.activationDate]);

  const lastConnectionFormatted = useMemo(() => {
    if (device?.lastConnection) {
      const date = new Date(device?.lastConnection);
      return date.toLocaleString();
    }
    return '';
  }, [device?.lastConnection]);

  return {
    note,
    device,
    dataConsumption,
    errorConsumption,
    consumptionFormatted,
    handleUpdateNickname,
    handleUpdateNote: handleUpdateObservation,
    locationFormatted,
    activationDateFormatted,
    lastConnectionFormatted,
  };
};
