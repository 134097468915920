import React, { useEffect, useState } from 'react';

import { Image, Button, Form, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileImageOutlined } from '@ant-design/icons';
import * as S from './styles';
import { convertFileInData64 } from '@src/utils/formatters';

const BannerPreview = ({ form, files }) => {
  const { t } = useTranslation('banners');
  const file = Form.useWatch('file', form);
  const enabledDefaultButton = Form.useWatch('enabledDefaultButton', form);
  const buttonText = Form.useWatch('buttonText', form);
  const addInfoEnabled = Form.useWatch('addInfoEnabled', form);
  const [newImgSrc, setNewImgSrc] = useState();

  useEffect(() => {
    if (file) {
      convertFileInData64(file, fileInData64 => setNewImgSrc(fileInData64));
    } else {
      setNewImgSrc(undefined);
    }
  }, [file]);

  return (
    <S.Container>
      <Divider>{t('modal.preview')}</Divider>
      <S.Content>
        {files?.main?.url || newImgSrc ? (
          <>
            <Image
              width="100%"
              src={newImgSrc ?? files?.main?.url}
              preview={false}
            />
            {addInfoEnabled && <S.Mask />}
          </>
        ) : (
          <p className="banner-preview-empty-image">
            <FileImageOutlined />
          </p>
        )}
        {addInfoEnabled && enabledDefaultButton && (
          <Button type="primary" size="small">
            {buttonText ?? t('modal.button-default-text')}
          </Button>
        )}
      </S.Content>
    </S.Container>
  );
};

export default BannerPreview;
