import React, { useMemo } from 'react';
import { LorawanActionTypes } from '@utils/deviceActions';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import rolesConstants from '@utils/rolesConstants';

const useChangeDescription = ({ devices, isUpload, form }) => {
  const { t } = useTranslation(['lorawan', 'device-actions']);
  const description = Form.useWatch('description', form);

  const steps = useMemo(
    () => [
      {
        title: t('newDescription'),
        name: 'description',
        content: <Input allowClear />,
      },
    ],
    [t],
  );

  const payloadToSend = useMemo(
    () => ({
      action: LorawanActionTypes.CHANGE_DESCRIPTION,
      devices: isUpload
        ? devices
        : devices?.map(device => ({
            devEui: device.devEui,
            description,
          })),
    }),
    [description, devices, isUpload],
  );

  return {
    steps,
    payloadToSend,
    title: t(
      `device-actions:labels.lorawan.${rolesConstants.LORAWAN_CHANGE_DESCRIPTION}`,
    ),
    totalDevices: devices?.length,
  };
};

export default useChangeDescription;
