import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import { useCallback, useContext, useMemo } from 'react';
import useSwr from './useSwr';

const useCustomers = (hasCustomersRoles, hasRoles) => {
  const { keycloak } = useContext(KeycloakContext);
  const customerLoggedType = keycloak.idTokenParsed?.customerType;

  const { data: customersDataIncludeMe } = useSwr(
    hasCustomersRoles ? '/customers/' : null,
    undefined,
    { revalidateOnFocus: false, shouldRetryOnError: false },
  );

  const { data: customersData } = useSwr(
    hasCustomersRoles ? '/customers/?myself=false' : null,
    undefined,
    { revalidateOnFocus: false, shouldRetryOnError: false },
  );

  const { data: customerLogged } = useSwr(
    hasRoles ? `/customers/self` : null,
    undefined,
    { revalidateOnFocus: false, shouldRetryOnError: false },
  );

  const { data: availableCustomerTypes } = useSwr(
    hasRoles ? '/customer-types/below' : null,
    {
      customerType: customerLoggedType,
    },
    { revalidateOnFocus: false, shouldRetryOnError: false },
  );

  const isCustomerFinanciallyBlocked = useMemo(
    () => customerLogged?.financialBlock,
    [customerLogged],
  );

  const customerLoggedName = useMemo(
    () => customerLogged?.name,
    [customerLogged],
  );

  const customersOptions = useMemo(
    () =>
      customersData?.map(
        customer =>
          customer && {
            label: customer.name,
            value: customer.id,
          },
      ),

    [customersData],
  );

  const customersOptionsIncludeMe = useMemo(
    () =>
      customersDataIncludeMe?.map(
        customer =>
          customer && {
            label: customer.name,
            value: customer.id,
          },
      ),

    [customersDataIncludeMe],
  );

  const getCustomerById = useCallback(customerId =>
    customersData?.find(cstData => cstData.id === customerId),
  );

  return {
    customersData,
    customersDataIncludeMe,
    customersOptions,
    customersOptionsIncludeMe,
    customerLoggedName,
    customerLogged,
    isCustomerFinanciallyBlocked,
    getCustomerById,
    availableCustomerTypes,
  };
};

export default useCustomers;
