import React, { useContext, useMemo } from 'react';
import { ConfigProvider } from 'antd';

import locale_pt from 'antd/lib/locale/pt_BR';
import locale_en from 'antd/lib/locale/en_US';
import locale_es from 'antd/lib/locale/es_ES';

import KeycloakContext from './KeycloakContext/KeycloakContext';

const AntdConfigProvider = ({ children }) => {
  const { keycloak } = useContext(KeycloakContext);
  const locale = keycloak?.idTokenParsed?.locale;

  const handleLocale = useMemo(() => {
    if (locale === 'pt') {
      return locale_pt;
    }
    if (locale === 'en') {
      return locale_en;
    }
    return locale_es;
  }, [locale]);

  return <ConfigProvider locale={handleLocale}>{children}</ConfigProvider>;
};

export default AntdConfigProvider;
