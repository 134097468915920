import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import SessionProvider from './store/SessionContext/SessionProvider';
import 'antd/dist/antd.css';
import AxiosConfigProvider from './components/AxiosConfigProvider/AxiosConfigProvider';
import axiosBasic from './services/axiosBasic';
import KeycloakProvider from './store/KeycloakContext/KeycloakProvider';
import keycloak from './services/keycloak';
import apiMiddleware from './services/apiMiddleware';
import i18n from './i18n';
import AntdConfigProvider from './store/AntdConfigProvider';
import PortalSpinner from './components/PortalSpinner/index';

function App() {
  return (
    <KeycloakProvider
      axios={apiMiddleware}
      i18next={i18n}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
      keycloak={keycloak}
    >
      <AxiosConfigProvider axios={axiosBasic}>
        <AntdConfigProvider>
          <Router>
            <Suspense fallback={<PortalSpinner />}>
              <SessionProvider>
                <Routes />
              </SessionProvider>
            </Suspense>
          </Router>
        </AntdConfigProvider>
      </AxiosConfigProvider>
    </KeycloakProvider>
  );
}

export default App;
