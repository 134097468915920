import styled from 'styled-components';

const Alert = styled.div`
  position: relative;
  background-color: #f66e84;
  padding: 0.85rem 1.5rem;
  margin: 1rem 1rem;
  border-color: #f55f78;
  color: #fff;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  border-radius: 4px;
`;

Alert.propTypes = styled.div.propTypes;

export default Alert;
