import React from 'react';
import { Form, Row, Col } from 'antd';

const TypeStep = ({ form, renderRadioItem }) => (
  <Form
    colon={false}
    form={form}
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
    scrollToFirstError
    initialValues={{ activation: 'OTAA', encryption: 'NS' }}
    layout="vertical"
  >
    <Row gutter={[8, 8]} justify="center">
      <Col xs={24} sm={8}>
        {renderRadioItem('activation', ['OTAA', 'ABP'])}
      </Col>
      <Col xs={24} sm={8}>
        {renderRadioItem('encryption', ['NS', 'APP'])}
      </Col>
    </Row>
  </Form>
);

export default TypeStep;
