import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Input, Button, Tag, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@hooks/useSwr';
import Table from '@components/Table';
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import apiMiddleware from '@services/apiMiddleware';
import AdminActions from '@components/AdminActions';
import GroupViewerModal from './GroupViewerModal';
import SessionContext from '@store/SessionContext/SessionContext';
import useSearchParams from '@src/hooks/useSearchParams';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import { Filters, FiltersCard } from '@components/Filters';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50'],
  style: { marginRight: 20 },
};

const UserGroups = () => {
  const { t } = useTranslation('roles');

  const { keycloak } = useContext(KeycloakContext);
  const { userRoles, mainCustomerInContext } = useContext(SessionContext);

  const customerId = keycloak.idTokenParsed?.customerId;
  const isCustomerParent = customerId === mainCustomerInContext?.id;

  const isCustomerCanManage =
    userRoles?.portal?.indexOf('manage-groups') !== -1;

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [modalVisible, setModalVisible] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState('');

  const [showFilters, setShowFilters] = useState(false);
  const userGroups = keycloak?.idTokenParsed?.groups;

  const { data: groupsData, mutate } = useSwr('/groups/', {
    search,
    filter: 'MINE',
    page: pagination.current ? pagination.current - 1 : 0,
    linesPerPage: pagination.pageSize,
  });

  const userLoggedGroups = useMemo(
    () =>
      userGroups?.map(item => {
        const groupsOnArray = item.split('/');
        return groupsOnArray[groupsOnArray.length - 1];
      }),
    [userGroups],
  );

  const isNotUserGroup = useCallback(
    groupName => userLoggedGroups?.indexOf(groupName) !== -1,
    [userLoggedGroups],
  );

  const groupsDataToShow = useMemo(
    () =>
      userLoggedGroups
        ? groupsData?.content?.filter(
            group =>
              !isCustomerParent ||
              (userLoggedGroups && !isNotUserGroup(group.name)),
          )
        : groupsData?.content,
    [groupsData, isNotUserGroup, isCustomerParent, userLoggedGroups],
  );

  const deleteGroup = useCallback(
    id => apiMiddleware.delete(`/groups/${id}`),
    [],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'search',
        setState: setSearch,
      },
    ],
    [],
  );

  const { handleSetSearchParams } = useSearchParams(paramsAttributes);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [search]);

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  const onSearch = useCallback(
    value => {
      handleSetSearchParams({ search: value });
      setPagination(oldPagination => ({
        ...oldPagination,
        current: DEFAULT_PAGINATION.current,
      }));
    },
    [handleSetSearchParams],
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      total: groupsData?.totalElements,
    }));
  }, [groupsData?.totalElements]);

  const handleMutateGroups = useCallback(
    (groupToMutate, type = 'NEW') => {
      if (type === 'NEW') {
        mutate({
          ...groupsData,
          content: [...groupsData?.content, groupToMutate],
        });
      } else {
        // DELETE -> groupToMutate = group.id
        mutate({
          ...groupsData,
          content: groupsData?.content?.filter(
            group => group.id !== groupToMutate,
          ),
        });
      }
    },
    [groupsData, mutate],
  );

  const handleActions = useCallback(
    async (actionType, id) => {
      const isUpdateGroup = actionType === 'UPDATE';

      if (isUpdateGroup) {
        setGroupId(id);
        setModalVisible(true);
      } else {
        try {
          await deleteGroup(id);
          handleMutateGroups(id, 'DELETE');
          message.success(t('msgs.success-deleting-profile'));
        } catch ({
          response: {
            data: { code },
          },
        }) {
          if (code && code === 'GROUP_CANNOT_BE_DELETED') {
            message.error(t('msgs.error-deleting-profile-being-used'));
          } else {
            message.error(t('msgs.error-deleting-profile'));
          }
        }
      }
    },
    [deleteGroup, t, handleMutateGroups],
  );
  const columnsDefault = useMemo(
    () => [
      {
        title: t('table.name'),
        dataIndex: 'name',
        render: (value, record) =>
          record.children ? (
            record.name
          ) : (
            <Tag color="blue">
              {record.attributes?.prettyName ?? record.prettyName}
            </Tag>
          ),
      },
    ],
    [t],
  );

  const columns = useMemo(() => {
    if (isCustomerCanManage) {
      columnsDefault.push({
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (value, record) => {
          const adminActions = (
            <AdminActions
              alertDelete={t('modal.delete.alert')}
              entityToExclude={record.name}
              ruleToNotExclude={record.name === 'ADMINISTRATORS'}
              onEdit={() => handleActions('UPDATE', record.id)}
              onDelete={() => handleActions('DELETE', record.id)}
            />
          );

          if (record.name !== 'ADMINISTRATORS') {
            return adminActions;
          }

          if (record.name === 'ADMINISTRATORS' && !isCustomerParent) {
            return adminActions;
          }

          if (!isNotUserGroup(record.name) && !isCustomerParent) {
            return adminActions;
          }
        },
      });
    }
    return columnsDefault;
  }, [
    handleActions,
    t,
    isCustomerCanManage,
    isCustomerParent,
    isNotUserGroup,
    columnsDefault,
  ]);

  const onClose = useCallback(() => {
    setModalVisible(false);
    setGroupId('');
  }, []);

  const handleTableChange = useCallback(paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  }, []);

  return (
    <>
      {modalVisible && (
        <GroupViewerModal
          groupId={groupId}
          visible={modalVisible}
          onClose={onClose}
          mutateGroups={handleMutateGroups}
        />
      )}

      <Table
        columns={columns}
        allColumns={columnsDefault}
        data={groupsDataToShow || []}
        loading={!groupsDataToShow}
        title={
          isCustomerParent ? (
            <Filters
              icon={<UserOutlined />}
              title={t('title')}
              secondaryTitle={t('tabs.my-profiles')}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          ) : (
            t('title')
          )
        }
        tableKey="userGroups"
        onChange={handleTableChange}
        pagination={pagination}
        extraActions={
          <>
            <Search
              placeholder={t('search')}
              onSearch={onSearch}
              value={searchValue}
              onChange={({ target: { value } }) => setSearchValue(value)}
              allowClear
              style={{ width: 200, margin: '0 10px' }}
            />
            {isCustomerCanManage && (
              <Tooltip title={t('actions.newRole')}>
                <Button
                  icon={<PlusCircleOutlined />}
                  type="link"
                  size="large"
                  onClick={() => setModalVisible(true)}
                />
              </Tooltip>
            )}
          </>
        }
        extraFilters={
          isCustomerParent &&
          showFilters && (
            <FiltersCard>
              {userLoggedGroups?.map(group => (
                <Tag key={group} color="blue">
                  {group.charAt(0).toUpperCase() + group.slice(1).toLowerCase()}
                </Tag>
              ))}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default UserGroups;
