import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border: 1px solid #fafafa;
  border-radius: 8px;
  overflow: hidden;
  max-width: 1200px;
  max-height: 400px;

  .ant-image {
    width: 100%;
    .ant-image-img {
      margin-bottom: -8px;
    }
  }

  .carousel-img-click {
    cursor: pointer;
    transition: 0.2s;

    :hover {
      transform: scale(1.02);
    }
  }

  .ant-btn {
    position: absolute;
    z-index: 4;
    right: 24px;
    bottom: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;
