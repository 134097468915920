import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Checkbox,
  Button,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import { maskPhone } from '@utils/formatters';
import useSwr from '@hooks/useSwr';
import SessionContext from '@src/store/SessionContext/SessionContext';
import apiMiddleware from '@src/services/apiMiddleware';
import roles from '@utils/rolesConstants';

const { Item } = Form;

const CustomerInfoForm = ({
  isNewCustomer,
  customerId = 0,
  form,
  onFormFinish,
  onChangeCustomerType,
  mutateData,
  close,
}) => {
  const { t } = useTranslation('customers');
  const [transformLoading, setTransformLoading] = useState(false);
  const { keycloak } = useContext(KeycloakContext);
  const {
    userRoles,
    customer: { availableCustomerTypes },
  } = useContext(SessionContext);
  const customerLoggedType = keycloak.idTokenParsed?.customerType;
  const isBroker = ['SUPERBROKER', 'BROKER'].includes(customerLoggedType);

  const parentId = keycloak.idTokenParsed?.customerId;
  const isAllcom = parentId === 10000;

  const { data: customerToUpdate, mutate } = useSwr(
    customerId > 0 ? `/customers/${customerId}` : null,
  );

  const hasRole = useCallback(
    (roleToCheck, client) => userRoles?.[client]?.includes(roleToCheck),
    [userRoles],
  );

  const handleTransformIntoBroker = useCallback(async () => {
    setTransformLoading(true);
    try {
      await apiMiddleware.put(
        `/customers/convert-to-broker?customerId=${customerId}`,
      );

      mutateData('CUSTOMER_TO_BROKER');
      message.success(t('notifications.successTransformIntoBroker'));
      close();
    } catch (err) {
      message.error(t('notifications.errorTransformIntoBroker'));
    }
    setTransformLoading(false);
  }, [customerId, mutateData, t, close]);

  useEffect(() => {
    if (!isNewCustomer && customerId !== -1) {
      form.setFieldsValue({
        ...customerToUpdate,
        dueDay:
          customerToUpdate?.secondDueDay && customerToUpdate?.dueDay
            ? [customerToUpdate?.dueDay, customerToUpdate?.secondDueDay]
            : customerToUpdate?.dueDay
            ? [customerToUpdate?.dueDay]
            : undefined,
      });
    }
  }, [isNewCustomer, customerId, form, customerToUpdate]);

  const customersDataIncludeMeTypesOptions = useMemo(
    () =>
      availableCustomerTypes?.map(customerType => {
        const label = t(
          `attributes.customerTypes.${customerType.toLowerCase()}`,
        );
        return { value: customerType, label };
      }),
    [availableCustomerTypes, t],
  );

  const handleFormFinish = useCallback(
    formData => {
      let payload = { ...formData, parentId };

      if (!isBroker) {
        payload = {
          ...payload,
          type: 'CUSTOMER',
        };
      }
      onFormFinish(payload);
      mutate(payload);
    },
    [parentId, isBroker, onFormFinish, mutate],
  );

  return (
    <Form
      colon={false}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError
      onFinish={handleFormFinish}
    >
      <Row gutter={[16, 16]}>
        {isNewCustomer && isBroker && (
          <>
            <div style={{ width: '100%', opacity: '0.6' }}>
              <Divider orientation="left">
                {t('form.dividers.hierarchy')}
              </Divider>
            </div>
            <Col xl={12} xs={24}>
              <Item
                label={t('attributes.customerLevel')}
                name="type"
                rules={[
                  {
                    required: isNewCustomer,
                    message: t('form.msgs.fillThisField'),
                  },
                ]}
              >
                <Select
                  onChange={newCustomerType =>
                    onChangeCustomerType(newCustomerType)
                  }
                  options={customersDataIncludeMeTypesOptions}
                />
              </Item>
            </Col>
          </>
        )}
      </Row>
      {customerToUpdate &&
        customerToUpdate.type === 'CUSTOMER' &&
        hasRole(roles.TRANSFORM_CUSTOMER_BROKER, 'portal') && (
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                onClick={() => handleTransformIntoBroker()}
                loading={transformLoading}
              >
                {t('buttons.transformIntoBroker')}
              </Button>
            </Col>
          </Row>
        )}

      <Row gutter={[16, 16]}>
        <div style={{ width: '100%', opacity: '0.6' }}>
          <Divider orientation="left">
            {t('form.dividers.identification')}
          </Divider>
        </div>

        <Col xl={12} xs={24}>
          {/* Name */}
          <Item
            label={t('attributes.corporateName')}
            name="name"
            rules={[
              {
                required: true,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input />
          </Item>

          {/* CPF/CNPJ */}
          <Item
            label={t('attributes.cpfCnpj')}
            name="cpfCnpj"
            normalize={value => (value ? value.replace(/\D/g, '') : '')}
            rules={[
              {
                required: true,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input maxLength={30} />
          </Item>

          {/* Trading name */}
          <Item
            label={t('attributes.tradingName')}
            name="tradingName"
            rules={[
              {
                required: false,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input />
          </Item>
        </Col>

        <Col xl={12} xs={24}>
          {/* Nickname */}
          <Item
            label={t('attributes.nickname')}
            name="nickname"
            rules={[
              {
                required: false,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input />
          </Item>

          {/* APN */}
          <Item
            label={t('attributes.apn')}
            name="apn"
            rules={[
              {
                required: true,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <div style={{ width: '100%', opacity: '0.6' }}>
          <Divider orientation="left">{t('form.dividers.contact')}</Divider>
        </div>

        <Col xl={12} xs={24}>
          <Item
            label={t('attributes.name')}
            name={['contact', 'name']}
            rules={[
              {
                required: true,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input />
          </Item>
        </Col>
        <Col xl={12} xs={24}>
          <Item
            label={t('attributes.phoneNumber')}
            name={['contact', 'phoneNumber']}
            normalize={value => maskPhone(value)}
            rules={[
              {
                required: true,
                message: t('form.msgs.fillThisField'),
              },
            ]}
          >
            <Input maxLength="15" />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <div style={{ width: '100%', opacity: '0.6' }}>
          <Divider orientation="left">{t('form.dividers.address')}</Divider>
        </div>

        <Col xl={12} xs={24}>
          {/* Street name and building number */}
          <Item label={t('attributes.streetName')}>
            <Input.Group compact>
              <Item name={['address', 'streetName']} noStyle>
                <Input
                  autoComplete="address-line1"
                  maxLength={100}
                  placeholder={t('attributes.streetName')}
                  style={{ width: '70%' }}
                />
              </Item>
              <Item
                name={['address', 'number']}
                normalize={value => value.replace(/\D/g, '')}
                noStyle
              >
                <Input
                  maxLength={10}
                  placeholder={t('attributes.buildingNumber')}
                  required={false}
                  style={{ width: '30%' }}
                />
              </Item>
            </Input.Group>
          </Item>

          <Item
            label={t('attributes.complement')}
            name={['address', 'complement']}
          >
            <Input autoComplete="address-line2" maxLength={100} />
          </Item>

          {/* Neighborhood */}
          <Item
            label={t('attributes.neighborhood')}
            name={['address', 'neighborhood']}
          >
            <Input autoComplete="address-level3" maxLength={100} />
          </Item>
        </Col>

        <Col xl={12} xs={24}>
          {/* City and state */}
          <Item label={t('attributes.city')}>
            <Input.Group compact>
              <Item name={['address', 'city']} noStyle>
                <Input
                  autoComplete="address-level2"
                  maxLength={50}
                  placeholder={t('attributes.city')}
                  style={{ width: '50%' }}
                />
              </Item>
              <Item name={['address', 'state']} noStyle>
                <Input
                  autoComplete="address-level1"
                  maxLength={50}
                  placeholder={t('attributes.state')}
                  style={{ width: '50%' }}
                />
              </Item>
            </Input.Group>
          </Item>

          {/* Country */}
          <Item
            label={t('attributes.country')}
            labelAlign="right"
            name={['address', 'country']}
          >
            <Input autoComplete="country-name" maxLength={50} />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <div style={{ width: '100%', opacity: '0.6' }}>
          <Divider orientation="left">{t('form.dividers.billing')}</Divider>
        </div>

        <Col xl={14} xs={24}>
          {/* Due day */}
          <Item
            label={t('attributes.dueDay')}
            name="dueDay"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 2) {
                    return Promise.reject(
                      new Error(t('form.msgs.dueDayLength')),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              mode="multiple"
              options={[{ value: 15 }, { value: 20 }, { value: 25 }]}
            />
          </Item>
        </Col>
        {isAllcom && (
          <>
            <Col xl={10} xs={24}>
              <Item label={t('attributes.billing')} name="nbMonthsToBillAfter">
                <Select
                  options={[
                    { value: 0, label: t('attributes.thirtyDays') },
                    { value: 1, label: t('attributes.sixtyDays') },
                    { value: 2, label: t('attributes.ninetyDays') },
                  ]}
                />
              </Item>
            </Col>
            <Col xl={15} xs={24}>
              <Item
                label={t('attributes.ignoreErpSync')}
                name="ignoreErpSync"
                valuePropName="checked"
              >
                <Checkbox />
              </Item>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default CustomerInfoForm;
