import React, { useState, useCallback, useMemo } from 'react';
import { Button, Divider, Form, Steps, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { LorawanActionTypes } from '@utils/deviceActions';
import { useNavigate } from 'react-router-dom';
import apiMiddleware from '@src/services/apiMiddleware';
import FilterStep from './ReferralModalSteps/FilterStep';
import ConnectionStep from './ReferralModalSteps/ConnectionStep';
import * as S from './styles';

const NewReferralModal = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['lorawan', 'device-actions-modal']);

  const [step, setStep] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [...forms] = [...Form.useForm(), ...Form.useForm()];
  const [filterValues, setFilterValues] = useState();
  // 'tags' | 'devices' | 'applications' | 'all'
  const [filterType, setFilterType] = useState('tags');

  const postReferral = useCallback(
    payload => apiMiddleware.post('/service-proxy/lorawan/actions', payload),
    [],
  );

  const handleSuccess = useCallback(() => {
    const navigatePath = `/lorawan/actions-queue?actionType=${LorawanActionTypes.REFERRAL}`;
    message.success(
      <>
        <span>{t('device-actions-modal:msgs.requested-action')}</span>
        <Button
          type="link"
          style={{ margin: '0 4px', padding: 0 }}
          onClick={() => navigate(navigatePath)}
        >
          {t('device-actions-modal:msgs.follow-in-the-actions-queue')}
        </Button>
      </>,
      2,
    );
  }, [navigate, t]);

  const handleClose = useCallback(() => {
    forms.forEach(f => f.resetFields());
    setFilterValues(undefined);
    setStep(0);
    onClose();
  }, [onClose, forms]);

  const handleFinish = useCallback(
    async connectionValues => {
      setSaveLoading(true);
      try {
        const payload = {
          action: LorawanActionTypes.REFERRAL,
          contractId: 'A119764047',
          deviceReferrals: {
            filter: {
              ...filterValues,
              devices: filterValues?.devices?.split(';'),
              applications: filterValues?.applications?.split(';'),
            },
            connection: connectionValues,
          },
        };
        await postReferral(payload);
        handleSuccess();
      } catch (error) {
        message.error(t('errorWhenCreatingReferral'));
      }
      handleClose();
      setSaveLoading(false);
    },
    [handleClose, filterValues, postReferral, handleSuccess, t],
  );

  const formValidate = useCallback(
    () =>
      forms[step].validateFields().then(data => {
        forms[step].submit();
        return data;
      }),
    [forms, step],
  );

  const handleStep = useCallback(
    action => {
      if (action === 'back' && step > 0) {
        setStep(oldStep => oldStep - 1);
      } else if (action === 'next') {
        formValidate().then(values => {
          setFilterValues(values);
          setStep(oldStep => oldStep + 1);
        });
      } else if (action === 'confirm') {
        formValidate().then(values => {
          handleFinish(values);
        });
      }
    },
    [formValidate, handleFinish, step],
  );

  const stepsContent = useMemo(
    () => [
      <FilterStep
        key={0}
        form={forms?.[0]}
        filterType={filterType}
        setFilterType={setFilterType}
      />,
      <ConnectionStep key={1} form={forms?.[1]} />,
    ],
    [filterType, forms],
  );

  return (
    <S.ModalContainer
      bodyStyle={{ padding: '24px 40px' }}
      afterClose={() => document.body.style.removeProperty('overflow')}
      footer={
        <>
          {step > 0 && (
            <Button onClick={() => handleStep('back')}>
              {t('buttons.back')}
            </Button>
          )}

          {step === 1 ? (
            <Button
              type="primary"
              onClick={() => handleStep('confirm')}
              loading={saveLoading}
            >
              {t('buttons.confirm')}
            </Button>
          ) : (
            <Button type="primary" onClick={() => handleStep('next')}>
              {t('buttons.next')}
            </Button>
          )}
        </>
      }
      okButtonProps={{ form: 'referrals', htmlType: 'submit' }}
      maskClosable={false}
      title={t('newReferrals')}
      open={visible}
      centered
      forceRender
      onCancel={() => {
        document.body.style.removeProperty('overflow');
        handleClose();
      }}
    >
      <Steps
        size="small"
        current={step}
        items={[
          {
            title: t('filter'),
          },
          {
            title: t('connection'),
          },
        ]}
      />
      <Divider />
      {stepsContent?.[step]}
    </S.ModalContainer>
  );
};

export default NewReferralModal;
