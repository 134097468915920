import { Col } from 'antd';
import React, { useMemo } from 'react';
import useSwr from '@hooks/useSwr';
import { useTranslation } from 'react-i18next';
import DashboardStatItem from './DashboardStatItem';

const DashboardStats = () => {
  const { t } = useTranslation('lorawan');

  const { data } = useSwr(
    '/service-proxy/lorawanDevices/deviceStats?product=LORAWAN_MOT',
  );

  const stats = useMemo(
    () => [
      {
        key: 'devices',
        title: t('devices'),
        value: data?.totalDevices,
        valueColor: 'var(--primary-color)',
      },
      {
        key: 'uplink',
        title: t('activeUplink'),
        value: data?.activeUplinkDevices,
        valueColor: 'purple',
      },
      {
        key: 'downlink',
        title: t('activeDownlink'),
        value: data?.activeDownlinkDevices,
        valueColor: 'green',
      },
    ],
    [data, t],
  );

  return (
    <>
      {stats?.map(({ key, title, value, valueColor }) => (
        <Col key={key} xs={24} sm={12} lg={8}>
          <DashboardStatItem
            title={title}
            value={value}
            valueColor={valueColor}
          />
        </Col>
      ))}
    </>
  );
};

export default DashboardStats;
