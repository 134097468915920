import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Carousel from '@src/components/Carousel/index';
import { Image, Row, Col } from 'antd';
import SessionContext from '@src/store/SessionContext/SessionContext';
import bannerApp from '@images/main-page/banner-app.jpg';
import bannerCommercial from '@images/main-page/banner-commercial.jpg';
import bannerFinancial from '@images/main-page/banner-financial.jpg';
import bannerSupport from '@images/main-page/banner-support.jpg';
import bannerTip from '@images/main-page/banner-tip.jpg';
import allRoutes from '@src/config/app';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import { useFilterRoutes } from '@src/components/RoleChecker/RoleChecker';

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('main-container');
  const { isAllcom, isHostAllcom, isBroker, bannersData, enableHomePage } =
    useContext(SessionContext);
  const [previewCarousel, setPreviewCarousel] = useState();

  const routes = useMemo(
    () =>
      allRoutes(t)?.map(groupRoute => {
        if (groupRoute && groupRoute?.url === '/management') {
          return {
            ...groupRoute,
            children: groupRoute?.children?.filter(route => {
              const isPageHome = route?.url === '';
              return !isPageHome || (isPageHome && enableHomePage);
            }),
          };
        }
        return groupRoute;
      }),
    [enableHomePage, t],
  );

  const filteredRoutes = useFilterRoutes({ routes });

  useEffect(() => {
    if (
      enableHomePage !== undefined &&
      !enableHomePage &&
      filteredRoutes?.length > 0
    ) {
      navigate(filteredRoutes[0]?.children?.[0]?.url?.replace('/', ''));
    }
  }, [enableHomePage, filteredRoutes, navigate]);

  const handleOnClickLink = useCallback(
    link => window.open(link, '_blank'),
    [],
  );

  const handleOnClickImg = useCallback(
    fileName =>
      setPreviewCarousel({
        visible: true,
        imgSrc: `/banners/${fileName}`,
      }),
    [],
  );

  const handleBanner = useCallback(
    bannersList =>
      bannersList?.map(banner => {
        const isLink = !!banner?.link && banner?.link !== '';
        const hasExtraDetails = isLink || banner?.fileDetailName;

        const defaultProps = {
          key: banner?.fileName,
          imgSrc: `/banners/${banner?.fileName}`,
          buttonText: banner?.buttonText,
          allowButton: !!banner?.link || !!banner?.fileDetailName,
        };

        const handleOnClick = () => {
          if (isLink) {
            handleOnClickLink(banner?.link);
          } else {
            handleOnClickImg(banner?.fileDetailName);
          }
        };

        if (hasExtraDetails) {
          if (banner?.buttonText) {
            defaultProps.buttonOnClick = handleOnClick;
          } else {
            defaultProps.imgOnClick = handleOnClick;
          }
        }

        return defaultProps;
      }),
    [handleOnClickImg, handleOnClickLink],
  );

  const carouselData = useMemo(
    () => ({
      banners: handleBanner(bannersData?.banners),
      parentBanners: handleBanner(bannersData?.parentBanners),
    }),
    [bannersData?.banners, bannersData?.parentBanners, handleBanner],
  );

  const imagesLink = useMemo(
    () => [
      {
        src: bannerApp,
        col: {
          lg: 14,
          xs: 24,
        },
        link: 'https://lp.allcomtelecom.com/download-app',
      },
      {
        src: bannerTip,
        col: {
          lg: 10,
          xs: 24,
        },
        link: 'https://ig.rdstation.com/allcomtelecom',
      },
    ],
    [],
  );

  const imagesContact = useMemo(
    () => [bannerCommercial, bannerSupport, bannerFinancial],
    [],
  );

  const showParentBanners = useMemo(
    () => !isBroker || (isBroker && isHostAllcom),
    [isBroker, isHostAllcom],
  );

  const showFixedAllcomBanners = useMemo(
    () => isAllcom && isHostAllcom,
    [isAllcom, isHostAllcom],
  );

  return (
    <S.Container>
      <Carousel data={carouselData?.banners} />

      {showParentBanners && (
        <Carousel
          data={carouselData?.parentBanners}
          style={{ marginTop: 16 }}
        />
      )}

      {showFixedAllcomBanners && (
        <>
          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            {imagesLink?.map(({ src, col, link }) => (
              <Col key={link} {...col}>
                <Image
                  src={src}
                  className="banner-img-link"
                  preview={false}
                  onClick={() => handleOnClickLink(link)}
                />
              </Col>
            ))}
          </Row>

          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            {imagesContact?.map(src => (
              <Col key={src} lg={8} xs={24}>
                <Image src={src} preview={false} />
              </Col>
            ))}
          </Row>
        </>
      )}

      <Image
        src={previewCarousel?.imgSrc}
        height={0}
        preview={{
          visible: previewCarousel?.visible,
          onVisibleChange: visible => setPreviewCarousel({ visible }),
        }}
      />
    </S.Container>
  );
};

export default Home;
