import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@components/ui';
import {
  Col,
  Row,
  Skeleton,
  Divider,
  Form,
  Input,
  message,
  Button,
  Tooltip,
  Select,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@src/hooks/useSwr';
import { PlusCircleOutlined } from '@ant-design/icons';

import BackToListing from '@src/components/BackToListing/BackToListing';
import apiMiddleware from '@src/services/apiMiddleware';
import AdminActions from '@src/components/AdminActions/index';
import Table from '@components/Table';
import VehicleModal from './VehicleModal/index';
import { statesOfBrazil } from '@src/utils/statesToList';
import { maskPhone } from '@src/utils/formatters';

const { Item } = Form;
const { Option } = Select;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30'],
  style: { marginRight: 20 },
};

const CustomerAstroDetails = () => {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [vehicleSelected, setVehicleSelected] = useState();
  const { customerId } = useParams();
  const { t } = useTranslation(['schedules', 'customers']);

  const { data: customerToBeEdited, mutate: mutateCustomerEdited } = useSwr(
    `/service-proxy/astro/client/${customerId}`,
  );

  const { data: vehiclesData, mutate: vehiclesDataMutate } = useSwr(
    `/service-proxy/astro/vehicles?clientId=${customerId}`,
    {
      page: pagination.current ? pagination.current - 1 : 0,
      per_page: pagination.pageSize,
    },
  );

  const [form] = Form.useForm();
  const [selectedUF, setSelectedUF] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const { data: citiesData } = useSwr(
    selectedUF ? `/service-proxy/astro/cities?uf=${selectedUF}` : null,
  );

  const updateCustomer = useCallback(
    payload => apiMiddleware.put(`/service-proxy/astro/client`, payload),
    [],
  );

  const onFinish = useCallback(async () => {
    form.validateFields().then(async updatedCustomer => {
      try {
        const payload = {
          ...customerToBeEdited,
          ...updatedCustomer,
          state: selectedUF,
          city: selectedCity,
          wppNumber: updatedCustomer.wppNumber
            ? [updatedCustomer.wppNumber.replace(/\D/g, '')]
            : null,
          phoneNumber: updatedCustomer.phoneNumber
            ? [updatedCustomer.phoneNumber.replace(/\D/g, '')]
            : null,
        };

        await updateCustomer(payload);
        mutateCustomerEdited(payload);
        close(false);
        message.success(t('customers:msgs.success-updating-customer'));
      } catch {
        message.error(t('customers:msgs.error-updating-customer'));
      }
    });
  }, [
    t,
    updateCustomer,
    form,
    customerToBeEdited,
    mutateCustomerEdited,
    selectedUF,
    selectedCity,
  ]);

  const deleteCustomer = useCallback(
    idToDelete =>
      apiMiddleware.delete(`/service-proxy/astro/vehicle/${idToDelete}`),
    [],
  );

  const handleMutateVehicles = useCallback(
    (type, data) => {
      if (vehiclesData) {
        if (type === 'CREATE') {
          vehiclesDataMutate({
            content: [...vehiclesData?.content, data],
          });
        } else if (type === 'UPDATE') {
          const index = vehiclesData?.content?.findIndex(
            vehicle => vehicle.idVeiculo === data.idVeiculo,
          );
          if (index !== -1) {
            const updatedVehiclesData = [...vehiclesData?.content];
            updatedVehiclesData[index] = data;
            vehiclesDataMutate(updatedVehiclesData);
          }
        } else {
          vehiclesDataMutate({
            content: [
              ...vehiclesData?.content?.filter(
                vehicle => vehicle.idVeiculo !== data.idVeiculo,
              ),
            ],
          });
        }
      }
    },
    [vehiclesData, vehiclesDataMutate],
  );

  const handleActions = useCallback(
    async (actionType, record) => {
      const isUpdate = actionType === 'UPDATE';
      if (isUpdate) {
        setIsModalVisible(true);
        setVehicleSelected(record);
      } else {
        try {
          await deleteCustomer(record.idVeiculo);
          handleMutateVehicles('DELETE', { id: record.idVeiculo });
          message.success(t('msgs.success-deleting'));
        } catch {
          message.error(t('msgs.error'));
        }
      }
    },
    [deleteCustomer, handleMutateVehicles, t],
  );

  const onClose = useCallback(() => {
    setIsModalVisible(false);
    setVehicleSelected(null);
  }, []);

  const handleNewVehicle = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const columnsDefault = useMemo(() => {
    return [
      {
        title: t('table.brand'),
        dataIndex: 'marca',
      },
      {
        title: t('table.model'),
        dataIndex: 'modelo',
      },
      {
        title: t('table.plate'),
        dataIndex: 'placa',
      },
      {
        title: t('table.action'),
        dataIndex: 'action',
        render: (value, record) => {
          return (
            <AdminActions
              alertDelete={t('alert.delete')}
              entityToExclude={record.modelo}
              onEdit={() => handleActions('UPDATE', record)}
              onDelete={() => handleActions('DELETE', record)}
            />
          );
        },
      },
    ];
  }, [t, handleActions]);

  const handleTableChange = useCallback(paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  }, []);

  useEffect(() => {
    if (customerToBeEdited) {
      form.setFieldsValue({
        ...customerToBeEdited,
        state: customerToBeEdited.state || undefined,
        city: customerToBeEdited.city || undefined,
        wppNumber:
          customerToBeEdited?.wppNumber &&
          customerToBeEdited?.wppNumber?.length > 0
            ? maskPhone(customerToBeEdited?.wppNumber[0])
            : null,
        phoneNumber:
          customerToBeEdited?.phoneNumber &&
          customerToBeEdited?.phoneNumber?.length > 0
            ? maskPhone(customerToBeEdited?.phoneNumber[0])
            : null,
      });
      setSelectedUF(customerToBeEdited.state);
      setSelectedCity(customerToBeEdited.city);
    }
  }, [customerToBeEdited, form]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  return (
    <>
      <VehicleModal
        mutateData={handleMutateVehicles}
        onClose={onClose}
        visible={isModalVisible}
        vehicleData={vehicleSelected}
        customerId={customerId}
      />
      <Skeleton loading={!customerToBeEdited} active>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col lg={24} sm={24} xs={24}>
            <Card title={<BackToListing title={t('clientsDetails')} />}>
              <Form
                colon={false}
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                scrollToFirstError
                onFinish={onFinish}
              >
                <Row gutter={[16, 16]}>
                  <div style={{ width: '100%', opacity: '0.6' }}>
                    <Divider orientation="left">
                      {t('dividers.identification')}
                    </Divider>
                  </div>

                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.firstName')}
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input />
                    </Item>

                    <Item
                      label={t('table.lastName')}
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>

                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.cpfCnpj')}
                      name="cpfCnpj"
                      normalize={value =>
                        value ? value.replace(/\D/g, '') : ''
                      }
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input maxLength={30} />
                    </Item>

                    <Item
                      label={t('table.email')}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <div style={{ width: '100%', opacity: '0.6' }}>
                    <Divider orientation="left">
                      {t('dividers.address')}
                    </Divider>
                  </div>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.cep')}
                      name="cep"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input maxLength={50} />
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.streetName')}
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input.Group compact>
                        <Item
                          name="streetName"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: t('form.msgs.fillThisField'),
                            },
                          ]}
                        >
                          <Input
                            autoComplete="address-line1"
                            maxLength={100}
                            style={{ width: '70%' }}
                          />
                        </Item>
                        <Item
                          name="streetNumber"
                          normalize={value => value.replace(/\D/g, '')}
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: t('form.msgs.fillThisField'),
                            },
                          ]}
                        >
                          <Input
                            maxLength={10}
                            required={false}
                            style={{ width: '30%' }}
                          />
                        </Item>
                      </Input.Group>
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item label={t('table.complement')} name="complement">
                      <Input autoComplete="address-line2" maxLength={100} />
                    </Item>
                  </Col>

                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.country')}
                      labelAlign="right"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input autoComplete="country-name" maxLength={50} />
                    </Item>
                  </Col>

                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.state')}
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Select onChange={value => setSelectedUF(value)}>
                        {statesOfBrazil.map(state => (
                          <Option key={state.value} value={state.value}>
                            {state.label}
                          </Option>
                        ))}
                      </Select>
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.city')}
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Select
                        onChange={value => {
                          setSelectedCity(value);
                        }}
                        optionFilterProp="children"
                        options={citiesData?.data?.map(city => ({
                          label: city.cidade,
                          value: city.cidade,
                        }))}
                        filterOption={(input, option) => {
                          return (
                            String(option?.label)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        showSearch
                        disabled={!citiesData}
                      />
                    </Item>
                    {!citiesData && selectedUF && (
                      <Alert
                        message="Ainda não estamos atendendo neste estado"
                        type="warning"
                        showIcon
                      />
                    )}
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <div style={{ width: '100%', opacity: '0.6' }}>
                    <Divider orientation="left">
                      {t('dividers.company')}
                    </Divider>
                  </div>

                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.ocupation')}
                      name="ocupation"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.fantasyName')}
                      name="fantasyName"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.corporateName')}
                      name="corporateName"
                      rules={[
                        {
                          required: true,
                          message: t('form.msgs.fillThisField'),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.wppNumber')}
                      name="wppNumber"
                      rules={[
                        {
                          min: 14,
                          max: 15,
                          message: t('form.msgs.invalidNumber'),
                        },
                      ]}
                    >
                      <Input
                        maxLength={15}
                        onChange={e =>
                          form.setFieldsValue({
                            wppNumber: maskPhone(e.target.value),
                          })
                        }
                      />
                    </Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Item
                      label={t('table.phoneNumber')}
                      name="phoneNumber"
                      rules={[
                        {
                          min: 14,
                          max: 15,
                          message: t('form.msgs.invalidNumber'),
                        },
                      ]}
                    >
                      <Input
                        maxLength={15}
                        onChange={e =>
                          form.setFieldsValue({
                            phoneNumber: maskPhone(e.target.value),
                          })
                        }
                      />
                    </Item>
                  </Col>
                </Row>
                <Row justify="end" align="end">
                  <Col>
                    <Button htmlType="submit" type="primary">
                      {t('button.save')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <Table
              columns={columnsDefault}
              allColumns={columnsDefault}
              data={vehiclesData?.content || []}
              loading={!vehiclesData?.content}
              title={<>{t('vehicles')}</>}
              tableKey="vehicles"
              pagination={pagination}
              onChange={handleTableChange}
              extraActions={
                <>
                  <Tooltip placement="top" title={t('modal.title.newVehicle')}>
                    <Button
                      icon={<PlusCircleOutlined />}
                      size="large"
                      type="link"
                      onClick={() => handleNewVehicle()}
                    />
                  </Tooltip>
                </>
              }
            />
          </Col>
        </Row>
      </Skeleton>
    </>
  );
};

export default CustomerAstroDetails;
