import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Tag, Space, Input, Col, ConfigProvider, Select } from 'antd';
import Table from '@components/Table';
import { useTranslation } from 'react-i18next';
import { dateFormat, formatCurrency } from '@utils/formatters';
import 'moment/locale/pt-br';
import useSwr from '@src/hooks/useSwr';
import ptBR from 'antd/es/locale/pt_BR';
import BackToListing from '@src/components/BackToListing/BackToListing';
import useSearchParams from '@src/hooks/useSearchParams';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import { Label } from '@components/ui';
import moment from 'moment';
import { getDateInISOFormat } from '@utils/formatters';
import SessionContext from '@src/store/SessionContext/SessionContext';
import SelectCustomers from '@src/components/SelectCustomers/index';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const DetailedInvoiceActions = () => {
  const { hasCustomersRoles } = useContext(SessionContext);
  // const { protocol: protoclParams } = useParams();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [showFilters, setShowFilters] = useState(false);
  const [description, setDescription] = useState(false);
  const [protocol, setProtocol] = useState('');
  const [protocolValue, setProtocolValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [createdByValue, setCreatedByValue] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [invoiceIdValue, setInvoiceIdValue] = useState('');
  const [actionType, setActionType] = useState();
  const [statusSearch, setStatusSearch] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const { t } = useTranslation('invoices-actions');

  const actionsTypesOptions = useMemo(() => {
    return [
      { label: t('action-type.ADD_ITEM'), value: 'ADD_ITEM' },
      { label: t('action-type.EDIT_ITEM'), value: 'EDIT_ITEM' },
      { label: t('action-type.REMOVE_ITEM'), value: 'REMOVE_ITEM' },
    ];
  }, [t]);

  const statusOptions = useMemo(() => {
    return [
      { label: t('status.SUCCESS'), value: 'SUCCESS' },
      { label: t('status.QUEUED'), value: 'QUEUED' },
      { label: t('status.CUSTOMER_NOT_FOUND'), value: 'CUSTOMER_NOT_FOUND' },
      { label: t('status.INVOICE_NOT_FOUND'), value: 'INVOICE_NOT_FOUND' },
      { label: t('status.INSERT_ERROR'), value: 'INSERT_ERROR' },
      { label: t('status.UNEXPECTED_ERROR'), value: 'UNEXPECTED_ERROR' },
      { label: t('status.ITEM_NOT_FOUND'), value: 'ITEM_NOT_FOUND' },
      { label: t('status.ITEM_NOT_EDITABLE'), value: 'ITEM_NOT_EDITABLE' },
      { label: t('status.ITEM_NOT_REMOVABLE'), value: 'ITEM_NOT_REMOVABLE' },
      { label: t('status.ERROR_REMOVING_ITEM'), value: 'ERROR_REMOVING_ITEM' },
      {
        label: t('status.ACTION_ALREADY_QUEUED'),
        value: 'ACTION_ALREADY_QUEUED',
      },
    ];
  }, [t]);

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'description',
        setState: setDescription,
        inTheFilters: true,
      },
      {
        name: 'createdBy',
        setState: setCreatedBy,
        inTheFilters: true,
      },
      {
        name: 'customerId',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'initialDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
      {
        name: 'invoiceId',
        setState: setInvoiceId,
        inTheFilters: true,
      },
      {
        name: 'actionType',
        setState: setActionType,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatusSearch,
        inTheFilters: true,
      },
      {
        name: 'protocol',
        setState: setProtocol,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const { data } = useSwr(
    `/service-proxy/broker/invoice-actions`,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      protocol,
      description,
      createdBy,
      actionType,
      status: statusSearch,
      invoiceId,
      customerId: selectedClient,
      startDate,
      endDate,
    },
    { refreshInterval: 10 * 2000 },
  );

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: t('table.customer'),
        dataIndex: 'customer',
        render: customer => customer?.name,
      },
      { title: t('table.protocol'), dataIndex: 'protocol' },
      { title: t('table.invoiceId'), dataIndex: 'invoiceId' },
      {
        title: t('table.actionType'),
        dataIndex: 'actionType',
        render: actionType => <span>{t(`action-type.${actionType}`)}</span>,
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        render: status => (
          <span>
            <Tag
              color={
                status === 'SUCCESS'
                  ? 'green'
                  : status === 'QUEUED'
                  ? 'blue'
                  : 'volcano'
              }
            >
              {t(`status.${status}`)}
            </Tag>
          </span>
        ),
      },
      { title: t('table.createdBy'), dataIndex: 'createdBy' },
      {
        title: t('table.itemId'),
        dataIndex: 'itemId',
      },
      {
        title: t('table.description'),
        dataIndex: 'description',
      },
      {
        title: t('table.createdAt'),
        dataIndex: 'createdAt',
        render: value => dateFormat(value),
      },
      {
        title: t('table.processedAt'),
        dataIndex: 'processedAt',
        render: value => dateFormat(value),
      },
      {
        title: t('table.value'),
        dataIndex: 'value',
        render: value => formatCurrency(Number(value)),
      },
      { title: t('table.quantity'), dataIndex: 'quantity' },
    ];

    return defaultColumns;
  }, [t]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const start = getDateInISOFormat(initialDate);
      const end = getDateInISOFormat(endDate);
      handleSetSearchParams({ initialDate: start, endDate: end });
    },
    [handleSetSearchParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: hasCustomersRoles,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.customer'),
        item: (
          <SelectCustomers
            value={selectedClient && Number(selectedClient)}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.protocol'),
        item: (
          <Search
            placeholder={t('table.protocol')}
            onSearch={value => handleSetSearchParams({ protocol: value })}
            onChange={({ target: { value } }) => setProtocolValue(value)}
            value={protocolValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.description'),
        item: (
          <Search
            placeholder={t('table.description')}
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.invoiceId'),
        item: (
          <Search
            placeholder={t('table.invoiceId')}
            onSearch={value => handleSetSearchParams({ invoiceId: value })}
            onChange={({ target: { value } }) => setInvoiceIdValue(value)}
            value={invoiceIdValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        label: t('table.status'),
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        item: (
          <Select
            optionFilterProp={'label'}
            options={statusOptions}
            placeholder={t('table.status')}
            style={{ width: '100%' }}
            value={statusSearch}
            allowClear
            showSearch
            onChange={value => handleSetSearchParams({ status: value })}
          />
        ),
      },
      {
        visible: true,
        label: t('table.actionType'),
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        item: (
          <Select
            optionFilterProp={'label'}
            options={actionsTypesOptions}
            placeholder={t('table.actionType')}
            style={{ width: '100%' }}
            value={actionType}
            allowClear
            showSearch
            onChange={value => handleSetSearchParams({ actionType: value })}
          />
        ),
      },
      {
        visible: true,
        label: t('table.createdBy'),
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        item: (
          <Search
            placeholder={t('table.createdBy')}
            onSearch={value => handleSetSearchParams({ createdBy: value })}
            onChange={({ target: { value } }) => setCreatedByValue(value)}
            value={createdByValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.date'),
        item: (
          <ConfigProvider locale={ptBR}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate
                  ? [moment(startDate, 'YYYYMMDD'), moment(endDate, 'YYYYMMDD')]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
    ],
    [
      handleSetSearchParams,
      t,
      descriptionValue,
      selectedClient,
      startDate,
      endDate,
      handleDateChange,
      createdByValue,
      invoiceIdValue,
      actionType,
      statusSearch,
      actionsTypesOptions,
      statusOptions,
      hasCustomersRoles,
      protocolValue,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [
    description,
    protocol,
    selectedClient,
    createdBy,
    invoiceId,
    actionType,
    statusSearch,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  useEffect(() => {
    setDescriptionValue(description);
  }, [description]);

  useEffect(() => {
    setProtocolValue(protocol);
  }, [protocol]);

  return (
    <>
      <Table
        title={
          <Space>
            <BackToListing title={t('title-details')} />
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </Space>
        }
        data={data?.content}
        columns={columns}
        loading={!data}
        pagination={pagination}
        onChange={handleTableChange}
        tableKey={'id'}
        extraFilters={
          showFilters && (
            <>
              <FiltersCard>
                {tableFilters?.map(
                  ({ col: { lg, xl, xs }, label, item, visible }) =>
                    visible && (
                      <Col key={label} lg={lg} xl={xl} xs={xs}>
                        {label && (
                          <div>
                            <Label color={'#575962'} htmlFor={label}>
                              {label}
                            </Label>
                          </div>
                        )}
                        {item}
                      </Col>
                    ),
                )}
              </FiltersCard>
            </>
          )
        }
      />
    </>
  );
};

export default DetailedInvoiceActions;
