import styled, { css } from 'styled-components';
import { List } from 'antd';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

const { Item } = List;

export const ListContainer = styled(Item)`
  padding: 0 !important;
  display: block !important;
`;

export const ListHeader = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid transparent !important;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: 0.3s ease;

  &:hover {
    background: #fafafa;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: #f4f4f4;
      border-radius: 4px 4px 0 0;
      border-bottom-color: #ccc !important;

      &:hover {
        background: #f6f6f6;
      }
    `}
`;

export const ListContent = styled.div`
  margin: 8px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  background: #fafafa;

  pre {
    margin: 0;
  }
`;

export const ArrowIcon = styled(MdOutlineKeyboardArrowDown)`
  transition: 0.3s;
  transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`} scale(1.2);
`;
