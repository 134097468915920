import React, { useContext, useMemo } from 'react';
import LabelModel from '../LabelModel/LabelModel';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import SessionContext from '@src/store/SessionContext/SessionContext';
import SelectContextItem from '../SelectContext/SelectContextItem/SelectContextItem';

const SelectCustomers = ({ showLabel, isRequired, ...rest }) => {
  const { t } = useTranslation(['attributes', 'placeholders']);
  const {
    customer: { customersData },
  } = useContext(SessionContext);

  const customSelectOptions = useMemo(() =>
    customersData?.map(customer => ({
      value: customer.id,
      label: <SelectContextItem id={customer.id} {...customer} />,
      filterProps: `${customer.name}${customer.tradingName ?? ''}${
        customer.cpfCnpj
      }${customer.id}`,
    })),
  );

  return (
    <>
      {showLabel && (
        <LabelModel isRequired={isRequired} text={t('attributes:client')} />
      )}
      <Select
        dropdownClassName="select-context-switch"
        optionFilterProp={'label'}
        placeholder={t('placeholders:client')}
        style={{ width: '100%' }}
        options={customSelectOptions}
        loading={!customSelectOptions}
        disabled={!customSelectOptions}
        allowClear
        showSearch
        filterOption={(input, option) => {
          const verifyString = string =>
            String(string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          return verifyString(option?.filterProps);
        }}
        {...rest}
      />
    </>
  );
};

export default SelectCustomers;
