import React, { useCallback } from 'react';
import moment from 'moment';

import DatePicker from '../DatePicker';

import './styles.less';
import { useTranslation } from 'react-i18next';

const QuickRangePicker = ({ onChange, rangesDefault, ...rest }) => {
  const { t } = useTranslation('date-picker');

  const handleDate = useCallback(
    date => {
      onChange({
        initialDate: date && date[0].toISOString(),
        endDate: date && date[1].toISOString(),
      });
    },
    [onChange],
  );

  const ranges = {
    [t('today')]: [
      moment({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
      moment(),
    ],
    [t('one-week-ago')]: [moment().subtract(7, 'days'), moment()],
    [t('two-weeks-ago')]: [moment().subtract(14, 'days'), moment()],
    [t('three-weeks-ago')]: [moment().subtract(21, 'days'), moment()],
    [t('one-month-ago')]: [moment().subtract(1, 'months'), moment()],
    [t('two-months-ago')]: [moment().subtract(2, 'months'), moment()],
    [t('three-months-ago')]: [moment().subtract(3, 'months'), moment()],
    [t('six-months-ago')]: [moment().subtract(6, 'months'), moment()],
    [t('nine-months-ago')]: [moment().subtract(9, 'months'), moment()],
  };

  return (
    <DatePicker
      allowClear
      range
      onChange={handleDate}
      ranges={rangesDefault ? rangesDefault : ranges}
      {...rest}
    />
  );
};

export default QuickRangePicker;
