import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Button, Popconfirm, Card } from 'antd';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import EditableCell from './EditableCell/EditableCell';
import EditableRow from './EditableRow/EditableRow';

const EditableTable = ({
  columns,
  data,
  tableData,
  oneLine,
  tableKey,
  allowDelete,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [defaultData, setDefaultData] = useState(data ? data[0] : {});
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(2);

  const { t } = useTranslation('table');

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const handleDelete = useCallback(
    key => {
      const newData = dataSource.filter(item => item.key !== key);
      setDataSource(newData);
      tableData(newData);
    },
    [dataSource, tableData],
  );

  const handleAdd = useCallback(() => {
    const { key, ...object } = defaultData;
    const newData = [
      ...dataSource,
      {
        key: count,
        ...object,
      },
    ];
    setDataSource(newData);
    tableData(newData);
    setCount(oldCount => oldCount + 1);
  }, [count, defaultData, dataSource, tableData]);

  const handleSave = useCallback(
    row => {
      const newData = [...dataSource];
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setDataSource(newData);
      tableData(newData);
    },
    [dataSource, tableData],
  );

  const columnsData = useMemo(() => {
    const allColumns = [...columns];

    if (allowDelete) {
      allColumns.push({
        align: 'center',
        render: (_, record) => {
          return (
            dataSource.length > 0 && (
              <Popconfirm
                title={t('editable.delete-confirm')}
                cancelText={t('editable.no')}
                okText={t('editable.yes')}
                onConfirm={() => handleDelete(record.key)}
              >
                <Button type="link" size="large" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )
          );
        },
      });
    }

    return allColumns;
  }, [columns, dataSource, handleDelete, t, allowDelete]);

  const columnsTable = useMemo(
    () =>
      columnsData.map(col => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            isRequired: col.isRequired,
          }),
        };
      }),
    [columnsData, handleSave],
  );

  return (
    <Card
      bodyStyle={{
        padding: 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 16,
        }}
      >
        <Button
          type="link"
          icon={<QuestionCircleOutlined />}
          style={{ opacity: 0.9, cursor: 'default' }}
        >
          {t('editable.help')}
        </Button>
        {!oneLine && (
          <Button onClick={handleAdd} type="primary">
            {t('editable.add-row')}
          </Button>
        )}
      </div>
      <Table
        dataSource={dataSource}
        columns={columnsTable}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        rowKey={tableKey}
        style={{ width: '100%' }}
        pagination={false}
        bordered
      />
    </Card>
  );
};

export default EditableTable;
