import React from 'react';

import { Button, Tooltip, Row, Card } from 'antd';

import { FilterOutlined, ClearOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './styles.less';

export const Filters = ({
  showFilters,
  setShowFilters,
  primaryColor,
  title,
  secondaryTitle,
  icon,
}) => {
  const { t } = useTranslation('table');
  const filterIcon = showFilters ? <ClearOutlined /> : <FilterOutlined />;
  const filterTooltip = showFilters ? t('clean-filters') : t('filters');

  return (
    <>
      {title}
      <Tooltip title={secondaryTitle ?? filterTooltip}>
        <Button
          icon={icon ?? filterIcon}
          type={showFilters ? 'primary' : 'link'}
          onClick={() => setShowFilters(oldValue => !oldValue)}
          className="filters-button"
          style={{ backgroundColor: primaryColor }}
        />
      </Tooltip>
      {showFilters && (
        <span className="filters-title">{secondaryTitle ?? t('filters')}</span>
      )}
    </>
  );
};

export const FiltersCard = ({ children, className, ...props }) => {
  return (
    <Card className={`filters-card ${className}`} {...props}>
      <Row align={'bottom'} gutter={[16, 16]}>
        {children}
      </Row>
    </Card>
  );
};
