import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Button, notification, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { resizeImageRatio } from '@src/utils/resizeImageRatio';
import {
  formatCpfCnpj,
  formatCurrency,
  maskPhone,
} from '@src/utils/formatters';
import SessionContext from '@src/store/SessionContext/SessionContext';
import apiMiddleware from '@src/services/apiMiddleware';

function PdfDetailedInvoice({ customerId, invoiceId }) {
  const { t } = useTranslation('invoices');
  const { logoImgName, mainColor } = useContext(SessionContext);

  const [image, setImage] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [downloadProgress, setDownloadProgress] = useState(false);

  useEffect(() => {
    if (logoImgName) {
      const img = new Image();
      img.src = `/images/${logoImgName}`;
      img.onload = function () {
        const { width, height } = resizeImageRatio(
          this.width,
          this.height,
          48,
          48,
        );
        setImageWidth(width);
        setImageHeight(height);
      };
      setImage(img);
    }
  }, [logoImgName]);

  const header = useCallback(
    (doc, data) => {
      const domain = data.customer?.domain;

      if (domain && imageWidth && imageHeight) {
        doc.addImage(image, 'PNG', 15, 15, imageWidth, imageHeight);
      }

      const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.text(t('pdf.header.simplifiedInvoice'), pageWidth / 2, 40, 'center');

      const subtitleLines = [
        {
          label: 'usePeriod',
          value: `${data.dataInicio} ${t('pdf.header.to')} ${data.dataTermino}`,
        },
        {
          label: 'dueDate',
          value: data.dataVencimento,
        },
        {
          label: 'customer',
          value: data.dsRazaoSocial,
        },
      ];

      let size = 54;
      subtitleLines.forEach(({ label, value }) => {
        label = t(`pdf.header.${label}`) + ': ';

        doc.setFontSize(10);
        // label
        doc.setFont('helvetica', 'bold');
        doc.text(label, 14, size);
        // value
        doc.setFont('helvetica', 'normal');
        doc.text(value, 14 + doc.getTextWidth(label) + 2, size);

        size += 6;
      });
    },
    [image, imageHeight, imageWidth, t],
  );

  const handleDocText = useCallback(
    (attrList, joinMask = ' | ') =>
      attrList?.filter(attr => attr)?.join(joinMask),
    [],
  );

  const footer = useCallback(
    (doc, customer) => {
      if (customer) {
        let { name, cpfCnpj } = customer;
        if (cpfCnpj) {
          cpfCnpj = `CNPJ: ${formatCpfCnpj(customer.cpfCnpj)}`;
        }

        const lines = [
          {
            font: ['helvetica', 'bold'],
            text: handleDocText([name, cpfCnpj]),
          },
        ];

        if (customer.contact) {
          const { supportEmail, financialEmail } = customer.contact;
          let { phoneNumber } = customer.contact;

          if (phoneNumber) {
            phoneNumber = maskPhone(phoneNumber);
          }
          lines.push({
            font: ['helvetica', 'normal'],
            text: handleDocText([financialEmail, supportEmail, phoneNumber]),
          });
        }

        if (customer.address) {
          const { streetName, number, complement, neighborhood, city, state } =
            customer.address;
          lines.push({
            font: ['helvetica', 'normal'],
            text: `${handleDocText(
              [streetName, number, complement, neighborhood],
              ', ',
            )} | ${handleDocText([city, state], '-')}`,
          });
        }

        let size = 16; // Distance from the lines
        lines.forEach(line => {
          doc.setFont(...line.font);
          doc.text(
            line.text,
            14,
            doc.lastAutoTable.finalY > 260
              ? size
              : doc.lastAutoTable.finalY + size,
          );
          size += 6;
        });
      }
    },
    [handleDocText],
  );

  const handleTableData = useCallback(
    (data, columns) =>
      data?.map(item =>
        columns?.map(col => {
          switch (col) {
            case 'nuValorUnitario':
            case 'nuValorTotal':
              return formatCurrency(item[col] ?? 0);
            case 'nuQtdItem':
              return item[col] ?? 0;
            default:
              return item[col] ?? '';
          }
        }),
      ),
    [],
  );

  const createTable = useCallback(
    ({ doc, title, columns, data, foot }) => {
      doc.autoTable({
        didParseCell: table => {
          if (table?.cell?.raw?.content === title) {
            table.cell.styles.fillColor = mainColor;
          }
        },
        showHead: 'firstPage',
        showFoot: 'lastPage',
        theme: 'grid',
        margin: { top: 75, bottom: 5 },
        didDrawPage: data => {
          data.settings.margin.top = 5;
        },
        headStyles: {
          halign: 'center',
          textColor: 80,
          fillColor: [237, 241, 247],
          lineWidth: 0.1,
          color: 'red',
        },
        footStyles: {
          halign: 'center',
          textColor: 80,
          fillColor: [237, 241, 247],
          lineWidth: 0.1,
        },
        bodyStyles: { halign: 'center' },
        head: [
          [
            {
              content: t(`pdf.header.${title}`),
              colSpan: columns?.length,
              styles: {
                halign: 'center',
                textColor: 255,
                fillColor: [100, 140, 196],
              },
            },
          ],
          columns?.map(col => t(`pdf.header.${col}`)),
        ],
        body: handleTableData(data, columns),
        foot,
      });
    },
    [handleTableData, mainColor, t],
  );

  const toPdf = useCallback(async () => {
    setDownloadProgress(true);
    try {
      const { data } = await apiMiddleware.get(
        `/service-proxy/broker/${customerId}/invoices/${invoiceId}/summary`,
      );

      setDownloadProgress(false);

      const doc = new jsPDF();

      header(doc, data);

      const recurrentItems = data?.itens?.itensRecorrentes;
      const activationItems = data?.itens?.itensAtivacoes;
      const proRataItems = data?.itens?.itensProRata;
      const otherItems = data?.itens?.itensOutros;

      createTable({
        doc,
        title: 'monthlyPayment',
        columns: [
          'dsOperadora',
          'dsItem',
          'nuValorUnitario',
          'nuQtdItem',
          'nuValorTotal',
        ],
        data: recurrentItems?.listaItensRecorrentes,
        foot: [
          [
            {
              content: t('pdf.header.subtotal'),
              colSpan: 3,
            },
            {
              content: recurrentItems?.nuQtdItemSubTotal ?? 0,
            },
            {
              content: formatCurrency(recurrentItems?.nuValorSubTotal ?? 0),
            },
          ],
        ],
      });
      createTable({
        doc,
        title: 'activations',
        columns: [
          'dsOperadora',
          'nuValorUnitario',
          'nuQtdItem',
          'nuValorTotal',
        ],
        data: activationItems?.listaItensAtivacoes,
        foot: [
          [
            {
              content: t('pdf.header.subtotal'),
              colSpan: 2,
            },
            {
              content: activationItems?.nuQtdItemSubTotal ?? 0,
            },
            {
              content: formatCurrency(activationItems?.nuValorSubTotal ?? 0),
            },
          ],
        ],
      });
      createTable({
        doc,
        title: 'proportional',
        columns: ['dsOperadora', 'nuQtdItem', 'nuValorTotal'],
        data: proRataItems?.listaItensProRata,
        foot: [
          [
            {
              content: t('pdf.header.subtotal'),
            },
            {
              content: proRataItems?.nuQtdItemSubTotal ?? 0,
            },
            {
              content: formatCurrency(proRataItems?.nuValorSubTotal ?? 0),
            },
          ],
        ],
      });
      createTable({
        doc,
        title: 'others',
        columns: ['dsItem', 'nuQtdItem', 'nuValorTotal'],
        data: otherItems?.listaItensOutros,
        foot: [
          [
            {
              content: t('pdf.header.subtotal'),
              colSpan: 2,
            },
            {
              content: formatCurrency(otherItems?.nuValorSubTotal ?? 0),
            },
          ],
          [
            {
              content: t('pdf.header.totalInvoiceValue'),
              colSpan: 2,
            },
            {
              content: formatCurrency(data.nuValorFatura ?? 0),
            },
          ],
        ],
      });

      doc.lastAutoTable.finalY > 260 && doc.addPage();

      footer(doc, data?.customer);

      const fileName = (
        data.dsRazaoSocial +
        ' ' +
        data.dataVencimento +
        '.pdf'
      ).replace(/ /g, '_');

      doc.save(fileName);
    } catch (error) {
      notification.error({ description: t('errors.exporting') });
    }
  }, [customerId, invoiceId, header, createTable, t, footer]);

  return (
    <Tooltip title={downloadProgress && t('pdf.preparing-the-invoice-pdf')}>
      <Button
        icon={<DownloadOutlined />}
        style={{ marginRight: '1rem' }}
        type={'primary'}
        onClick={toPdf}
        disabled={downloadProgress}
        loading={downloadProgress}
      >
        {t('pdfExport')}
      </Button>
    </Tooltip>
  );
}

export default PdfDetailedInvoice;
