import React, { useCallback, useMemo } from 'react';

import { Tag, Popover, Divider } from 'antd';

const CustomPopover = ({
  title,
  titleValue,
  titleContent,
  dataContent,
  children,
  ...rest
}) => {
  const customTag = useCallback(
    (titleTag, value, color) => (
      <p style={{ margin: 4 }}>
        {`${titleTag}:`}
        <Tag
          color={color}
          style={{
            marginLeft: 8,
            fontSize: '1rem',
            fontWeight: 'bold',
            opacity: '0.9',
          }}
        >
          {value}
        </Tag>
      </p>
    ),
    [],
  );

  const customTitle = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
        }}
      >
        <span>{`${title}:`}</span>
        {titleValue && <Tag style={{ marginLeft: 6 }}>{titleValue}</Tag>}
      </div>
    ),
    [title, titleValue],
  );

  const customContent = useMemo(
    () => (
      <div>
        <div>
          {titleContent && (
            <Divider
              style={{
                opacity: '0.4',
                fontSize: '0.9rem',
                margin: 0,
                marginBottom: 8,
              }}
            >
              {titleContent.toUpperCase()}
            </Divider>
          )}
        </div>
        {dataContent?.map(
          ({ title, value, color, show }) =>
            show && customTag(title, value, color),
        )}
      </div>
    ),
    [dataContent, customTag, titleContent],
  );

  return (
    <Popover title={customTitle} content={customContent} {...rest}>
      {children}
    </Popover>
  );
};

export default CustomPopover;
