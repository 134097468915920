import React, { memo } from 'react';

import { Tooltip, Tag, Button, Divider } from 'antd';

import { FiLock, FiUnlock } from 'react-icons/fi';

import * as S from './styles';

const PadlockStatusColumn = ({
  isOpen,
  openText,
  closedText,
  openActionTooltip,
  closedActionTooltip,
  onAction,
}) => {
  const colorTag = isOpen ? 'green' : 'red';
  const iconTag = isOpen ? <FiUnlock /> : <FiLock />;
  const tagText = isOpen ? openText : closedText;
  const tooltipActionText = isOpen ? closedActionTooltip : openActionTooltip;

  return (
    <S.PadlockStatusContainer>
      <Tag className="padlock-status-tag" color={colorTag}>
        {tagText}
      </Tag>
      <Divider type="vertical" className="padlock-status-divider" />
      <Tooltip title={tooltipActionText}>
        <Button
          size="small"
          onClick={onAction}
          icon={iconTag}
          className="padlock-status-button"
          style={{
            borderColor: !isOpen && '#389e0d',
            color: !isOpen && '#389e0d',
          }}
          danger={isOpen}
        />
      </Tooltip>
    </S.PadlockStatusContainer>
  );
};

export default memo(PadlockStatusColumn);
