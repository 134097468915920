import React from 'react';

import { Divider, Tag, Tree } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const NetworkBlockUploadHelp = t => {
  return (
    <div
      style={{
        border: '1px solid #dbdbdb',
        borderRadius: 6,
      }}
    >
      <Divider orientation="left">
        {t('steps.network-block-unlock.types-of-blocks')}
      </Divider>
      <Tree
        style={{ padding: '0 8px' }}
        treeData={[
          {
            title: (
              <>
                <Tag>1</Tag>
                <ArrowRightOutlined />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  {t(
                    'steps.network-block-unlock.block-types.permanent',
                  ).toUpperCase()}
                </span>
              </>
            ),
            key: '0-0',
            children: [
              {
                title: (
                  <>
                    {t('steps.network-block-unlock.block-types.permanent-help')}
                    <p style={{ fontSize: '0.8rem', color: 'red' }}>
                      {`* MSISDN, ${t(
                        'steps.network-block-unlock.block-type',
                      )}`}
                    </p>
                  </>
                ),
                key: '0-0-0',
              },
            ],
          },
          {
            title: (
              <>
                <Tag>2</Tag>
                <ArrowRightOutlined />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  {t(
                    'steps.network-block-unlock.block-types.until-renovation',
                  ).toUpperCase()}
                </span>
              </>
            ),
            key: '0-1',
            children: [
              {
                title: (
                  <>
                    {t(
                      'steps.network-block-unlock.block-types.until-renovation-help',
                    )}
                    <p style={{ fontSize: '0.8rem', color: 'red' }}>
                      {`* MSISDN, ${t(
                        'steps.network-block-unlock.block-type',
                      )}`}
                    </p>
                  </>
                ),
                key: '0-0-1',
              },
            ],
          },
          {
            title: (
              <>
                <Tag>3</Tag>
                <ArrowRightOutlined />
                &nbsp;&nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'rgba(0, 0, 0, 0.8)',
                  }}
                >
                  {t(
                    'steps.network-block-unlock.block-types.temporary',
                  ).toUpperCase()}
                </span>
              </>
            ),
            key: '0-2',
            children: [
              {
                title: (
                  <>
                    {t('steps.network-block-unlock.block-types.temporary-help')}
                    <p style={{ fontSize: '0.8rem', color: 'red' }}>
                      {`* MSISDN, ${t(
                        'steps.network-block-unlock.block-type',
                      )}, ${t('steps.network-block-unlock.end-validity')}`}
                    </p>
                  </>
                ),
                key: '0-0-2',
              },
            ],
          },
        ]}
      />
      <Divider style={{ margin: '8px 0' }} />
      <p
        style={{
          color: 'red',
          fontSize: '0.8rem',
          textAlign: 'center',
        }}
      >{`* ${t(
        'steps.network-block-unlock.help-date-not-selected-in-upload',
      )}`}</p>
    </div>
  );
};

export default NetworkBlockUploadHelp;
