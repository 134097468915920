import { formatAddressFromGeoCode } from '@src/utils/formatters';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CgSearch } from 'react-icons/cg';
import { IoIosCloseCircle } from 'react-icons/io';

import './styles.less';

const CLOSE_ZOOM = 16;

function SearchBoxGoogle({
  googleMaps,
  searchValue,
  setSearchValue,
  onPlacesChanged,
  clearLocation,
}) {
  const inputRef = useRef(null);
  const searchBox = useRef(null);

  const { t } = useTranslation('maps');

  const [search, setSearch] = useState('');
  const [placeActive, setPlaceActive] = useState();

  useEffect(() => {
    if (searchValue) {
      setSearch(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (placeActive) {
      onPlacesChanged(placeActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeActive]);

  const handleOnPlacesChanged = useCallback(() => {
    const place = searchBox?.current?.getPlaces()?.[0];
    const pointLocation = place?.geometry?.location;
    const placeAddress = place?.formatted_address;
    setSearch(placeAddress);
    setSearchValue(placeAddress);
    if (pointLocation) {
      setPlaceActive({
        lat: pointLocation.lat(),
        lng: pointLocation.lng(),
        zoom: CLOSE_ZOOM,
      });
    }
  }, [setSearchValue]);

  useEffect(() => {
    if (!searchBox.current && googleMaps) {
      searchBox.current = new googleMaps.places.SearchBox(inputRef.current);
      searchBox.current.addListener('places_changed', handleOnPlacesChanged);
    }

    return () => {
      if (googleMaps) {
        searchBox.current = null;
        googleMaps.event.clearInstanceListeners(searchBox);
      }
    };
  }, [googleMaps, handleOnPlacesChanged]);

  useEffect(() => {
    if (googleMaps) {
      const searchParams = new URL(window.location.href).searchParams;
      const lat = searchParams.get('lat');
      const lng = searchParams.get('lng');
      if (lat && lng) {
        const geocoder = new googleMaps.Geocoder();
        geocoder
          .geocode({ location: { lat: Number(lat), lng: Number(lng) } })
          .then(({ results }) => {
            setSearchValue(
              formatAddressFromGeoCode(results[0]?.address_components),
            );
          });
      }
    }
  }, [setSearchValue, googleMaps]);

  const handleClearSearch = useCallback(() => {
    setSearch('');
    setSearchValue(undefined);
    clearLocation();
  }, [clearLocation, setSearchValue]);

  return (
    <div className="search-in-map">
      <input
        type="text"
        ref={inputRef}
        placeholder={t('placeholder')}
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
      />
      {search && (
        <span className="search-in-map-close" onClick={handleClearSearch}>
          <IoIosCloseCircle />
        </span>
      )}
      <CgSearch className="search-in-map-icon" />
    </div>
  );
}

export default SearchBoxGoogle;
