import { useCallback, useEffect, useMemo } from 'react';
import {
  deleteAttrInObj,
  getSearchParam,
  manageSearchParams,
  removeAttrFromAList,
} from '@src/utils/manageSearchParams';
import { useNavigate, useLocation } from 'react-router-dom';

const useSearchParams = (attributes, setShowFilters) => {
  const search = useLocation().search;
  const navigate = useNavigate();

  const allParams = useMemo(() => {
    const params = [];
    attributes?.forEach(attr => {
      params.push(attr.name);
    });
    return params;
  }, [attributes]);

  const paramsInUrl = useMemo(
    () => getSearchParam(search, allParams) ?? {},
    [search, allParams],
  );

  const handleClearParams = useCallback(
    (filters, except) => {
      let newSearchParams = {};

      if (filters) {
        if (except) {
          // removes all parameters except those informed in the filters
          newSearchParams = deleteAttrInObj(
            paramsInUrl,
            removeAttrFromAList(allParams, filters),
          );
        } else {
          // removes only the parameters informed in the filters
          newSearchParams = deleteAttrInObj(paramsInUrl, filters);
        }
      }

      manageSearchParams(navigate, newSearchParams);
    },
    [navigate, paramsInUrl, allParams],
  );

  const handleAttrIsInTheFilters = useCallback(() => {
    Object.keys(paramsInUrl).forEach(paramName => {
      const attrFromName = attributes.find(attr => attr.name === paramName);
      if (attrFromName.inTheFilters) {
        setShowFilters(true);
      }
    });
  }, [setShowFilters, attributes, paramsInUrl]);

  // Handle search params
  useEffect(() => {
    if (setShowFilters) {
      handleAttrIsInTheFilters();
    }

    attributes?.forEach(attr => {
      attr.setState(paramsInUrl[attr.name]);
      if (attr.setStateValue) {
        attr.setStateValue(paramsInUrl[attr.name]);
      }
    });
  }, [attributes, paramsInUrl, setShowFilters, handleAttrIsInTheFilters]);

  const handleSetSearchParams = useCallback(
    (values, paramsToDelete) => {
      let params = { ...paramsInUrl };
      if (paramsToDelete) {
        params = deleteAttrInObj(params, paramsToDelete);
      }
      manageSearchParams(navigate, {
        ...params,
        ...values,
      });
    },
    [paramsInUrl, navigate],
  );

  return {
    handleClearParams,
    handleSetSearchParams,
  };
};

export default useSearchParams;
