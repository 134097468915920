import styled from 'styled-components';

const LoginButton = styled.button`
  padding: 1.25rem 4rem;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  -webkit-appearance: button;
  line-height: 1.25;
  background-color: #9816f4;
  border: 1px solid #9816f4;
  border-radius: 60px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  box-shadow: 0 2px 5px 2px rgba(200, 200, 200, 0.36);
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
  :hover {
    background-color: #7c0acd;
    border-color: #7509c1;
    box-shadow: 0 5px 10px 2px rgba(152, 22, 244, 0.36);
  }
  :active {
    background-color: #7c0acd;
    border-color: #7509c1;
  }
  :disabled {
    background-color: #c7c7c7;
    border-color: #c7c7c7;
  }
  :disabled:hover {
    cursor: not-allowed;
    background-color: #c7c7c7;
    box-shadow: 0 2px 5px 2px rgba(200, 200, 200, 0.36);
  }
  @media (max-width: 768px) {
    padding: 0.8rem 3rem;
  }
`;

export default LoginButton;
