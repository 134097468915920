import { firstLetterUpper } from './formatters';

const treatedHostname = () => {
  const { hostname } = window.location;
  // get first hostname, Ex: 'allcom.iot' -> 'allcom'
  const host = hostname.split('.')[0];
  // capitalize first letter, Ex: 'allcom' -> 'Allcom'
  return firstLetterUpper(host);
};

const treatCustomerName = customerName => {
  if (customerName.length === 1) {
    return firstLetterUpper(customerName);
  }
  const splitCustomerName = customerName
    .split(' ')
    .filter(str => !['', '-', ','].includes(str) && str.length >= 2);

  if (splitCustomerName.length === 1) {
    return firstLetterUpper(splitCustomerName[0]);
  }

  return `${firstLetterUpper(splitCustomerName[0])} ${firstLetterUpper(
    splitCustomerName[1],
  )}`;
};

const treatPathnameRouteDetail = pathname => {
  let treatedPathname = '';
  const splitPathname = pathname.split('/');
  for (let index = 0; index < 3; index++) {
    if (splitPathname[index]) {
      treatedPathname += `/${splitPathname[index]}`;
    }
  }

  return `${treatedPathname}/id`;
};

export const getDocumentTitle = (customerLogged, t, detailName) => {
  const { pathname } = window.location;

  const isLocalhost = treatedHostname() === 'Localhost';
  const newHostname = isLocalhost ? 'Allcom' : treatedHostname();

  // details route: '/management/requests/123 -> ['', 'management', 'requests', '123']
  const isDetailsRoute = pathname.split('/').length > 3;

  let title = newHostname;

  if (isDetailsRoute) {
    title = `${detailName ? detailName + ' - ' : ''}${t(
      `tab-subtitles.${treatPathnameRouteDetail(pathname)}`,
    )}${customerLogged ? ` - ${treatCustomerName(customerLogged)}` : ''}`;
  } else {
    title = `${t(`tab-subtitles.${pathname}`)}${
      customerLogged ? ` - ${treatCustomerName(customerLogged)}` : ''
    }`;
  }

  document.title = title;
};
