import React, { useMemo } from 'react';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import rolesConstants from '@utils/rolesConstants';
import { LorawanActionTypes } from '@utils/deviceActions';

const useUplinkBlockUnlock = ({ devices, isUpload, form }) => {
  const { t } = useTranslation(['lorawan', 'device-actions']);
  const uplinkAction = Form.useWatch('uplinkAction', form);

  const actionTypeOptions = useMemo(
    () => [
      {
        label: t('block'),
        value: LorawanActionTypes.UPLINK_BLOCKING,
      },
      {
        label: t('unlock'),
        value: LorawanActionTypes.UPLINK_UNBLOCKING,
      },
    ],
    [t],
  );

  const steps = useMemo(
    () => [
      {
        title: t('whatDoYouWantToDo'),
        name: 'uplinkAction',
        showWhenUpload: true,
        content: (
          <Radio.Group optionType="button" options={actionTypeOptions} />
        ),
      },
    ],
    [actionTypeOptions, t],
  );

  const payloadToSend = useMemo(
    () => ({
      action: uplinkAction,
      devices: isUpload
        ? devices
        : devices?.map(device => ({
            devEui: device.devEui,
          })),
    }),
    [devices, isUpload, uplinkAction],
  );

  const title = useMemo(() => {
    const labelPrefix = uplinkAction ? 'by-key.' : '';
    const action = uplinkAction || rolesConstants.LORAWAN_UPLINK_BLOCK_UNLOCK;
    return t(`device-actions:labels.${labelPrefix}lorawan.${action}`);
  }, [t, uplinkAction]);

  return {
    steps,
    payloadToSend,
    title,
    totalDevices: devices?.length,
    uploadPosition: 1,
  };
};

export default useUplinkBlockUnlock;
