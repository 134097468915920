import React, { memo, useCallback, useMemo, useState } from 'react';

import {
  Modal,
  Button,
  DatePicker,
  Select,
  ConfigProvider,
  Col,
  Row,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ptBR from 'antd/es/locale/pt_BR';
import { Label } from '@components/ui';
import { handleFirstAndLastDayOfMonth } from '@src/utils/formatters';
import { useNavigate } from 'react-router-dom';
import apiMiddleware from '@src/services/apiMiddleware';

function RequestInvoiceReport({
  visible,
  invoicesCustomersOptions,
  statusOptions,
  onClose,
}) {
  const { t, i18n } = useTranslation('invoices');
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const [loadingRequest, setLoadingRequest] = useState();

  const requestReport = useCallback(
    () =>
      apiMiddleware.get(
        `/service-proxy/broker/invoices-report?locale=${i18n.language}`,
        { params },
      ),
    [params, i18n],
  );

  const handleSetPayload = useCallback(
    newValue =>
      setParams(oldPayload => ({
        ...oldPayload,
        ...newValue,
      })),
    [],
  );

  const handleClose = useCallback(() => {
    onClose();
    setParams({});
  }, [onClose]);

  const handleChangeDate = useCallback(
    date => {
      const newDate = {
        initialDate: undefined,
        endDate: undefined,
      };
      if (date) {
        const { firstDay, lastDay } = handleFirstAndLastDayOfMonth(date._d);

        newDate.initialDate = firstDay;
        newDate.endDate = lastDay;
      }
      handleSetPayload(newDate);
    },
    [handleSetPayload],
  );

  const navigateToReports = useCallback(() => {
    message.destroy('invoice-report');
    navigate(`/management/reports?type=financial`);
  }, [navigate]);

  const handleRequestReport = useCallback(async () => {
    try {
      setLoadingRequest(true);
      await requestReport();
      message.success({
        content: (
          <span>
            {t('report.msgs.request-success')}
            <Button
              onClick={navigateToReports}
              type="link"
              style={{ padding: 3 }}
            >
              {t('report.msgs.go-to')}
            </Button>
            {t('report.msgs.check-progress-and-download')}
          </span>
        ),
      });
    } catch {
      message.error(t('report.msgs.check-progress-and-download'));
    }
    handleClose();
    setLoadingRequest(false);
  }, [t, navigateToReports, requestReport, handleClose]);

  const modalInputs = useMemo(() => {
    const inputs = [
      {
        col: {
          lg: 8,
          xl: 8,
          xs: 24,
        },
        label: t('search.customer'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={invoicesCustomersOptions || []}
            placeholder={t('search.customerPlaceholder')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={params?.customerId}
            loading={!invoicesCustomersOptions}
            disabled={!invoicesCustomersOptions}
            onChange={value => handleSetPayload({ customerId: value })}
          />
        ),
      },
      {
        col: {
          lg: 8,
          xl: 8,
          xs: 24,
        },
        label: t('search.status'),
        item: (
          <Select
            options={statusOptions}
            placeholder={t('search.statusPlaceholder')}
            style={{ width: '100%' }}
            allowClear
            value={params?.status}
            onChange={value => {
              if (value === 'O') {
                handleChangeDate(moment().subtract(1, 'months'));
              } else {
                handleSetPayload({
                  initialDate: undefined,
                  endDate: undefined,
                });
              }

              handleSetPayload({ status: value });
            }}
          />
        ),
      },
    ];

    if (params.status && params.status === 'C') {
      inputs.push({
        col: {
          lg: 8,
          xl: 8,
          xs: 24,
        },
        label: t('search.datesRange'),
        item: (
          <ConfigProvider locale={ptBR}>
            <DatePicker
              format={'MMMM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={d =>
                d && d.add(1, 'months') > moment().endOf('day')
              }
              value={
                params?.endDate
                  ? moment(params?.endDate, 'YYYYMMDD')
                  : undefined
              }
              onChange={handleChangeDate}
              placeholder={t('search.datesRangePlaceholder')}
              picker="month"
            />
          </ConfigProvider>
        ),
      });
    }
    return inputs;
  }, [
    invoicesCustomersOptions,
    statusOptions,
    t,
    handleChangeDate,
    handleSetPayload,
    params,
  ]);

  return (
    <Modal
      bodyStyle={{
        paddingTop: 0,
      }}
      footer={
        <>
          <Button onClick={handleClose}>{t('report.buttons.cancel')}</Button>
          <Button
            type={'primary'}
            loading={loadingRequest}
            disabled={loadingRequest}
            onClick={handleRequestReport}
          >
            {t('report.buttons.request')}
          </Button>
        </>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerForm', htmlType: 'submit' }}
      style={{
        maxWidth: 900,
      }}
      title={t('report.request')}
      visible={visible}
      width={'80%'}
      centered
      onCancel={handleClose}
    >
      <Row gutter={[16, 16]} style={{ padding: 16 }}>
        {modalInputs?.map(({ col: { lg, xl, xs }, label, item }) => (
          <Col key={label} lg={lg} xl={xl} xs={xs}>
            {label && (
              <div>
                <Label color={'#575962'} htmlFor={label}>
                  {label}
                </Label>
              </div>
            )}
            {item}
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default memo(RequestInvoiceReport);
