import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form, Select, Input, Button, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined, FormOutlined } from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import SessionContext from '@src/store/SessionContext/SessionContext';

const { Option } = Select;

const ModalSender = ({ defaultValues, mutate }) => {
  const {
    customer: { customersOptionsIncludeMe },
  } = useContext(SessionContext);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { t: translate } = useTranslation('sms-send');

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (defaultValues) {
      form.setFieldsValue(defaultValues);
    }
  }, [defaultValues, form]);

  const validateMaxCharacters = (_, value) => {
    if (value && value.length > 15) {
      return Promise.reject(new Error(translate('sender-validator')));
    }
    return Promise.resolve();
  };

  const handleOk = () => {
    form.validateFields().then(async values => {
      try {
        if (defaultValues) {
          await apiMiddleware.put(
            `/service-proxy/sms-senders/${defaultValues.id}`,
            values,
          );

          message.success(translate('sender.updated'));
        } else {
          await apiMiddleware.post('/service-proxy/sms-senders', values);
          message.success(translate('sender.created'));
        }
        onClose();
      } catch (err) {
        onClose();
        message.error(translate('sender.error'));
      }
      mutate();
    });
  };

  return (
    <>
      <Tooltip
        title={
          defaultValues ? translate('sender.edit') : translate('sender.create')
        }
      >
        <Button
          type="link"
          onClick={() => setVisible(true)}
          icon={defaultValues ? <FormOutlined /> : <PlusCircleOutlined />}
        />
      </Tooltip>

      <Modal
        visible={visible}
        onCancel={onClose}
        onOk={handleOk}
        title={
          defaultValues ? translate('sender.edit') : translate('sender.create')
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item name="customerId" label={translate('sender.customerId')}>
            <Select
              placeholder={translate('sender.selectCustomer')}
              disabled={defaultValues}
              allowClear
            >
              {customersOptionsIncludeMe?.map(customer => (
                <Option value={customer.value} key={customer.value}>
                  {customer.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="sender"
            label={translate('sender.name')}
            rules={[
              { required: true, message: translate('sender.required') },
              { validator: validateMaxCharacters },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalSender;
