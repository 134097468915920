import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@components/ui';

import { Statistic, Progress, Empty } from 'antd';
import { formatBRNumber } from '@utils/formatters';
import useSwr from '@hooks/useSwr';
import { useNavigate } from 'react-router-dom';
import { getPercentage } from '@utils/getPercentage';
import { green, gold, red } from '@ant-design/colors';

const getColor = percentage => {
  if (percentage < 50) {
    return red[4];
  }
  if (percentage < 70) {
    return gold[5];
  }
  return green[5];
};

const Actives = ({ filters }) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const { data } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/count-by-status?status=ACTIVE`,
    { ...filters },
    {
      method: 'post',
    },
  );

  const percentage = useMemo(
    () => (data ? getPercentage(data?.matching, data.total) : 0),
    [data],
  );

  return (
    <Card
      loading={!data}
      className="card-click-filter"
      onClick={() => {
        navigate(
          `/management/inventory?showContracted=${filters.showContracted}&status=ACTIVE`,
        );
      }}
    >
      {data?.length === 0 || data?.total === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Statistic
            precision={0}
            suffix={`/ ${formatBRNumber(data?.total)}`}
            title={t('actives')}
            value={data?.matching}
            valueStyle={{
              color: getColor(percentage),
            }}
            formatter={value => {
              return formatBRNumber(value);
            }}
          />
          <Progress
            percent={percentage}
            showInfo={false}
            strokeColor={getColor(percentage)}
          />
        </>
      )}
    </Card>
  );
};

export default Actives;
