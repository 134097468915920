import React, { useCallback, useMemo } from 'react';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import copy from 'clipboard-copy';
import CopyButton from '@components/CopyButton/CopyButton';

import * as S from './styles';

const PrettyJSON = ({ json, keyColor }) => {
  const theme = useMemo(
    () => ({
      main: 'line-height:1.3;color:#888;background:transparent;overflow:auto;',
      error: 'line-height:1.3;color:#888;background:transparent;overflow:auto;',
      key: `color:${keyColor ?? 'green'}`,
      string: 'color:#f5870b;font-weight:bold',
      value: 'color:#6e7bf0;font-weight:bold',
      boolean: 'color:#6e7bf0;font-weight:bold',
    }),
    [keyColor],
  );

  const handleCopyClick = useCallback(() => {
    copy(json);
  }, [json]);

  return (
    <S.PrettyJSONContainer>
      <CopyButton onClick={handleCopyClick} />
      <JSONPretty data={json} space={3} theme={theme} />
    </S.PrettyJSONContainer>
  );
};

export default PrettyJSON;
