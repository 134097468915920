import React, { useMemo } from 'react';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { Statistic, Progress, Empty } from 'antd';
import { formatBRNumber } from '@utils/formatters';
import { useNavigate } from 'react-router-dom';
import useSwr from '@hooks/useSwr';
import { getPercentage } from '@utils/getPercentage';
import { green, red, gold } from '@ant-design/colors';

const getColor = percent => {
  if (percent < 5) {
    return green[5];
  }
  if (percent < 20) {
    return gold[5];
  }
  return red[4];
};

const TradeIn = ({ filters }) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const { data, error } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/count-by-status?status=TRADE_IN`,
    {
      ...filters,
    },
    {
      method: 'post',
    },
  );

  const percentage = useMemo(
    () => (data ? getPercentage(data.matching, data.total) : 0),
    [data],
  );

  return (
    <Card
      loading={!data}
      className="card-click-filter"
      onClick={() => {
        navigate(
          `/management/inventory?showContracted=${filters.showContracted}&status=TRADE_IN`,
        );
      }}
    >
      {error || !data ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Statistic
            precision={0}
            suffix={`/ ${formatBRNumber(data?.total)}`}
            title={t('trade-in')}
            value={data.matching}
            valueStyle={{ color: getColor(percentage) }}
            formatter={value => {
              return formatBRNumber(value);
            }}
          />
          <Progress
            percent={percentage}
            showInfo={false}
            strokeColor={getColor(percentage)}
          />
        </>
      )}
    </Card>
  );
};

export default TradeIn;
