import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Tooltip, Tabs, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import { bytesFormat, bytesToMbytes, dateFormat } from '@src/utils/formatters';
import rolesConstants from '@utils/rolesConstants';
import apiMiddleware from '@src/services/apiMiddleware';
import ModalCreateAlerts from './ModalCreateAlerts/index';

const { TabPane } = Tabs;
const { confirm } = Modal;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Alerts = () => {
  const { t } = useTranslation(['alerts', 'attributes']);

  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [active, setActive] = useState('true');

  const [page, setPage] = useState(DEFAULT_PAGINATION.current);
  const [linesPerPage, setLinesPerPage] = useState(DEFAULT_PAGINATION.pageSize);

  const { data, mutate } = useSwr(`/service-proxy/alerts`, {
    page: pagination?.current - 1,
    size: pagination?.pageSize,
    active: active === 'false' ? false : true,
  });

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'active',
        setState: setActive,
        inTheFilters: true,
      },

      {
        name: 'page',
        setState: setPage,
        inTheFilters: false,
      },
      {
        name: 'linesPerPage',
        setState: setLinesPerPage,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleSetSearchParams } = useSearchParams(paramsAttributes);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [active, linesPerPage]);

  useEffect(() => {
    if (data?.content) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data?.totalElements,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (page && linesPerPage) {
      setPagination(oldPagination => ({
        ...oldPagination,
        current: parseInt(page),
        pageSize: parseInt(linesPerPage),
      }));
    }
  }, [page, linesPerPage]);

  const handleDelete = useCallback(
    async id => {
      try {
        await apiMiddleware.delete(`/service-proxy/alerts/${id}`);
        message.success(t('msgs.alert-deleted'));
        mutate();
      } catch {
        message.error(t('msgs.alert-deletion-error'));
      }
    },
    [t, mutate],
  );

  const showDelConfirm = useCallback(
    (idToDelte, name) => {
      confirm({
        title: t('titles.delete-modal', { name }),
        icon: <ExclamationCircleOutlined />,
        content: t('titles.delete-modal-content'),
        centered: true,
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        okButtonProps: { type: 'primary', danger: true },
        onOk: () => handleDelete(idToDelte),
      });
    },
    [handleDelete, t],
  );

  const adminActions = useCallback(
    (text, record) => (
      <Button
        icon={<DeleteOutlined />}
        type={'link'}
        danger
        onClick={() => showDelConfirm(record.id, record.name)}
      />
    ),
    [showDelConfirm],
  );

  const columns = useMemo(
    () => [
      { title: 'ID', dataIndex: 'id', align: 'center' },
      {
        title: t('table.eventType'),
        dataIndex: 'eventType',
        align: 'center',
        render: value => t(`eventType.${value}`),
      },
      {
        title: t('table.field'),
        dataIndex: 'field',
        align: 'center',
        render: value => t(`field.${value}`),
      },
      {
        title: t('table.thresholdValue'),
        dataIndex: 'thresholdValue',
        align: 'center',
        render: (value, record) => {
          if (record.field === 'consumptionPercent') {
            return `${value}%`;
          }
          if (
            record.field === 'consumptionTotalAnomaly' ||
            record.field === 'consumptionCurrentDayAnomaly'
          ) {
            return `${value * 100}%`;
          }
          return bytesFormat(value);
        },
      },
      {
        title: t('attributes:name'),
        dataIndex: 'name',
        align: 'center',
      },
      {
        title: t('attributes:createdAt'),
        dataIndex: 'createdAt',
        align: 'center',
        render: value => value && dateFormat(value),
      },
      ...(active === 'false'
        ? [
            {
              title: t('table.updatedAt'),
              dataIndex: 'updatedAt',
              align: 'center',
              render: value => value && dateFormat(value),
            },
            {
              title: t('table.updatedBy'),
              dataIndex: 'updatedBy',
              align: 'center',
            },
          ]
        : [
            {
              title: t('attributes:actions'),
              dataIndex: 'actions',
              align: 'center',
              render: adminActions,
            },
          ]),
    ],
    [t, adminActions, active],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      handleSetSearchParams({
        page: paginationObj.current,
        linesPerPage: paginationObj.pageSize,
      });
    }
  };

  return (
    <>
      {modalVisible && (
        <ModalCreateAlerts
          visible={modalVisible}
          mutate={mutate}
          onClose={() => setModalVisible(false)}
        />
      )}
      <Tabs
        type="card"
        defaultActiveKey="false"
        activeKey={active}
        onChange={key => {
          handleSetSearchParams({
            active: key,
          });
        }}
        style={{ background: '#ffffff' }}
      >
        <TabPane key="true" tab={t('visions.active')} />
        <TabPane key="false" tab={t('visions.inactive')} />
      </Tabs>

      <Table
        title={t('titles.alerts')}
        columns={columns}
        data={data?.content}
        loading={!data?.content}
        tableKey="contracts"
        pagination={pagination}
        onChange={handleTableChange}
        extraActions={
          <RoleChecker role={rolesConstants.MANAGE_PLANS}>
            <Tooltip title={t('buttons.newAlert')}>
              <Button
                icon={<PlusCircleOutlined />}
                size={'large'}
                type={'link'}
                onClick={() => setModalVisible(true)}
              />
            </Tooltip>
          </RoleChecker>
        }
      />
    </>
  );
};

export default Alerts;
