import React from 'react';

import { Typography } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './styles.less';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

const DataNotFoundStep = ({ data, text, color }) => {
  const { t } = useTranslation('device-actions-modal');
  return (
    <div style={{ padding: 24, textAlign: 'center' }}>
      <span
        className="data-not-found-step-exclamation-circle"
        style={{
          color: data ? color : 'var(--primary-color)',
          fontSize: !data && '2rem',
        }}
      >
        {data ? <ExclamationCircleOutlined /> : <LoadingOutlined />}
      </span>
      <Paragraph
        className="data-not-found-step-paragraph"
        style={{
          color: !data && 'var(--primary-color)',
          fontSize: !data && '1rem',
        }}
      >
        {data ? text : t('loading-data')}
      </Paragraph>
    </div>
  );
};

export default DataNotFoundStep;
