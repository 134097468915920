import apiMiddleware from '@services/apiMiddleware';
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Button,
  Select,
  Alert,
} from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import SessionContext from '@src/store/SessionContext/SessionContext';
import useSwr from '@src/hooks/useSwr';
import { statesOfBrazil } from '@src/utils/statesToList';
import { maskPhone } from '@src/utils/formatters';

const { Item } = Form;
const { Option } = Select;

const CustomerModal = ({ visible, onClose, mutateData }) => {
  const { t } = useTranslation('schedules');
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const {
    customer: { customerLogged },
  } = useContext(SessionContext);
  const [selectedUF, setSelectedUF] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const { data: citiesData } = useSwr(
    selectedUF ? `/service-proxy/astro/cities?uf=${selectedUF}` : null,
  );

  const createCustomer = useCallback(
    newCustomer =>
      apiMiddleware.post(`/service-proxy/astro/client`, {
        ...newCustomer,
        city: selectedCity,
        state: selectedUF,
        wppNumber: newCustomer.wppNumber ? [newCustomer.wppNumber] : null,
        phoneNumber: newCustomer.phoneNumber ? [newCustomer.phoneNumber] : null,
        fkCompany: customerLogged?.idCompanyAstro,
      }),
    [customerLogged, selectedCity, selectedUF],
  );

  const close = useCallback(
    (cancelled = false) => {
      setSaveLoading(false);
      form.resetFields();
      onClose(cancelled);
    },
    [form, onClose],
  );

  const onFinish = useCallback(async () => {
    const customerInfo = form.getFieldValue();
    try {
      const { data } = await createCustomer(customerInfo);
      mutateData('CREATE', {
        ...customerInfo,
        idClient: data,
      });
      message.success(t('msgs.success-creating-customer'));
    } catch (error) {
      message.error(t('msgs.error-creating-customer'));
    }
    close();
    setSaveLoading(false);
  }, [close, createCustomer, mutateData, t, form]);

  useEffect(() => {
    if (saveLoading) {
      onFinish();
    }
  }, [saveLoading, onFinish]);

  return (
    <Styled.ModalContainer
      bodyStyle={{ minHeight: 500 }}
      maskClosable={false}
      title={t('modal.title.newCustomer')}
      visible={visible}
      width="70%"
      centered
      forceRender
      footer={false}
      onCancel={() => close(true)}
    >
      <Form
        colon={false}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <div style={{ width: '100%', opacity: '0.6' }}>
            <Divider orientation="left">{t('dividers.identification')}</Divider>
          </div>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.firstName')}
              name="firstName"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input />
            </Item>
            <Item
              label={t('table.lastName')}
              name="lastName"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.cpfCnpj')}
              name="cpfCnpj"
              normalize={value => (value ? value.replace(/\D/g, '') : '')}
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input maxLength={30} />
            </Item>
            <Item
              label={t('table.email')}
              name="email"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <div style={{ width: '100%', opacity: '0.6' }}>
            <Divider orientation="left">{t('dividers.address')}</Divider>
          </div>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.cep')}
              name="cep"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input maxLength={50} />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.streetName')}
              name="streetName"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input.Group compact>
                <Item name="streetName" noStyle>
                  <Input
                    autoComplete="address-line1"
                    maxLength={100}
                    style={{ width: '70%' }}
                  />
                </Item>
                <Item
                  name="streetNumber"
                  normalize={value => value.replace(/\D/g, '')}
                  noStyle
                >
                  <Input maxLength={10} style={{ width: '30%' }} />
                </Item>
              </Input.Group>
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item label={t('table.complement')} name="complement">
              <Input autoComplete="address-line2" maxLength={100} />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.country')}
              labelAlign="right"
              name="country"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input autoComplete="country-name" maxLength={50} />
            </Item>
          </Col>

          <Col xl={12} xs={24}>
            <Item
              label={t('table.state')}
              name="state"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Select
                onChange={value => setSelectedUF(value)}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    String(option?.label).toLowerCase().indexOf(input) >= 0
                  );
                }}
                showSearch
                options={statesOfBrazil.map(state => ({
                  label: state.label,
                  value: state.value,
                }))}
              />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.city')}
              name="city"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Select
                onChange={value => {
                  setSelectedCity(value);
                }}
                optionFilterProp="children"
                options={citiesData?.data?.map(city => ({
                  label: city.cidade,
                  value: city.cidade,
                }))}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                showSearch
                disabled={!citiesData}
              />
            </Item>
            {!citiesData && selectedUF && (
              <Alert
                message="Ainda não estamos atendendo neste estado"
                type="warning"
                showIcon
              />
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <div style={{ width: '100%', opacity: '0.6' }}>
            <Divider orientation="left">{t('dividers.company')}</Divider>
          </div>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.ocupation')}
              name="ocupation"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.fantasyName')}
              name="fantasyName"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.corporateName')}
              name="corporateName"
              rules={[
                { required: true, message: t('form.msgs.fillThisField') },
              ]}
            >
              <Input />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.wppNumber')}
              name="wppNumber"
              rules={[
                {
                  min: 14,
                  max: 15,
                  message: t('form.msgs.invalidNumber'),
                },
              ]}
            >
              <Input
                maxLength={15}
                onChange={e =>
                  form.setFieldsValue({
                    wppNumber: maskPhone(e.target.value),
                  })
                }
              />
            </Item>
          </Col>
          <Col xl={12} xs={24}>
            <Item
              label={t('table.phoneNumber')}
              name="phoneNumber"
              rules={[
                {
                  min: 14,
                  max: 15,
                  message: t('form.msgs.invalidNumber'),
                },
              ]}
            >
              <Input
                maxLength={15}
                onChange={e =>
                  form.setFieldsValue({
                    phoneNumber: maskPhone(e.target.value),
                  })
                }
              />
            </Item>
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="end" gutter={16}>
          <Col>
            <Button onClick={() => close(true)}>{t('button.cancel')}</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              {t('button.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Styled.ModalContainer>
  );
};

export default CustomerModal;
