import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import {
  Col,
  ConfigProvider,
  Button,
  Select,
  Tooltip,
  Input,
  Divider,
  Dropdown,
  Form,
  message,
} from 'antd';
import {
  LogoutOutlined,
  LoginOutlined,
  InboxOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { FiExternalLink } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Label } from '@components/ui';
import { getDateInISOFormat } from '@utils/formatters';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import ptBR from 'antd/es/locale/pt_BR';
import 'moment/locale/pt-br';
import { Filters, FiltersCard } from '@components/Filters';
import useSearchParams from '@src/hooks/useSearchParams';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import moment from 'moment';
import StatusMenu from '@src/components/StatusMenu/StatusMenu';
import SessionContext from '@src/store/SessionContext/SessionContext';
import Actions from '@src/components/Actions/index';
import ActionsItems from '@src/components/Actions/ActionsItems/index';
import rolesConstants from '@src/utils/rolesConstants';
import RoleChecker from '@src/components/RoleChecker/RoleChecker';

const { Search } = Input;

const dateFormat = date => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj.toLocaleString(i18next.language);
  }
  return '';
};

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Transfer = ({
  isInTheRequestDetails,
  defaultFilters,
  columnsToRemove,
}) => {
  const { t } = useTranslation(['transfer', 'lorawan']);
  const { t: tInventory } = useTranslation('inventory');
  const {
    hasCustomersRoles,
    customer: {
      customerLogged,
      customersOptions,
      isCustomerFinanciallyBlocked,
    },
  } = useContext(SessionContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const uploadedItems = Form.useWatch('uploadedItems', form);

  const [showActionModal, setShowActionModal] = useState(false);
  const [sendDeviceLoading, setSendDeviceLoading] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [status, setStatus] = useState('');
  const [devEui, setDevEui] = useState('');
  const [devEuiValue, setDevEuiValue] = useState('');
  const [username, setUsername] = useState('');
  const [usernameValue, setUsernameValue] = useState('');
  const [virtualAccountId, setVirtualAccountId] = useState();

  const { data } = useSwr('/service-proxy/lorawan/transfer', {
    customerId: selectedClient,
    page: pagination?.current - 1 || pagination?.defaultCurrent - 1,
    linesPerPage: pagination?.pageSize,
    startDate,
    endDate,
    status,
    username,
    virtualAccountId,
    devEui,
    ...defaultFilters,
  });

  const { data: countByStatus } = useSwr(
    '/service-proxy/lorawan/transfer-count-by-status',
    {
      customerId: selectedClient,
      startDate,
      endDate,
      status: status?.toUpperCase(),
      username,
      virtualAccountId,
      devEui,
      ...defaultFilters,
    },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customer',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'initialDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
      {
        name: 'devEui',
        setState: setDevEui,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: false,
      },
      {
        name: 'transferredBy',
        setState: setUsername,
        inTheFilters: true,
      },
      {
        name: 'virtualAccountId',
        setState: setVirtualAccountId,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    if (countByStatus?.length < 2) {
      handleClearParams(['status']);
    }
  }, [countByStatus?.length, handleClearParams]);

  useEffect(() => {
    setDevEuiValue(devEui);
  }, [devEui]);

  useEffect(() => {
    setUsernameValue(username);
  }, [username]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [startDate, endDate, status, devEui, selectedClient]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const fromToButton = useCallback(
    record => {
      const currentVAId = defaultFilters?.virtualAccountId;
      const oldVAId = record.oldCustomerAccountId;
      const newVAId = record.newCustomerAccountId;
      const isOldVAId = currentVAId === oldVAId;
      const isOldVAIdNull = oldVAId === null;
      const isNewVAIdNull = newVAId === null;
      const isOldVAIdZero = oldVAId === 0;
      const isNewVAIdZero = newVAId === 0;
      const isOldBrokerStock = record.oldCustomer === customerLogged?.id;
      const isNewBrokerStock = record.newCustomer === customerLogged?.id;

      let tooltipText = t(
        t(`movement.see${isOldVAId ? 'Destination' : 'Origin'}Request`),
      );
      let icon = isOldVAId ? <LogoutOutlined /> : <LoginOutlined />;
      const stockIcon = <InboxOutlined style={{ fontSize: '1.25rem' }} />;
      let idToLink;
      if (isOldVAIdNull || isOldBrokerStock || isOldVAIdZero) {
        tooltipText = t('movement.transferred', {
          option: t('movement.fromStock'),
        });
        icon = stockIcon;
      } else if (isNewVAIdNull || isNewBrokerStock || isNewVAIdZero) {
        tooltipText = t('movement.transferred', {
          option: t('movement.forStock'),
        });
        icon = stockIcon;
      } else if (isOldVAId) {
        idToLink = newVAId;
      } else {
        idToLink = oldVAId;
      }

      const showButton =
        !isOldVAIdNull &&
        !isNewVAIdNull &&
        !isOldBrokerStock &&
        !isNewBrokerStock &&
        !isOldVAIdZero &&
        !isNewVAIdZero;

      return (
        <Dropdown
          overlayStyle={{
            background: 'white',
            padding: '4px 8px',
            borderRadius: 5,
            boxShadow: '4px 8px 30px -4px rgb(0 51 90 / 10%)',
          }}
          overlay={
            <div
              style={{
                color: '#333',
                padding: '2px 6px',
                fontSize: '0.9rem',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              {showButton ? (
                <Button
                  type="link"
                  danger={isNewVAIdNull || isOldVAId || isNewVAIdZero}
                  onClick={() => navigate(`/lorawan/requests/${idToLink}`)}
                >
                  {tooltipText}
                </Button>
              ) : (
                tooltipText
              )}
            </div>
          }
        >
          <Button
            size="middle"
            type="link"
            icon={icon}
            danger={isNewVAIdNull || isOldVAId || isNewVAIdZero}
          />
        </Dropdown>
      );
    },
    [t, defaultFilters, navigate, customerLogged],
  );
  const allColumns = useMemo(() => {
    const defaultColumns = [
      { title: t('table.date'), dataIndex: 'changeDate', render: dateFormat },
      { title: t('table.devEui'), dataIndex: 'devEui' },
      { title: t('table.transferred-by'), dataIndex: 'username' },

      {
        title: t('table.result.title'),
        dataIndex: 'result',
        render: value =>
          t(`table.result.options.${value}`, { type: t('lorawan:device') }),
      },
    ];

    if (!isInTheRequestDetails) {
      defaultColumns.unshift(
        {
          title: t('table.request'),
          align: 'center',
          dataIndex: 'newCustomerAccountId',
          render: newCustomerAccountId => {
            const inStock = newCustomerAccountId === 0;
            return (
              (newCustomerAccountId || inStock) && (
                <Tooltip
                  title={t(`table.${inStock ? 'inStock' : 'seeRequest'}`)}
                >
                  <Button
                    icon={
                      inStock ? (
                        <InboxOutlined style={{ transform: 'scale(1.2)' }} />
                      ) : (
                        <FileSearchOutlined />
                      )
                    }
                    type="link"
                    size="large"
                    onClick={() => {
                      if (!inStock) {
                        navigate(`/lorawan/requests/${newCustomerAccountId}`);
                      }
                    }}
                  />
                </Tooltip>
              )
            );
          },
        },
        {
          title: t('table.from'),
          dataIndex: 'oldCustomer',
          render: value => value?.name || value?.tradingName || value?.nickname,
        },
        {
          title: t('table.to'),
          dataIndex: 'newCustomer',
          render: value => value?.name || value?.tradingName || value?.nickname,
        },
      );
    } else {
      defaultColumns.unshift({
        title: t('movement.title'),
        dataIndex: 'transfersCustomers',
        align: 'center',
        render: (_, record) => fromToButton(record),
      });
    }

    return defaultColumns;
  }, [isInTheRequestDetails, t, fromToButton, navigate]);
  const columns = useMemo(() => {
    if (columnsToRemove) {
      return allColumns
        ?.filter(col => col)
        ?.filter(column => !columnsToRemove.includes(column.dataIndex));
    }
    return allColumns.filter(col => col);
  }, [columnsToRemove, allColumns]);

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const start = getDateInISOFormat(initialDate);
      const end = getDateInISOFormat(endDate);
      handleSetSearchParams({ initialDate: start, endDate: end });
    },
    [handleSetSearchParams],
  );

  const getColorStatus = useCallback(statusName => {
    if (statusName === 'SUCCESS') {
      return '#52c41a';
    }
    return '#ff4d4f';
  }, []);

  const statusMenuData = useMemo(() => {
    const statusData = countByStatus
      ?.map(({ item, value }) => ({
        label: t(`table.result.options.${item}`, { type: t('lorawan:device') }),
        item,
        value,
        color: getColorStatus(item),
      }))
      ?.sort((a, b) => a.value - b.value);
    return statusData?.reverse();
  }, [countByStatus, t, getColorStatus]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('dateRange'),
        item: (
          <ConfigProvider locale={ptBR}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate
                  ? [moment(startDate, 'YYYYMMDD'), moment(endDate, 'YYYYMMDD')]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
      {
        visible: hasCustomersRoles && !isInTheRequestDetails,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('client'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={customersOptions}
            loading={!customersOptions}
            disabled={!customersOptions}
            placeholder={t('client')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={selectedClient ? Number(selectedClient) : undefined}
            onChange={value => handleSetSearchParams({ customer: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.devEui'),
        item: (
          <Search
            placeholder={t('table.devEui')}
            onSearch={value => handleSetSearchParams({ devEui: value })}
            onChange={({ target: { value } }) => setDevEuiValue(value)}
            value={devEuiValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.transferred-by'),
        item: (
          <Search
            placeholder={t('table.transferred-by')}
            onSearch={value => handleSetSearchParams({ transferredBy: value })}
            onChange={({ target: { value } }) => setUsernameValue(value)}
            value={usernameValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      handleDateChange,
      startDate,
      endDate,
      hasCustomersRoles,
      isInTheRequestDetails,
      customersOptions,
      selectedClient,
      devEuiValue,
      usernameValue,
      handleSetSearchParams,
    ],
  );

  const extraActions = useMemo(
    () =>
      !isCustomerFinanciallyBlocked &&
      !isInTheRequestDetails && (
        <RoleChecker role={rolesConstants.LORAWAN_TRANSFERS}>
          <Button
            type={'primary'}
            onClick={() => setShowActionModal(true)}
            style={{ marginRight: 12 }}
          >
            {t('batch-transfer-button')}
          </Button>
        </RoleChecker>
      ),
    [isCustomerFinanciallyBlocked, isInTheRequestDetails, t],
  );

  const transfersAction = ActionsItems.Lorawan.useTransfers({
    isUpload: true,
    devices: uploadedItems,
    form,
  });

  const handleOnClose = useCallback(() => {
    setShowActionModal(false);
    form.resetFields();
  }, [form]);

  const handleActionConfirm = useCallback(async () => {
    setSendDeviceLoading(true);
    try {
      transfersAction?.sendDeviceAction();
    } catch {
      message.error(t('msgs.error-when-requesting'));
    }
    handleOnClose();
    setSendDeviceLoading(false);
  }, [handleOnClose, transfersAction, t]);

  return (
    <>
      <Table
        columns={columns}
        allColumns={allColumns}
        data={data?.content}
        extraActions={extraActions}
        loading={!data?.content}
        pagination={pagination}
        tableKey={isInTheRequestDetails ? 'transferInDetails' : 'transfer'}
        title={
          <>
            {t('title')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        onChange={handleTableChange}
        tabsFilters={
          statusMenuData?.length > 0 && (
            <div
              style={{
                padding: 8,
                marginBottom: 6,
                background: !showFilters && '#f8f8f8',
              }}
            >
              <Button
                size="small"
                type={!status && 'primary'}
                style={{ margin: '4px 6px' }}
                onClick={() => {
                  handleClearParams(['status']);
                }}
              >
                {tInventory('stock-values.all')}
              </Button>
              <StatusMenu
                itemActive={status?.toUpperCase()}
                data={statusMenuData}
                handleSelectedCard={item =>
                  handleSetSearchParams({ status: item.toLowerCase() })
                }
              />
            </div>
          )
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
        mainExtraAction={
          isInTheRequestDetails && (
            <>
              <Divider type="vertical" />
              <Tooltip title={t('requests:viewOnTransferPage')}>
                <Button
                  icon={
                    <FiExternalLink
                      style={{
                        fontSize: '1.4rem',
                        marginTop: 4,
                      }}
                    />
                  }
                  size="large"
                  type="link"
                  onClick={() => {
                    navigate(
                      `/management/transfer?virtualAccountId=${defaultFilters?.virtualAccountId}`,
                    );
                  }}
                />
              </Tooltip>
            </>
          )
        }
      />

      <Actions.Modal
        form={form}
        devices={[]}
        isOpen={showActionModal}
        isUpload
        onClose={handleOnClose}
        onConfirm={handleActionConfirm}
        onConfirmLoading={sendDeviceLoading}
        selectedAction={transfersAction}
      />
    </>
  );
};

export default Transfer;
