import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '@utils/formatters';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Descriptions,
  Popover,
  Segmented,
  Tag,
  message,
  Tooltip,
  Modal,
} from 'antd';
import {
  DeleteOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import SessionContext from '@src/store/SessionContext/SessionContext';
import rolesConstants from '@src/utils/rolesConstants';
import apiMiddleware from '@src/services/apiMiddleware';
import * as S from './styles';
import { LorawanActionTypes } from '@src/utils/deviceActions';

const ReferralListItem = ({ item }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('lorawan');
  const { userRoles } = useContext(SessionContext);
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState('filter');
  const [deletionInProgress, setDeletionInProgress] = useState(false);

  const canDeletingForwarding = userRoles?.portal?.includes(
    rolesConstants.LORAWAN_DELETING_REFERRALS,
  );

  const postAction = useCallback(
    payload => apiMiddleware.post('/service-proxy/lorawan/actions', payload),
    [],
  );

  const toggleOpen = useCallback(() => setIsOpen(crrOpen => !crrOpen), []);

  const segmentedOptions = useMemo(
    () =>
      ['filter', 'connection'].map(opt => ({
        label: t(opt),
        value: opt,
      })),
    [t],
  );

  const renderItems = useCallback(items => {
    if (!items) return undefined;
    const firstFourItems = items.slice(0, 4).join(', ');
    if (items.length <= 4) {
      return firstFourItems;
    }
    const otherItems = items.slice(4).join(', ');
    return (
      <div>
        {firstFourItems}
        <Popover
          content={
            <div style={{ maxWidth: 300, width: '100%' }}>{otherItems}</div>
          }
        >
          <Button
            size="small"
            icon={<EllipsisOutlined />}
            style={{ marginLeft: 8 }}
          />
        </Popover>
      </div>
    );
  }, []);

  const renderTags = useCallback(
    tags =>
      tags && (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tags?.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      ),
    [],
  );

  const treatedFilter = useMemo(() => {
    const { gateways, ...filter } = item?.filter;
    return {
      ...filter,
      applications: renderItems(filter?.applications),
      devices: renderItems(filter?.devices),
      types: renderTags(filter?.types),
      tags: renderTags(filter?.tags),
    };
  }, [item, renderItems, renderTags]);

  const treatedConnection = useMemo(() => {
    const { id, clientId, filter, ...connection } = item?.connection;
    return {
      ...connection,
      type: connection?.type === 'http2' ? 'HTTP' : connection?.type,
    };
  }, [item]);

  const renderContentDescriptions = useMemo(() => {
    const selected =
      itemSelected === 'filter' ? treatedFilter : treatedConnection;

    return Object.entries(selected)?.map(([label, value]) => ({
      label: t(label),
      value,
    }));
  }, [itemSelected, treatedFilter, treatedConnection, t]);

  const onForwardingDelete = useCallback(async () => {
    try {
      setDeletionInProgress(true);
      await postAction({
        action: LorawanActionTypes.FORWARD_DELETION,
        deviceReferrals: {
          id: item?.id,
        },
      });
      message.success(
        <span>
          {t('followTheActionQueue')}
          <Button
            type="link"
            onClick={() => {
              navigate('/lorawan/actions-queue', { replace: false });
            }}
          >
            {t('clickHere')}
          </Button>
        </span>,
      );
    } catch {
      message.error(t('errorWhenInsertingIntoQueue'));
    }
    setDeletionInProgress(false);
  }, [item?.id, navigate, postAction, t]);

  const showDelConfirm = useCallback(() => {
    Modal.confirm({
      title: (
        <h3>
          {t('admin-actions:deleteModal.title', {
            entityToExclude: item?.connection?.description,
          })}
        </h3>
      ),
      icon: <ExclamationCircleOutlined />,
      content: t('deleteForwardingWarning'),
      okText: t('admin-actions:buttons.deleteYes'),
      cancelText: t('admin-actions:buttons.deleteNo'),
      okButtonProps: { type: 'primary', danger: true },
      afterClose: () => document.body.style.removeProperty('overflow'),
      onOk: onForwardingDelete,
    });
  }, [item, onForwardingDelete, t]);

  return (
    <S.ListItemContainer>
      <S.ListItemHeader onClick={toggleOpen} isOpen={isOpen}>
        <S.ListItemHeaderTitle>
          <span>{item?.connection?.description}</span>
          <span>{dateFormat(item?.createdAt)}</span>
        </S.ListItemHeaderTitle>
        <S.ArrowIcon isOpen={isOpen} />
      </S.ListItemHeader>
      {isOpen && (
        <S.ListItemContent>
          <S.ListItemContentTitle>
            <Segmented
              size="small"
              default={false}
              options={segmentedOptions}
              onChange={value => setItemSelected(value)}
            />
            {canDeletingForwarding && (
              <Tooltip title={t('deleteForwarding')}>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={showDelConfirm}
                  disabled={deletionInProgress}
                />
              </Tooltip>
            )}
          </S.ListItemContentTitle>
          <Descriptions column={itemSelected === 'filter' ? 1 : 2}>
            {renderContentDescriptions?.map(
              ({ label, value }) =>
                value && (
                  <Descriptions.Item key={label} label={label}>
                    {value}
                  </Descriptions.Item>
                ),
            )}
          </Descriptions>
        </S.ListItemContent>
      )}
    </S.ListItemContainer>
  );
};

export default memo(ReferralListItem);
