import useSwr from '@src/hooks/useSwr';
import apiMiddleware from '@src/services/apiMiddleware';
import { Button, Form, Input, message, Card, Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SmsConfigurations = () => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation('sms-send');
  const { data } = useSwr(`/service-proxy/sms-config`);

  const onClose = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        apiKey: data.hasApiKey ? '********' : '',
        apiUrl: data.apiUrl || '',
      });
    }
  }, [data, form]);

  const handleSave = () => {
    form.validateFields().then(async values => {
      try {
        await apiMiddleware.post('/service-proxy/sms-config', {
          ...(values.apiUrl && values.apiUrl !== ''
            ? { apiUrl: values.apiUrl.replace(/(?:https?:\/\/)[^\s]+/g, '') }
            : {}),
          ...(values.apiKey !== '********' ? { apiKey: values.apiKey } : {}),
        });
        message.success(translate('sender.created'));
        onClose;
      } catch (err) {
        message.error(translate('sender.error'));
      }
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Card style={{ marginBottom: '8px' }}>
            <Form form={form} layout="vertical">
              <div
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <Form.Item
                  name="apiKey"
                  label={translate('sender.apiKey')}
                  rules={[
                    { required: true, message: translate('sender.required') },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="apiUrl"
                  label={translate('sender.apiUrl')}
                  rules={[
                    { required: true, message: translate('sender.required') },
                  ]}
                >
                  <Input />
                </Form.Item>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '5px',
                  }}
                >
                  <Button onClick={handleSave}>
                    {translate('sender.submit')}
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SmsConfigurations;
