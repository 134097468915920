import React, { memo, useCallback, useMemo, useState } from 'react';
import { Button, List, Radio, Row, Tooltip } from 'antd';
import { Card } from '@components/ui';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useSwr from '@hooks/useSwr';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import rolesConstants from '@utils/rolesConstants';
import RealtimeListItem from './RealtimeListItem';
import RequestRealtimeReport from './RequestRealtimeReport';
import useFluxEndpoint from '@src/hooks/useFluxEndpoint';

const DeviceRealtime = ({ devEui, ...props }) => {
  const { t } = useTranslation('lorawan');
  const [maxNbRows, setMaxNbRows] = useState('25');
  const [showRequestReport, setShowRequestReport] = useState(false);

  const realTimeData = useFluxEndpoint(
    `/events-service-proxy/lorawanDevices/messages-stream?devEui=${devEui}`,
  );

  const { data: dataHistory } = useSwr(
    devEui ? `/service-proxy/lorawanDevices/messages` : null,
    {
      devEui,
      linesPerPage: maxNbRows,
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    },
  );

  const combinedItems = useMemo(() => {
    // Convert maxNbRows to number
    const maxRows = Number(maxNbRows);

    // If realTimeData is undefined and data has content, show data
    if (!realTimeData && dataHistory) {
      return dataHistory.content;
    }

    // If realTimeData has reached the size of maxNbRows, only show it
    if (realTimeData && realTimeData.length >= maxRows) {
      return realTimeData.slice(0, maxRows);
    }

    // Check if realTimeData and data are not undefined
    if (realTimeData && dataHistory) {
      // Calculate the number of items to take from data
      const numDataItems = maxRows - realTimeData.length;

      // Take the first numDataItems from data
      const dataItems = dataHistory.content.slice(0, numDataItems);

      // Combine realTimeData and dataItems
      return [...realTimeData, ...dataItems];
    }

    return [];
  }, [maxNbRows, realTimeData, dataHistory]);

  const reportToggle = useCallback(
    () => setShowRequestReport(crrShow => !crrShow),
    [],
  );

  return (
    <Row gutter={[16, 16]} style={{ padding: 10 }} {...props}>
      <RoleChecker role={rolesConstants.REQUEST_REPORT}>
        <RequestRealtimeReport
          devEui={devEui}
          visible={showRequestReport}
          onClose={reportToggle}
        />
      </RoleChecker>
      <Card
        id="realtime"
        title={t('realtimeData')}
        style={{ width: '100%' }}
        extra={
          <>
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              onChange={e => setMaxNbRows(e.target.value)}
              defaultValue="25"
              value={maxNbRows}
            >
              <Radio.Button value="25">25</Radio.Button>
              <Radio.Button value="50">50</Radio.Button>
              <Radio.Button value="100">100</Radio.Button>
            </Radio.Group>
            <RoleChecker role={rolesConstants.REQUEST_REPORT}>
              <Tooltip title={t('exportReport')}>
                <Button
                  type="default"
                  icon={<CopyOutlined />}
                  style={{ marginLeft: 8 }}
                  onClick={reportToggle}
                />
              </Tooltip>
            </RoleChecker>
          </>
        }
      >
        <List
          style={{ borderColor: '#f1f1f1' }}
          bordered
          dataSource={combinedItems ?? []}
          loading={!combinedItems}
          renderItem={item => <RealtimeListItem item={item} />}
        />
      </Card>
    </Row>
  );
};

export default memo(DeviceRealtime);
