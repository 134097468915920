import React from 'react';

import { Container, SignalBar, SignalOff } from './styles';

function SignalBars({ numberOfBars = 0, size = 24, color = '#5b5a5a' }) {
  const signalBars = [
    {
      barNumber: 1,
      height: 20,
    },
    {
      barNumber: 2,
      height: 40,
    },
    {
      barNumber: 3,
      height: 60,
    },
    {
      barNumber: 4,
      height: 80,
    },
    {
      barNumber: 5,
      height: 100,
    },
  ];

  return (
    <Container size={size}>
      {signalBars.map(({ barNumber, height }) => (
        <SignalBar
          key={barNumber}
          barNumber={barNumber}
          height={height}
          color={color}
          size={size}
          numberOfBars={numberOfBars}
        />
      ))}
      {numberOfBars === 0 && <SignalOff color={color} />}
    </Container>
  );
}

export default SignalBars;
