import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.7;
  transition: 0.3s;

  :hover {
    opacity: 1;
  }

  button {
    width: 40px;
    height: 40px;
  }
`;

export const PopoverContent = styled.div`
  p {
    margin-top: 12px;
    font-size: 1rem;
    opacity: 0.8;
    text-align: center;
  }
  img {
    max-width: 700px;
    width: 100%;
  }
`;
