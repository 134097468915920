import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';

const PageContainer = styled.div`
  background-color: white;
  padding: 0 2rem 2rem;
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 3px 34px 30px -4px rgba(0, 51, 90, 0.1);
  @media (max-width: 1300px) {
    padding: 2rem 4rem;
    background-color: transparent;
    box-shadow: none;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

function Page({ children }) {
  return (
    <PageContainer>
      <Space direction={'vertical'} style={{ width: '100%' }}>
        {children}
      </Space>
    </PageContainer>
  );
}

export default Page;
