import React, { useContext, useEffect } from 'react';
import './styles.css';
import SessionContext from '@src/store/SessionContext/SessionContext';

const PortalSpinner = ({ children }) => {
  const { logoImgName } = useContext(SessionContext);

  useEffect(() => {
    const documentOverflow = overflow =>
      (document.body.style.overflow = overflow);
    documentOverflow('hidden');

    return () => {
      documentOverflow('auto');
    };
  }, []);

  return (
    <div className="logo-container">
      <img alt="loading" src={`/images/${logoImgName}`} id="breathing-img" />
      {children}
    </div>
  );
};

export default PortalSpinner;
