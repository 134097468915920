import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import namespaces from './utils/namespaces';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['pt', 'en', 'es'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: namespaces,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`,
    },
  });

export const getAvailableLocales = () =>
  Object.keys(i18n.services.resourceStore.data);

export default i18n;
