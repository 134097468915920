import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { FieldTimeOutlined } from '@ant-design/icons';
import { DatePicker, Tag, Popover, Button, Tooltip } from 'antd';
import {
  getDateInISOFormat,
  getDateWithISOFormat,
} from '@src/utils/formatters';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import moment from 'moment';

import './styles.less';

// value ---> ['YYYYMMdd', 'YYYYMMdd']
// onChange ---> { initialDate: 'YYYYMMdd', endDate: 'YYYYMMdd' }
const SingleRangePicker = ({
  onChange,
  value,
  filters = true,
  disableEndDateLimit = false,
}) => {
  const { t } = useTranslation('date-picker');
  const { i18n } = useTranslation();
  const { keycloak } = useContext(KeycloakContext);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showPopOver, setShowPopOver] = useState(false);

  useEffect(() => {
    if (value) {
      setStartDate(getDateWithISOFormat(value[0]));
      setEndDate(getDateWithISOFormat(value[1]));
    }
  }, [value]);

  const whichFormat = locale => {
    const localeDictionary = {
      pt: 'DD/MM/YYYY',
      es: 'DD/MM/YYYY',
      en: 'MM/DD/YYYY',
    };
    return localeDictionary[locale];
  };

  const configDatePicker = useMemo(
    () => ({
      format: whichFormat(
        i18n?.language ?? keycloak?.idTokenParsed?.locale ?? 'en',
      ),
      size: 'middle',
      style: { width: '49.5%' },
      allowClear: true,
    }),
    [i18n?.language, keycloak?.idTokenParsed?.locale],
  );

  const handleChangeStartDate = useCallback(
    date => {
      const initialDate = date && date.toISOString();
      setStartDate(initialDate);

      onChange({
        startDate: getDateInISOFormat(initialDate),
        endDate: endDate ?? undefined,
      });
    },
    [endDate, onChange],
  );

  const handleChangeEndDate = useCallback(
    date => {
      const finalDate = date && date.toISOString();
      if (startDate > finalDate) {
        setStartDate(undefined);
        onChange({
          startDate: undefined,
          endDate: getDateInISOFormat(finalDate),
        });
      } else {
        onChange({
          startDate: startDate && getDateInISOFormat(startDate),
          endDate: getDateInISOFormat(finalDate),
        });
      }
      setEndDate(finalDate);
    },
    [onChange, startDate],
  );

  const handlePresetFilters = useCallback(
    momentDay => {
      const dateInISOFormat = momentDay.toISOString();
      setStartDate(undefined);
      setEndDate(dateInISOFormat);
      onChange({
        startDate: undefined,
        endDate: getDateInISOFormat(dateInISOFormat),
      });
    },
    [onChange],
  );

  const tags = useMemo(
    () => [
      {
        name: t('one-week-ago'),
        momentDay: moment().subtract(7, 'days'),
      },
      {
        name: t('two-weeks-ago'),
        momentDay: moment().subtract(14, 'days'),
      },
      {
        name: t('three-weeks-ago'),
        momentDay: moment().subtract(21, 'days'),
      },
      {
        name: t('one-month-ago'),
        momentDay: moment().subtract(1, 'months'),
      },
      {
        name: t('two-months-ago'),
        momentDay: moment().subtract(2, 'months'),
      },
      {
        name: t('three-months-ago'),
        momentDay: moment().subtract(3, 'months'),
      },
    ],
    [t],
  );

  return (
    <div className="single-range-container">
      <div className="single-range-pickers">
        <DatePicker
          placeholder={t('initial-date')}
          defaultValue={value?.[0] && moment(value[0], 'YYYYMMDD')}
          disabledDate={d => d > moment(endDate)}
          value={startDate && moment(startDate, 'YYYYMMDD')}
          onChange={handleChangeStartDate}
          {...configDatePicker}
        />
        <DatePicker
          placeholder={t('end-date')}
          defaultValue={value?.[1] && moment(value[1], 'YYYYMMDD')}
          disabledDate={d =>
            d && !disableEndDateLimit && d > moment().endOf('day')
          }
          value={endDate && moment(endDate, 'YYYYMMDD')}
          onChange={handleChangeEndDate}
          {...configDatePicker}
        />
      </div>
      {filters && (
        <div className="single-range-popover">
          <Tooltip title={t('preset-ranges')}>
            <Popover
              visible={showPopOver}
              onVisibleChange={setShowPopOver}
              content={
                <div className="tags-wrapper">
                  {tags?.map(({ name, momentDay }) => (
                    <Tag
                      key={name}
                      color="processing"
                      className="tag-quick-filters"
                      onClick={() => handlePresetFilters(momentDay)}
                    >
                      {name}
                    </Tag>
                  ))}
                </div>
              }
              trigger="click"
            >
              <Button
                type="primary"
                icon={<FieldTimeOutlined />}
                style={{ margin: '0 2px' }}
                onClick={() => setShowPopOver(oldShow => !oldShow)}
              />
            </Popover>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default memo(SingleRangePicker);
