import React, { useCallback, useContext, useMemo } from 'react';
import rolesConstants from '@utils/rolesConstants';
import { useTranslation } from 'react-i18next';
import { Select, Form, Button, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useSwr from '@src/hooks/useSwr';
import { useNavigate } from 'react-router-dom';
import SessionContext from '@src/store/SessionContext/SessionContext';
import apiMiddleware from '@src/services/apiMiddleware';
import { LorawanActionTypes } from '@src/utils/deviceActions';

const useTransfers = ({ devices, isUpload, form }) => {
  const { t } = useTranslation(['lorawan', 'device-actions-modal']);
  const {
    customer: { customerLogged, customersOptions },
  } = useContext(SessionContext);
  const navigate = useNavigate();
  const customerId = Form.useWatch('customerId', form);
  const requestId = Form.useWatch('requestId', form);
  const contractId = Form.useWatch('contractId', form);

  const { data: contractsByClient } = useSwr(
    customerId
      ? `/service-proxy/lorawanVirtualPlans/customers/${customerId}`
      : null,
  );

  const { data: requestsByContract } = useSwr(
    contractId
      ? `/service-proxy/lorawanVirtualAccounts?contractId=${contractId}&linesPerPage=9999`
      : null,
  );

  const contractOptions = useMemo(
    () =>
      contractsByClient &&
      contractsByClient?.map(contract => ({
        label: `${contract.id} - ${contract.desc}`,
        value: contract.id,
      })),
    [contractsByClient],
  );

  const requestsOptions = useMemo(
    () =>
      requestsByContract &&
      requestsByContract?.content?.map(request => ({
        label: `${request.id} - ${request.name}`,
        value: request?.id,
      })),
    [requestsByContract],
  );

  const transfersCustomersOptions = useMemo(() => {
    const options = [
      {
        label: `(${t('stock')}) ${customerLogged?.name ?? ' '}`,
        value: 0,
      },
    ];

    if (customersOptions) {
      options.push({
        label: t('customer'),
        options: customersOptions ?? [
          {
            label: (
              <div style={{ textAlign: 'center' }}>
                <LoadingOutlined />
              </div>
            ),
          },
        ],
      });
    }

    return options;
  }, [t, customersOptions, customerLogged]);

  const steps = useMemo(() => {
    const defaultSteps = [
      {
        title: t('customer'),
        name: 'customerId',
        content: (
          <Select
            options={transfersCustomersOptions}
            loading={!transfersCustomersOptions}
            disabled={!transfersCustomersOptions}
            optionFilterProp={'label'}
            placeholder={t('placeholders:client')}
            allowClear
            showSearch
            style={{ width: '100%' }}
          />
        ),
      },
    ];

    if (customerId && customerId !== 0) {
      const disableStep = !contractOptions || contractOptions?.length === 0;
      defaultSteps.push({
        title: t('contract'),
        name: 'contractId',
        itemMessage: t('clientWithoutContracts'),
        showItemMessage: disableStep,
        content: <Select options={contractOptions} disabled={disableStep} />,
      });
    }
    if (contractId && contractId !== 0) {
      const disableStep = !requestsOptions || requestsOptions?.length === 0;
      defaultSteps.push({
        title: t('request'),
        name: 'requestId',
        itemMessage: t('contractWithoutRequests'),
        showItemMessage: disableStep,
        content: <Select options={requestsOptions} disabled={disableStep} />,
      });
    }
    return defaultSteps;
  }, [
    contractId,
    contractOptions,
    customerId,
    requestsOptions,
    t,
    transfersCustomersOptions,
  ]);

  const payloadToSend = useMemo(
    () =>
      !isUpload &&
      devices?.map(device => ({
        devEui: device?.devEui,
        newCustomerVirtualAccountId: customerId === 0 ? 0 : requestId,
      })),
    [isUpload, devices, customerId, requestId],
  );

  const handleSuccess = useCallback(() => {
    const navigatePath = `/lorawan/transfer`;
    message.success(
      <>
        <span>{t('device-actions-modal:msgs.requested-action')}</span>
        <Button
          type="link"
          style={{ margin: '0 4px', padding: 0 }}
          onClick={() => navigate(navigatePath)}
        >
          {t('device-actions-modal:msgs.follow-in-the-transfers-queue')}
        </Button>
      </>,
      2,
    );
  }, [t, navigate]);

  const sendDeviceAction = useCallback(async () => {
    await apiMiddleware.patch(
      '/service-proxy/lorawan/transfer',
      isUpload ? devices : payloadToSend,
    );
    handleSuccess();
  }, [devices, handleSuccess, isUpload, payloadToSend]);

  return {
    steps,
    actionKey: LorawanActionTypes.TRANSFER,
    title: t(
      `device-actions:labels.lorawan.${rolesConstants.LORAWAN_TRANSFERS}`,
    ),
    payloadToSend,
    totalDevices: devices?.length,
    sendDeviceAction,
    uploadCustomInfo: t('transferRequestInfo'),
  };
};

export default useTransfers;
