import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`
  .button-previous {
    margin: 0 8px;
  }

  .button-download {
    padding: 0;
    margin-top: 8px;
  }

  .ant-steps {
    margin-bottom: 15px;
  }

  .action-validation {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .action-activation-in-devices {
    padding: 16px;

    .ant-row,
    .ant-select {
      width: 100%;
    }
  }

  .action-operator-restriction {
    .ant-select {
      width: 90%;
    }
  }

  .block-types-tabs {
    .ant-tabs-tab {
      border-color: #dbdbdb;
    }
  }

  .device-actions-last-step,
  .device-actions-last-step-error {
    padding: 32px;
    .ant-result-title {
      font-size: 1.3rem;
      margin-bottom: 4px;
    }
  }
  .device-actions-last-step .ant-result-subtitle {
    text-align: justify;
  }
  .device-actions-last-step-error .ant-result-subtitle {
    text-align: center;
  }

  .action-transfer {
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
