import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Drawer, Col, Row, Button } from 'antd';
import Stat from '@components/Stat/Stat';

import { useTranslation } from 'react-i18next';

import Table from '@components/Table';
import { dateFormat, bytesFormat, hourFormat } from '@utils/formatters';
import moment from 'moment';
import DatePicker from '@components/DatePicker/DatePicker';
import {
  PhoneOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { VscRadioTower } from 'react-icons/vsc';

import Title from 'antd/lib/typography/Title';
import useSwr from '@hooks/useSwr';
import CoverageMap from '@src/components/Maps/CoverageMap/CoverageMap';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30'],
  style: { marginRight: 20 },
};

const Sessions = ({ msisdn }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['sessions', 'device-actions']);

  const [date, setDate] = useState(moment());
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const { data } = useSwr('/service-proxy/accts/accounts', {
    page: pagination?.current - 1,
    linesPerPage: pagination?.pageSize,
    msisdn,
    initDate: (date ?? moment()).format('YYYY-MM-DD'),
    endDate: (date ?? moment()).format('YYYY-MM-DD'),
  });

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [msisdn, date]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const handleTableChange = paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  };

  // Drawer
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerLocationInfo, setDrawerLocationInfo] = useState();

  const handleLocationInfoClick = useCallback(row => {
    setDrawerLocationInfo(row);
    setDrawerVisible(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: t('table.localization'),
        dataIndex: 'city',
        render: (value, record) =>
          record.latitude &&
          record.longitude && (
            <Button
              type="link"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => handleLocationInfoClick(record)}
              icon={<VscRadioTower style={{ marginRight: 8 }} />}
            >
              {value ?? t('table.localization')}
            </Button>
          ),
      },
      {
        title: t('table.apn'),
        dataIndex: 'apn',
      },
      {
        title: t('table.ip'),
        width: `${t('table.ip').length + 6}ch`,
        dataIndex: 'ip',
      },
      {
        title: t('table.technology'),
        dataIndex: 'technology',
        width: `${t('table.technology').length + 6}ch`,
      },
      {
        title: t('table.operator'),
        dataIndex: 'operator',
      },
      {
        title: t('table.consume'),
        dataIndex: 'consumption',
        render: bytesFormat,
      },
      {
        title: t('table.duration'),
        dataIndex: 'sessionTime',
        width: `${t('table.duration').length + 6}ch`,

        render: hourFormat,
      },
      {
        title: t('table.acctstarttime'),
        dataIndex: 'startTime',
        width: `${t('table.acctstarttime').length + 6}ch`,
        render: value => dateFormat(value, language),
      },
      {
        title: t('table.acctstoptime'),
        dataIndex: 'stopTime',
        width: `${t('table.acctstoptime').length + 6}ch`,
        render: value => dateFormat(value, language),
      },
    ],
    [t, language, handleLocationInfoClick],
  );

  return (
    <>
      <Drawer
        width="60%"
        placement="right"
        closable={false}
        bodyStyle={{ backgroundColor: '#f0f2f5' }}
        onClose={() => {
          setDrawerVisible(false);
        }}
        destroyOnClose
        visible={drawerVisible}
      >
        {drawerLocationInfo && (
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <div style={{ flex: '0 0 50px' }}>
              <Title level={4}>{t('table.localization')}</Title>
            </div>
            <Row gutter={[12, 12]} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <Stat
                  value={`${drawerLocationInfo.city ?? ''} ${
                    drawerLocationInfo.city && drawerLocationInfo.state
                      ? ','
                      : ''
                  } ${drawerLocationInfo.state ?? ''}`}
                  title={t('drawer.city')}
                  icon={<EnvironmentOutlined />}
                />
              </Col>
              <Col span={12}>
                <Stat
                  value={`${drawerLocationInfo.latitude ?? '-'}° , ${
                    drawerLocationInfo.longitude ?? '-'
                  }°`}
                  title={
                    <>
                      <div>
                        {t('drawer.coordinates')}
                        <span
                          style={{ fontSize: '0.75rem', marginLeft: '5px' }}
                        >
                          {`(${t('drawer.antennasbase')})`}
                        </span>
                      </div>
                    </>
                  }
                  icon={<GlobalOutlined />}
                />
              </Col>
              <Col span={12}>
                <Stat
                  value={drawerLocationInfo.antenna ?? '-'}
                  title={t('drawer.antenna') + ' (0x)'}
                  icon={<VscRadioTower />}
                />
              </Col>
              <Col span={12}>
                <Stat
                  value={
                    drawerLocationInfo.operator
                      ? `${drawerLocationInfo.operator} (${
                          drawerLocationInfo.technology ?? ''
                        })`
                      : '-'
                  }
                  title={t('table.operator')}
                  icon={<PhoneOutlined />}
                />
              </Col>
            </Row>

            <CoverageMap
              mapHeight={580}
              dataProps={
                drawerLocationInfo?.latitude || drawerLocationInfo?.longitude
                  ? [
                      {
                        id: `${drawerLocationInfo?.id}`,
                        lat: Number(drawerLocationInfo.latitude),
                        lng: Number(drawerLocationInfo?.longitude),
                      },
                    ]
                  : undefined
              }
              allowCoverage
            />
          </div>
        )}
      </Drawer>
      <Table
        columns={columns}
        data={data?.content}
        customRowKey="uniqueId"
        loading={!data}
        title={t('title')}
        tableKey="sessionsTable"
        pagination={pagination}
        onChange={handleTableChange}
        extraActions={
          <DatePicker
            value={date}
            onChange={setDate}
            style={{ margin: '0 12px' }}
            disabledDate={current => {
              return current && current > moment().endOf('day');
            }}
          />
        }
      />
    </>
  );
};

export default Sessions;
