import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Table from '@components/Table';
import useSwr from '@src/hooks/useSwr';
import { dateFormat } from '@src/utils/formatters';
import 'moment/locale/pt-br';
import ModalSender from './ModalSender/index';
import { Col, Popconfirm, Button, message, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import SessionContext from '@src/store/SessionContext/SessionContext';
import useSearchParams from '@src/hooks/useSearchParams';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import { Label } from '@src/components/ui/index';

const SmsSenders = () => {
  const { t } = useTranslation('sms-send');

  const [selectedClient, setSelectedClient] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const { data, mutate } = useSwr(`/service-proxy/sms-senders`, {
    customerId: selectedClient,
  });

  const {
    customer: { customersOptions },
  } = useContext(SessionContext);

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customerId',
        setState: setSelectedClient,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );
  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.customerName'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={customersOptions}
            loading={!customersOptions}
            disabled={!customersOptions}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={selectedClient ? Number(selectedClient) : undefined}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
    ],
    [t, customersOptions, selectedClient, handleSetSearchParams],
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleDelete = useCallback(
    async idToDelete => {
      try {
        await apiMiddleware.delete(`/service-proxy/sms-senders/${idToDelete}`);
        message.success(t('sender.deleted'));
      } catch (err) {
        message.error(t('sender.error'));
      }
      mutate();
    },
    [mutate, t],
  );

  const columns = useMemo(() => {
    const defaultColumns = [
      { title: t('table.customerName'), dataIndex: 'customerName' },
      { title: t('table.sender'), dataIndex: 'sender' },
      {
        title: t('table.createdAt'),
        dataIndex: 'createdAt',
        render: value => value && dateFormat(value),
      },
      {
        title: t('table.updatedAt'),
        dataIndex: 'updatedAt',
        render: value => value && dateFormat(value),
      },
      {
        title: t('table.createdBy'),
        dataIndex: 'createdBy',
      },
      {
        title: t('table.updatedBy'),
        dataIndex: 'updatedBy',
      },
      {
        title: t('columns.actions'),
        dataIndex: 'actions',
        align: 'center',
        render: (value, record) => (
          <>
            <ModalSender defaultValues={record} mutate={mutate} />

            <Popconfirm
              title={t('pop-over.confirm-delete')}
              cancelText={t('pop-over.no')}
              okText={t('pop-over.yes')}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link" size="large" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    return defaultColumns;
  }, [t, mutate, handleDelete]);

  return (
    <>
      <Table
        title={
          <>
            {t('title.sender')}
            {data && (
              <span
                style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
              >
                {` (${data.length} ${t('of', {
                  total: data.length,
                })})`}
              </span>
            )}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        columns={columns}
        data={data}
        extraActions={
          <>
            <ModalSender mutate={mutate} />
          </>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
        loading={!data}
        tableKey={'sms-send-senders'}
        pagination={false}
      />
    </>
  );
};

export default SmsSenders;
