import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Space, Tabs, Select, Col, Tooltip } from 'antd';
import { Label } from '@components/ui';
import { Link } from 'react-router-dom';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import { useTranslation } from 'react-i18next';
import {
  dateFormat,
  getDateFromBRPattern,
  getDateToBRPattern,
  getDateWithISOFormat,
} from '@utils/formatters';
import Table from '@components/Table';
import { Filters, FiltersCard } from '@components/Filters';
import useSwr from '@hooks/useSwr';
import DatePicker from '@components/DatePicker/DatePicker';
import {
  DownloadOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import useSearchParams from '@src/hooks/useSearchParams';
import SessionContext from '@src/store/SessionContext/SessionContext';
import rolesConstants from '@src/utils/rolesConstants';

const { TabPane } = Tabs;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [],
  total: 1,
};

const Reports = () => {
  const { t } = useTranslation('reports');

  const { keycloak } = useContext(KeycloakContext);
  const customerId = keycloak?.idTokenParsed?.customerId;
  const customerType = keycloak?.idTokenParsed?.customerType;
  const isBroker = ['SUPERBROKER', 'BROKER'].includes(customerType);
  const { userRoles } = useContext(SessionContext);
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [initialDate, setInitialDate] = useState();

  const [showFilters, setShowFilters] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const { data } = useSwr(
    `/report-request/customers/${customerId}`,
    {
      type: type?.toUpperCase(),
      status,
      initialDate: initialDate ? getDateToBRPattern(initialDate) : undefined,
      page: pagination.current - 1 || pagination.defaultCurrent - 1,
      linesPerPage: pagination.pageSize,
    },
    { refreshInterval: 10 * 1000 },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'type',
        setState: setType,
        inTheFilters: false,
      },
      {
        name: 'initialDate',
        setState: setInitialDate,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [type, status, initialDate]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
        current: data.pageable?.pageNumber + 1,
      }));
    }
  }, [data]);

  const haveSmsSend = useMemo(() => {
    const hasRole = userRoles?.['portal']?.includes(rolesConstants.SMS_SEND);
    return hasRole;
  }, [userRoles]);

  const haveSmsHiring = useMemo(() => {
    const hasRole = userRoles?.['portal']?.includes(rolesConstants.SMS_HIRING);
    return hasRole;
  }, [userRoles]);

  const selectOptions = useMemo(() => {
    const defaultOptions = {
      type: [
        {
          label: t('types.INVENTORY'),
          value: 'MOT_INVENTORY',
        },
        {
          label: t('types.FINANCIAL'),
          value: 'FINANCIAL',
        },
        {
          label: t('types.BROKER_INVOICE'),
          value: 'BROKER_INVOICE',
        },
        {
          label: t('types.CUSTOMERS'),
          value: 'CUSTOMERS',
        },
        {
          label: t('types.MANAGEMENT'),
          value: 'MANAGEMENT',
        },
        {
          label: t('types.LORAWAN_DEVICE_MOT'),
          value: 'LORAWAN_DEVICE_MOT',
        },
        {
          label: t('types.LORAWAN_MESSAGE'),
          value: 'LORAWAN_MESSAGE',
        },
        {
          label: t('types.BROKER_INVOICE_LORA'),
          value: 'BROKER_INVOICE_LORA',
        },
        { label: t('types.ALERT_EVENTS'), value: 'ALERT_EVENTS' },
      ],
      status: [
        {
          label: t('filters.processing'),
          value: 'F',
        },
        {
          label: t('filters.concluded'),
          value: 'T',
        },
      ],
    };

    if (isBroker) {
      defaultOptions.type.push(
        {
          label: t('types.CANCELLATION_MANAGEMENT'),
          value: 'CANCELLATION_MANAGEMENT',
        },
        {
          label: t('types.CANCELLATION_MANAGEMENT_DETAILED'),
          value: 'CANCELLATION_MANAGEMENT_DETAILED',
        },
        {
          label: t('types.CANCELLATION'),
          value: 'CANCELLATION',
        },
        {
          label: t('types.SUBSTITUTION'),
          value: 'SUBSTITUTION',
        },
        {
          label: t('types.SUSPENSION'),
          value: 'SUSPENSION',
        },
        {
          label: t('types.SUSPENSION_REVERSE'),
          value: 'SUSPENSION_REVERSE',
        },
        {
          label: t('types.MANAGE_SMS'),
          value: 'MANAGE_SMS',
        },
      );
    }

    if (haveSmsSend) {
      defaultOptions.type.push(
        {
          label: t('types.SMS'),
          value: 'SMS',
        },
        {
          label: t('types.SMS_REQUESTS'),
          value: 'SMS_REQUESTS',
        },
      );
    }

    if (haveSmsHiring) {
      defaultOptions.type.push({
        label: t('types.SMS_HIRING'),
        value: 'SMS_HIRING',
      });
    }

    return defaultOptions;
  }, [t, isBroker, haveSmsSend, haveSmsHiring]);

  const handleFileColumn = useCallback(
    (url, record) => {
      const reportStatus = record?.status;
      switch (reportStatus) {
        case 'UNEXPECTED_ERROR':
          return <CloseCircleOutlined style={{ color: 'red' }} />;
        case 'FINISH':
        case 'WAITING_FORMATTING':
          return (
            <Link to={`/reports/${url}`} target="_blank" download>
              <Tooltip
                title={t(
                  `download${
                    reportStatus === 'WAITING_FORMATTING'
                      ? '-unformatted-file'
                      : ''
                  }`,
                )}
              >
                <DownloadOutlined
                  style={{
                    fontSize: '16px',
                    color: 'var(--primary-color)',
                  }}
                />
              </Tooltip>
            </Link>
          );
        default:
          return (
            <LoadingOutlined
              style={{
                fontSize: '16px',
                color: 'var(--primary-color)',
              }}
            />
          );
      }
    },
    [t],
  );

  const columns = useMemo(
    () => [
      { title: t('table.user'), dataIndex: 'customerName' },
      {
        title: t('table.type'),
        dataIndex: 'type',
        render: type => t(`types.${type}`),
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        align: 'center',
        render: value => value && t(`status.${value}`),
      },
      {
        title: t('table.start-request'),
        dataIndex: 'initialDate',
        render: initialDate => dateFormat(initialDate),
      },
      {
        title: t('table.final-request'),
        dataIndex: 'finalDate',
        render: finalDate => dateFormat(finalDate),
      },
      {
        title: t('table.file'),
        dataIndex: 'url',
        align: 'center',
        render: handleFileColumn,
      },
    ],
    [t, handleFileColumn],
  );

  const onChangeInitialDate = (_, dateString) => {
    const formattedDate = getDateFromBRPattern(dateString);
    handleSetSearchParams({ initialDate: formattedDate });
  };

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['type'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const handleTabFilterChange = useCallback(
    value => {
      const newType = value === 'all' ? undefined : value.toLowerCase();
      handleSetSearchParams({ type: newType });
    },
    [handleSetSearchParams],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const statusOptions = useMemo(
    () =>
      [
        'IN_QUEUE',
        'PROCESSING',
        'FORMATTING',
        'FINISH',
        'UNEXPECTED_ERROR',
        'WAITING_FORMATTING',
      ].map(value => ({
        label: t(`status.${value}`),
        value,
      })),
    [t],
  );

  return (
    <Space direction={'vertical'} style={{ width: '100%' }}>
      <Table
        title={
          <>
            {t('title')}{' '}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        columns={columns}
        data={data?.content || []}
        loading={!data?.content}
        pagination={pagination}
        onChange={handleTableChange}
        tableKey={'reports'}
        tabsFilters={
          <Tabs
            tabBarStyle={{
              margin: 0,
              padding: '0 20px',
              background: showFilters ? '#ffffff' : '#f9f9f9',
              borderBottom: '2px solid #808fe671',
            }}
            activeKey={type?.toUpperCase()}
            defaultActiveKey="all"
            onChange={handleTabFilterChange}
          >
            <TabPane key="all" tab={t('all')} />
            {selectOptions?.type?.map(step => (
              <TabPane key={step.value} tab={step.label} />
            ))}
          </Tabs>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              <Col lg={8} sm={12} xl={6} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('table.from-request')}</Label>
                </div>
                <DatePicker
                  allowClear
                  format={'DD/MM/YYYY'}
                  disabledDate={d => d && d > moment().endOf('day')}
                  placeholder={t('table.start-request')}
                  size={'middle'}
                  value={
                    initialDate && moment(getDateWithISOFormat(initialDate))
                  }
                  style={{ width: '100%' }}
                  onChange={onChangeInitialDate}
                />
              </Col>
              <Col lg={8} sm={12} xl={7} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'} htmlFor={'status'}>
                    {t('table.status')}
                  </Label>
                </div>
                <Select
                  optionFilterProp="label"
                  options={statusOptions}
                  placeholder={t('table.status')}
                  style={{ width: '100%' }}
                  value={status}
                  allowClear
                  showSearch
                  onChange={value => handleSetSearchParams({ status: value })}
                />
              </Col>
            </FiltersCard>
          )
        }
      />
    </Space>
  );
};

export default Reports;
