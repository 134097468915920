import React, { useCallback, useMemo } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const FileUpload = ({
  onUpload,
  onRemove,
  uploadColumns,
  maxCount = 1,
  ...props
}) => {
  const { t } = useTranslation(['file-uploader', 'device-actions-modal']);

  const handleUpload = useCallback(
    result => {
      if (result) {
        try {
          const csvHeaderReference = JSON.stringify(
            uploadColumns?.map(item => item.title),
          );

          const headerInCsv = JSON.stringify(result.meta.fields);
          if (csvHeaderReference === headerInCsv) {
            const devices = result.data.map(device => {
              const rowObject = {};
              uploadColumns?.forEach(header => {
                // @ts-ignore
                rowObject[header.dataIndex] = device[header.title];
              });
              return rowObject;
            });
            onUpload(devices);
          } else {
            message.error(t('device-actions-modal:invalid-header'));
          }
        } catch (err) {
          message.error(
            t('device-actions-modal:invalid-data', { register: err?.message }),
          );
          onUpload(undefined);
        }
      } else {
        onUpload(undefined);
      }
    },
    [onUpload, t, uploadColumns],
  );

  const convertFileInObj = useCallback(
    file => {
      Papa.parse(file, {
        skipEmptyLines: 'greedy',
        header: true,
        complete: result => handleUpload(result),
      });
    },
    [handleUpload],
  );

  const uploaderProps = useMemo(
    () => ({
      name: 'file',
      multiple: false,
      accept: '.csv',
      maxCount,
      beforeUpload: file => {
        convertFileInObj(file);
        return false;
      },
      onRemove,
      ...props,
    }),
    [convertFileInObj, maxCount, onRemove, props],
  );

  return (
    <Upload.Dragger {...uploaderProps}>
      <S.DraggerContent>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('uploader-title')}</p>
        <p className="ant-upload-hint">{t('uploader-subtitle')}</p>
      </S.DraggerContent>
    </Upload.Dragger>
  );
};

export default FileUpload;
