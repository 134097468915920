import { LorawanActionTypes } from '@utils/deviceActions';

const loraWanModels = actionKey => {
  const model = ['devEui'];
  if (actionKey === LorawanActionTypes.CHANGE_DESCRIPTION) {
    model.push('description');
  } else if (actionKey === LorawanActionTypes.CHANGE_TAGS) {
    model.push('tags');
  } else if (actionKey === LorawanActionTypes.TRANSFER) {
    model.push('newCustomerVirtualAccountId');
  }
  return model;
};

export default loraWanModels;
