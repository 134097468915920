import { hostIsAllcom } from '@src/utils/hostIsAllcom';
import axios from 'axios';

const baseURL = hostIsAllcom()
  ? process.env.REACT_APP_MIDDLEWARE_URL
  : `${location.origin}/api`;

const threeSeconds = 3 * 60000;

const apiMiddleware = axios.create({
  baseURL,
  timeout: threeSeconds,
  headers: {},
});

export default apiMiddleware;
