import SignalBars from '@src/components/SignalBars/index';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './styles.less';

const CoverageMiniTable = ({ data }) => {
  const { t } = useTranslation('maps');

  const coverageIcons = useMemo(
    () => [
      <p key={0} className="no-coverage-table-icon">
        <CloseCircleOutlined />
      </p>,
      <SignalBars key={1} numberOfBars={1} color="#5b5a5a" size={22} />,
      <SignalBars key={2} numberOfBars={2} color="#d01313" size={22} />,
      <SignalBars key={3} numberOfBars={3} color="#ea9e0d" size={22} />,
      <SignalBars key={4} numberOfBars={4} color="green" size={22} />,
      <SignalBars key={5} numberOfBars={5} color="darkgreen" size={22} />,
    ],
    [],
  );

  const mapped = useMemo(
    () =>
      data?.anatel
        ? data.anatel.reduce((acc, currentCoverage) => {
            return [
              ...acc,
              <tr key={currentCoverage.operadora}>
                <td>{currentCoverage.operadora}</td>
                <td style={{ textAlign: 'center' }} className="td-signal-bars">
                  {coverageIcons[Number(currentCoverage.cobertura2g) ?? '0']}
                </td>
                <td style={{ textAlign: 'center' }} className="td-signal-bars">
                  {coverageIcons[Number(currentCoverage.cobertura3g) ?? '0']}
                </td>
                <td style={{ textAlign: 'center' }} className="td-signal-bars">
                  {coverageIcons[Number(currentCoverage.cobertura4g) ?? '0']}
                </td>
                <td style={{ textAlign: 'center' }} className="td-signal-bars">
                  {coverageIcons[Number(currentCoverage.cobertura5g) ?? '0']}
                </td>
              </tr>,
            ];
          }, [])
        : undefined,
    [data, coverageIcons],
  );

  if (!mapped) {
    return <div>-</div>;
  }

  if (mapped.length === 0) {
    return (
      <div className="no-coverage-table">
        {coverageIcons[0]}
        <p>{t('coverageTable.unavailable-information')}</p>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '18px',
      }}
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          padding: 6,
          paddingTop: 0,
          marginBottom: 8,
          borderBottom: '1px solid #eee',
          fontSize: '0.9rem',
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        {t('coverageTable.signalQuality')}
      </div>
      <div style={{ marginTop: '4px', fontSize: '0.8125rem' }}>
        {data ? (
          <table style={{ width: '100%' }} className="coverage-table">
            <thead>
              <tr>
                <th
                  style={{
                    minWidth: '80px',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                </th>
                <th
                  style={{
                    minWidth: '40px',
                    textAlign: 'center',
                  }}
                >
                  2G
                </th>
                <th
                  style={{
                    minWidth: '40px',
                    textAlign: 'center',
                  }}
                >
                  3G
                </th>
                <th
                  style={{
                    minWidth: '40px',
                    textAlign: 'center',
                  }}
                >
                  4G
                </th>
                <th
                  style={{
                    minWidth: '40px',
                    textAlign: 'center',
                  }}
                >
                  5G
                </th>
              </tr>
            </thead>
            <tbody>{mapped}</tbody>
          </table>
        ) : (
          <div
            style={{
              height: '136px',
              width: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoverageMiniTable;
