import React, { useCallback, useState } from 'react';

import { Tooltip, Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';

function ButtonIndividualSync({
  value,
  customerId,
  invoiceId,
  mutate,
  mutateStatus,
  t,
}) {
  const [syncLoading, setSyncLoading] = useState(false);

  const synchronization = useCallback(
    () =>
      apiMiddleware.post('/service-proxy/omie/sync-invoice', {
        customerId,
        invoiceId,
      }),
    [customerId, invoiceId],
  );

  const handleSynchronization = useCallback(async () => {
    setSyncLoading(true);
    try {
      await synchronization(invoiceId);
      mutate();
      mutateStatus('success');
      message.success(t('synchronization.msgs.success'));
    } catch (error) {
      if (error.response && error.response.status === 409) {
        message.error(t('synchronization.msgs.error-sync'));
      } else {
        message.error(t('synchronization.msgs.error'));
      }
      mutateStatus('error');
    }
    setSyncLoading(false);
  }, [t, synchronization, invoiceId, mutate, mutateStatus]);

  return (
    <Tooltip
      title={
        value
          ? t('synchronization.msgs.invoice-already-synchronized')
          : t('synchronization.msgs.invoice-not-synchronized')
      }
    >
      <Button
        type={!value && 'primary'}
        icon={<SyncOutlined />}
        size="small"
        onClick={handleSynchronization}
        loading={syncLoading}
      >
        {value
          ? t('synchronization.resynchronize')
          : t('synchronization.synchronize')}
      </Button>
    </Tooltip>
  );
}

export default ButtonIndividualSync;
