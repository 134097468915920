import React from 'react';
import { useTranslation } from 'react-i18next';
import BackToListing from '@components/BackToListing/BackToListing';
import { useParams } from 'react-router-dom';
import useSwr from '@hooks/useSwr';
import DeviceStats from './DeviceStats';
import DeviceRealtime from './DeviceRealtime/DeviceRealtime';

const InventoryDetails = () => {
  const { t } = useTranslation('lorawan');
  const { devEui } = useParams();

  const { data } = useSwr(
    devEui
      ? `/service-proxy/lorawanDevices/details?product=LORAWAN_MOT&devEui=${devEui}`
      : null,
  );

  return (
    <div>
      <BackToListing title={t('deviceDetails')} />
      <DeviceStats data={data} style={{ marginTop: 24 }} />
      <DeviceRealtime devEui={devEui} />
    </div>
  );
};

export default InventoryDetails;
