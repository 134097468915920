import React, { useEffect, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { List, Switch } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

import './styles.less';

const SortableList = ({ items, onChange, switchItemChange }) => {
  const [listItems, setListItems] = useState(items);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  useEffect(() => {
    onChange(listItems);
  }, [listItems, onChange]);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer', color: '#999', fontSize: 16 }} />
  ));

  const SortableItem = SortableElement(({ item, sortIndex }) => (
    <List.Item
      actions={[
        switchItemChange && (
          <Switch
            checked={item.visible}
            size={'small'}
            onChange={() => switchItemChange(sortIndex)}
          />
        ),
      ]}
    >
      <List.Item.Meta
        avatar={<DragHandle />}
        style={{ alignItems: 'center' }}
        title={item.title}
        disabled
      />
    </List.Item>
  ));

  const Sortable = SortableContainer(() => (
    <List
      dataSource={listItems}
      itemLayout={'horizontal'}
      renderItem={(item, index) => (
        <SortableItem index={index} item={item} sortIndex={index} />
      )}
      size={'small'}
      style={{ width: '100%' }}
    />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setListItems(itemsToMove => arrayMove(itemsToMove, oldIndex, newIndex));
  };

  return (
    <Sortable
      helperClass={'sortableHelper'}
      useDragHandle
      onSortEnd={onSortEnd}
    />
  );
};

export default SortableList;
