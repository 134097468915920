import React, { useState, useEffect } from 'react';
import KeycloakContext from './KeycloakContext';
import { Spinner } from '@components/ui';

const KeycloakProvider = props => {
  const { keycloak, initOptions, axios, i18next, children } = props;

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    keycloak.init(initOptions).then(async authenticated => {
      setAuthenticated(authenticated);

      if (authenticated) {
        if (keycloak.idTokenParsed.locale) {
          await i18next.changeLanguage(keycloak.idTokenParsed.locale);
        }

        const MIN_TOKEN_VALIDITY =
          process.env.REACT_APP_MIN_TOKEN_VALIDITY || +'30';
        axios.defaults.baseURL = `${axios.defaults.baseURL}/realms/${keycloak.realm}`;

        axios.interceptors.request.use(
          async config => {
            await keycloak
              .updateToken(MIN_TOKEN_VALIDITY)
              .then(() => {
                config.headers['Authorization'] = `Bearer ${keycloak.token}`;
                setAuthenticated(true);
              })
              .catch(() => {
                setAuthenticated(false);
              });

            return config;
          },
          error => Promise.reject(error),
        );
      }
    });
  }, [axios, i18next, keycloak, initOptions]);

  return (
    <>
      {authenticated ? (
        <KeycloakContext.Provider value={{ keycloak, axios }}>
          {children}
        </KeycloakContext.Provider>
      ) : (
        <Spinner fullscreen />
      )}
    </>
  );
};

KeycloakProvider.propTypes = {};

export default KeycloakProvider;
