import { hostIsAllcom } from '@src/utils/hostIsAllcom';
import axios from 'axios';

const axiosBasic = axios.create({
  baseURL: hostIsAllcom() ? process.env.REACT_APP_BASIC_URL : location.origin,
  timeout: 60000,
  headers: {},
});

export default axiosBasic;
