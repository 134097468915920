import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Form, Input, Radio, Steps, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { LorawanActionTypes } from '@utils/deviceActions';
import { hexadecimalGenerator } from '@utils/hexadecimalGenerator';
import { useNavigate } from 'react-router-dom';
import cleanObject from '@utils/cleanObject';
import apiMiddleware from '@src/services/apiMiddleware';
import DetailsStep from './ActivationModalSteps/DetailsStep';
import ConfigStep from './ActivationModalSteps/ConfigStep';
import TypeStep from './ActivationModalSteps/TypeStep';

import * as S from './styles';

const { Item } = Form;

const ActivationModal = ({ visible, isBatch, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['lorawan', 'device-actions-modal']);

  const [...forms] = [...Form.useForm(), ...Form.useForm(), ...Form.useForm()];
  const [saveLoading, setSaveLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [uploadedDevices, setUploadedDevices] = useState();
  const [typeValues, setTypeValues] = useState();
  const [detailsValues, setDetailsValues] = useState();
  const [activation, setActivation] = useState();
  const [encryption, setEncryption] = useState();

  useEffect(() => {
    if (forms?.[0]) {
      setActivation(forms[0].getFieldValue('activation'));
      setEncryption(forms[0].getFieldValue('encryption'));
    }
  }, [forms]);

  const postActivation = useCallback(
    payload => apiMiddleware.post('/service-proxy/lorawan/actions', payload),
    [],
  );

  const renderItemLabel = useCallback(
    (label, isRequired) => (
      <>
        {isRequired && <span style={{ color: 'red' }}>*&nbsp;</span>}
        {t(label)}
      </>
    ),
    [t],
  );

  const renderLabelWithHexadecimalGenerate = useCallback(
    (label, numSize) => (
      <>
        {renderItemLabel(label)}
        {numSize && (
          <Tooltip title={t('generateHexadecimal')}>
            <Button
              icon={<SyncOutlined />}
              type="link"
              size="small"
              onClick={() => {
                forms?.[step].setFieldValue(
                  label,
                  hexadecimalGenerator(numSize),
                );
              }}
            />
          </Tooltip>
        )}
      </>
    ),
    [forms, renderItemLabel, step, t],
  );

  const validateLength = useCallback(
    (value, size) =>
      value && value.length === size
        ? Promise.resolve()
        : Promise.reject(new Error(t('fieldSize', { size }))),
    [t],
  );

  const renderInputItem = useCallback(
    (name, numSize, validateNumSize) => (
      <Item
        label={
          numSize
            ? renderLabelWithHexadecimalGenerate(name, numSize)
            : renderItemLabel(name)
        }
        name={name}
        rules={[
          {
            required: true,
            message: t('fieldIsRequired'),
          },
          ...(validateNumSize
            ? [
                {
                  validator: (_, value) => validateLength(value, numSize),
                },
              ]
            : []),
        ]}
      >
        <Input allowClear />
      </Item>
    ),
    [renderItemLabel, renderLabelWithHexadecimalGenerate, t, validateLength],
  );

  const renderRadioItem = useCallback(
    (name, options) => (
      <Item
        label={t(name)}
        name={name}
        rules={[
          {
            required: true,
            message: t('fieldIsRequired'),
          },
        ]}
      >
        <Radio.Group defaultValue={options?.[0]} buttonStyle="solid">
          {options?.map(opt => (
            <Radio.Button key={opt} value={opt}>
              {opt}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Item>
    ),
    [t],
  );

  const handleClose = useCallback(() => {
    forms.forEach(f => f.resetFields());
    setStep(0);
    setUploadedDevices(undefined);
    onClose();
  }, [forms, onClose]);

  const payloadToSend = useCallback(
    configValues => {
      const {
        requestId,
        contractMotId,
        contractLorawanId,
        ...detailsValuesRest
      } = detailsValues || {};

      // activation made from allcom
      const allcomPayload = {
        contractIdMOT: contractMotId,
        contractId: contractLorawanId,
      };
      // activation made from allcom children
      const childOfAllcomPayload = { accountId: requestId };

      const defaultPayload = {
        action: LorawanActionTypes.ACTIVATION,
        devices: [],
        ...(requestId ? childOfAllcomPayload : allcomPayload),
      };

      const deviceDefaultValues = {
        ...typeValues,
        ...configValues,
        band: 'LA915-928A',
      };

      let devices = [];

      if (isBatch) {
        devices =
          uploadedDevices?.map(device =>
            cleanObject({
              ...deviceDefaultValues,
              ...device,
              tags: `${device?.tags}`.split(';'),
            }),
          ) ?? [];
      } else {
        devices = detailsValuesRest
          ? [
              {
                ...deviceDefaultValues,
                ...detailsValuesRest,
              },
            ]
          : [];
      }
      return {
        ...defaultPayload,
        devices,
      };
    },
    [detailsValues, isBatch, typeValues, uploadedDevices],
  );

  const handleSuccess = useCallback(() => {
    const navigatePath = `/lorawan/actions-queue?actionType=0`;
    message.success(
      <>
        <span>{t('device-actions-modal:msgs.requested-action')}</span>
        <Button
          type="link"
          style={{ margin: '0 4px', padding: 0 }}
          onClick={() => navigate(navigatePath)}
        >
          {t('device-actions-modal:msgs.follow-in-the-actions-queue')}
        </Button>
      </>,
      2,
    );
  }, [navigate, t]);

  const handleFormFinish = useCallback(
    async configValues => {
      setSaveLoading(true);
      try {
        await postActivation(payloadToSend(configValues));
        handleSuccess();
        handleClose();
      } catch {
        message.error(t('errorWhenActivating'));
      }
      setSaveLoading(false);
    },
    [postActivation, payloadToSend, handleSuccess, handleClose, t],
  );

  const formValidate = useCallback(
    () =>
      forms[step].validateFields().then(data => {
        forms[step].submit();
        return data;
      }),
    [forms, step],
  );

  const handleStep = useCallback(
    action => {
      if (action === 'back') {
        setStep(oldStep => oldStep - 1);
      } else if (action === 'next') {
        formValidate().then(values => {
          if (step === 0) {
            setTypeValues(values);
          } else {
            setDetailsValues(values);
          }
          setStep(oldStep => oldStep + 1);
        });
      } else if (action === 'confirm') {
        formValidate().then(values => {
          handleFormFinish(values);
        });
      }
    },
    [formValidate, handleFormFinish, step],
  );

  const modalSteps = useMemo(() => {
    const defaultSteps = [
      {
        name: 'type',
        content: (
          <TypeStep key={0} form={forms[0]} renderRadioItem={renderRadioItem} />
        ),
      },
      {
        name: 'details',
        content: (
          <DetailsStep
            key={1}
            form={forms[1]}
            isBatch={isBatch}
            onUpload={setUploadedDevices}
            activation={activation}
            encryption={encryption}
            renderItemLabel={renderItemLabel}
            renderInputItem={renderInputItem}
          />
        ),
      },
      {
        name: 'config',
        content: (
          <ConfigStep
            key={2}
            form={forms[2]}
            renderItemLabel={renderItemLabel}
            renderRadioItem={renderRadioItem}
            isBatch={isBatch}
          />
        ),
      },
    ];

    return defaultSteps;
  }, [
    forms,
    isBatch,
    renderRadioItem,
    activation,
    encryption,
    renderItemLabel,
    renderInputItem,
  ]);

  const stepsTitles = useMemo(
    () => modalSteps?.map(({ name }) => ({ title: t(name) })),
    [modalSteps, t],
  );

  return (
    <S.ModalContainer
      bodyStyle={{ padding: '24px 40px' }}
      afterClose={() => document.body.style.removeProperty('overflow')}
      footer={
        <>
          {step > 0 && (
            <Button onClick={() => handleStep('back')}>
              {t('buttons.back')}
            </Button>
          )}

          {step === 2 ? (
            <Button
              type="primary"
              onClick={() => handleStep('confirm')}
              loading={saveLoading}
            >
              {t('buttons.confirm')}
            </Button>
          ) : (
            <Button type="primary" onClick={() => handleStep('next')}>
              {t('buttons.next')}
            </Button>
          )}
        </>
      }
      maskClosable={false}
      title={t(`${isBatch ? 'batch' : 'unit'}Activation`)}
      open={visible}
      centered
      forceRender
      onCancel={() => {
        document.body.style.removeProperty('overflow');
        handleClose();
      }}
    >
      <Steps
        size="small"
        current={step}
        items={stepsTitles}
        style={{ marginBottom: 48 }}
      />
      {modalSteps?.[step]?.content}
    </S.ModalContainer>
  );
};

export default ActivationModal;
