import React, { useCallback, useState } from 'react';

import {
  Modal,
  Button,
  Typography,
  Divider,
  message,
  Tag,
  Slider,
  InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.less';
import apiMiddleware from '@src/services/apiMiddleware';

const { Paragraph } = Typography;

const AddCredit = ({
  creditType,
  idToAdd,
  visible,
  onClose,
  type = 'input',
}) => {
  const { t } = useTranslation('credits');

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [franchise, setFranchise] = useState(0);

  const postCreditSim = useCallback(
    (id, payload) =>
      apiMiddleware.post(`/service-proxy/sims/${id}/credit`, payload),
    [],
  );

  const postCreditContract = useCallback(
    (id, payload) =>
      apiMiddleware.post(
        `/service-proxy/broker/virtual-plan/${id}/credit`,
        payload,
      ),
    [],
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleFinish = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const data = {
        franchiseMB: franchise,
      };
      if (creditType === 'sim') {
        await postCreditSim(idToAdd, data);
      } else {
        await postCreditContract(idToAdd, data);
      }
      message.success(t('msgs.success-to-add'));
      handleClose();
    } catch (error) {
      const status = error?.response?.status;
      if (status === 404) {
        if (creditType === 'sim') {
          message.error(t('msgs.request-not-found-sim'));
        } else {
          message.error(t('msgs.request-not-found'));
        }
      } else {
        message.error(t('msgs.error-to-add'));
      }
    }
    setConfirmLoading(false);
  }, [
    creditType,
    idToAdd,
    postCreditContract,
    postCreditSim,
    t,
    handleClose,
    franchise,
  ]);

  return (
    <Modal
      bodyStyle={{ minHeight: 180, padding: 24 }}
      footer={
        <Button
          loading={confirmLoading}
          type={'primary'}
          onClick={handleFinish}
          disabled={franchise === 0}
        >
          {t('confirm')}
        </Button>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerForm', htmlType: 'submit' }}
      style={{ maxWidth: 480 }}
      title={t('add-credit')}
      visible={visible}
      width={'100%'}
      centered
      onCancel={handleClose}
    >
      <Paragraph>{t('description')}</Paragraph>
      <Divider />
      {type === 'input' ? (
        <div style={{ textAlign: 'center' }}>
          <InputNumber
            defaultValue={franchise}
            formatter={value => `${value} MB`}
            min={0}
            size="large"
            parser={value => value.replace(' MB', '')}
            style={{ width: '100%', maxWidth: 140, margin: '0 auto' }}
            onChange={setFranchise}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '80%', padding: '0 8px' }}>
            <Slider
              max={20}
              tipFormatter={value => `${value} MB`}
              onChange={setFranchise}
            />
          </div>
          <div style={{ textAlign: 'center', width: '20%' }}>
            <Tag
              color="processing"
              style={{
                width: '100%',
                fontSize: 14,
                padding: '8px 16px',
              }}
            >{`${franchise} MB`}</Tag>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddCredit;
