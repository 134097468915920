import React, { memo, useMemo } from 'react';

import { Tooltip, Tag } from 'antd';
import { getDateToBRPattern } from '@src/utils/formatters';
import { useTranslation } from 'react-i18next';

import { RiCalendarTodoFill } from 'react-icons/ri';

const BlockStatusTag = ({
  blockType,
  blockingInitialDate,
  blockingFinalDate,
}) => {
  const { t } = useTranslation('inventory');

  const iconsStyles = {
    margin: 2,
    opacity: 0.4,
    fontSize: 20,
    color: 'green',
    cursor: 'pointer',
  };

  const showBlockingDates = useMemo(
    () =>
      ![
        '0',
        '1',
        '5',
        'NONE',
        'TEMPORARY-SUSPENSION',
        'CANCELLATION',
        'QUARENTINE',
      ].includes(blockType) &&
      (!['null', ''].includes(blockingInitialDate) ||
        !['null', ''].includes(blockingFinalDate)),
    [blockType, blockingInitialDate, blockingFinalDate],
  );

  const titleToolTip = useMemo(() => {
    let title = getDateToBRPattern(blockingInitialDate);
    // 3 = temporary
    if (
      blockType === '3' ||
      blockType === 'TEMPORARY' ||
      blockType === 'SUSPENSION'
    ) {
      if (blockingFinalDate) {
        title += ` ${t('consumption.to')} ${getDateToBRPattern(
          blockingFinalDate,
        )}`;
      }
    }

    return title;
  }, [blockType, t, blockingInitialDate, blockingFinalDate]);

  if (!blockType) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Tag color={blockType === 'NONE' ? 'green' : 'red'}>
        {t(`lock-status.types.${blockType}`).toUpperCase()}
      </Tag>

      {showBlockingDates && (
        <Tooltip title={titleToolTip}>
          <RiCalendarTodoFill style={{ ...iconsStyles, fontSize: 18 }} />
        </Tooltip>
      )}
    </div>
  );
};

export default memo(BlockStatusTag);
