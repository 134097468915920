import React, { useContext } from 'react';
import SessionContext from '@src/store/SessionContext/SessionContext';

const Logo = () => {
  const { logoImgName } = useContext(SessionContext);
  return (
    <span>
      <img alt={'Allcom'} src={`/images/${logoImgName}`} width={'100%'} />
    </span>
  );
};

export default Logo;
