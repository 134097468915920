import React, { memo, useCallback, useMemo, useState } from 'react';
import { Label } from '@components/ui';
import apiMiddleware from '@src/services/apiMiddleware';
import { Button, Col, Modal, Row, message, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function SmsContracsRequestReport({ visible, onClose }) {
  const { t, i18n } = useTranslation('invoices');
  const navigate = useNavigate();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(3, 'months').startOf('month'),
  );
  const [endDate, setEndDate] = useState(
    moment().startOf('month').endOf('month'),
  );

  const navigateToReports = useCallback(() => {
    message.destroy('invoice-report');
    navigate(`/management/reports?type=SMS_HIRING`);
  }, [navigate]);

  const requestReport = useCallback(
    () =>
      apiMiddleware.get(
        `/service-proxy/sms-hiring/report?locale=${
          i18n.language
        }&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(
          endDate,
        ).format('YYYY-MM-DD')}`,
      ),
    [i18n, startDate, endDate],
  );

  const handleClose = useCallback(() => {
    onClose();
    setStartDate(moment().startOf('month'));
    setEndDate(moment().add(3, 'months').startOf('month').endOf('month'));
  }, [onClose]);

  const handleRequestReport = useCallback(async () => {
    if (endDate.isBefore(startDate)) {
      message.error(t('report.msgs.date-error'));
      return;
    }
    try {
      setLoadingRequest(true);
      await requestReport();
      message.success({
        content: (
          <span>
            {t('report.msgs.request-success')}
            <Button
              onClick={navigateToReports}
              type="link"
              style={{ padding: 3 }}
            >
              {t('report.msgs.go-to')}
            </Button>
            {t('report.msgs.check-progress-and-download')}
          </span>
        ),
      });
    } catch {
      message.error(t('report.msgs.request-error'));
    }
    handleClose();
    setLoadingRequest(false);
  }, [t, navigateToReports, requestReport, handleClose, startDate, endDate]);

  const handleStartDateChange = useCallback(date => {
    setStartDate(date ? moment(date).startOf('month') : null);
  }, []);

  const handleEndDateChange = useCallback(date => {
    setEndDate(date ? moment(date).endOf('month') : null);
  }, []);

  const modalInputs = useMemo(() => {
    return [
      {
        col: {
          lg: 12,
          xl: 12,
          xs: 24,
        },
        label: `${t('search.date')}`,
        item: (
          <div style={{ display: 'flex', gap: '8px' }}>
            <DatePicker
              placeholder="Mês inicial"
              picker="month"
              format="MM/YYYY"
              value={startDate}
              onChange={handleStartDateChange}
              allowClear={false}
            />
            <DatePicker
              placeholder="Mês final"
              picker="month"
              format="MM/YYYY"
              value={endDate}
              onChange={handleEndDateChange}
              disabledDate={current => current && current.isBefore(startDate)}
              allowClear={false}
            />
          </div>
        ),
      },
    ];
  }, [t, startDate, endDate, handleStartDateChange, handleEndDateChange]);
  return (
    <Modal
      bodyStyle={{
        paddingTop: 0,
      }}
      footer={
        <>
          <Button onClick={handleClose}>{t('report.buttons.cancel')}</Button>
          <Button
            type={'primary'}
            loading={loadingRequest}
            disabled={loadingRequest}
            onClick={handleRequestReport}
          >
            {t('report.buttons.request')}
          </Button>
        </>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerSmSForm', htmlType: 'submit' }}
      style={{
        maxWidth: 900,
      }}
      destroyOnClose={true}
      title={t('report.request')}
      visible={visible}
      width={'80%'}
      centered
      onCancel={handleClose}
    >
      <Row gutter={[16, 16]} style={{ padding: 16 }}>
        {modalInputs?.map(({ col: { lg, xl, xs }, label, item }) => (
          <Col key={label} lg={lg} xl={xl} xs={xs}>
            {label && (
              <div>
                <Label color={'#575962'} htmlFor={label}>
                  {label}
                </Label>
              </div>
            )}
            {item}
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default memo(SmsContracsRequestReport);
