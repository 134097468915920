import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

export const EditableContext = React.createContext(null);

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  isRequired = true,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  const { t } = useTranslation('table');

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex] ?? '',
    });
  };

  const save = async () => {
    const values = await form.validateFields();
    toggleEdit();
    handleSave({ ...record, ...values });
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: isRequired,
            message: t('editable.is-required'),
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          padding: '0 24px',
          cursor: 'pointer',
          border: '1px dashed var(--primary-color)',
          color: 'var(--primary-color)',
          minHeight: 16,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
