import styled, { css } from 'styled-components';
import { List } from 'antd';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

const { Item } = List;

export const ListItemContainer = styled(Item)`
  padding: 0 !important;
  display: block;
`;

export const ListHeader = styled.div`
  background: var(--primary-color);
  color: white;
  padding: 8px 24px;
`;

export const ListItemHeader = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid transparent !important;
  transition: 0.3s ease;

  &:hover {
    background: #fafafa;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: #f4f4f4;
      border-radius: 4px 4px 0 0;
      border-bottom-color: #ccc !important;

      &:hover {
        background: #f6f6f6;
      }
    `}
`;

export const ListItemHeaderTitle = styled.div`
  opacity: 0.8;

  span:first-child {
    font-weight: bold;
  }
  span:last-child {
    margin-left: 16px;
    font-size: 0.8rem;
  }
`;

export const ListItemContent = styled.div`
  margin: 8px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  background: #fafafa;

  .ant-descriptions {
    margin-top: 16px;
    td {
      padding-bottom: 8px;
    }
  }

  pre {
    margin: 0;
  }

  .ant-segmented-item-selected {
    color: #f1f1f1;
    background: var(--primary-color);
  }
`;

export const ListItemContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ArrowIcon = styled(MdOutlineKeyboardArrowDown)`
  transition: 0.3s;
  transform: ${({ isOpen }) => `rotate(${isOpen ? 180 : 0}deg)`} scale(1.2);
`;
