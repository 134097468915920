import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    margin: 0 0 4px 0;
    opacity: 0.7;
    font-weight: bold;
    font-size: 1.2rem;
  }

  p {
    margin: 0;
    font-size: 1rem;
    margin-bottom: 16px;
    opacity: 0.6; 
`;
