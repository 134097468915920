import { Empty } from 'antd';
import React from 'react';
import { Card } from 'antd';

import * as S from '../styles';

const DashboardStatItem = ({
  title,
  value,
  valueColor,
  onClick,
  statisticProps,
}) => (
  <Card
    loading={!value}
    onClick={onClick}
    bodyStyle={{
      padding: 16,
      height: '100%',
    }}
    style={{ height: '100%' }}
  >
    {!value ? (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ height: '100%' }} />
    ) : (
      <S.StatisticStyled
        title={title}
        value={value?.toLocaleString('pt-BR')}
        valueColor={valueColor}
        style={{ height: '100%' }}
        {...statisticProps}
      />
    )}
  </Card>
);

export default DashboardStatItem;
