import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTypes from '@utils/deviceActions';
import ActionsUpload from '@components/Actions/ActionsUpload';

const useUpload = (actionKey, form, customInfo, disabled, onRemove) => {
  const { t } = useTranslation(['device-actions-modal', 'attributes']);

  const selectedAction = useMemo(() => actionKey ?? -1, [actionKey]);

  const handleUpload = useCallback(
    devices => {
      if (devices && devices?.length === 0) {
        const emptyCSVText = `* ${t('empty-csv')}`;
        form?.setFields([
          {
            name: 'uploadedItems',
            errors: [emptyCSVText],
          },
        ]);
      }
      form?.setFieldValue('uploadedItems', devices);
    },
    [form, t],
  );

  const csvFilename = useMemo(() => {
    const actionName = ActionTypes[selectedAction]
      ?.toLowerCase()
      .replaceAll('_', '-');
    const defaultName = 'import-model';
    return actionName ? `${actionName}-${defaultName}` : defaultName;
  }, [selectedAction]);

  return {
    title: t('upload.title'),
    name: 'uploadedItems',
    content: (
      <ActionsUpload
        actionKey={actionKey}
        onUpload={handleUpload}
        onRemove={onRemove}
        customInfo={customInfo}
        csvFilename={csvFilename}
        disabled={disabled}
      />
    ),
  };
};

export default useUpload;
