import apiMiddleware from '@services/apiMiddleware';
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Button,
  Select,
  InputNumber,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from '../../CustomerModal/styles';

const { Item } = Form;

const VehicleModal = ({
  vehicleData,
  visible,
  onClose,
  mutateData,
  customerId,
}) => {
  const { t: translate } = useTranslation('schedules');

  const [form] = Form.useForm();

  const [saveLoading, setSaveLoading] = useState(false);

  const createVehicle = useCallback(
    newVehicle =>
      apiMiddleware.post(
        `/service-proxy/astro/vehicle/${customerId}`,
        newVehicle,
      ),
    [customerId],
  );

  const updateVehicle = useCallback(
    payload => apiMiddleware.put(`/service-proxy/astro/vehicle`, payload),
    [],
  );

  const close = useCallback(() => {
    setSaveLoading(false);
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const onFinish = useCallback(async () => {
    const vehicleInfo = form.getFieldValue();

    try {
      if (!vehicleData) {
        const { data } = await createVehicle(vehicleInfo);
        mutateData('CREATE', {
          ...vehicleInfo,
          idVeiculo: data,
        });
        message.success(translate('msgs.success-creating-vehicle'));
        close();
      } else {
        const payload = {
          ...vehicleData,
          ...vehicleInfo,
        };
        await updateVehicle(payload);
        mutateData('UPDATE', payload);
        message.success(translate('msgs.success-updating-vehicle'));
        close();
      }
    } catch (error) {
      message.error(
        vehicleData
          ? translate('msgs.error-updating-vehicle')
          : translate('msgs.error-creating-vehicle'),
      );
    }
  }, [
    close,
    createVehicle,
    mutateData,
    translate,
    form,
    vehicleData,
    updateVehicle,
  ]);

  useEffect(() => {
    if (vehicleData) {
      form.setFieldsValue({
        ...vehicleData,
      });
    }
  }, [vehicleData, form]);

  return (
    <Styled.ModalContainer
      maskClosable={false}
      title={
        vehicleData
          ? translate('modal.title.updateVehicle')
          : translate('modal.title.newVehicle')
      }
      visible={visible}
      width="70%"
      centered
      forceRender
      footer={false}
      onCancel={() => close()}
    >
      <Form
        colon={false}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        scrollToFirstError
        onFinish={onFinish}
        onCancel={() => close()}
      >
        <Row gutter={[16, 16]}>
          <div style={{ width: '100%', opacity: '0.6' }}>
            <Divider orientation="left">
              {translate('dividers.identification')}
            </Divider>
          </div>

          <Col xl={12} xs={24}>
            <Item
              label={translate('table.year')}
              name="ano"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <Input type="number" min="1900" max="2099" />
            </Item>

            <Item
              label={translate('table.brand')}
              name="marca"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <Input />
            </Item>
          </Col>

          <Col xl={12} xs={24}>
            <Item
              label={translate('table.plate')}
              name="placa"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <Input maxLength={30} />
            </Item>

            <Item
              label={translate('table.model')}
              name="modelo"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <Input />
            </Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xl={12} xs={24}>
            <Item
              label={translate('table.fipeValue')}
              name="valorFipe"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <InputNumber
                formatter={value => `R$ ${value}`}
                min={0}
                parser={value => value.replace('R$ ', '')}
                step={0.01}
                style={{ width: '100%' }}
              />
            </Item>
          </Col>

          <Col xl={12} xs={24}>
            <Item
              label={translate('table.fuel')}
              name="combustivel"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <Select>
                <Select.Option value="etanol">
                  {translate('options.etanol')}
                </Select.Option>
                <Select.Option value="gasolina">
                  {translate('options.gasoline')}
                </Select.Option>
                <Select.Option value="diesel">
                  {translate('options.diesel')}
                </Select.Option>
                <Select.Option value="gás veicular">
                  {translate('options.gas')}
                </Select.Option>
                <Select.Option value="flex">
                  {translate('options.flex')}
                </Select.Option>

                <Select.Option value="híbrido">
                  {translate('options.hibrid')}
                </Select.Option>
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xl={12} xs={24}>
            <Item
              label={translate('table.vehicleType')}
              name="tipoVeiculo"
              rules={[
                {
                  required: true,
                  message: translate('form.msgs.fillThisField'),
                },
              ]}
            >
              <Select>
                <Select.Option value="carro">
                  {translate('options.cars')}
                </Select.Option>
                <Select.Option value="moto">
                  {translate('options.motorcycle')}
                </Select.Option>
                <Select.Option value="caminhão">
                  {translate('options.truck')}
                </Select.Option>
                <Select.Option value="outros">
                  {translate('options.others')}
                </Select.Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify="end" align="end" gutter={16}>
          <Col>
            <Button onClick={() => close()}>
              {translate('button.cancel')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" loading={saveLoading}>
              {translate('button.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Styled.ModalContainer>
  );
};

export default VehicleModal;
