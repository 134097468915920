import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import rolesConstants from '@utils/rolesConstants';
import Actions from '@components/Actions';
import ActionsItems from '@components/Actions/ActionsItems';
import apiMiddleware from '@src/services/apiMiddleware';

const InventoryActionsModal = ({
  visible,
  onClose,
  actionRole,
  selectedDevices,
  isUpload,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('device-actions-modal');
  const [form] = Form.useForm();
  const uploadedItems = Form.useWatch('uploadedItems', form);

  const [sendDeviceLoading, setSendDeviceLoading] = useState(false);

  const postAction = useCallback(
    payload => apiMiddleware.post('/service-proxy/lorawan/actions', payload),
    [],
  );

  const devices = useMemo(
    () => (isUpload ? uploadedItems : selectedDevices),
    [isUpload, selectedDevices, uploadedItems],
  );

  const transfersAction = ActionsItems.Lorawan.useTransfers({
    isUpload,
    devices,
    form,
  });

  const importAction = ActionsItems.Lorawan.useImport({
    devices,
  });

  const deactivationAction = ActionsItems.Lorawan.useDeactivation({
    isUpload,
    devices,
  });

  const uplinkBlockUnlockAction = ActionsItems.Lorawan.useUplinkBlockUnlock({
    isUpload,
    devices,
    form,
  });

  const downlinkBlockUnlockAction = ActionsItems.Lorawan.useDownlinkBlockUnlock(
    {
      isUpload,
      devices,
      form,
    },
  );

  const changeDescriptionAction = ActionsItems.Lorawan.useChangeDescription({
    isUpload,
    devices,
    form,
  });

  const changeTagsAction = ActionsItems.Lorawan.useChangeTags({
    isUpload,
    devices,
    form,
  });

  const selectedAction = useMemo(() => {
    const actions = {
      [rolesConstants.LORAWAN_TRANSFERS]: transfersAction,
      [rolesConstants.LORAWAN_IMPORT]: importAction,
      [rolesConstants.LORAWAN_DEACTIVATION]: deactivationAction,
      [rolesConstants.LORAWAN_UPLINK_BLOCK_UNLOCK]: uplinkBlockUnlockAction,
      [rolesConstants.LORAWAN_DOWNLINK_BLOCK_UNLOCK]: downlinkBlockUnlockAction,
      [rolesConstants.LORAWAN_CHANGE_DESCRIPTION]: changeDescriptionAction,
      [rolesConstants.LORAWAN_CHANGE_TAGS]: changeTagsAction,
    };

    return actionRole && actions[actionRole];
  }, [
    transfersAction,
    importAction,
    deactivationAction,
    uplinkBlockUnlockAction,
    downlinkBlockUnlockAction,
    changeDescriptionAction,
    changeTagsAction,
    actionRole,
  ]);

  const handleSuccess = useCallback(() => {
    const navigatePath = `/lorawan/actions-queue?actionType=${selectedAction?.payloadToSend?.action}`;
    message.success(
      <>
        <span>{t('msgs.requested-action')}</span>
        <Button
          type="link"
          style={{ margin: '0 4px', padding: 0 }}
          onClick={() => navigate(navigatePath)}
        >
          {t('msgs.follow-in-the-actions-queue')}
        </Button>
      </>,
      2,
    );
  }, [selectedAction?.payloadToSend?.action, t, navigate]);

  const handleOnClose = useCallback(() => {
    onClose();
    form.resetFields();
  }, [form, onClose]);

  const handleActionConfirm = useCallback(async () => {
    setSendDeviceLoading(true);
    try {
      if (selectedAction?.sendDeviceAction) {
        selectedAction?.sendDeviceAction();
      } else {
        await postAction(selectedAction?.payloadToSend);
        handleSuccess();
      }
    } catch {
      message.error(t('msgs.error-when-requesting'));
    }
    handleOnClose();
    setSendDeviceLoading(false);
  }, [handleOnClose, postAction, selectedAction, handleSuccess, t]);

  return (
    <Actions.Modal
      form={form}
      devices={[]}
      isOpen={visible}
      isUpload={isUpload}
      onClose={handleOnClose}
      onConfirm={handleActionConfirm}
      onConfirmLoading={sendDeviceLoading}
      selectedAction={selectedAction}
    />
  );
};

export default InventoryActionsModal;
