import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from 'antd';

const CardContainer = styled(Card)`
  border-radius: 5px;
  margin: 0rem;
  box-shadow: 0 1px 15px 1px rgba(81, 77, 92, 0.08);
`;

const CardShadowed = ({ children, ...rest }) => {
  return (
    <CardContainer
      headStyle={{
        fontWeight: 600,
        color: '#575962',
        fontSize: '1.25rem',
      }}
      {...rest}
    >
      {children}
    </CardContainer>
  );
};

CardShadowed.propTypes = {
  children: PropTypes.node,
};

CardShadowed.defaultProps = {
  children: null,
};

export default CardShadowed;
