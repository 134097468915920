import styled from 'styled-components';
import { Statistic } from 'antd';

export const StatisticStyled = styled(Statistic)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .ant-statistic-title {
    max-width: 50%;
    order: 2;
  }
  .ant-statistic-content {
    font-weight: bold;
    font-size: 1.6rem;
    padding: 0;
    margin: 0 16px;
    display: flex;
    align-items: center;
    opacity: 0.8;
    color: ${({ valueColor }) => valueColor};

    &::after {
      content: '|';
      position: relative;
      top: -2px;
      margin-left: 16px;
      font-size: 1.2rem;
      font-weight: normal;
      opacity: 0.2;
    }
  }
`;
