import React, { useState } from 'react';
import { Modal, Tooltip, Button, Space } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import useSwr from '@src/hooks/useSwr';
import HistoricCard from './HistoricCard/index';

const HistoricModal = ({ contractId }) => {
  const [visible, setVisible] = useState(false);

  const { data } = useSwr(
    visible ? `/service-proxy/sms-contract/history/${contractId}` : null,
  );
  const { t: translate } = useTranslation(['sms-send']);

  return (
    <>
      <Tooltip placement="top" title={translate('title.historic')}>
        <Button
          icon={<FileSearchOutlined />}
          size="large"
          type="link"
          onClick={() => setVisible(true)}
        />
      </Tooltip>

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title={translate('title.historic')}
        bodyStyle={{ maxHeight: '50vh', overflow: 'auto' }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {data?.map((item, index) => (
            <HistoricCard historicData={item} key={item.id} index={index} />
          ))}
        </Space>
      </Modal>
    </>
  );
};

export default HistoricModal;
