import React, { useMemo } from 'react';
import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import useSwr from '@hooks/useSwr';
import { useTranslation } from 'react-i18next';

const ChartDevicesByCustomer = () => {
  const { t } = useTranslation('lorawan');

  const { data } = useSwr(
    '/service-proxy/lorawanDevices/topCustomersByDeviceCount?product=LORAWAN_MOT',
  );

  const sortedData = useMemo(() => {
    return (data ?? []).slice().sort((a, b) => a?.value - b?.value);
  }, [data]);

  const config = {
    data: [{ value: 0 }, ...sortedData],
    xField: 'name',
    yField: 'value',
    columnWidthRatio: 0.6,
    color: '#1ca9e6',
    yAxis: {
      min: 0,
      minLimit: 0,
      beginAtZero: true,
      label: {
        formatter: value => Math.floor(Number(value)).toString(),
      },
    },
    meta: {
      value: {
        alias: t('devices'),
      },
    },
  };

  return (
    <Card
      title={t('devicesByCustomers')}
      style={{ width: '100%' }}
      bodyStyle={{
        padding: 24,
      }}
    >
      <Column {...config} />
    </Card>
  );
};

export default ChartDevicesByCustomer;
