import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  message,
  Input,
  Select,
  InputNumber,
  Radio,
} from 'antd';
import { useTranslation } from 'react-i18next';

import apiMiddleware from '@src/services/apiMiddleware';
import { mbytesToBytes } from '@src/utils/formatters';

const { Option } = Select;

const ModalCreateAlerts = ({ visible, onClose, mutate }) => {
  const { t } = useTranslation(['alerts', 'attributes']);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [field, setField] = useState();
  const [thresholdValue, setThresholdValue] = useState(0);
  const [name, setName] = useState();
  const [eventType, setEventType] = useState('CONSUMPTION');

  const createAlert = useCallback(
    async payload => {
      await apiMiddleware.post('/service-proxy/alerts', {
        ...payload,
        eventType,
        operator: '>',
      });

      mutate('/service-proxy/alerts');
    },
    [mutate, eventType],
  );

  const handleClose = useCallback(() => {
    setName();
    setField();
    setThresholdValue(0);
    setEventType('CONSUMPTION');
    onClose();
  }, [onClose]);

  const handleFinish = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const payload = {
        field,
        name,
        thresholdValue:
          field === 'consumptionPercent'
            ? thresholdValue
            : field === 'consumptionTotalAnomaly' ||
              field === 'consumptionCurrentDayAnomaly'
            ? thresholdValue / 100
            : mbytesToBytes(thresholdValue),
      };
      await createAlert(payload);
      message.success(t('msgs.alert-created'));
      handleClose();
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data === 'MAX_ALERTS_REACHED'
      ) {
        message.error(t('msgs.max-alerts'));
        handleClose();
        return;
      }
      message.error(t('msgs.alert-creation-error'));
      handleClose();
    }
    setConfirmLoading(false);
  }, [field, thresholdValue, createAlert, name, t, handleClose]);

  useEffect(() => {
    if (
      field === 'consumptionPercent' ||
      field === 'consumptionTotalAnomaly' ||
      field === 'consumptionCurrentDayAnomaly'
    ) {
      setThresholdValue(100);
    }
    if (field === 'consumptionCurrentDay' || field === 'consumptionTotal') {
      setThresholdValue(0);
    }
  }, [visible, field]);

  return (
    <Modal
      bodyStyle={{ minHeight: 180, padding: 24 }}
      footer={
        <Button
          loading={confirmLoading}
          type={'primary'}
          onClick={handleFinish}
          disabled={!name || !field || thresholdValue === null}
        >
          {t('buttons.confirm')}
        </Button>
      }
      maskClosable={false}
      title={t('titles.create-alert')}
      visible={visible}
      centered
      onCancel={handleClose}
    >
      <div style={{ marginBottom: 16 }}>
        <label>{t('table.eventType')}</label>
        <Radio.Group
          onChange={e => {
            setEventType(e.target.value);
            setField();
          }}
          value={eventType}
          style={{
            display: 'flex',
            marginBottom: 16,
            justifyContent: 'center',
          }}
          optionType="button"
        >
          <Radio value="CONSUMPTION">{t('eventType.CONSUMPTION')}</Radio>
          <Radio value="CONTRACT_CONSUMPTION">
            {t('eventType.CONTRACT_CONSUMPTION')}
          </Radio>
        </Radio.Group>
      </div>

      <div style={{ marginBottom: 16 }}>
        <label>{t('attributes:name')}</label>
        <Input value={name} onChange={e => setName(e.target.value)} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <label>{t('table.field')}</label>
        <Select
          value={field}
          onChange={value => setField(value)}
          style={{ width: '100%' }}
        >
          <Option value="consumptionTotal">
            {t('field.consumptionTotal')}
          </Option>
          <Option value="consumptionPercent">
            {t('field.consumptionPercent')}
          </Option>
          <Option value="consumptionTotalAnomaly">
            {t('field.consumptionTotalAnomaly')}
          </Option>

          {eventType === 'CONSUMPTION' && (
            <>
              <Option value="consumptionCurrentDayAnomaly">
                {t('field.consumptionCurrentDayAnomaly')}
              </Option>
              <Option value="consumptionCurrentDay">
                {t('field.consumptionCurrentDay')}
              </Option>
            </>
          )}
        </Select>
      </div>

      <div style={{ marginBottom: 16 }}>
        <label>{t('table.thresholdValue')}</label>
        <div style={{ width: '100%' }}>
          <InputNumber
            value={thresholdValue}
            onChange={value => setThresholdValue(value)}
            style={{ width: '80px' }}
            min={
              field === 'consumptionTotalAnomaly' ||
              field === 'consumptionCurrentDayAnomaly'
                ? 100
                : null
            }
          />
          <span style={{ marginLeft: 8 }}>
            {field === 'consumptionPercent' ||
            field === 'consumptionTotalAnomaly' ||
            field === 'consumptionCurrentDayAnomaly'
              ? `% ${
                  field === 'consumptionTotalAnomaly' ||
                  field === 'consumptionCurrentDayAnomaly'
                    ? 'do valor esperado'
                    : ''
                }`
              : 'MB'}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateAlerts;
