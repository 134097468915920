import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Modal, message, Button } from 'antd';
import EditableTable from '@src/components/EditableTable/EditableTable';
import { getModelDataColumns } from '../modelDataInvoice';
import { useTranslation } from 'react-i18next';
import apiMiddleware from '@src/services/apiMiddleware';
import { leaveOnlyNumbers } from '@src/utils/formatters';
import { useNavigate } from 'react-router-dom';

function EditItemInvoice({
  modalVisible,
  onClose,
  itemToEdit,
  customerId,
  invoiceId,
}) {
  const { t } = useTranslation('invoices');

  const [isSaving, setIsSaving] = useState(false);
  const [temporaryItem, setTemporaryItem] = useState(itemToEdit);

  const navigate = useNavigate();

  const navigateActionsInvoices = useCallback(
    protocol => {
      message.destroy('protocol-invoice');
      navigate(`/financial/invoices/actions?protocol=${protocol}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (itemToEdit && modalVisible) {
      setTemporaryItem({
        id: itemToEdit.idItemFatura,
        description: itemToEdit.dsItem?.replaceAll('Extra - ', ''),
        quantity: itemToEdit.nuQtdItem,
        value: itemToEdit.nuValorUnitario,
        totalValue: itemToEdit.nuValorTotal,
        blItemEdit: itemToEdit.blItemEdit,
      });
    }
  }, [itemToEdit, modalVisible]);

  const dataModelColumns = useMemo(() => {
    const columns = getModelDataColumns(t);
    columns.shift();
    columns.shift();
    return columns;
  }, [t]);

  const handleItemUpdate = useCallback(async () => {
    try {
      setIsSaving(true);
      const { id, description, quantity, value } = temporaryItem;

      const payload = {
        customerId,
        invoiceId,
        itemId: id,
        description,
        quantity,
        value,
      };

      apiMiddleware
        .post(`/service-proxy/broker/invoice-actions`, {
          type: 'EDIT_ITEM',
          actions: [payload],
        })
        .then(response => {
          if (response.data.code === '200') {
            message.success({
              content: (
                <span>
                  {t('actions.request-success')}
                  <Button
                    onClick={() =>
                      navigateActionsInvoices(response.data.operationProtocol)
                    }
                    type="link"
                    style={{ padding: 3 }}
                  >
                    {t('actions.go-to')}
                  </Button>
                  {t('actions.check-progress')}
                </span>
              ),
              key: 'protocol-invoice',
              duration: 5,
            });
          }
        });

      setTemporaryItem(undefined);
      onClose();
    } catch {
      message.error(t('invoiceItem.msgs.error-to-updating'));
    }
    setIsSaving(false);
  }, [
    temporaryItem,
    onClose,
    t,
    customerId,
    invoiceId,
    navigateActionsInvoices,
  ]);

  const handleInsertCurrencyNumber = useCallback(currency => {
    return String(currency).replaceAll(' ', '').replaceAll(',', '.');
  }, []);

  const handleEditableTableChange = useCallback(
    items => {
      const { quantity, value, ...item } = items[0];
      const treatedValue = handleInsertCurrencyNumber(value);
      const treatedQuantity = leaveOnlyNumbers(quantity);

      setTemporaryItem({
        ...item,
        quantity: treatedQuantity,
        value: treatedValue,
        totalValue: treatedQuantity * treatedValue,
      });
    },
    [setTemporaryItem, handleInsertCurrencyNumber],
  );

  return (
    <Modal
      cancelText={t('items-modal.buttons.cancel')}
      maskClosable={false}
      style={{ maxWidth: 800 }}
      title={`${t('items-modal.edit-items')} (${itemToEdit?.dsItem})`}
      visible={modalVisible}
      width="70%"
      centered
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>{t('items-modal.buttons.cancel')}</Button>
          <Button
            loading={isSaving}
            type={'primary'}
            onClick={handleItemUpdate}
          >
            {t('items-modal.buttons.confirm')}
          </Button>
        </>
      }
    >
      <EditableTable
        columns={dataModelColumns}
        data={[temporaryItem]}
        tableData={handleEditableTableChange}
        tableKey="editItems"
        oneLine
        allowDelete
      />
    </Modal>
  );
}

export default EditItemInvoice;
