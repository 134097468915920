import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, List, Pagination, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import useSwr from '@hooks/useSwr';
import rolesConstants from '@utils/rolesConstants';
import SessionContext from '@src/store/SessionContext/SessionContext';
import NewReferralModal from './NewReferralModal';
import ReferralListItem from './ReferralListItem';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const Referrals = () => {
  const { t } = useTranslation('lorawan');
  const [showNewReferralModal, setShowNewReferralModal] = useState(false);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { userRoles } = useContext(SessionContext);

  const canMakeReferral = userRoles?.portal?.includes(
    rolesConstants.LORAWAN_REFERRALS,
  );

  const { data } = useSwr(
    '/service-proxy/lorawan/referrals',
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
    },
    {
      refreshInterval: 10 * 1000,
    },
  );

  const extraActions = useMemo(
    () => (
      <Tooltip title={t('newReferrals')}>
        <Button
          className="guide-identity-table-new"
          icon={<PlusCircleOutlined />}
          size="large"
          type="link"
          onClick={() => setShowNewReferralModal(true)}
        />
      </Tooltip>
    ),
    [t],
  );

  return (
    <div>
      <NewReferralModal
        visible={showNewReferralModal}
        onClose={() => setShowNewReferralModal(false)}
      />

      <Card
        title={t('referrals')}
        style={{ width: '100%' }}
        bodyStyle={{ padding: 8 }}
        extra={canMakeReferral && extraActions}
        headStyle={{
          fontWeight: 'bold',
          color: 'rgb(87, 89, 98)',
          fontSize: '1.1rem',
        }}
      >
        <List
          style={{ borderColor: '#f1f1f1' }}
          bordered
          dataSource={data?.content ?? []}
          loading={!data}
          renderItem={item => <ReferralListItem item={item} />}
        />
        <Pagination
          size="small"
          defaultCurrent={1}
          current={pagination?.current}
          pageSize={pagination.pageSize}
          total={data?.totalElements}
          style={{ textAlign: 'right', margin: 16 }}
          onChange={pageValue =>
            setPagination(oldPagination => ({
              ...oldPagination,
              current: pageValue,
            }))
          }
        />
      </Card>
    </div>
  );
};

export default Referrals;
