import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Skeleton,
  Divider,
  Form,
  Input,
  Select,
  message,
  Col,
  Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import i18next, { getAvailableLocales } from '../../../../i18n';

const { Item } = Form;
const { Option } = Select;

const PersonalInfo = () => {
  const { keycloak, axios } = useContext(KeycloakContext);

  const { t } = useTranslation('accounts');

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [hasTypedPassword, setHasTypedPassword] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const updateUser = useCallback(
    (id, updUser) => axios.put(`/users/${id}`, updUser),
    [axios],
  );

  useEffect(() => {
    keycloak
      .loadUserProfile()
      .then(fields => {
        if (fields.attributes.cpfCnpj?.length > 0) {
          fields.attributes.cpfCnpj = fields.attributes.cpfCnpj[0];
        }
        if (fields.attributes.locale?.length > 0) {
          [fields.attributes.locale] = fields.attributes.locale;
        }
        if (fields.attributes.phoneNumber?.length > 0) {
          [fields.attributes.phoneNumber] = fields.attributes.phoneNumber;
        }

        form.setFieldsValue(fields);
      })
      .catch(() => message.error(t('msgs.error-finding-profile')))
      .finally(() => setLoading(false));
  }, [keycloak, axios, form, t]);

  const onFinish = useCallback(
    async values => {
      setSaveLoading(true);
      try {
        const { password, confirm, ...newValues } = values;
        const payload = {
          ...newValues,
        };

        if (password) {
          payload.credentials = [{ value: password }];
        }
        await updateUser(keycloak.subject, payload);
        i18next.changeLanguage(values.attributes.locale);
        message.success(t('msgs.success-updating-user'));
      } catch {
        message.error(t('msgs.error-updating-user'));
      }
      setSaveLoading(false);
    },
    [keycloak.subject, t, updateUser],
  );

  return (
    <Skeleton loading={loading} active>
      <Form
        colon={false}
        form={form}
        id={'userForm'}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]} justify="center">
          <Col xs={12}>
            {/* First name */}
            <Item
              label={t('modal.form.labels.firstName')}
              name={'firstName'}
              rules={[
                {
                  required: true,
                  message: t('modal.form.msgs.fillThisField'),
                },
              ]}
            >
              <Input />
            </Item>

            {/* Last name */}
            <Item
              label={t('modal.form.labels.lastName')}
              name={'lastName'}
              rules={[
                {
                  required: true,
                  message: t('modal.form.msgs.fillThisField'),
                },
              ]}
            >
              <Input />
            </Item>

            {/* CPF/CNPJ */}
            <Item
              label={t('modal.form.labels.cpfCnpj')}
              name={['attributes', 'cpfCnpj']}
              normalize={value => (value ? value.replace(/\D/g, '') : '')}
            >
              <Input maxLength={30} />
            </Item>

            {/* Username */}
            <Item label={t('modal.form.labels.username')} name={'username'}>
              <Input disabled />
            </Item>
          </Col>
          <Col xs={12}>
            {/* Locale */}
            <Item
              label={t('modal.form.labels.locale')}
              name={['attributes', 'locale']}
            >
              <Select>
                {getAvailableLocales().map(locale => (
                  <Option key={locale} value={locale}>
                    {t(`locales.${locale}`)}
                  </Option>
                ))}
              </Select>
            </Item>

            {/* Email */}
            <Item
              label={t('modal.form.labels.email')}
              name={'email'}
              rules={[
                {
                  required: true,
                  message: t('modal.form.msgs.fillThisField'),
                },
                {
                  type: 'email',
                  message: t('modal.form.msgs.notValidEmail'),
                },
              ]}
            >
              <Input />
            </Item>

            {/* Phone number */}
            <Item
              label={t('modal.form.labels.phoneNumber')}
              name={['attributes', 'phoneNumber']}
            >
              <Input />
            </Item>
          </Col>
        </Row>

        <Divider />

        {/* Password */}
        <Item
          label={t('modal.form.labels.password')}
          name={'password'}
          rules={[
            {
              required: false,
              message: t('modal.form.msgs.fillThisField'),
            },
            {
              min: 10,
              message: t('modal.form.msgs.passwordLength', { minLength: 10 }),
            },
          ]}
        >
          <Input.Password
            onChange={e => setHasTypedPassword(!!e.target.value)}
          />
        </Item>

        {/* Confirm password */}
        <Item
          dependencies={['password']}
          label={t('modal.form.labels.confirmPassword')}
          name={'confirm'}
          rules={[
            {
              required: hasTypedPassword,
              message: t('modal.form.msgs.passwordConfirmRequired'),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t('modal.form.msgs.passwordDoesNotMatch'),
                );
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Item>

        <Divider />
        <div style={{ textAlign: 'right' }}>
          <Button htmlType={'submit'} loading={saveLoading} type={'primary'}>
            {t('modal.buttons.saveButton')}
          </Button>
        </div>
      </Form>
    </Skeleton>
  );
};

export default PersonalInfo;
