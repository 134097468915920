import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import SessionContext from '@store/SessionContext/SessionContext';

const UNAUTHORIZED = 401;

const AxiosConfigProvider = props => {
  const token = localStorage.getItem('wso2') || '';
  const { children, axios } = props;
  const { logout } = useContext(SessionContext);
  axios.defaults.headers['Authorization'] = token ? `Bearer ${token}` : '';

  useEffect(() => {
    const requestInterceptorId = axios.interceptors.request.use(
      request => {
        request.params = {
          ...request.params,
          token: window?.keycloak?.token,
        };
        return request;
      },
      e => {
        return Promise.reject(e);
      },
    );

    const responseInterceptorId = axios.interceptors.response.use(
      response => {
        return response;
      },
      e => {
        if (e.response && e.response.status === UNAUTHORIZED) {
          if (window?.keycloak?.logout) {
            window.keycloak.logout();
          }
        }
        return Promise.reject(e);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptorId);
      axios.interceptors.response.eject(responseInterceptorId);
    };
  }, [logout, axios.interceptors.request, axios.interceptors.response]);

  return children;
};

AxiosConfigProvider.propTypes = {
  children: PropTypes.node,
};

AxiosConfigProvider.defaultProps = {
  children: null,
};

export default AxiosConfigProvider;
