import React, { useCallback, useMemo, useState } from 'react';
import { Col, Collapse, ConfigProvider, Typography, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/ui';
import { Filters, FiltersCard } from '@components/Filters';
import Table from '@components/Table';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import useSearchParams from '@src/hooks/useSearchParams';
import useSwr from '@src/hooks/useSwr';
import { firstLetterUpper, formatCurrency } from '@src/utils/formatters';
import { getAntLocale } from '@src/utils/locales';
import moment from 'moment';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30'],
  style: { marginRight: 20 },
};

export const HistoricTab = () => {
  const {
    t: translate,
    i18n: { language },
  } = useTranslation('schedules');

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [endDate, setEndDate] = useState(moment().add(1, 'days'));

  const { data: scheduleHistoric } = useSwr(
    `/service-proxy/astro/credit/statement`,
    {
      startDate: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : moment().add(1, 'days').format('YYYY-MM-DD'),
    },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'startDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
    ],
    [],
  );

  const handleTableChange = useCallback(paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  }, []);

  const { handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const handleShowFilters = useCallback(value => {
    setShowFilters(value);
  }, []);

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const initial = initialDate || moment(endDate).subtract(1, 'months');
      const final = endDate || moment(initialDate).add(2, 'months');
      setStartDate(initial);
      setEndDate(final);

      handleSetSearchParams({
        startDate: moment(initial).format('YYYYMMDD'),
        endDate: moment(final).format('YYYYMMDD'),
      });
    },
    [handleSetSearchParams],
  );

  const disabledDate = useCallback(
    current => {
      if (startDate && current) {
        return (
          current < moment(startDate).subtract(60, 'days') ||
          current > moment(startDate).add(60, 'days')
        );
      }
      if (endDate && current) {
        return (
          current < moment(endDate).subtract(60, 'days') ||
          current > moment(endDate).add(60, 'days')
        );
      }
      return false;
    },
    [startDate, endDate],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: translate('table.createdAt'),
        item: (
          <ConfigProvider locale={getAntLocale(language)}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate && endDate
                  ? [moment(startDate), moment(endDate)]
                  : [moment().subtract(1, 'months'), moment().add(1, 'days')]
              }
              style={{ width: '100%' }}
              allowClear={false}
              disabledDate={disabledDate}
            />
          </ConfigProvider>
        ),
      },
    ],
    [startDate, endDate, handleDateChange, translate, language, disabledDate],
  );

  const columnsDefault = useMemo(() => {
    return [
      {
        title: translate('table.type'),
        dataIndex: 'typeStatement',
        render: value => value && firstLetterUpper(value),
      },
      {
        title: translate('table.item'),
        dataIndex: 'items',
        render: items => {
          if (items && items.length > 0) {
            return (
              <Collapse bordered={false}>
                <Collapse.Panel header={translate('table.allProducts')} key="1">
                  {items.map((item, index) => (
                    <Typography key={index}>
                      {item?.name} - ({item?.quantityItem}) -{' '}
                      {formatCurrency(Number(item?.price))}
                    </Typography>
                  ))}
                </Collapse.Panel>
              </Collapse>
            );
          }
        },
      },
      {
        title: translate('table.total'),
        dataIndex: 'total',
        render: (value, record) => {
          if (record?.typeStatement !== 'creditos') {
            return value ? <Tag color="red">{`${value} un`}</Tag> : 0;
          }
          return value ? (
            <Tag color="green">{`+ ${formatCurrency(
              Number(value / 100),
            )}`}</Tag>
          ) : (
            'R$ 0,00'
          );
        },
      },
      {
        title: translate('table.date'),
        dataIndex: 'dataDeCompra',
      },
    ];
  }, [translate]);

  return (
    <>
      <Table
        columns={columnsDefault}
        allColumns={columnsDefault}
        data={scheduleHistoric?.data || []}
        loading={!scheduleHistoric?.data}
        title={
          <>
            {translate('historicCredits')}{' '}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        tableKey="historic"
        pagination={pagination}
        onChange={handleTableChange}
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};
