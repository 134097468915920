import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '@components/ui/Typography/Title';

const NotFound = () => {
  const { t } = useTranslation('not-found');

  return (
    <div style={{ textAlign: 'center', padding: '300px 0' }}>
      <Title>{t('not-found')}</Title>
      <p>404</p>
    </div>
  );
};

export default NotFound;
