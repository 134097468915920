import React, { useMemo } from 'react';

import { Radio, Typography, Row, Table, Alert } from 'antd';
import SignalBars from '@src/components/SignalBars';
import { useTranslation } from 'react-i18next';
import { LockOutlined, UnlockOutlined, StopOutlined } from '@ant-design/icons';

import './styles.less';
import ActionTypes from '@src/utils/deviceActions';

const { Text } = Typography;

const ChooseLockOrUnlock = ({
  lockUnlockSelectDevices,
  showContracted,
  lockUnlockType,
  onChange,
  actionKey,
}) => {
  const { t: tDevice } = useTranslation('device-actions-modal');
  const { t: tInventory } = useTranslation('inventory');

  const columns = useMemo(() => {
    const titleByShowContracted =
      showContracted === 'true'
        ? tInventory('table.provider')
        : tInventory('table.owner');
    return [
      {
        key: 'customerName',
        title: titleByShowContracted,
        dataIndex: 'customerName',
        width: 200,
      },
      {
        key: 'msisdn',
        title: tInventory('inventory:table.caller-id'),
        dataIndex: 'msisdn',
        width: 100,
      },
    ];
  }, [showContracted, tInventory]);

  const tableData = useMemo(
    () =>
      lockUnlockType === 'block'
        ? lockUnlockSelectDevices?.devicesToBlock
        : lockUnlockSelectDevices?.devicesToUnlock,
    [lockUnlockType, lockUnlockSelectDevices],
  );

  const customChoice = useMemo(() => {
    if (actionKey === ActionTypes.SUSPENSION_WITHDRAWAL) {
      return {
        lock: {
          text: tDevice('steps.suspension-withdrawal.block'),
          icon: <LockOutlined className="suspension-icons suspension-lock" />,
        },
        unlock: {
          text: tDevice('steps.suspension-withdrawal.unlock'),
          icon: (
            <UnlockOutlined className="suspension-icons suspension-unlock" />
          ),
        },
        cancell: {
          text: tDevice('steps.suspension-withdrawal.cancell'),
          icon: <StopOutlined className="suspension-icons suspension-lock" />,
        },
      };
    }
    if (actionKey === ActionTypes.BROKER_CANCELLATION) {
      return {
        lock: {
          text: tDevice('steps.broker-cancellation.radio-request-cancell'),
          icon: <LockOutlined className="suspension-icons suspension-lock" />,
        },
        unlock: {
          text: tDevice('steps.broker-cancellation.radio-request-withdrawal'),
          icon: (
            <UnlockOutlined className="suspension-icons suspension-unlock" />
          ),
        },
      };
    }
    return {
      lock: {
        text: tDevice('steps.lock-unlock.block'),
        icon: <SignalBars color="green" />,
      },
      unlock: {
        text: tDevice('steps.lock-unlock.unlock'),
        icon: <SignalBars numberOfBars={5} color="green" />,
      },
    };
  }, [actionKey, tDevice]);

  return (
    <div style={{ marginTop: 24 }}>
      <Radio.Group
        className="action-lock-unlock"
        value={lockUnlockType}
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
      >
        <Radio.Button value="block" className="action-lock-unlock-radio">
          {customChoice?.lock?.icon}
          {customChoice?.lock?.text}
        </Radio.Button>
        <Radio.Button value="unlock" className="action-lock-unlock-radio">
          {customChoice?.unlock?.icon}
          {customChoice?.unlock?.text}
        </Radio.Button>
        {actionKey === ActionTypes.SUSPENSION_WITHDRAWAL && (
          <Radio.Button value="cancell" className="action-lock-unlock-radio">
            {customChoice?.cancell?.icon}
            {customChoice?.cancell?.text}
          </Radio.Button>
        )}
      </Radio.Group>

      {tableData && lockUnlockType && (
        <div style={{ marginTop: 16 }}>
          <Row justify="center">
            <Text type="secondary" style={{ marginBottom: 8 }}>
              {lockUnlockType === 'block'
                ? tDevice('steps.lock-unlock.unlocked-devices', {
                    count: lockUnlockSelectDevices?.devicesToBlock?.length,
                  })
                : tDevice('steps.lock-unlock.blocked-devices', {
                    count: lockUnlockSelectDevices?.devicesToUnlock?.length,
                  })}
            </Text>
          </Row>
          <Table
            dataSource={tableData}
            columns={columns}
            pagination={false}
            scroll={{ y: 240 }}
          />
        </div>
      )}
      {actionKey === ActionTypes.SUSPENSION_WITHDRAWAL &&
        lockUnlockType === 'cancell' && (
          <Alert
            style={{ padding: 12, marginTop: 18, textAlign: 'justify' }}
            description={tDevice(
              'steps.suspension-withdrawal.informative.cancell-inform',
            )}
            type="info"
            showIcon
          />
        )}
      {actionKey === ActionTypes.BROKER_CANCELLATION &&
        lockUnlockType === 'block' && (
          <Alert
            style={{ padding: 12, marginTop: 18, textAlign: 'justify' }}
            description={tDevice('steps.broker-cancellation.warning-message')}
            type="info"
            showIcon
          />
        )}
    </div>
  );
};

export default ChooseLockOrUnlock;
