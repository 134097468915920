import React, { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { FaSimCard } from 'react-icons/fa';
import { VscRadioTower } from 'react-icons/vsc';
import './styles.less';

const Marker = ({ children, lat, lng, type }) => {
  const [infoVisible, setInfoVisible] = useState(true);

  useEffect(() => {
    setInfoVisible(true);
  }, [lat, lng]);

  return (
    <div className="mark" style={{ zIndex: type === 'click' ? 2 : 1 }}>
      {(
        <div
          style={{ padding: '4px' }}
          onClick={() => {
            setInfoVisible(!infoVisible);
          }}
        >
          {type === 'click' ? (
            <VscRadioTower className="coverage-map-marker coverage-map-marker-click" />
          ) : (
            <FaSimCard className="coverage-map-marker coverage-map-marker-static" />
          )}
        </div>
      ) ?? <div className="mark--circle" />}
      {children && (
        <div className="mark-content" id="mark-content">
          <div
            onClick={e => e.stopPropagation()}
            onDoubleClick={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            style={{ position: 'relative' }}
          >
            {infoVisible && (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '5px',
                    cursor: 'pointer',
                    zIndex: 1,
                  }}
                  onClick={() => {
                    setInfoVisible(false);
                  }}
                >
                  <IoIosClose style={{ fontSize: '1.25rem', color: '#ccc' }} />
                </div>
                <div className="mark-content__arrow" />
                {children}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Marker;
