import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@src/hooks/useSwr';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const RequestContractForm = ({
  contractMotId,
  contractLorawanId,
  requestId,
  onChange,
  parentForm,
  onlyFormItems = false,
  span = 20,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['requests', 'attributes', 'lorawan']);
  const { keycloak } = useContext(KeycloakContext);
  const isAllcom = keycloak.idTokenParsed?.customerType === 'SUPERBROKER';

  const { data: motContracts } = useSwr(
    isAllcom ? '/service-proxy/contracts/hierarchyMOT' : null,
    {
      acronym: 'MOT_LORAWAN',
    },
  );

  const { data: loraWanContracts } = useSwr(
    isAllcom ? '/service-proxy/contracts' : null,
    {
      product: 'LORAWAN',
    },
  );

  const { data: requests } = useSwr(
    '/service-proxy/lorawanVirtualAccounts/active',
  );

  useEffect(() => {
    if (contractMotId) {
      form.setFieldValue('contractMotId', contractMotId);
    }
    if (contractLorawanId) {
      form.setFieldValue('contractLorawanId', contractLorawanId);
    }
    if (requestId) {
      form.setFieldValue('requestId', requestId);
    }
  }, [contractLorawanId, contractMotId, form, requestId]);

  const handleSelectOptions = useCallback(contractsList => {
    const hasNickname = contract =>
      contract?.nickname ? `${contract?.nickname} -` : '';
    return (
      contractsList &&
      contractsList?.map(contract => ({
        label: `${contract?.assetNumber} - ${hasNickname(contract)} ${
          contract?.planName
        }`,
        value: contract?.childId ?? contract?.id,
      }))
    );
  }, []);

  const motContractsOptions = useMemo(
    () => handleSelectOptions(motContracts),
    [handleSelectOptions, motContracts],
  );

  const loraWanContractsOptions = useMemo(
    () => handleSelectOptions(loraWanContracts),
    [handleSelectOptions, loraWanContracts],
  );

  const requestsOptions = useMemo(
    () =>
      requests &&
      requests?.map(request => ({
        label: `${request?.id} - ${request?.name}`,
        value: request?.id,
      })),
    [requests],
  );

  const formContractLabel = useCallback(
    label => (
      <>
        {t('attributes:contract')}
        <span style={{ marginLeft: 4, fontWeight: 'bold' }}>{label}</span>
      </>
    ),
    [t],
  );

  const hasSingleOption = useCallback(options => options?.length === 1, []);

  const getFirstValueWhenHasSingleOption = useCallback(
    options => (hasSingleOption(options) ? options?.[0]?.value : undefined),
    [hasSingleOption],
  );

  const initialValues = useMemo(
    () => ({
      contractMotId: getFirstValueWhenHasSingleOption(motContractsOptions),
      contractLorawanId: getFirstValueWhenHasSingleOption(
        loraWanContractsOptions,
      ),
      requestId: getFirstValueWhenHasSingleOption(requestsOptions),
    }),
    [
      getFirstValueWhenHasSingleOption,
      loraWanContractsOptions,
      motContractsOptions,
      requestsOptions,
    ],
  );

  useEffect(() => {
    if (
      (hasSingleOption(motContractsOptions) &&
        hasSingleOption(loraWanContractsOptions)) ||
      hasSingleOption(requestsOptions)
    ) {
      if (parentForm) {
        parentForm.setFieldsValue(initialValues);
      } else {
        onChange?.(initialValues);
      }
    }
  }, [
    hasSingleOption,
    initialValues,
    loraWanContractsOptions,
    motContractsOptions,
    onChange,
    parentForm,
    requestsOptions,
  ]);

  const renderFormItems = useMemo(
    () => (
      <Row gutter={[8, 8]} justify="center">
        {isAllcom ? (
          <>
            <Col span={span}>
              <Form.Item
                name="contractMotId"
                label={formContractLabel('MoT')}
                rules={[
                  {
                    required: true,
                    message: t('lorawan:fieldIsRequired'),
                  },
                ]}
              >
                <Select
                  optionFilterProp="label"
                  options={motContractsOptions}
                  disabled={
                    !motContractsOptions || hasSingleOption(motContractsOptions)
                  }
                  loading={!motContracts}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={span}>
              <Form.Item
                name="contractLorawanId"
                label={formContractLabel('LoraWan')}
                rules={[
                  {
                    required: true,
                    message: t('lorawan:fieldIsRequired'),
                  },
                ]}
              >
                <Select
                  optionFilterProp="label"
                  options={loraWanContractsOptions}
                  disabled={
                    !loraWanContractsOptions ||
                    hasSingleOption(loraWanContractsOptions)
                  }
                  loading={!loraWanContracts}
                  showSearch
                />
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col span={span}>
            <Form.Item
              name="requestId"
              label={t('attributes:baseRequest')}
              rules={[
                {
                  required: true,
                  message: t('lorawan:fieldIsRequired'),
                },
              ]}
            >
              <Select
                optionFilterProp="label"
                options={requestsOptions}
                disabled={!requestsOptions || hasSingleOption(requestsOptions)}
                loading={!requests}
                showSearch
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    ),
    [
      formContractLabel,
      hasSingleOption,
      isAllcom,
      loraWanContracts,
      loraWanContractsOptions,
      motContracts,
      motContractsOptions,
      requests,
      requestsOptions,
      span,
      t,
    ],
  );

  return onlyFormItems ? (
    renderFormItems
  ) : (
    <Form
      form={form}
      onValuesChange={(_, values) => onChange(values)}
      initialValues={initialValues}
      layout="vertical"
    >
      {renderFormItems}
    </Form>
  );
};

export default RequestContractForm;
