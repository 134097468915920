import styled from 'styled-components';

export const DraggerContent = styled.div`
  padding: 8px 16px;

  .ant-upload-text {
    opacity: 0.8;
    margin-bottom: 8px !important;
  }

  .ant-upload-hint {
    font-size: 0.8rem !important;
  }
`;
