import React from 'react';
import { Card, Statistic } from 'antd';
import { Container, IconContainer } from './styles.js';

const Stat = ({
  title,
  icon,
  iconColor,
  value,
  noStyleIcon,
  suffix,
  prefix,
  loading,
  className,
}) => (
  <Card className={className} style={{ height: '100%', boxShadow: 'none' }}>
    {icon ? (
      <Container>
        <IconContainer>
          {React.cloneElement(
            icon,
            !noStyleIcon && {
              style: {
                ...icon.props.style,
                marginRight: 20,
                color: iconColor ?? 'rgba(0, 166, 80, 1)',
                fontSize: 20,
              },
            },
          )}
        </IconContainer>
        <Statistic
          valueStyle={{
            fontSize: 20,
          }}
          title={title}
          value={value}
          suffix={suffix}
          prefix={prefix}
          loading={loading}
        />
      </Container>
    ) : (
      <Statistic valueStyle={{ fontSize: 20 }} title={title} value={value} />
    )}
  </Card>
);

export default Stat;
