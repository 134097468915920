export const getModelDataColumns = column => {
  switch (column) {
    case 'msisdn':
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
      ];
    case 'iccid':
      return [
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          key: 'iccid',
        },
      ];
    case 'imsi':
      return [
        {
          title: 'IMSI',
          dataIndex: 'imsi',
          key: 'imsi',
        },
      ];
    case 'cpfCnpj':
      return [
        {
          title: 'CPF/CNPJ',
          dataIndex: 'cpfCnpj',
          key: 'cpfCnpj',
        },
      ];
    default:
      return [];
  }
};

export const getModelData = column => {
  switch (column) {
    case 'msisdn':
      return [
        {
          key: '1',
          msisdn: '5534912345678',
        },
        {
          msisdn: '5534912654892',
          key: '2',
        },
        {
          key: '3',
          msisdn: '5534912345678',
        },
        {
          key: '4',
          msisdn: '5534912321895',
        },
      ];
    case 'imsi':
      return [
        {
          key: '1',
          imsi: '724320200047910',
        },
        {
          key: '2',
          imsi: '724320200047911',
        },
        {
          key: '3',
          imsi: '724320200047912',
        },
        {
          key: '4',
          imsi: '724320200047913',
        },
      ];
    case 'iccid':
      return [
        {
          key: '1',
          iccid: '89553201800009778610',
        },
        {
          key: '2',
          iccid: '89553201800009778611',
        },
        {
          key: '3',
          iccid: '89553201800009778612',
        },
        {
          key: '4',
          iccid: '89553201800009778613',
        },
      ];
    case 'cpfCnpj':
      return [
        {
          key: '1',
          cpfCnpj: '02118475000104',
        },
        {
          key: '2',
          cpfCnpj: '97506166000114',
        },
        {
          key: '3',
          cpfCnpj: '69041993000142',
        },
        {
          key: '4',
          cpfCnpj: '92018570000129',
        },
      ];
    default:
      return [];
  }
};
