import ParametrizedDatePicker from '@src/components/DatePicker/DatePicker';
import apiMiddleware from '@src/services/apiMiddleware';
import { Button, Col, Form, Input, Modal, Row, message } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImportFile from './ImportFile/index';

const { Item } = Form;
const { TextArea } = Input;

const CreateTermsModal = ({ visible, onClose, mutateCreate }) => {
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation('terms-use');
  const [form] = Form.useForm();

  const submitHandler = async values => {
    const dateFormat = moment(values.effectiveFrom).format('DD/MM/YYYY');
    setLoading(true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('file', uploadedFile);
      await apiMiddleware.post('/service-proxy/termsOfUse', bodyFormData, {
        params: {
          ...values,
          effectiveFrom: String(dateFormat),
          file: null,
        },
      });

      mutateCreate();
      setLoading(false);
      handleCloseModal();
      message.success(translate('modal.message-succes'));
    } catch (error) {
      setLoading(false);
      message.error(translate('modal.error-message-create'));
    }
  };

  const clearState = useCallback(() => {
    form.resetFields();
    setUploadedFile(undefined);
  }, [form]);

  const handleCloseModal = useCallback(() => {
    clearState();
    onClose();
  }, [clearState, onClose]);

  return (
    <>
      <Modal
        title={translate('modal.title')}
        visible={visible}
        onCancel={handleCloseModal}
        footer={false}
      >
        <Form
          labelAlign={'left'}
          layout={'vertical'}
          onFinish={submitHandler}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Item
                id={'effectiveFrom'}
                label={translate('form.effectiveFrom')}
                name={'effectiveFrom'}
                rules={[
                  {
                    required: true,
                    message: translate('form.effectiveFrom-required'),
                  },
                ]}
              >
                <ParametrizedDatePicker disabledDate={false} />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                id={'version'}
                label={translate('form.version')}
                name={'version'}
                rules={[
                  {
                    required: true,
                    message: translate('form.version-required'),
                  },
                ]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                id={'description'}
                label={translate('form.description')}
                name={'description'}
                rules={[
                  {
                    required: true,
                    message: translate('form.description-required'),
                  },
                ]}
              >
                <TextArea />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                id={'file'}
                label={translate('form.file')}
                name={'file'}
                rules={[
                  {
                    required: true,
                    message: translate('form.file-required'),
                  },
                ]}
              >
                <ImportFile
                  fileList={uploadedFile ? [uploadedFile] : undefined}
                  style={{ marginTop: 10 }}
                  onUpload={file => {
                    setUploadedFile(file);
                    form.setFieldsValue({ file });
                  }}
                  handleRemoveFile={() => {
                    setUploadedFile(undefined);
                    form.setFieldsValue({ file: undefined });
                  }}
                />
              </Item>
            </Col>
          </Row>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '24px',
              gridGap: '16px',
            }}
          >
            <Button onClick={handleCloseModal}>
              {translate('modal.cancel')}
            </Button>
            <Button htmlType={'submit'} type={'primary'} loading={loading}>
              {translate('form.sent')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTermsModal;
