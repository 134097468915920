import React, { useCallback, useState } from 'react';
import { Timeline, Row, Spin, Typography, Tooltip, Button } from 'antd';
import { formatDate } from '@utils/formatters';
import { green, red } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import useSwr from '@hooks/useSwr';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Text } = Typography;

const DeviceActionsTimeline = ({ id }) => {
  const { t } = useTranslation('device-actions');
  const { data } = useSwr(`/service-proxy/inventory/MOT/${id}/actions`);

  const [expandedView, setExpandedView] = useState(false);

  const getDotByStatus = status => {
    switch (status) {
      case 1:
      case 2:
      case 3:
      case 4:
        return (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
        );
      case 0:
        return (
          <CheckCircleOutlined style={{ fontSize: 16, color: green[6] }} />
        );
      default:
        return (
          <ExclamationCircleOutlined style={{ fontSize: 16, color: red[5] }} />
        );
    }
  };

  const formatterOperator = useCallback(
    operators =>
      operators.replaceAll(',', '').replace('[', '').replace(']', ''),
    [],
  );

  const handleMorInfo = useCallback(
    action => {
      if (action?.operator) {
        if (action.actionType === 6 && action.operator === '[]') {
          return t('operators-reset');
        }
        return `${formatterOperator(action.operator || '')}`;
      }
      if (action.idContract) {
        return `${t('contract')} ${action.idContract}`;
      }
      return '';
    },
    [formatterOperator, t],
  );

  return (
    <div>
      {data && data.length > 0 ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '10px',
            }}
          >
            <Tooltip title={t(expandedView ? 'compact' : 'expand')}>
              <Button
                // type="link"
                style={{ padding: 0 }}
                size="large"
                icon={
                  expandedView ? (
                    <BsArrowsCollapse
                      style={{
                        marginTop: 8,
                      }}
                    />
                  ) : (
                    <BsArrowsExpand
                      style={{
                        marginTop: 8,
                      }}
                    />
                  )
                }
                onClick={() => setExpandedView(!expandedView)}
              />
            </Tooltip>
          </div>
          <Timeline mode="right" style={{ paddingTop: 10 }}>
            {data?.map(action => (
              <Timeline.Item
                label={
                  <div>
                    <div style={{ paddingLeft: 10 }}>
                      {formatDate(
                        new Date(action.createdAt),
                        'dd/MM/yyyy HH:mm',
                      )}
                    </div>
                    {expandedView && action.flagProcessed !== 0 && (
                      <Row>
                        <Text type="secondary">
                          {t(
                            `status.${action.actionType}.${action.flagProcessed}`,
                          )}
                        </Text>
                      </Row>
                    )}
                  </div>
                }
                dot={getDotByStatus(action.flagProcessed)}
                key={action.id}
              >
                <div style={{ paddingRight: 10 }}>
                  <Row justify="end">{t(`labels.${action.actionType}`)}</Row>
                  <Text type="secondary">
                    {expandedView && <div>{handleMorInfo(action)}</div>}
                  </Text>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        </>
      ) : (
        <Paragraph style={{ padding: '16px' }}>
          <div>{t('no-data')}</div>
        </Paragraph>
      )}
    </div>
  );
};

export default DeviceActionsTimeline;
