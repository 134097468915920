import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Label, Card } from '@components/ui';
import PositionMap from '@components/Maps/PositionMap/PositionMap';
import { Select, Row, Col, Button, Tooltip, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import roles from '@utils/rolesConstants';
import { Filters, FiltersCard } from '@components/Filters';
import useSwr from '@hooks/useSwr';
import CoverageMap from '@components/Maps/CoverageMap/CoverageMap';
import {
  EnvironmentOutlined,
  FireOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { VscRadioTower } from 'react-icons/vsc';
import SessionContext from '@src/store/SessionContext/SessionContext';
import useSearchParams from '@src/hooks/useSearchParams';
import HeatMap from '@src/components/Maps/HeatMap/HeatMap';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import ButtonHelp from '@src/components/Maps/ButtonHelp/ButtonHelp';
import CoverageMapImg from '@images/coverage-map.png';
import LocationMapImg from '@images/location-map.png';
import HeatMapImg from '@images/heat-map.png';

import * as S from './styles.js';

const { Option } = Select;

const ZOOM_DEFAULT = 4;

const Maps = () => {
  const { t } = useTranslation('maps');
  const { userRoles } = useContext(SessionContext);
  const {
    customer: { customersOptions },
  } = useContext(SessionContext);
  const { keycloak } = useContext(KeycloakContext);
  const customerId = keycloak?.idTokenParsed?.customerId;

  const [clientId, setClientId] = useState();
  const [operator, setOperator] = useState();
  const [days, setDays] = useState('1');
  const [showFilters, setShowFilters] = useState(false);
  const [tabMapSelected, setTabMapSelected] = useState('coverage');
  const [search, setSearch] = useState();
  const [mapsZoom, setMapsZoom] = useState();
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();

  const operators = useMemo(() => {
    const allOperators = [
      'ALGAR TELECOM',
      'ARQIA',
      'CLARO',
      'OI',
      'TIM',
      'VIVO',
      'SIERRA',
    ];

    return allOperators.map(name => ({
      label: name,
      value: name,
    }));
  }, []);

  const { data } = useSwr(`/service-proxy/sims/query-massive-location`, {
    customerId: clientId || customerId,
    operator,
    days,
  });

  const mapsCenter = useMemo(
    () => lat && lng && { lat: Number(lat), lng: Number(lng) },
    [lat, lng],
  );

  const hasRole = useCallback(
    roleToCheck => userRoles?.portal?.includes(roleToCheck),
    [userRoles],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'type',
        setState: setTabMapSelected,
      },
      {
        name: 'lat',
        setState: setLat,
      },
      {
        name: 'lng',
        setState: setLng,
      },
      {
        name: 'zoom',
        setState: setMapsZoom,
      },
      {
        name: 'operator',
        setState: setOperator,
        inTheFilters: true,
      },
      {
        name: 'days',
        setState: setDays,
        inTheFilters: true,
      },
      {
        name: 'clientId',
        setState: setClientId,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    if ((lat && !lng) || (!lat && lng)) {
      handleClearParams(['lat', 'zoom']);
    }
  }, [lat, lng, handleClearParams]);

  useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams;
    const mapTypeInParams = searchParams.get('type');
    if (mapTypeInParams === null) {
      handleSetSearchParams({ type: 'coverage' });
    }
  }, [handleSetSearchParams]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        setDays('1');
        handleClearParams(['days', 'operator', 'clientId']);
      }
    },
    [showFilters, handleClearParams],
  );

  const buttonsTypes = useMemo(
    () => [
      {
        type: 'coverage',
        title: t('coverage-map'),
        icon: <VscRadioTower style={{ fontSize: 22, marginTop: 2 }} />,
      },
      {
        type: 'location',
        title: t('location-map'),
        icon: <EnvironmentOutlined />,
      },
      {
        type: 'heat-map',
        title: t('heat-map'),
        icon: <FireOutlined />,
      },
    ],
    [t],
  );

  const handleSetZoomParam = useCallback(
    value => {
      handleSetSearchParams({ zoom: value });
    },
    [handleSetSearchParams],
  );

  const handleSetCenterParams = useCallback(
    values => {
      handleSetSearchParams({ lat: values?.lat, lng: values?.lng });
    },

    [handleSetSearchParams],
  );

  const handleClearLocation = useCallback(() => {
    handleSetSearchParams({ zoom: ZOOM_DEFAULT }, ['lat', 'lng']);
  }, [handleSetSearchParams]);

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 4,
          xs: 24,
        },
        label: t('carrier'),
        item: (
          <Select
            disabled={!operators}
            optionFilterProp={'label'}
            options={operators}
            placeholder={t('carrier')}
            style={{ width: '100%' }}
            value={operator}
            allowClear
            showSearch
            onChange={value => handleSetSearchParams({ operator: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 4,
          xs: 24,
        },
        label: t('days'),
        item: (
          <Select
            placeholder={t('days')}
            style={{ width: '100%' }}
            defaultValue="1"
            value={days}
            onChange={value => handleSetSearchParams({ days: value })}
          >
            <Option value={'1'}>1</Option>
            <Option value={'7'}>7</Option>
            <Option value={'15'}>15</Option>
            <Option value={'30'}>30</Option>
            <Option value={'60'}>60</Option>
            <Option value={'90'}>90</Option>
          </Select>
        ),
      },
      {
        visible: hasRole(roles.VIEW_CUSTOMERS),
        col: {
          lg: 12,
          xl: 6,
          xs: 24,
        },
        label: t('client'),
        item: (
          <Select
            disabled={!customersOptions}
            loading={!customersOptions}
            optionFilterProp={'label'}
            options={customersOptions}
            placeholder={t('client')}
            style={{ width: '100%' }}
            value={clientId && Number(clientId)}
            allowClear
            showSearch
            onChange={value => handleSetSearchParams({ clientId: value })}
          />
        ),
      },
    ],
    [
      operators,
      clientId,
      customersOptions,
      days,
      hasRole,
      operator,
      t,
      handleSetSearchParams,
    ],
  );

  const mapToBeRendered = useMemo(() => {
    const renderData = {};
    switch (tabMapSelected) {
      case 'coverage':
        renderData.title = t('coverage-map');
        renderData.imgHelp = CoverageMapImg;
        renderData.description = t('help.description.coverage');
        renderData.map = (
          <CoverageMap
            allowSearch
            allowCoverage
            mapHeight={640}
            mapsZoom={mapsZoom ? Number(mapsZoom) : ZOOM_DEFAULT}
            setMapsZoom={handleSetZoomParam}
            mapsCenter={mapsCenter}
            setMapsCenter={handleSetCenterParams}
            searchValue={search}
            setSearchValue={setSearch}
            handleSetSearchParams={handleSetSearchParams}
            clearLocation={handleClearLocation}
          />
        );
        break;
      case 'location':
        renderData.title = t('location-map');
        renderData.imgHelp = LocationMapImg;
        renderData.description = t('help.description.location');
        renderData.map = (
          <PositionMap
            data={data}
            allowSearch
            mapHeight={640}
            mapsZoom={mapsZoom ? Number(mapsZoom) : ZOOM_DEFAULT}
            setMapsZoom={handleSetZoomParam}
            mapsCenter={mapsCenter}
            setMapsCenter={handleSetCenterParams}
            searchValue={search}
            setSearchValue={setSearch}
            handleSetSearchParams={handleSetSearchParams}
            clearLocation={handleClearLocation}
          />
        );
        break;
      case 'heat-map':
        renderData.title = t('heat-map');
        renderData.imgHelp = HeatMapImg;
        renderData.description = t('help.description.heat');
        renderData.map = (
          <HeatMap
            data={data}
            allowSearch
            mapHeight={640}
            mapsZoom={mapsZoom ? Number(mapsZoom) : ZOOM_DEFAULT}
            setMapsZoom={handleSetZoomParam}
            mapsCenter={mapsCenter}
            setMapsCenter={handleSetCenterParams}
            searchValue={search}
            setSearchValue={setSearch}
            handleSetSearchParams={handleSetSearchParams}
            clearLocation={handleClearLocation}
          />
        );
        break;
      default:
        return;
    }
    if (tabMapSelected !== 'coverage') {
      renderData.alertMsgNoData = t('help.alert.no-data');
      renderData.alertLoading = (
        <>
          <LoadingOutlined style={{ marginRight: 8 }} />
          {t('help.alert.loading')}
        </>
      );
    }
    return renderData;
  }, [
    data,
    handleClearLocation,
    handleSetCenterParams,
    handleSetZoomParam,
    search,
    t,
    tabMapSelected,
    mapsZoom,
    mapsCenter,
    handleSetSearchParams,
  ]);

  return (
    <Card
      bodyStyle={{ display: 'none' }}
      cover={
        <S.Container>
          {showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )}
          {tabMapSelected !== 'coverage' && (
            <>
              {!data ? (
                <Alert message={mapToBeRendered?.alertLoading} type="info" />
              ) : (
                data.length === 0 && (
                  <Alert
                    message={mapToBeRendered?.alertMsgNoData}
                    type="error"
                    showIcon
                  />
                )
              )}
            </>
          )}
          <div style={{ position: 'relative' }}>
            {mapToBeRendered?.map}
            <ButtonHelp
              type="help"
              img={mapToBeRendered?.imgHelp}
              description={mapToBeRendered?.description}
            />
          </div>
        </S.Container>
      }
      style={{ marginTop: 16, padding: 0 }}
      title={
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {mapToBeRendered?.title}
            {tabMapSelected !== 'coverage' && (
              <Filters
                showFilters={showFilters}
                setShowFilters={handleShowFilters}
              />
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {buttonsTypes?.map(buttonType => (
              <Tooltip key={buttonType.title} title={buttonType.title}>
                <Button
                  type={tabMapSelected === buttonType.type && 'primary'}
                  size="large"
                  style={{ margin: '0 2px' }}
                  icon={buttonType.icon}
                  onClick={() => {
                    if (buttonType.type === 'coverage') {
                      handleSetSearchParams({ type: buttonType.type }, [
                        'operator',
                        'days',
                        'clientId',
                      ]);
                      setShowFilters(false);
                    } else {
                      handleSetSearchParams({ type: buttonType.type });
                    }
                  }}
                />
              </Tooltip>
            ))}
          </div>
        </Row>
      }
    />
  );
};

export default Maps;
