import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSwr from '@src/hooks/useSwr';

import {
  Select,
  Button,
  Tooltip,
  Skeleton,
  Divider,
  Checkbox,
  InputNumber,
  message,
} from 'antd';
import { FormOutlined } from '@ant-design/icons';

import { Card, Label } from '@components/ui';

import './styles.less';
import SessionContext from '@src/store/SessionContext/SessionContext';
import apiMiddleware from '@src/services/apiMiddleware';
import { useTranslation } from 'react-i18next';

const DEFAULT_TAXES = {
  flagCOFINS: false,
  flagCSLL: false,
  flagIRRF: false,
  flagINSS: false,
  flagISS: false,
  flagPIS: false,
  rateCOFINS: 0,
  rateCSLL: 0,
  rateIRRF: 0,
  rateINSS: 0,
  rateISS: 0,
  ratePIS: 0,
};

function ERPConfig() {
  const { t } = useTranslation('erp-config');
  const { mainColor } = useContext(SessionContext);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [saveLoading, setSaveLoading] = useState(false);

  const [taxes, setTaxes] = useState(DEFAULT_TAXES);
  const [omieFilteredConfig, setOmieFilteredConfig] = useState(DEFAULT_TAXES);

  const { data: omieConfig, mutate: mutateOmieConfig } = useSwr(
    '/service-proxy/omie/config',
  );
  const { data: bankAccounts } = useSwr('/service-proxy/omie/bank-accounts');

  const allTaxesOptions = useMemo(
    () => ['COFINS', 'CSLL', 'INSS', 'IRRF', 'ISS', 'PIS'],
    [],
  );

  const postConfig = useCallback(
    payload => apiMiddleware.post(`/service-proxy/omie/config`, payload),
    [],
  );

  useEffect(() => {
    if (omieConfig) {
      setSelectedAccount(oldAccount => oldAccount ?? omieConfig.accountCode);

      const omie = {};
      allTaxesOptions.forEach(tax => {
        omie[`flag${tax}`] = omieConfig[`flag${tax}`];
        omie[`rate${tax}`] = omieConfig[`rate${tax}`];
      });
      setOmieFilteredConfig(omie);
      setTaxes(omie);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  }, [omieConfig, allTaxesOptions]);

  const verifyIfObjectsEquals = useCallback((obj, obj2) => {
    for (let key in obj) {
      if (!(key in obj2)) return false;
      if (obj[key] !== obj2[key]) return false;
    }
    return true;
  }, []);

  const bankSelected = useMemo(
    () => bankAccounts?.find(bank => bank.ncodCC === selectedAccount),
    [bankAccounts, selectedAccount],
  );

  const bankAccountsOptions = useMemo(
    () =>
      bankAccounts?.map(({ descricao, ncodCC }) => ({
        label: descricao,
        value: ncodCC,
      })),
    [bankAccounts],
  );

  const handleConfigCanBeSaved = useMemo(
    () =>
      omieConfig?.accountCode !== selectedAccount ||
      !verifyIfObjectsEquals(omieFilteredConfig, taxes),
    [
      omieConfig?.accountCode,
      selectedAccount,
      taxes,
      verifyIfObjectsEquals,
      omieFilteredConfig,
    ],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setSelectedAccount(omieConfig?.accountCode);
  }, [omieConfig?.accountCode]);

  const DescriptionValue = useCallback(
    ({ value }) => <p className="descriptions-card-value">{value}</p>,
    [],
  );

  const DescriptionTaxesValue = useCallback(
    ({ tax, value, retained }) => (
      <>
        {isEditing ? (
          <Label color={'#575962'}>{tax}</Label>
        ) : (
          <div
            className="descriptions-card-taxes"
            style={{
              borderColor: retained && !isEditing && mainColor,
              textAlign: retained && 'center',
              padding: retained && '4px 16px',
            }}
          >
            <Label color={'#575962'}>{tax}</Label>
            {!isEditing && (
              <>
                <p className="descriptions-card-value">{value}</p>
                {retained && (
                  <p
                    className="descriptions-card-taxes-retained"
                    style={{ background: retained && !isEditing && mainColor }}
                  >
                    {t('fields.retained')}
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </>
    ),
    [isEditing, mainColor, t],
  );

  const handleToSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      const payload = {
        ...taxes,
        accountCode: selectedAccount,
      };

      if (omieConfig) {
        payload.id = omieConfig.id;
      }

      await postConfig(payload);
      mutateOmieConfig(payload);
      setIsEditing(false);
      message.success(t('msgs.success'));
    } catch {
      message.error(t('msgs.error'));
    }
    setSaveLoading(false);
  }, [taxes, omieConfig, selectedAccount, postConfig, mutateOmieConfig, t]);

  const descriptionsExtraCards = useMemo(
    () => [
      {
        label: t('fields.number'),
        value: bankSelected?.ncodCC,
      },
      {
        label: t('fields.bank'),
        value: bankSelected?.codigo_banco,
      },
      {
        label: t('fields.type'),
        value: bankSelected?.tipo,
      },
    ],
    [bankSelected, t],
  );

  return (
    <Card
      title={
        <div className="erp-config-card-title">
          {t('title')}
          <div>
            {isEditing ? (
              <>
                {!saveLoading && (
                  <Button style={{ margin: 4 }} onClick={handleCancel}>
                    {t('buttons.cancel')}
                  </Button>
                )}
                <Button
                  style={{ margin: 4 }}
                  type="primary"
                  disabled={!handleConfigCanBeSaved || saveLoading}
                  onClick={handleToSave}
                  loading={saveLoading}
                >
                  {t('buttons.to-save')}
                </Button>
              </>
            ) : (
              <Tooltip title={t('buttons.to-edit')}>
                <Button
                  className="erp-config-card-title-button"
                  type="primary"
                  icon={<FormOutlined />}
                  onClick={() => setIsEditing(true)}
                />
              </Tooltip>
            )}
          </div>
        </div>
      }
    >
      <div
        className="descriptions-wrapper"
        style={{
          marginTop: isEditing && 24,
        }}
      >
        <div
          className="descriptions-card"
          style={{
            borderColor: isEditing && mainColor,
            minWidth: isEditing && 300,
          }}
        >
          {isEditing && (
            <p
              className="descriptions-card-select-account"
              style={{
                background: isEditing && mainColor,
                borderColor: isEditing && mainColor,
              }}
            >
              {t('info.select-an-account')}
            </p>
          )}
          <Label color={'#575962'}>{t('fields.account')}</Label>
          {!isEditing && bankSelected?.descricao ? (
            <DescriptionValue value={bankSelected?.descricao} />
          ) : (
            <Select
              optionFilterProp={'label'}
              options={bankAccountsOptions}
              placeholder={t('info.select-an-account')}
              style={{ width: '100%', margin: '4px 0' }}
              value={selectedAccount}
              showSearch
              onChange={setSelectedAccount}
              disabled={!bankAccountsOptions}
            />
          )}
        </div>

        {descriptionsExtraCards?.map(({ label, value }) => (
          <div key={label} className="descriptions-card">
            <Skeleton loading={!value}>
              <Label color={'#575962'}>{label}</Label>
              <DescriptionValue value={value} />
            </Skeleton>
          </div>
        ))}
      </div>

      {bankSelected && (
        <>
          <Divider orientation="left">{t('taxes')}</Divider>
          <div className="erp-config-taxes-wrapper">
            {allTaxesOptions.map(tax => (
              <div key={`input-${tax}`} className="erp-config-taxes-item">
                <DescriptionTaxesValue
                  tax={tax}
                  value={`${omieConfig?.[`rate${tax}`] ?? 0} %`}
                  retained={taxes[`flag${tax}`]}
                />
                {isEditing && (
                  <>
                    <InputNumber
                      defaultValue={0}
                      formatter={value => `${value} %`}
                      min={0}
                      parser={value => value.replace(' %', '')}
                      step={0.1}
                      value={taxes[`rate${tax}`]}
                      onChange={value =>
                        setTaxes(oldTaxes => ({
                          ...oldTaxes,
                          [`rate${tax}`]: value,
                        }))
                      }
                    />
                    <Checkbox
                      key={tax}
                      value={tax}
                      style={{ marginTop: 6 }}
                      checked={taxes[`flag${tax}`]}
                      onClick={() =>
                        setTaxes(oldTaxes => ({
                          ...oldTaxes,
                          [`flag${tax}`]: !oldTaxes[`flag${tax}`],
                        }))
                      }
                    >
                      {t('fields.retained')}
                    </Checkbox>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
}

export default ERPConfig;
