import React, { useCallback, useContext, useEffect, useState } from 'react';

import LogoUpload from '@src/components/LogoUpload/LogoUpload';
import {
  Divider,
  Input,
  Form,
  Col,
  Button,
  Row,
  Tooltip,
  Skeleton,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import SessionContext from '@src/store/SessionContext/SessionContext';

const { Item } = Form;

const Personalization = ({
  form,
  onFormChange,
  domainIsRequired = true,
  isEditing = false,
  loading,
}) => {
  const { t } = useTranslation('customers');
  const { keycloak } = useContext(KeycloakContext);
  const { mainCustomerInContext } = useContext(SessionContext);
  const customerLoggedType = keycloak.idTokenParsed?.customerType;
  const parentInContextIsAllcom = mainCustomerInContext?.id === 10000;
  const isBroker = ['SUPERBROKER', 'BROKER'].includes(customerLoggedType);

  const [mainColor, setMainColor] = useState();
  const [file, setFile] = useState();
  const [domain, setDomain] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [whatsappMessage, setWhatsappMessage] = useState();

  useEffect(() => {
    onFormChange({
      file,
      mainColor,
      domain: domain ?? form.getFieldValue('domain'),
      whatsapp: whatsapp ?? form.getFieldValue('whatsapp'),
      whatsappMessage: whatsappMessage ?? form.getFieldValue('whatsappMessage'),
    });
  }, [
    domain,
    file,
    mainColor,
    form,
    t,
    whatsapp,
    whatsappMessage,
    onFormChange,
  ]);

  const handleDomainChange = useCallback(
    ({ target: { value: url } }) => {
      try {
        const hostname = new URL(url).hostname;
        setDomain(hostname);
      } catch {
        form.setFields([
          {
            name: 'url',
          },
        ]);
      }
    },
    [form],
  );

  return (
    <div style={{ width: '80%', margin: '0 auto' }}>
      <Skeleton loading={loading}>
        <LogoUpload
          setMainColor={setMainColor}
          file={setFile}
          isEditing={isEditing}
        />

        <Form
          colon={false}
          form={form}
          id="personalizationForm"
          scrollToFirstError
        >
          {(isBroker || parentInContextIsAllcom) && (
            <Row gutter={[16, 16]}>
              <Divider style={{ marginTop: 24 }} />
              <Col lg={12} xl={24} xs={24}>
                <Item
                  label={t('personalization.fields.domain')}
                  name="domain"
                  rules={[
                    domainIsRequired && {
                      required: true,
                      message: t('personalization.domain-is-required'),
                    },
                    {
                      type: 'url',
                      message: t('personalization.domain-is-invalid'),
                    },
                  ]}
                >
                  <Input
                    placeholder="http(s)://example.com.br"
                    onChange={handleDomainChange}
                  />
                </Item>
              </Col>
            </Row>
          )}
          <Divider orientation="left" style={{ marginTop: 24 }}>
            {t('personalization.fields.whatsapp.title')}
          </Divider>
          <Row gutter={[16, 16]}>
            <Col lg={12} xl={12} xs={24}>
              <Item
                label={
                  <>
                    {t('personalization.fields.whatsapp.number')}
                    <Tooltip
                      title={t('personalization.fields.whatsapp.number-help')}
                    >
                      <Button type="link" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                  </>
                }
                name="whatsapp"
              >
                <Input
                  placeholder="55119888888"
                  onChange={({ target: { value } }) =>
                    setWhatsapp(value?.replace(/\D/g, ''))
                  }
                />
              </Item>
            </Col>
            <Col lg={12} xl={12} xs={24}>
              <Item
                label={t('personalization.fields.whatsapp.message')}
                name="whatsappMessage"
              >
                <Input
                  placeholder={t(
                    'personalization.fields.whatsapp.message-placeholder',
                  )}
                  onChange={({ target: { value } }) =>
                    setWhatsappMessage(value)
                  }
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </div>
  );
};

export default Personalization;
