import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import {
  Col,
  ConfigProvider,
  Button,
  Select,
  Space,
  Tooltip,
  Input,
  Divider,
  Dropdown,
} from 'antd';
import {
  FileSearchOutlined,
  LogoutOutlined,
  LoginOutlined,
  InboxOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { FiExternalLink } from 'react-icons/fi';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Label } from '@components/ui';
import { getDateInISOFormat } from '@utils/formatters';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import ptBR from 'antd/es/locale/pt_BR';
import 'moment/locale/pt-br';
import roles from '@utils/rolesConstants';
import DeviceActionModal from '@components/DeviceActionModal';
import ActionTypes from '@utils/deviceActions';
import { Filters, FiltersCard } from '@components/Filters';
import useSearchParams from '@src/hooks/useSearchParams';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import moment from 'moment';
import StatusMenu from '@src/components/StatusMenu/StatusMenu';
import SessionContext from '@src/store/SessionContext/SessionContext';

const { Search } = Input;

const dateFormat = date => {
  if (date) {
    const dateObj = new Date(date);
    return dateObj.toLocaleString(i18next.language);
  }
  return '';
};

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Transfer = ({
  isInTheRequestDetails,
  defaultFilters,
  columnsToRemove,
}) => {
  const { t } = useTranslation(['transfer', 'request']);
  const { t: tInventory } = useTranslation('inventory');

  const {
    hasCustomersRoles,
    customer: {
      customerLogged,
      customersOptions,
      isCustomerFinanciallyBlocked,
    },
  } = useContext(SessionContext);

  const navigate = useNavigate();

  const [modal, setModal] = useState(undefined);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [status, setStatus] = useState('');
  const [msisdn, setMsisdn] = useState('');
  const [iccid, setIccid] = useState('');
  const [imsi, setImsi] = useState('');
  const [username, setUsername] = useState('');
  const [msisdnValue, setMsisdnValue] = useState('');
  const [iccidValue, setIccidValue] = useState('');
  const [imsiValue, setImsiValue] = useState('');
  const [usernameValue, setUsernameValue] = useState('');
  const [virtualAccountId, setVirtualAccountId] = useState();
  const [archived, setArchived] = useState('0');
  const isArchived = archived === '1';

  const { data: contracts } = useSwr('/service-proxy/contracts');

  const { data: operatorsData } = useSwr('/service-proxy/mot-operators');

  const { data } = useSwr(
    '/service-proxy/sims/simcard-transfer',
    {
      archived,
      customerId: selectedClient,
      page: pagination?.current - 1 || pagination?.defaultCurrent - 1,
      linesPerPage: pagination?.pageSize,
      startDate,
      endDate,
      status,
      imsi,
      msisdn,
      iccid,
      username,
      virtualAccountId,
      ...defaultFilters,
    },
    {
      refreshInterval: 10 * 1000,
    },
  );

  const { data: countByStatus } = useSwr(
    '/service-proxy/sims/simcard-transfer-count-by-status',
    {
      archived,
      customerId: selectedClient,
      startDate,
      endDate,
      status: status?.toUpperCase(),
      imsi,
      msisdn,
      iccid,
      username,
      virtualAccountId,
      ...defaultFilters,
    },
    { refreshInterval: 10 * 1000 },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customer',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'initialDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
      {
        name: 'msisdn',
        setState: setMsisdn,
        inTheFilters: true,
      },
      {
        name: 'iccid',
        setState: setIccid,
        inTheFilters: true,
      },
      {
        name: 'imsi',
        setState: setImsi,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: false,
      },
      {
        name: 'msisdn',
        setState: setMsisdn,
        inTheFilters: true,
      },
      {
        name: 'iccid',
        setState: setIccid,
        inTheFilters: true,
      },
      {
        name: 'imsi',
        setState: setImsi,
        inTheFilters: true,
      },
      {
        name: 'transferredBy',
        setState: setUsername,
        inTheFilters: true,
      },
      {
        name: 'virtualAccountId',
        setState: setVirtualAccountId,
      },
      {
        name: 'archived',
        setState: setArchived,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    if (countByStatus?.length < 2) {
      handleClearParams(['status']);
    }
  }, [countByStatus?.length, handleClearParams]);

  useEffect(() => {
    setMsisdnValue(msisdn);
  }, [msisdn]);

  useEffect(() => {
    setIccidValue(iccid);
  }, [iccid]);

  useEffect(() => {
    setImsiValue(imsi);
  }, [imsi]);

  useEffect(() => {
    setUsernameValue(username);
  }, [username]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [startDate, endDate, status, imsi, msisdn, iccid, selectedClient]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const fromToButton = useCallback(
    record => {
      const currentVAId = defaultFilters?.virtualAccountId;
      const oldVAId = record.oldCustomerVirtualAccountId;
      const newVAId = record.newCustomerVirtualAccountId;
      const isOldVAId = currentVAId === oldVAId;
      const isOldVAIdNull = oldVAId === null;
      const isNewVAIdNull = newVAId === null;
      const isOldVAIdZero = oldVAId === 0;
      const isNewVAIdZero = newVAId === 0;
      const isOldBrokerStock = record.oldCustomer === customerLogged?.id;
      const isNewBrokerStock = record.newCustomer === customerLogged?.id;

      let tooltipText = t(
        t(`movement.see${isOldVAId ? 'Destination' : 'Origin'}Request`),
      );
      let icon = isOldVAId ? <LogoutOutlined /> : <LoginOutlined />;
      const stockIcon = <InboxOutlined style={{ fontSize: '1.25rem' }} />;
      let idToLink;
      if (isOldVAIdNull || isOldBrokerStock || isOldVAIdZero) {
        tooltipText = t('movement.transferred', {
          option: t('movement.fromStock'),
        });
        icon = stockIcon;
      } else if (isNewVAIdNull || isNewBrokerStock || isNewVAIdZero) {
        tooltipText = t('movement.transferred', {
          option: t('movement.forStock'),
        });
        icon = stockIcon;
      } else if (isOldVAId) {
        idToLink = newVAId;
      } else {
        idToLink = oldVAId;
      }

      const showButton =
        !isOldVAIdNull &&
        !isNewVAIdNull &&
        !isOldBrokerStock &&
        !isNewBrokerStock &&
        !isOldVAIdZero &&
        !isNewVAIdZero;

      return (
        <Dropdown
          overlayStyle={{
            background: 'white',
            padding: '4px 8px',
            borderRadius: 5,
            boxShadow: '4px 8px 30px -4px rgb(0 51 90 / 10%)',
          }}
          overlay={
            <div
              style={{
                color: '#333',
                padding: '2px 6px',
                fontSize: '0.9rem',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              {showButton ? (
                <Button
                  type="link"
                  danger={isNewVAIdNull || isOldVAId || isNewVAIdZero}
                  onClick={() => navigate(`/financial/requests/${idToLink}`)}
                >
                  {tooltipText}
                </Button>
              ) : (
                tooltipText
              )}
            </div>
          }
        >
          <Button
            size="middle"
            type="link"
            icon={icon}
            danger={isNewVAIdNull || isOldVAId || isNewVAIdZero}
          />
        </Dropdown>
      );
    },
    [t, defaultFilters, navigate, customerLogged],
  );
  const allColumns = useMemo(
    () => [
      !isInTheRequestDetails && {
        title: t('table.from'),
        dataIndex: 'oldCustomer',
        render: value => value?.name || value?.tradingName || value?.nickname,
      },
      !isInTheRequestDetails && {
        title: t('table.to'),
        dataIndex: 'newCustomer',
        render: value => value?.name || value?.tradingName || value?.nickname,
      },
      isInTheRequestDetails && {
        title: t('movement.title'),
        dataIndex: 'transfersCustomers',
        align: 'center',
        render: (_, record) => fromToButton(record),
      },
      { title: t('table.date'), dataIndex: 'changeDate', render: dateFormat },
      { title: t('table.msisdn'), dataIndex: 'msisdn' },
      { title: t('table.iccid'), dataIndex: 'iccid' },
      { title: t('table.imsi'), dataIndex: 'imsi' },
      { title: t('table.transferred-by'), dataIndex: 'username' },
      {
        title: t('table.request'),
        align: 'center',
        dataIndex: 'request',
        render: (_, record) =>
          record.newCustomerVirtualAccountId ? (
            <Tooltip title={t('table.seeRequest')}>
              <Button
                icon={<FileSearchOutlined />}
                type={'link'}
                size="large"
                onClick={() =>
                  navigate(
                    `/financial/requests/${record.newCustomerVirtualAccountId}`,
                    { state: { showContracted: 'false' } },
                  )
                }
              />
            </Tooltip>
          ) : (
            ''
          ),
      },
      {
        title: t('table.result.title'),
        dataIndex: 'result',
        render: value =>
          t(`table.result.options.${value}`, { type: 'Simcard' }),
      },
    ],
    [isInTheRequestDetails, t, fromToButton, navigate],
  );
  const columns = useMemo(() => {
    if (columnsToRemove) {
      return allColumns
        ?.filter(col => col)
        ?.filter(column => !columnsToRemove.includes(column.dataIndex));
    }
    return allColumns.filter(col => col);
  }, [columnsToRemove, allColumns]);

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const start = getDateInISOFormat(initialDate);
      const end = getDateInISOFormat(endDate);
      handleSetSearchParams({ initialDate: start, endDate: end });
    },
    [handleSetSearchParams],
  );

  const getColorStatus = useCallback(statusName => {
    if (statusName === 'SUCCESS') {
      return '#52c41a';
    }
    return '#ff4d4f';
  }, []);

  const statusMenuData = useMemo(() => {
    const statusData = countByStatus
      ?.map(({ item, value }) => ({
        label: t(`table.result.options.${item}`, { type: 'Simcard' }),
        item,
        value,
        color: getColorStatus(item),
      }))
      ?.sort((a, b) => a.value - b.value);
    return statusData?.reverse();
  }, [countByStatus, t, getColorStatus]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('dateRange'),
        item: (
          <ConfigProvider locale={ptBR}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate
                  ? [moment(startDate, 'YYYYMMDD'), moment(endDate, 'YYYYMMDD')]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
      {
        visible: hasCustomersRoles && !isInTheRequestDetails,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('client'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={customersOptions}
            loading={!customersOptions}
            disabled={!customersOptions}
            placeholder={t('client')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={selectedClient ? Number(selectedClient) : undefined}
            onChange={value => handleSetSearchParams({ customer: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.msisdn'),
        item: (
          <Search
            placeholder={t('table.msisdn')}
            onSearch={value => handleSetSearchParams({ msisdn: value })}
            onChange={({ target: { value } }) => setMsisdnValue(value)}
            value={msisdnValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.iccid'),
        item: (
          <Search
            placeholder={t('table.iccid')}
            onSearch={value => handleSetSearchParams({ iccid: value })}
            onChange={({ target: { value } }) => setIccidValue(value)}
            value={iccidValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.imsi'),
        item: (
          <Search
            placeholder={t('table.imsi')}
            onSearch={value => handleSetSearchParams({ imsi: value })}
            onChange={({ target: { value } }) => setImsiValue(value)}
            value={imsiValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.transferred-by'),
        item: (
          <Search
            placeholder={t('table.transferred-by')}
            onSearch={value => handleSetSearchParams({ transferredBy: value })}
            onChange={({ target: { value } }) => setUsernameValue(value)}
            value={usernameValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      customersOptions,
      endDate,
      handleDateChange,
      handleSetSearchParams,
      hasCustomersRoles,
      iccidValue,
      imsiValue,
      msisdnValue,
      selectedClient,
      startDate,
      usernameValue,
      isInTheRequestDetails,
    ],
  );

  const extraActions = useMemo(
    () =>
      !isCustomerFinanciallyBlocked &&
      !isInTheRequestDetails && (
        <Space>
          <RoleChecker role={roles.TRANSFER}>
            <Button
              type={'primary'}
              onClick={() => setModal('group-transfer')}
              style={{ marginRight: 12 }}
            >
              {t('batch-transfer-button')}
            </Button>
          </RoleChecker>
        </Space>
      ),
    [isCustomerFinanciallyBlocked, isInTheRequestDetails, t],
  );

  return (
    <>
      <Table
        columns={columns}
        allColumns={allColumns}
        data={data?.content}
        extraActions={extraActions}
        loading={!data?.content}
        pagination={pagination}
        tableKey={isInTheRequestDetails ? 'transferInDetails' : 'transfer'}
        title={
          <>
            {t('title')}
            <Tooltip title={t(`${isArchived ? 'hide' : 'show'}Archived`)}>
              <Button
                style={{ marginLeft: 16 }}
                type={isArchived ? 'primary' : 'link'}
                icon={isArchived ? <FolderOpenOutlined /> : <FolderOutlined />}
                onClick={() =>
                  handleSetSearchParams({
                    archived: isArchived ? '0' : '1',
                  })
                }
              />
            </Tooltip>
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        onChange={handleTableChange}
        tabsFilters={
          statusMenuData?.length > 0 && (
            <div
              style={{
                padding: 8,
                marginBottom: 6,
                background: !showFilters && '#f8f8f8',
              }}
            >
              <Button
                size="small"
                type={!status && 'primary'}
                style={{ margin: '4px 6px' }}
                onClick={() => {
                  handleClearParams(['status']);
                }}
              >
                {tInventory('stock-values.all')}
              </Button>
              <StatusMenu
                itemActive={status?.toUpperCase()}
                data={statusMenuData}
                handleSelectedCard={item =>
                  handleSetSearchParams({ status: item.toLowerCase() })
                }
              />
            </div>
          )
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
        mainExtraAction={
          isInTheRequestDetails && (
            <>
              <Divider type="vertical" />
              <Tooltip title={t('requests:viewOnTransferPage')}>
                <Button
                  icon={
                    <FiExternalLink
                      style={{
                        fontSize: '1.4rem',
                        marginTop: 4,
                      }}
                    />
                  }
                  size="large"
                  type="link"
                  onClick={() => {
                    navigate(
                      `/management/transfer?virtualAccountId=${defaultFilters?.virtualAccountId}`,
                    );
                  }}
                />
              </Tooltip>
            </>
          )
        }
      />

      <DeviceActionModal
        actionKey={ActionTypes.TRANSFER}
        visible={modal === 'group-transfer'}
        onClose={() => setModal(undefined)}
        availableOperators={operatorsData}
        dataContracts={contracts}
      />
    </>
  );
};

export default Transfer;
