import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Modal,
  Select,
  Checkbox,
  Skeleton,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import useSwr from '@hooks/useSwr';
import apiMiddleware from '@services/apiMiddleware';
import { getAvailableLocales } from '@src/i18n';
import { maskPhone } from '@utils/formatters';

const { Item } = Form;
const { Option } = Select;

const UserViewerModal = ({ userId, visible, onClose, mutateUsers }) => {
  const { keycloak } = useContext(KeycloakContext);
  const customerId = keycloak.idTokenParsed?.customerId;
  const isNewUser = userId === '';
  const rolesToAccess = keycloak.resourceAccess?.portal.roles;

  const isCustomerCanManageGroups =
    rolesToAccess?.indexOf('manage-groups') !== -1;

  const { t } = useTranslation('accounts');
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const { data: userToUpdate, mutate } = useSwr(
    !isNewUser ? `/users/${userId}` : null,
  );

  const { data: groups } = useSwr('/groups/', { customerId, filter: 'MINE' });

  const formInitialValues = useMemo(() => {
    if (!isNewUser && userToUpdate) {
      const fields = { ...userToUpdate };
      if (!fields.attributes.customerIds) {
        fields.attributes.customerIds = [fields.attributes.customerId];
      }
      fields.groups = fields.groups?.map(g => g.id);
      return fields;
    }
    return {
      attributes: {
        customerIds: [],
      },
    };
  }, [isNewUser, userToUpdate]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formInitialValues);

    setModalVisible(visible);
  }, [form, formInitialValues, visible]);

  const close = useCallback(() => {
    setModalVisible(false);
    onClose();
  }, [onClose]);

  const optionsGroups = useMemo(
    () =>
      groups?.content?.map(({ id, name, attributes }) => ({
        label: attributes?.prettyName || name,
        value: id,
      })),
    [groups],
  );

  const onFinish = useCallback(
    async values => {
      setSaveLoading(true);
      if (isNewUser) {
        const { confirm, groups, password, ...newUser } = values;

        newUser.credentials = [{ value: password }];
        newUser.attributes.customerId = customerId;
        newUser.attributes.customerIds = [customerId];

        try {
          const { data } = await apiMiddleware.post('/users/', newUser);
          await apiMiddleware.put(
            `/users/${data.id}/groups/`,
            groups?.map(g => ({ id: g })),
          );
          mutateUsers('CREATE', {
            ...newUser,
            id: data.id,
          });
          message.success(t('msgs.success-creating-user'));
          close();
        } catch ({ response: { status } }) {
          if (status === 409) {
            form.setFields([
              {
                name: 'username',
                errors: [t('modal.form.msgs.usernameAlreadyRegistered')],
              },
            ]);
          } else {
            message.error(t('msgs.error-creating-user'));
          }
        }
      } else {
        try {
          const { groups: groupsId, password, ...updUser } = values;
          updUser.credentials = password && [{ value: password }];

          await apiMiddleware.put(`/users/${userId}`, updUser);

          if (isCustomerCanManageGroups) {
            await apiMiddleware.put(
              `/users/${userId}/groups/`,
              groupsId?.map(g => ({ id: g })),
            );
          }
          const { data } = await apiMiddleware.get(`/users/${userId}`);
          mutate(data);
          mutateUsers('UPDATE', data);
          message.success(t('msgs.success-updating-user'));
          close();
        } catch ({ response: { status } }) {
          if (status === 409) {
            form.setFields([
              {
                name: 'username',
                errors: [t('modal.form.msgs.usernameAlreadyRegistered')],
              },
            ]);
          } else if (status === 403) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              groups: userToUpdate.groups.map(group => group.id),
            });
            form.setFields([
              {
                name: 'groups',
                errors: [t('modal.form.msgs.profileCannotBeRemoved')],
              },
            ]);
          } else {
            message.error(t('msgs.error-updating-user'));
          }
        }
      }
      setSaveLoading(false);
    },
    [
      close,
      form,
      isNewUser,
      mutate,
      mutateUsers,
      t,
      userId,
      userToUpdate,
      isCustomerCanManageGroups,
      customerId,
    ],
  );

  return (
    <Modal
      cancelText={t('modal.buttons.cancelButton')}
      confirmLoading={saveLoading}
      maskClosable={false}
      okButtonProps={{ form: 'userForm', htmlType: 'submit' }}
      okText={t('modal.buttons.saveButton')}
      style={{ maxWidth: 800 }}
      title={
        isNewUser
          ? t('modal.titles.newAccount')
          : t('modal.titles.updateAccount')
      }
      visible={modalVisible}
      width="70%"
      centered
      onCancel={close}
    >
      <Skeleton
        loading={!isNewUser && (optionsGroups?.length === 0 || !userToUpdate)}
        active
      >
        <Form
          colon={false}
          form={form}
          id="userForm"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          scrollToFirstError
          onFinish={onFinish}
        >
          <Row>
            <Col sm={12} xs={24}>
              {/* First name */}
              <Item
                label={t('modal.form.labels.firstName')}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('modal.form.msgs.fillThisField'),
                  },
                ]}
              >
                <Input />
              </Item>

              {/* Last name */}
              <Item
                label={t('modal.form.labels.lastName')}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('modal.form.msgs.fillThisField'),
                  },
                ]}
              >
                <Input />
              </Item>

              {/* Username */}
              <Item
                label={t('modal.form.labels.username')}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('modal.form.msgs.fillThisField'),
                  },
                  {
                    validator: (rule, value) => {
                      const hasWhitespace = value?.indexOf(' ') !== -1;
                      if (value && hasWhitespace) {
                        return Promise.reject(
                          t('modal.form.msgs.removeTheBlanks'),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input autoComplete="off" disabled={!isNewUser} />
              </Item>

              {/* CPF/CNPJ */}
              <Item
                label={t('modal.form.labels.cpfCnpj')}
                name={['attributes', 'cpfCnpj']}
                normalize={value => (value ? value.replace(/\D/g, '') : '')}
              >
                <Input maxLength={30} />
              </Item>
            </Col>
            <Col sm={12} xs={24}>
              {/* Locale */}
              <Item label={t('locales.title')} name={['attributes', 'locale']}>
                <Select>
                  {getAvailableLocales().map(locale => (
                    <Option key={locale} value={locale}>
                      {t(`locales.${locale}`)}
                    </Option>
                  ))}
                </Select>
              </Item>

              {/* Email */}
              <Item
                label={t('modal.form.labels.email')}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('modal.form.msgs.fillThisField'),
                  },
                  {
                    type: 'email',
                    message: t('modal.form.msgs.notValidEmail'),
                  },
                ]}
              >
                <Input />
              </Item>

              {/* Phone number */}
              <Item
                label={t('modal.form.labels.phoneNumber')}
                name={['attributes', 'phoneNumber']}
                normalize={value => maskPhone(value)}
              >
                <Input maxLength="15" />
              </Item>
            </Col>
          </Row>
          <Divider />
          {/* Roles */}
          <Item
            label={t('modal.form.labels.roles')}
            name="groups"
            rules={[
              {
                required: true,
                message: t('modal.form.msgs.selectAtLeastOneRole'),
              },
            ]}
          >
            <Select
              loading={!optionsGroups}
              mode="multiple"
              optionLabelProp="label"
              options={optionsGroups}
              showSearch
              disabled={
                !isCustomerCanManageGroups ||
                !optionsGroups ||
                optionsGroups?.length === 0
              }
              filterOption={(input, option) => {
                return String(option?.label).toLowerCase().indexOf(input) >= 0;
              }}
            />
          </Item>
          <Divider />
          {/* Password */}
          <Item
            label={t('modal.form.labels.password')}
            name="password"
            rules={[
              {
                required: isNewUser,
                message: t('modal.form.msgs.fillThisField'),
              },
              {
                min: 10,
                message: t('modal.form.msgs.passwordLength', { minLength: 10 }),
              },
            ]}
          >
            <Input.Password autoComplete="off" />
          </Item>
          {/* Confirm password */}
          <Item
            dependencies={['password']}
            label={t('modal.form.labels.confirmPassword')}
            name="confirm"
            rules={[
              {
                required: isNewUser,
                message: t('modal.form.msgs.fillThisField'),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  return !value || getFieldValue('password') === value
                    ? Promise.resolve()
                    : Promise.reject(t('modal.form.msgs.passwordDoesNotMatch'));
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Item>
          <Divider />
          {/* Required actions */}
          <Item
            label={t('modal.form.labels.onNextLogin')}
            name="requiredActions"
          >
            <Checkbox.Group style={{ width: '100%', padding: '0 20px' }}>
              <Row>
                <Checkbox value="VERIFY_EMAIL" style={{ margin: 0 }}>
                  {t('modal.form.labels.verifyEmail')}
                </Checkbox>
                <Checkbox value="UPDATE_PASSWORD" style={{ margin: 0 }}>
                  {t('modal.form.labels.updatePassword')}
                </Checkbox>
              </Row>
            </Checkbox.Group>
          </Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};

export default UserViewerModal;
