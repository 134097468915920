import React, { useCallback, useMemo, useState } from 'react';
import { Column } from '@ant-design/plots';
import { Card } from 'antd';
import { Radio } from 'antd';
import useSwr from '@hooks/useSwr';
import moment from 'moment';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ChartActivationDeactivation = () => {
  const { t } = useTranslation('lorawan');
  const [periodType, setPeriodType] = useState('daily');

  const { data } = useSwr(
    '/service-proxy/lorawanDevices/activationDeactivationStats',
    {
      periodType,
      product: 'LORAWAN_MOT',
    },
  );

  const handleDate = useCallback(
    dateString => {
      const isDaily = periodType === 'daily';
      const dateFormat = isDaily ? 'YYYY-MM-DD' : 'YYYY-MM';

      const dateInMoment = moment(dateString, dateFormat).locale(
        i18next.language,
      );

      return isDaily
        ? dateInMoment.format('L')
        : dateInMoment.format('MMM YYYY')?.toUpperCase();
    },
    [periodType],
  );

  const treatedData = useMemo(
    () =>
      data
        ?.map(({ key, activations, deactivations }) => {
          if (!activations && !deactivations) {
            return [];
          }
          return [
            {
              name: t('activations'),
              value: Math.floor(activations),
              month: handleDate(key),
            },
            {
              name: t('deactivations'),
              value: Math.floor(deactivations),
              month: handleDate(key),
            },
          ];
        })
        ?.flat()
        ?.sort((a, b) => {
          if (periodType === 'monthly') {
            return (
              Number(moment(a.month, 'MMM YYYY')) -
              Number(moment(b.month, 'MMM YYYY'))
            );
          }
          return 0;
        }),
    [data, handleDate, periodType, t],
  );

  const config = {
    data: treatedData ?? [],
    isGroup: true,
    xField: 'month',
    yField: 'value',
    seriesField: 'name',
    color: ['#1ca9e6', 'red'],
    yAxis: {
      label: {
        formatter: value => Math.floor(Number(value)).toString(),
      },
    },
  };

  return (
    <Card
      title={t('activationAndDeactivations')}
      style={{ width: '100%' }}
      bodyStyle={{
        padding: 24,
      }}
      extra={
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          onChange={e => setPeriodType(e.target.value)}
          defaultValue="daily"
          value={periodType}
        >
          <Radio.Button value="daily">{t('daily')}</Radio.Button>
          <Radio.Button value="monthly">{t('monthly')}</Radio.Button>
        </Radio.Group>
      }
    >
      <Column {...config} />
    </Card>
  );
};

export default ChartActivationDeactivation;
