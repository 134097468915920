import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Input, Space, Tag, Tooltip, Button, Col } from 'antd';
import { formatLongText } from '@src/utils/formatters';

const TagInput = ({ newButtonText, onChange }) => {
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    onChange?.(tags);
  }, [onChange, tags]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = useCallback(
    removedTag => {
      const newTags = tags?.filter(tag => tag !== removedTag);
      setTags(newTags);
    },
    [tags],
  );

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = useCallback(e => {
    setInputValue(e.target.value);
  }, []);

  const handleInputConfirm = useCallback(() => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  }, [inputValue, tags]);

  const handleEditInputChange = useCallback(e => {
    setEditInputValue(e.target.value);
  }, []);

  const handleEditInputConfirm = useCallback(() => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  }, [editInputIndex, editInputValue, tags]);

  return (
    <div>
      <Space size={[0, 8]} wrap>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }
          const characterLimit = 16;
          return (
            <Tooltip title={tag?.length > characterLimit && tag} key={tag}>
              <Tag
                key={tag}
                closable
                onClose={() => handleClose(tag)}
                onClick={() => {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                }}
                style={{
                  fontSize: '0.9rem',
                  opacity: 0.8,
                }}
              >
                {formatLongText(tag, characterLimit)}
              </Tag>
            </Tooltip>
          );
        })}
      </Space>
      <Col
        style={{ padding: 0, marginTop: tags?.length > 0 && 16 }}
        lg={12}
        xl={12}
        xs={24}
      >
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Button
            style={{
              borderStyle: 'dashed',
              fontSize: '0.9rem',
              opacity: 0.8,
            }}
            onClick={showInput}
            icon={<PlusCircleOutlined />}
          >
            {newButtonText}
          </Button>
        )}
      </Col>
    </div>
  );
};
export default TagInput;
