import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Form, Input, InputNumber, Row, Tag, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

const formInitialValues = {
  name: undefined,
  tradeInMonths: undefined,
  uplinkCount: undefined,
  downlinkCount: undefined,
  excessUplinkPrice: undefined,
  excessDownlinkPrice: undefined,
  monthlyFee: undefined,
  activationPrice: undefined,
};

const ConfigStep = ({ onChange, contractDetails }) => {
  const { t } = useTranslation(['requests', 'attributes']);
  const [form] = Form.useForm();

  useEffect(() => {
    if (contractDetails) {
      form.setFieldsValue(contractDetails);
    }
  }, [contractDetails, form]);

  const sharingType = useMemo(() => {
    const type = contractDetails?.sharingType;
    return type ? t(`attributes:type.${type}`) : undefined;
  }, [t, contractDetails]);

  const DescriptionModel = useCallback(
    ({ label, text }) => (
      <Tag
        style={{
          margin: 4,
          opacity: 0.6,
          padding: '2px 8px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ fontWeight: 'bold', fontSize: '0.75rem' }}>
          {`${label}: `}
        </span>
        <span style={{ fontSize: '0.85rem', marginLeft: 4 }}>{text}</span>
      </Tag>
    ),
    [],
  );

  const requestDetailsItems = useMemo(
    () => [
      {
        name: 'name',
        label: 'description',
        element: <Input allowClear />,
        required: true,
      },
      {
        name: 'tradeInMonths',
        label: 'numberOfTradeInInvoices',
        element: <InputNumber min={0} step={1} style={{ width: '100%' }} />,
      },
    ],
    [],
  );

  const preDefinedItems = useMemo(() => {
    const inputNumberCurrencyElement = (
      <InputNumber
        defaultValue={0}
        min={0}
        step={0.01}
        addonBefore="R$"
        style={{ width: '100%' }}
      />
    );

    const inputNumberValueElement = (
      <InputNumber
        defaultValue={0}
        min={0}
        step={1}
        style={{ width: '100%' }}
      />
    );

    return [
      {
        name: 'uplinkCount',
        element: inputNumberValueElement,
      },
      {
        name: 'excessUplinkPrice',
        element: inputNumberCurrencyElement,
      },
      {
        name: 'downlinkCount',
        element: inputNumberValueElement,
      },
      {
        name: 'excessDownlinkPrice',
        element: inputNumberCurrencyElement,
      },
      {
        name: 'monthlyFee',
        element: inputNumberCurrencyElement,
      },
      {
        name: 'activationPrice',
        element: inputNumberCurrencyElement,
      },
    ];
  }, []);

  const onValuesChange = useCallback(
    (valueChanged, values) => {
      const valueChangedKey = Object.keys(valueChanged)?.[0];
      if (valueChangedKey === 'loyaltyType') {
        form.setFieldsValue({ penalty: 0, loyaltyTime: undefined });
      }
      onChange(values);
    },
    [form, onChange],
  );

  return (
    <Form
      form={form}
      initialValues={formInitialValues}
      onValuesChange={onValuesChange}
      layout="vertical"
    >
      <Row gutter={[16, 16]}>
        {requestDetailsItems?.map(({ name, label, required, element }) => (
          <Col key={name} lg={12} xs={24}>
            <Form.Item
              required={required}
              name={name}
              label={t(`attributes:${label ?? name}`)}
            >
              {element}
            </Form.Item>
          </Col>
        ))}
      </Row>
      <>
        <Divider
          style={{
            fontSize: '0.75rem',
            opacity: 0.7,
            textTransform: 'uppercase',
          }}
        >
          {t('contracts:details.preDefined')}
        </Divider>
        <Row
          gutter={[16, 10]}
          style={{
            marginBottom: 24,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {sharingType && (
            <DescriptionModel
              label={t('attributes:type.title')}
              text={sharingType}
            />
          )}
        </Row>
        <Row gutter={[16, 10]}>
          {preDefinedItems?.map(
            ({ name, label, element, required, doNotShow }) =>
              !doNotShow && (
                <Col key={name} lg={12} xl={12} xs={24}>
                  <Form.Item
                    required={required}
                    name={name}
                    label={t(`attributes:${label ?? name}`)}
                  >
                    {element}
                  </Form.Item>
                </Col>
              ),
          )}
        </Row>
      </>
    </Form>
  );
};

export default ConfigStep;
