import styled from 'styled-components';

export const PadlockStatusContainer = styled.div`
  display: inline-flex;
  align-items: center;

  .padlock-status-tag {
    opacity: 0.8;
    margin: 0;
  }

  .padlock-status-divider {
    margin: 0 12px;
  }

  .padlock-status-button {
    display: grid;
    place-items: center;

    &:hover {
      transform: scale(1.05);
    }
  }
`;
