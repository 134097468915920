import ActionsItems from './ActionsItems';
import ActionsMenu from './ActionsMenu';
import ActionsModal from './ActionsModal';
import ActionsUpload from './ActionsUpload';

const Actions = {
  Menu: ActionsMenu,
  Modal: ActionsModal,
  Upload: ActionsUpload,
  Items: ActionsItems,
};

export default Actions;
