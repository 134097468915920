import React, { useMemo } from 'react';
import { Input, Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import ImageUpload from '@src/components/ImageUpload/index';
import ParagraphTitle from '@src/components/ParagraphTitle/index';

const BannerUpload = ({ defaultFile }) => {
  const { t } = useTranslation('banners');
  const dimensions = useMemo(() => ({ width: 1200, height: 400 }), []);
  return (
    <>
      <Form.Item
        name="description"
        label={t('modal.labels.description')}
        style={{ marginBottom: 32 }}
        rules={[
          {
            required: true,
            message: t('msgs.required'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <ParagraphTitle
        title={t('modal.img-upload')}
        subtitle={t('modal.img-upload-subtitle')}
        style={{ marginBottom: 24 }}
      />
      <Form.Item
        name="file"
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: t('msgs.required'),
          },
        ]}
      >
        <ImageUpload
          dimensions={dimensions}
          defaultFile={defaultFile}
          allowCut
        />
      </Form.Item>
      <Form.Item
        name="addInfoEnabled"
        valuePropName="checked"
        style={{ margin: '8px 0 0 0' }}
      >
        <Checkbox>{t('modal.labels.enabled-extra-info')}</Checkbox>
      </Form.Item>
    </>
  );
};

export default BannerUpload;
