export const allAttributesWithValue = data => {
  const allDataIndex = [];
  if (data) {
    data?.content?.forEach(line => {
      allDataIndex.push(...Object.keys(line));
    }, []);
  }

  return [...new Set(allDataIndex)]; // Remove duplicated values
};
