import React, { memo, useEffect, useMemo, useState } from 'react';

import { Modal, Steps, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.less';

const { Step } = Steps;

const ModalOfSteps = ({
  title,
  visible,
  width,
  steps,
  confirmLoading,
  handleConfirm,
  onClose,
  disableConfirmButton,
  footer,
  allowSteps = true,
}) => {
  const { t } = useTranslation('general');
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!visible) {
      setCurrent(0);
    }
  }, [visible]);

  const stepsToBeDisplayed = useMemo(
    () => steps?.filter(item => !item.doNotShow),
    [steps],
  );

  const modalFooter = useMemo(
    () => (
      <div key="0" className={'steps-action'}>
        {current > 0 && (
          <Button onClick={() => setCurrent(current - 1)}>
            {t('buttons.return')}
          </Button>
        )}
        {current < stepsToBeDisplayed?.length - 1 && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setCurrent(current + 1)}
            loading={stepsToBeDisplayed?.[current]?.loadingNextButton}
            disabled={stepsToBeDisplayed?.[current]?.disableNextButton}
          >
            {t('buttons.next')}
          </Button>
        )}
        {current === stepsToBeDisplayed?.length - 1 && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleConfirm}
            loading={confirmLoading}
            disabled={
              stepsToBeDisplayed?.[current]?.disableNextButton ||
              disableConfirmButton
            }
          >
            {t('buttons.confirm')}
          </Button>
        )}
      </div>
    ),
    [
      current,
      stepsToBeDisplayed,
      t,
      confirmLoading,
      handleConfirm,
      disableConfirmButton,
    ],
  );

  return (
    <Modal
      footer={footer ?? modalFooter}
      title={title}
      visible={visible}
      onCancel={onClose}
      width={width ?? '600px'}
    >
      {allowSteps && (
        <Steps current={current} size="small">
          {stepsToBeDisplayed?.map(item => (
            <Step key={item?.title} title={item?.title} />
          ))}
        </Steps>
      )}
      <div className="modal-of-steps-content">
        {stepsToBeDisplayed?.[current]?.content}
      </div>
    </Modal>
  );
};

export default memo(ModalOfSteps);
