import React from 'react';
import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Checkbox,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getAvailableLocales } from '@src/i18n';

const { Item } = Form;
const { Paragraph } = Typography;
const { Option } = Select;

const CustomerAdminForm = ({ form, onFormFinish }) => {
  const { t } = useTranslation('customers');

  return (
    <>
      <Form
        colon={false}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFormFinish}
      >
        <Paragraph style={{ padding: '10px 0' }}>
          {t('form.help.admin')}
        </Paragraph>
        <Divider />
        <Row>
          <Col xl={12} xs={24}>
            {/* First name */}
            <Item
              label={t('attributes.name')}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t('form.msgs.fillThisField'),
                },
              ]}
            >
              <Input />
            </Item>

            {/* Last name */}
            <Item
              label={t('attributes.lastName')}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t('form.msgs.fillThisField'),
                },
              ]}
            >
              <Input />
            </Item>

            {/* Username */}
            <Item
              label={t('attributes.username')}
              name="username"
              rules={[
                {
                  required: true,
                  message: t('form.msgs.fillThisField'),
                },
                {
                  validator: (rule, value) => {
                    const hasWhitespace = value?.indexOf(' ') !== -1;
                    if (value && hasWhitespace) {
                      return Promise.reject(t('form.msgs.removeTheBlanks'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input autoComplete="off" />
            </Item>

            {/* CPF/CNPJ */}
            <Item
              label={t('attributes.cpfCnpj')}
              name={['attributes', 'cpfCnpj']}
              normalize={value => (value ? value.replace(/\D/g, '') : '')}
            >
              <Input maxLength={30} />
            </Item>
          </Col>

          <Col xl={12} xs={24}>
            {/* Locale */}
            <Item
              label={t('attributes.locale')}
              name={['attributes', 'locale']}
            >
              <Select>
                {getAvailableLocales().map(locale => (
                  <Option key={locale} value={locale}>
                    {t(`attributes.locales.${locale}`)}
                  </Option>
                ))}
              </Select>
            </Item>

            {/* Email */}
            <Item
              label={t('attributes.email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('form.msgs.fillThisField'),
                },
                {
                  type: 'email',
                  message: t('form.msgs.notValidEmail'),
                },
              ]}
            >
              <Input />
            </Item>

            {/* Phone number */}
            <Item
              label={t('attributes.phoneNumber')}
              name={['attributes', 'phoneNumber']}
            >
              <Input />
            </Item>
          </Col>
        </Row>

        <Divider />
        {/* Password */}
        <Item
          label={t('attributes.password')}
          name="password"
          rules={[
            {
              required: true,
              message: t('form.msgs.fillThisField'),
            },
            {
              min: 10,
              message: t('form.msgs.passwordLength', { minLength: 10 }),
            },
          ]}
        >
          <Input.Password />
        </Item>
        {/* Confirm password */}
        <Item
          dependencies={['password']}
          label={t('attributes.confirmPassword')}
          name="confirm"
          rules={[
            {
              required: true,
              message: t('form.msgs.passwordConfirmRequired'),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('form.msgs.passwordDoesNotMatch'));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Item>
        <Divider />
        <Row>
          <Col xs={24}>
            {/* Required actions */}
            <Item label={t('form.admin.onNextLogin')} name="requiredActions">
              <Checkbox.Group className="checkbox-group">
                <Checkbox value="VERIFY_EMAIL">
                  {t('form.admin.verifyEmail')}
                </Checkbox>
                <Checkbox value="UPDATE_PASSWORD" style={{ margin: 0 }}>
                  {t('form.admin.updatePassword')}
                </Checkbox>
              </Checkbox.Group>
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CustomerAdminForm;
