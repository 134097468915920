import React, { useCallback, useMemo } from 'react';
import { Row, Col, Tooltip, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { bytesFormat } from '@utils/formatters';

import * as S from './styles';

const ConsumptionCard = ({ data, currentConsumption }) => {
  const { t } = useTranslation('contracts');

  const hasCurrentConsumption = useMemo(
    () => !!currentConsumption,
    [currentConsumption],
  );

  const updatedDate = useMemo(() => {
    const date = data?.updatedDate;
    if (!date) return undefined;

    const [day, month, year, hour, minute, second] = date.split('-');

    const formattedDate = `${day}/${month}/${year} ${hour}:${minute}:${second}`;

    return formattedDate;
  }, [data]);

  const rateConsumptionStates = useMemo(() => {
    const {
      totalConsumption,
      totalFranchise,
      franchiseConsumption,
      surplusConsumption,
    } = data;

    return [
      {
        name: 'totalConsumption',
        title: `${t('totalConsumption')} / ${t('totalFranchise')}`,
        value: `${bytesFormat(totalConsumption)} / ${bytesFormat(
          totalFranchise,
        )}`,
        md: 10,
      },
      {
        name: 'franchiseConsumption',
        value: bytesFormat(franchiseConsumption),
        md: 7,
      },
      {
        name: 'surplusConsumption',
        value: bytesFormat(surplusConsumption),
        md: 7,
      },
    ];
  }, [data, t]);

  const statsInfo = useCallback(
    title => (
      <Tooltip title={t(title)}>
        <Button type="link" icon={<QuestionCircleOutlined />} />
      </Tooltip>
    ),
    [t],
  );

  const progressBar = useCallback((value, total, size) => {
    if (!value || !total) {
      return <></>;
    }

    const percentage = (value / total) * 100;

    return (
      <S.ProgressBarContainer size={size}>
        <S.ProgressBarPercentage>{`${percentage.toFixed(
          1,
        )}%`}</S.ProgressBarPercentage>
        <S.ProgressBarContent>
          <S.ProgressBarContentMain percentage={percentage} />
        </S.ProgressBarContent>
      </S.ProgressBarContainer>
    );
  }, []);

  return (
    <Card title={t('consumption')} bodyStyle={{ padding: 24 }}>
      <Row gutter={[16, 16]}>
        {hasCurrentConsumption && (
          <S.ColCurrentConsumption md={8} sm={24} xs={24}>
            <Col key="currentConsumption" span={24}>
              <S.ConsumptionSectionHeader>
                <S.ConsumptionSectionHeaderTitle>
                  {t('currentConsumption')}
                  {statsInfo('currentConsumptionInfo')}
                </S.ConsumptionSectionHeaderTitle>
              </S.ConsumptionSectionHeader>
              <S.ConsumptionStat
                title={`${t('totalConsumption')} / ${t('totalFranchise')}`}
                value={`${bytesFormat(
                  currentConsumption?.consumption,
                )} / ${bytesFormat(currentConsumption?.franchise)}`}
              />
            </Col>
            {progressBar(
              currentConsumption?.consumption,
              currentConsumption?.franchise,
              300,
            )}
          </S.ColCurrentConsumption>
        )}
        <S.ColRateConsumption
          md={hasCurrentConsumption ? 16 : 24}
          sm={24}
          xs={24}
          hasCurrentConsumption={hasCurrentConsumption}
        >
          {hasCurrentConsumption && (
            <S.ConsumptionSectionHeader>
              <S.ConsumptionSectionHeaderTitle>
                {t('rateConsumption')}
                {statsInfo('rateConsumptionInfo')}
              </S.ConsumptionSectionHeaderTitle>
              <S.ConsumptionSectionHeaderDate>
                <span>{`${t('updatedIn')}: `}</span>
                {updatedDate}
              </S.ConsumptionSectionHeaderDate>
            </S.ConsumptionSectionHeader>
          )}
          <Row gutter={[16, 16]}>
            {rateConsumptionStates?.map(({ name, title, value, md }) => (
              <Col key={name} md={md} sm={24} xs={24}>
                <S.ConsumptionStat
                  title={title ?? t(name)}
                  type={name}
                  value={value}
                />
              </Col>
            ))}
          </Row>
          {progressBar(data?.totalConsumption, data?.totalFranchise, 500)}
        </S.ColRateConsumption>
      </Row>
    </Card>
  );
};

export default ConsumptionCard;
