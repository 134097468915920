import styled from 'styled-components';

const Title = styled.div`
  color: #575962;
  font-size: 1.25rem;
  font-weight: 600;
`;

Title.propTypes = {};

export default Title;
