import { useMemo } from 'react';
import { LorawanActionTypes } from '@utils/deviceActions';
import rolesConstants from '@utils/rolesConstants';
import { useTranslation } from 'react-i18next';

const useDeactivation = ({ devices, isUpload }) => {
  const { t } = useTranslation('device-actions');

  const payloadToSend = useMemo(
    () => ({
      action: LorawanActionTypes.DEACTIVATION,
      devices: isUpload
        ? devices
        : devices?.map(device => ({
            devEui: device.devEui,
          })),
    }),
    [devices, isUpload],
  );

  return {
    steps: [],
    title: t(
      `device-actions:labels.lorawan.${rolesConstants.LORAWAN_DEACTIVATION}`,
    ),
    payloadToSend,
    totalDevices: devices?.length,
  };
};

export default useDeactivation;
