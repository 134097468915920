import Keycloak from 'keycloak-js';

const host = window.location.hostname;
const protocol = window.location.protocol;

const keycloak = new Keycloak({
  url:
    host === 'localhost'
      ? 'http://allcom.iot/auth'
      : `${protocol}//${host}/auth`,
  realm: '0d5a3954',
  clientId: 'portal',
});

window.keycloak = keycloak;

export default keycloak;
