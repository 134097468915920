import React, { useContext } from 'react';
import SessionContext from '@store/SessionContext/SessionContext';
import PropTypes from 'prop-types';

const RoleChecker = ({ role, children, client = 'portal' }) => {
  const { userRoles } = useContext(SessionContext);
  const hasRole = roleToCheck => userRoles?.[client]?.includes(roleToCheck);
  return <>{hasRole(role) ? children : null}</>;
};

export const useCheckRole = ({ role, client = 'portal' }) => {
  const { userRoles } = useContext(SessionContext);
  return userRoles?.[client]?.includes(role);
};

export const useFilterRoutes = ({ routes, client = 'portal' }) => {
  const {
    userRoles,
    customer: { customerLogged },
  } = useContext(SessionContext);

  const childrenHasRole = children =>
    children.some(({ role }) => userRoles?.[client]?.includes(role));

  const removeAstroRoutes = () => {
    if (!customerLogged?.isRegisteredInAstro) {
      const findAstroRoutes = routes.find(({ url }) => url === '/schedule');

      return [
        ...routes.filter(({ url }) => url !== '/schedule'),
        {
          ...findAstroRoutes,
          children: findAstroRoutes?.children?.filter(
            child => child.url == '/company',
          ),
        },
      ];
    }
    return routes;
  };

  return removeAstroRoutes()
    .filter(({ children }) => childrenHasRole(children))
    .map(route => {
      route.children = route.children.filter(child => {
        const isPageHome = child.url === '';
        if (isPageHome) {
          return child;
        }
        return userRoles?.[client]?.includes(child.role);
      });
      return route;
    });
};

RoleChecker.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.string.isRequired,
};

export default RoleChecker;
