import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Progress,
  Button,
  Input,
  Col,
  ConfigProvider,
  Tooltip,
  Space,
  Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusSquareOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/pt-br';
import useSwr from '@src/hooks/useSwr';
import Table from '@components/Table';
import { Label } from '@components/ui';
import { dateFormat } from '@src/utils/formatters';
import CustomPopover from '@src/components/CustomPopover/CustomPopover';
import useSearchParams from '@src/hooks/useSearchParams';
import { FiltersCard, Filters } from '@src/components/Filters/index';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import { getDateInISOFormat } from '@utils/formatters';
import moment from 'moment';
import ptBR from 'antd/es/locale/pt_BR';
import BackToListing from '@src/components/BackToListing/BackToListing';
import RoleChecker from '@src/components/RoleChecker/RoleChecker';
import rolesConstants from '@src/utils/rolesConstants';
import AddItemsInvoice from '../AddItemsInvoice/AddItemsIvoice';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const InvoiceActions = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const [createdByValue, setCreatedByValue] = useState('');
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [protocol, setProtocol] = useState('');
  const [protocolValue, setProtocolValue] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [showAddItemsModal, setShowAddItemsModal] = useState(false);
  const { t } = useTranslation('invoices-actions');
  const navigate = useNavigate();

  const { data } = useSwr(
    `/service-proxy/broker/invoice-actions/summary`,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      createdBy,
      startDate,
      endDate,
      protocol,
    },
    { refreshInterval: 10 * 2000 },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'createdBy',
        setState: setCreatedBy,
        inTheFilters: true,
      },
      {
        name: 'protocol',
        setState: setProtocol,
        inTheFilters: true,
      },
      {
        name: 'initialDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const columnProgress = useCallback(
    (value, record) => {
      const { nbTotal, nbQueued, nbSuccess, nbError } = record;

      const dataContentPopover = [
        {
          title: t('upload-items.queu'),
          value: `${nbQueued}`,
          color: 'blue',
          show: nbQueued > 0,
        },
        {
          title: t('upload-items.error'),
          value: `${nbError}`,
          color: 'red',
          show: nbError > 0,
        },
        {
          title: t('upload-items.success'),
          value: `${nbSuccess}`,
          color: 'green',
          show: nbSuccess > 0,
        },
      ];

      const isFinish = nbQueued === 0;

      return (
        <div>
          {value && (
            <>
              {nbTotal && (
                <CustomPopover
                  title={t('upload-items.total')}
                  titleValue={nbTotal}
                  dataContent={dataContentPopover}
                  style={{ cursor: 'pointer' }}
                >
                  <span>
                    <Progress
                      percent={
                        isFinish
                          ? 100
                          : nbError === nbTotal
                          ? 100
                          : 100 -
                            Math.trunc(((nbQueued + nbError) / nbTotal) * 100)
                      }
                      size="small"
                      status={
                        nbQueued !== 0
                          ? 'active'
                          : nbError !== 0
                          ? 'exception'
                          : 'success'
                      }
                      style={{ cursor: 'pointer' }}
                    />
                  </span>
                </CustomPopover>
              )}
            </>
          )}
        </div>
      );
    },
    [t],
  );

  const columns = useMemo(() => {
    const defaultColumns = [
      { title: t('table.protocol'), dataIndex: 'protocol' },
      {
        title: t('table.actionType'),
        dataIndex: 'type',
        render: actionType => <span>{t(`action-type.${actionType}`)}</span>,
      },
      {
        title: t('table.progress'),
        dataIndex: 'nbTotal',
        render: columnProgress,
      },
      {
        title: t('table.createdAt'),
        dataIndex: 'createdAt',
        render: value => dateFormat(value),
      },
      {
        title: t('table.createdBy'),
        dataIndex: 'createdBy',
      },
      {
        title: '',
        dataIndex: 'actions',
        align: 'center',
        render: (_, record) => {
          return (
            <Button
              type="link"
              color={'#5867dd'}
              icon={<FileSearchOutlined />}
              onClick={() =>
                navigate(
                  `/financial/invoices/actions/details?protocol=${record.protocol}`,
                )
              }
            />
          );
        },
      },
    ];

    return defaultColumns;
  }, [t, columnProgress, navigate]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const start = getDateInISOFormat(initialDate);
      const end = getDateInISOFormat(endDate);
      handleSetSearchParams({ initialDate: start, endDate: end });
    },
    [handleSetSearchParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.createdBy'),
        item: (
          <Search
            placeholder={t('table.createdBy')}
            onSearch={value => handleSetSearchParams({ createdBy: value })}
            onChange={({ target: { value } }) => setCreatedByValue(value)}
            value={createdByValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.protocol'),
        item: (
          <Search
            placeholder={t('table.protocol')}
            onSearch={value => handleSetSearchParams({ protocol: value })}
            onChange={({ target: { value } }) => setProtocolValue(value)}
            value={protocolValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.date'),
        item: (
          <ConfigProvider locale={ptBR}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate
                  ? [moment(startDate, 'YYYYMMDD'), moment(endDate, 'YYYYMMDD')]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
    ],
    [
      handleSetSearchParams,
      t,
      createdByValue,
      endDate,
      handleDateChange,
      protocolValue,
      startDate,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [createdBy, protocol, startDate, endDate]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  useEffect(() => {
    setCreatedByValue(createdBy);
  }, [createdBy]);

  useEffect(() => {
    setProtocolValue(protocol);
  }, [protocol]);

  return (
    <>
      <RoleChecker role={rolesConstants.ADD_ITEMS_TO_INVOICE}>
        <AddItemsInvoice
          visible={showAddItemsModal}
          onClose={() => setShowAddItemsModal(false)}
        />
      </RoleChecker>
      <Table
        title={
          <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            <BackToListing title={t('title-action')} noPadding />
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
              style={{ padding: 0 }}
            />
          </div>
        }
        columns={columns}
        data={data?.content}
        loading={!data}
        pagination={pagination}
        onChange={handleTableChange}
        tableKey={'id'}
        extraActions={
          <>
            <RoleChecker role={rolesConstants.ADD_ITEMS_TO_INVOICE}>
              <Tooltip title={t('new-items-in-list')}>
                <Button
                  type="link"
                  size="large"
                  icon={<PlusSquareOutlined />}
                  onClick={() => setShowAddItemsModal(true)}
                />
              </Tooltip>
            </RoleChecker>
          </>
        }
        mainExtraAction={
          <Space>
            <Divider orientation="center" type="vertical" />
            <Tooltip title={t('invoice-all-actions')}>
              <Button
                icon={<FileSearchOutlined />}
                onClick={() => navigate(`/financial/invoices/actions/details`)}
                type="primary"
              />
            </Tooltip>
          </Space>
        }
        extraFilters={
          showFilters && (
            <>
              <FiltersCard>
                {tableFilters?.map(
                  ({ col: { lg, xl, xs }, label, item, visible }) =>
                    visible && (
                      <Col key={label} lg={lg} xl={xl} xs={xs}>
                        {label && (
                          <div>
                            <Label color={'#575962'} htmlFor={label}>
                              {label}
                            </Label>
                          </div>
                        )}
                        {item}
                      </Col>
                    ),
                )}
              </FiltersCard>
            </>
          )
        }
      />
    </>
  );
};

export default InvoiceActions;
