import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import { Button, Modal, message, Space, Alert } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;

const HireContract = ({ entityToHire, idToHire, mutate }) => {
  const { t } = useTranslation('sms-send');
  const navigate = useNavigate();
  const hireContract = useCallback(
    () => apiMiddleware.post(`/service-proxy/sms-hiring/${idToHire}`),
    [idToHire],
  );

  const navigateToReports = useCallback(() => {
    navigate(`/sms/hiring`);
  }, [navigate]);

  const handleHireContract = useCallback(async () => {
    try {
      await hireContract(idToHire);
      message.success({
        content: (
          <span>
            {t('msgs.contract-hired')}
            <Button
              onClick={navigateToReports}
              type="link"
              style={{ padding: 3 }}
            >
              {t('msgs.go-to-action-queue')}
            </Button>
          </span>
        ),
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        message.error(t('msgs.contract-error-ppu'));
      } else {
        message.error(t('msgs.contract-error'));
      }
    }
    mutate();
  }, [hireContract, idToHire, mutate, t, navigateToReports]);

  const showDelConfirm = () => {
    confirm({
      title: <h3>{t('title.hireContract', { entityToHire })}</h3>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <Space direction="vertical">
          <p>{t('msgs.hire-contract')}</p>
          <Alert type="warning" message={t('msgs.sms-warnig')} />
        </Space>
      ),
      okText: t('pop-over.yes'),
      cancelText: t('pop-over.no'),
      okButtonProps: { type: 'primary' },
      onOk: handleHireContract,
    });
  };

  return (
    <>
      <Button
        onClick={e => {
          e.stopPropagation();
          showDelConfirm?.();
        }}
        type="primary"
        style={{ width: '100%', marginTop: '20px' }}
      >
        {t('pop-over.hire')}
      </Button>
    </>
  );
};

export default HireContract;
