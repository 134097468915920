import styled from 'styled-components';

export const Container = styled.div`
  .ant-card {
    .card-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .ant-space {
      flex-wrap: wrap;
    }
  }

  .ant-table {
    white-space: nowrap;
    padding: 10px 10px 0 10px;

    .ant-table-body {
      overflow: auto !important;
    }
  }

  .table--striped {
    .ant-table-row:nth-child(even) {
      background-color: #f8f8f8;
      .ant-table-selection-column {
        background: #f8f8f8;
      }
    }
  }
`;
