import React, { memo, useCallback } from 'react';
import { Modal, message, Button, Tooltip } from 'antd';
import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import rolesConstants from '@src/utils/rolesConstants';
import RoleChecker from '@src/components/RoleChecker/RoleChecker';

const { confirm } = Modal;

const AdminActionsRequest = ({
  data,
  mutate,
  item,
  showArchived,
  isInTheContractDetails,
  showContracted = false,
}) => {
  const { t } = useTranslation('requests');

  const navigate = useNavigate();

  const archiveAccount = useCallback(
    id =>
      apiMiddleware.patch(
        `/service-proxy/broker/virtual-account/${id}/archive`,
      ),
    [],
  );

  const showArchiveConfirm = useCallback(
    (requestId, requestName) => {
      confirm({
        title: t('archiveModal.title'),
        icon: <ExclamationCircleOutlined />,
        content: t('archiveModal.alert', { requestName }),
        centered: true,
        okText: t('archiveModal.yes'),
        cancelText: t('archiveModal.no'),
        okButtonProps: { type: 'primary', danger: true },
        onOk: () =>
          archiveAccount(requestId)
            .then(() => {
              mutate({
                ...data,
                content: [
                  ...data.content.filter(request => request.id !== requestId),
                ],
              });
              message.success(t('modal.notifications.successArchiving'));
            })
            .catch(({ response: { status } }) => {
              if (status === 409) {
                message.error(t('errors.archivingWithDependency'));
              } else {
                message.error(t('errors.archiving'));
              }
            }),
      });
    },
    [archiveAccount, t, data, mutate],
  );

  return (
    <>
      <Tooltip title={t('detailsButton')}>
        <Button
          icon={<FileSearchOutlined />}
          type={'link'}
          disabled={!item?.id}
          onClick={() => navigate(`/financial/requests/${item.id}`)}
        />
      </Tooltip>
      {showArchived && !isInTheContractDetails && (
        <RoleChecker role={rolesConstants.MANAGE_ACCOUNTS}>
          {showContracted === 'false' && (
            <Tooltip title={t('toFileButton')}>
              <Button
                icon={<FolderOpenOutlined />}
                type={'link'}
                danger
                onClick={() => showArchiveConfirm(item.id, item.name)}
              />
            </Tooltip>
          )}
        </RoleChecker>
      )}
    </>
  );
};

export default memo(AdminActionsRequest);
