import { useEffect, useMemo, useRef } from 'react';
import hash from 'object-hash';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { CancelToken } from 'axios';
import apiMiddleware from '@src/services/apiMiddleware';

const handleCancelToken = cancelToken => {
  if (cancelToken) {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = CancelToken.source();
  }
};

export default function useSwr(url, params, swrOptions) {
  const cancelTokenRef = useRef(null);

  useEffect(() => () => cancelTokenRef?.current?.cancel(), []);

  const swrURL = params
    ? url
      ? [url, ...Object.values({ ...params })]
      : null
    : url;

  const { data, error, mutate, isValidating } = useSWR(
    swrURL,
    async urlToFetch => {
      handleCancelToken(cancelTokenRef);
      let response;
      if (swrOptions?.method === 'post') {
        handleCancelToken(cancelTokenRef);
        response = await apiMiddleware.post(urlToFetch, params, {
          cancelToken: cancelTokenRef.current.token,
        });
      } else {
        response = await apiMiddleware.get(urlToFetch, {
          params,
          cancelToken: cancelTokenRef.current.token,
        });
      }
      return response.data;
    },
    {
      errorRetryCount: 2,
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      ...swrOptions,
    },
  );

  return { data, error, mutate, isValidating };
}

export function useSwrInfinitePost(url, linesPerPage, body = undefined) {
  const cancelTokenRef = useRef(null);

  useEffect(() => () => cancelTokenRef?.current?.cancel(), []);

  const { data, size, setSize, error } = useSWRInfinite(
    index => {
      let urlWithParams = `${url}?page=${index}&linesPerPage=${linesPerPage}&hash=${hash(
        body,
      )}`;
      return urlWithParams;
    },
    async urlToFetch => {
      const urlParams = new URLSearchParams(urlToFetch.split('?')[1]);
      body.page = urlParams.get('page');
      body.linesPerPage = linesPerPage;
      handleCancelToken(cancelTokenRef);
      const response = await apiMiddleware.post(urlToFetch, body, {
        cancelToken: cancelTokenRef.current.token,
      });
      return response.data;
    },
    {
      initialSize: 1,
      revalidateFirstPage: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const isLoadingInitialData = useMemo(() => !data && !error, [data, error]);
  const isLoadingMore = useMemo(
    () =>
      isLoadingInitialData ||
      (size > 0 && data && typeof data[size - 1] === 'undefined'),
    [data, isLoadingInitialData, size],
  );
  const isEmpty = useMemo(() => data?.[0]?.content?.length === 0, [data]);
  const isReachingEnd = useMemo(
    () =>
      isEmpty ||
      (data && data[data.length - 1]?.length < linesPerPage) ||
      (data && data[0]?.totalElements <= linesPerPage),
    [data, isEmpty, linesPerPage],
  );

  return {
    data,
    size,
    setSize,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
  };
}

export function useSwrInfinite(url, linesPerPage, params = undefined) {
  const { data, size, setSize, error } = useSWRInfinite(
    index => {
      let urlWithParams = `${url}?page=${index}&linesPerPage=${linesPerPage}`;
      Object.entries(params?.params).forEach(([key, value]) => {
        if (value || value === false) {
          urlWithParams += `&${key}=${value}`;
        }
      });

      return urlWithParams;
    },
    async urlToFetch => {
      const response = await apiMiddleware.get(urlToFetch);
      return response.data;
    },
  );

  const isLoadingInitialData = useMemo(() => !data && !error, [data, error]);
  const isLoadingMore = useMemo(
    () =>
      isLoadingInitialData ||
      (size > 0 && data && typeof data[size - 1] === 'undefined'),
    [data, isLoadingInitialData, size],
  );
  const isEmpty = useMemo(() => data?.[0]?.length === 0, [data]);
  const isReachingEnd = useMemo(
    () => isEmpty || (data && data[data.length - 1]?.length < linesPerPage),
    [data, isEmpty, linesPerPage],
  );

  return {
    data,
    size,
    setSize,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
  };
}
