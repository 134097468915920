import React, { memo, useCallback, useState } from 'react';

import { Modal, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import apiMiddleware from '@src/services/apiMiddleware';
import { useNavigate } from 'react-router-dom';
import DatePicker from '@components/DatePicker/DatePicker';

const RequestRealtimeReport = ({ devEui, visible, onClose }) => {
  const { t, i18n } = useTranslation('invoices');
  const navigate = useNavigate();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const requestReport = useCallback(
    params =>
      apiMiddleware.get(
        `/service-proxy/lorawanReport/message?locale=${i18n.language}`,
        {
          params,
        },
      ),
    [i18n],
  );

  const navigateToReports = useCallback(() => {
    message.destroy('invoice-report');
    navigate(`/management/reports?type=LORAWAN_MESSAGE`);
  }, [navigate]);

  const handleRequestReport = useCallback(async () => {
    try {
      setLoadingRequest(true);

      const params = {
        devEui,
        startDate,
        endDate,
      };
      await requestReport(params);
      message.success({
        content: (
          <span>
            {t('report.msgs.request-success')}
            <Button
              onClick={navigateToReports}
              type="link"
              style={{ padding: 4 }}
            >
              {t('report.msgs.go-to')}
            </Button>
            {t('report.msgs.check-progress-and-download')}
          </span>
        ),
      });
    } catch {
      message.error(t('report.msgs.check-progress-and-download'));
    }
    onClose();
    setLoadingRequest(false);
  }, [
    devEui,
    endDate,
    navigateToReports,
    onClose,
    requestReport,
    startDate,
    t,
  ]);

  const handleDateChange = useCallback(dates => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  }, []);

  return (
    <Modal
      title={t('report.request')}
      open={visible}
      style={{
        maxWidth: 320,
      }}
      centered
      onCancel={() => onClose()}
      afterClose={() => document.body.style.removeProperty('overflow')}
      footer={
        <>
          <Button onClick={() => onClose()}>
            {t('report.buttons.cancel')}
          </Button>
          <Button
            type="primary"
            loading={loadingRequest}
            disabled={loadingRequest}
            onClick={handleRequestReport}
          >
            {t('report.buttons.request')}
          </Button>
        </>
      }
    >
      <DatePicker
        range
        allowClear
        onChange={(_, dates) => handleDateChange(dates)}
      />
    </Modal>
  );
};

export default memo(RequestRealtimeReport);
