import ptAnt from 'antd/es/locale/pt_BR';
import enAnt from 'antd/es/locale/en_US';
import esAnt from 'antd/es/locale/es_ES';

export const getAntLocale = language => {
  switch (language) {
    case 'pt':
      return ptAnt;
    case 'en':
      return enAnt;
    case 'es':
      return esAnt;
    default:
      return enAnt;
  }
};
