export const modelValuesByDataIndex = () => ({
  iccid: [
    '89553202000000159564',
    '89553202000000157854',
    '89553202000000151243',
  ],
  newIccid: [
    '89553202000000157854',
    '89553202000000151243',
    '89553202000000159564',
  ],
  msisdn: ['5503404446239', '5503404444567', '5503404442390'],
  newMsisdn: ['5503404444567', '5503404446239', '5503404442390'],
  imsi: ['724320200047912', '724320200048956', '724320200043452'],
  imei: ['3566669999444456', '3566669999445578', '3566669999449834'],
  vendor: ['OI', 'TIM', 'VIVO', 'ALGAR'],
  newVendor: ['OI', 'TIM', 'VIVO', 'ALGAR'],
  blockType: [1, 2, 3],
  blockStartDate: ['20211120', '20220223', '20230112'],
  blockEndDate: [undefined, '20230112', undefined],
  unlockDate: ['20220116', '20220130', '20220201'],
  newNickname: ['Nick 1', 'Nick 2', 'Nick 3'],
  requestId: ['331', '456', '987'],
  customerId: ['421421', '409568', '803492'],
  mb: ['20', '12', '2'],
  devEui: ['357c82512a00fefb', '72d70b8cc24a6090', '055aaa8c3a523ce4'],
  appEui: ['5f7e090d8b6f8975', '4de0442322bb5c2d', 'b33076b61ad12948'],
  tags: ['tag_1', 'tag_2;tag_3;tag_4', 'tag_5;tag_6'],
  description: ['description 1', 'description 2', 'description 3'],
  newCustomerVirtualAccountId: ['0', '12345', '56789'],
});
