import React, { useCallback, useMemo } from 'react';
import { Form, Row, Col, Radio, Switch, Divider, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const ConfigStep = ({ form, renderItemLabel, renderRadioItem, isBatch }) => {
  const { t } = useTranslation('lorawan');

  const adr = Form.useWatch('adr', form);
  const blockUplink = Form.useWatch('blockUplink', form);
  const blockDownlink = Form.useWatch('blockDownlink', form);

  const isAdrActivated = adr?.mode === 'on';
  const isAdrDeactivated = adr?.mode === 'off';
  const isAdrStatic = adr?.mode === 'static';

  const adrModeOptions = useMemo(
    () => [
      {
        label: t('activated'),
        value: 'on',
      },
      {
        label: t('deactivated'),
        value: 'off',
      },
      {
        label: t('static'),
        value: 'static',
      },
    ],
    [t],
  );

  const txPowerOptions = useMemo(
    () =>
      // Do not change the order of the elements.
      [30, 28, 26, 24, 22, 20, 18, 16, 14, 12, 10]?.map((tx, index) => ({
        label: `${tx}dbm`,
        value: index,
      })),
    [],
  );

  const dataRateOptions = useMemo(
    () =>
      // Do not change the order of the elements.
      [
        '0: LORA 125kHz SF 12',
        '1: LORA 125kHz SF 11',
        '2: LORA 125kHz SF 10',
        '3: LORA 125kHz SF 9',
        '4: LORA 125kHz SF 8',
        '5: LORA 125kHz SF 7',
        '6: LORA 250kHz SF 7',
        '7: FSK',
      ].map((item, index) => ({
        label: item,
        value: index,
      })),
    [],
  );

  const renderSwitch = useCallback(
    name => {
      // 'blockUplink' | 'blockDownlink' | 'adr'
      const isBlockUplink = name === 'blockUplink';
      const switchValue = isBlockUplink ? blockUplink : blockDownlink;

      return (
        <Col span={12}>
          <Item label={renderItemLabel(name)} name={name}>
            <Switch
              checkedChildren={t('activated')}
              unCheckedChildren={t('deactivated')}
              checked={switchValue}
              onChange={checked => {
                form.setFieldValue(name, checked);
              }}
            />
          </Item>
        </Col>
      );
    },
    [blockDownlink, blockUplink, form, renderItemLabel, t],
  );

  return (
    <Form
      colon={false}
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError
      layout="vertical"
      initialValues={{
        devClass: 'A',
        countersSize: 4,
        adr: {
          mode: 'on',
        },
      }}
    >
      {!isBatch && (
        <Row gutter={[8, 8]}>
          {renderSwitch('blockUplink')}
          {renderSwitch('blockDownlink')}
        </Row>
      )}
      <Divider orientation="left">LoRa</Divider>
      <Row gutter={[8, 8]} style={{ marginTop: 16 }}>
        <Col span={12}>{renderRadioItem('devClass', ['A', 'B', 'C'])}</Col>
        <Col span={12}>
          <Item label={renderItemLabel('countersSize')} name="countersSize">
            <Radio.Group buttonStyle="solid">
              {[2, 4]?.map(opt => (
                <Radio.Button key={opt} value={opt}>
                  {opt}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Item>
        </Col>
      </Row>
      <Divider orientation="left">{renderItemLabel('adr', true)}</Divider>
      <Row gutter={[8, 8]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Item
            name={['adr', 'mode']}
            rules={[
              {
                required: true,
                message: t('fieldIsRequired'),
              },
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={adrModeOptions}
            />
          </Item>
        </Col>
        {adr && !isAdrDeactivated && (
          <>
            {isAdrStatic && (
              <Col sm={12} xs={24}>
                <Item
                  label={renderItemLabel('datarate')}
                  name={['adr', 'datarate']}
                  rules={[
                    {
                      required: true,
                      message: t('fieldIsRequired'),
                    },
                  ]}
                >
                  <Select options={dataRateOptions} allowClear />
                </Item>
              </Col>
            )}
            <Col sm={12} xs={24}>
              <Item
                label={renderItemLabel(
                  isAdrActivated ? 'txPowerMax' : 'txPower',
                )}
                name={['adr', isAdrActivated ? 'txPowerMax' : 'txPower']}
                rules={[
                  ...(adr?.mode === 'on'
                    ? [{ required: false }]
                    : [{ required: true, message: t('fieldIsRequired') }]),
                ]}
              >
                <Select options={txPowerOptions} allowClear />
              </Item>
            </Col>
          </>
        )}
      </Row>
      <Divider orientation="left">
        {`${t('band')}: `}
        <span style={{ fontWeight: 'bold' }}>LA915-928A</span>
      </Divider>
    </Form>
  );
};

export default ConfigStep;
