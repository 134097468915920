import Label from './Label';
import styled from 'styled-components';

const Link = styled(Label)`
  cursor: pointer;
  display: inline-block;
  transition: color 0.7s ease;
  :hover {
    color: #776e87;
  }
  :after {
    content: '';
    display: block;
    height: 1px;
    width: 0;
    margin-top: 1px;
    background: transparent;
    transition: width 0.7s ease, background-color 0.7s ease;
    -webkit-transition: width 0.5s ease, background-color 0.5s ease;
    -moz-transition: width 0.5s ease, background-color 0.5s ease;
  }
  :hover:after {
    width: 100%;
    background: #776e87;
  }
`;

export default Link;
