import styled, { css } from 'styled-components';
import Stat from '@components/Stat/Stat';
import { Col } from 'antd';

const statColor = type => {
  switch (type) {
    case 'totalConsumption':
      return 'green';
    case 'franchiseConsumption':
      return 'cornflowerblue';
    case 'surplusConsumption':
      return 'red';
    default:
      return 'orange';
  }
};

export const ColCurrentConsumption = styled(Col)`
  padding: 0 16px !important;
`;

export const ColRateConsumption = styled(Col)`
  border-radius: 8px;
  padding: 0 16px !important;
  padding-bottom: 12px !important;
  height: 100%;

  ${({ hasCurrentConsumption }) =>
    hasCurrentConsumption &&
    css`
      background: #fafafa;
      border: 2px dashed #dbdbdb;
      padding-bottom: 12px;
    `}
`;

export const ConsumptionStat = styled(Stat)`
  span {
    font-size: 1.6rem;
    color: ${({ type }) => statColor(type)};
  }

  margin: 0;

  div {
    padding: 4px 8px;
  }

  border-bottom: 4px solid ${({ type }) => statColor(type)} !important;

  .ant-statistic-title {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1rem;

    .anticon {
      font-size: inherit;
      color: inherit;
    }
  }

  .ant-statistic-content-value {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
`;

export const ConsumptionSectionHeader = styled.div`
  padding: 0 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConsumptionSectionHeaderTitle = styled.span`
  padding: 2px 8px;
  border-radius: 8px;
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 0.9rem;
`;

export const ConsumptionSectionHeaderDate = styled.span`
  font-size: 0.9rem;

  span {
    opacity: 0.5;
  }
`;

export const ProgressBarContainer = styled.div`
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  align-items: center;
  max-width: ${({ size }) => size}px;
  width: 100%;
  padding: 4px;
`;

export const ProgressBarPercentage = styled.span`
  padding: 0 6px;
  font-size: 0.9rem;
`;

export const ProgressBarContent = styled.div`
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 80px;
  height: 16px;
  display: flex;
  background: #f0f2f5;
  overflow: hidden;
`;

export const ProgressBarContentMain = styled.div`
  height: 100%;
  text-align: center;
  position: relative;
  width: ${({ percentage }) => `${percentage}%`};
  background: ${({ percentage }) =>
    percentage > 100 ? 'red' : 'cornflowerblue'};

  ${({ percentage }) =>
    percentage >= 100
      ? css`
          border-radius: 8px;
        `
      : css`
          border-radius: 8px 0 0 8px;
        `}
`;
