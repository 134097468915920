import useSwr from '@src/hooks/useSwr';
import apiMiddleware from '@src/services/apiMiddleware';
import { Button, message, Tooltip, Space } from 'antd';
import Table from '@components/Table';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  dateFormat,
  getDateFromPartiallyInIsoFormat,
} from '@src/utils/formatters';
import CreateTermsModal from './CreateTermsModal/index';
import { FiExternalLink } from 'react-icons/fi';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const TermsOfUse = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { t: translate } = useTranslation('terms-use');

  const { data: termsOfUseData, mutate } = useSwr('/service-proxy/termsOfUse', {
    page: pagination?.current - 1,
    linesPerPage: pagination.pageSize,
  });

  const deleteTermOfUse = useCallback(
    async idToDelete => {
      try {
        await apiMiddleware.delete('/service-proxy/termsOfUse', {
          params: {
            termsOfUseId: idToDelete,
          },
        });
        message.success(translate('modal.message-succes'));
      } catch (error) {
        message.error(translate('modal.error-message-update'));
      }
      mutate();
    },
    [translate, mutate],
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      total: termsOfUseData?.totalElements,
    }));
  }, [termsOfUseData?.totalElements]);

  const mutateCreate = useCallback(() => {
    mutate();
  }, [mutate]);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const closeModal = useCallback(() => setModalVisible(false), []);

  const columns = useMemo(
    () => [
      { title: translate('form.description'), dataIndex: 'description' },
      {
        title: translate('form.createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: translate('form.effectiveFrom'),
        dataIndex: 'effectiveFrom',
        render: value => <>{getDateFromPartiallyInIsoFormat(value)}</>,
      },
      { title: translate('form.version'), dataIndex: 'version' },
      {
        title: translate('form.actions'),
        dataIndex: 'actions',
        align: 'center',
        render: (value, record) => (
          <Space>
            <Tooltip title={translate('view-terms')}>
              <a
                href={`/termsOfUse/${record.fileName}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    textDecoration: 'underline',
                    paddingLeft: '5px',
                  }}
                >
                  <FiExternalLink
                    style={{
                      fontSize: '1.4rem',
                      marginTop: 4,
                    }}
                  />
                </span>
              </a>
            </Tooltip>
            <div>
              <Tooltip title={translate('form.delete')}>
                <Button
                  size="large"
                  danger
                  type="link"
                  onClick={e => {
                    e.stopPropagation();
                    deleteTermOfUse(record.id);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </div>
          </Space>
        ),
      },
    ],
    [translate, deleteTermOfUse],
  );

  return (
    <>
      <CreateTermsModal
        visible={modalVisible}
        onClose={closeModal}
        mutateCreate={mutateCreate}
      />

      <Table
        columns={columns}
        data={termsOfUseData?.content}
        loading={!termsOfUseData}
        title={<>{translate('title')}</>}
        tableKey="termsOfuse"
        extraActions={
          <Tooltip placement="top" title={translate('modal.title')}>
            <Button
              icon={<PlusCircleOutlined />}
              size="large"
              type="link"
              onClick={() => setModalVisible(true)}
            />
          </Tooltip>
        }
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

TermsOfUse.propTypes = {};

export default TermsOfUse;
