import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import { Button, Modal, message, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

const CancelContract = ({ mutate }) => {
  const { t } = useTranslation('sms-send');

  const cancelContract = useCallback(
    () => apiMiddleware.delete(`/service-proxy/sms-hiring/cancel-ppu`),
    [],
  );

  const handleCancelContract = useCallback(async () => {
    try {
      await cancelContract();
      message.success(t('msgs.contract-cancelled'));
    } catch {
      message.error(t('msgs.contract-error'));
    }

    mutate();
  }, [cancelContract, mutate, t]);

  const showDelConfirm = () => {
    confirm({
      title: <h3>{t('title.cancelContract')}</h3>,
      icon: <ExclamationCircleOutlined />,
      content: t('msgs.cancel-contract'),
      okText: t('pop-over.yes'),
      cancelText: t('pop-over.no'),
      okButtonProps: { type: 'primary', danger: true },
      onOk: handleCancelContract,
    });
  };

  return (
    <>
      <Tooltip title={t('pop-over.cancel-ppu')}>
        <Button
          onClick={e => {
            e.stopPropagation();
            showDelConfirm?.();
          }}
          danger
          style={{ width: '100%', marginTop: '20px' }}
        >
          {t('pop-over.cancel')}
        </Button>
      </Tooltip>
    </>
  );
};

export default CancelContract;
