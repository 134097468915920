import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Button, Divider, message } from 'antd';
import BannerUpload from './BannerModalStepsContent/BannerUpload/index';
import BannerExtraDetails from './BannerModalStepsContent/BannerExtraDetails/index';
import BannerPreview from './BannerModalStepsContent/BannerPreview/index';
import apiMiddleware from '@src/services/apiMiddleware';
import { convertImgInFile } from '@src/utils/formatters';

const BannerModal = ({ visible, onClose, bannerToEdit, mutate, isEdition }) => {
  const { t } = useTranslation('banners');
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [defaultFile, setDefaultFile] = useState();
  const [defaultFileDetail, setDefaultFileDetail] = useState();

  useEffect(() => {
    if (bannerToEdit?.fileName) {
      convertImgInFile(
        `/banners/${bannerToEdit?.fileName}`,
        bannerToEdit?.fileName,
        setDefaultFile,
      );
    }
  }, [bannerToEdit?.fileName]);

  useEffect(() => {
    if (bannerToEdit?.fileDetailName) {
      convertImgInFile(
        `/banners/${bannerToEdit?.fileDetailName}`,
        bannerToEdit?.fileDetailName,
        setDefaultFileDetail,
      );
    }
  }, [bannerToEdit?.fileDetailName]);

  const bannerToEditTreated = useMemo(() => {
    if (!isEdition || !bannerToEdit) {
      return undefined;
    }
    const { fileName, fileDetailName, link, ...values } = bannerToEdit;
    const isTypeLink = !!link && link !== '';
    const isTypeImg = !!fileDetailName;
    const addInfoEnabled = isTypeLink || isTypeImg;

    return {
      ...values,
      link,
      addInfoEnabled,
      file: defaultFile,
      fileDetail: defaultFileDetail,
      type: addInfoEnabled && isTypeLink ? 'url' : 'img',
    };
  }, [bannerToEdit, defaultFile, defaultFileDetail, isEdition]);

  useEffect(() => {
    form?.setFieldsValue(bannerToEditTreated);
  }, [form, bannerToEditTreated]);

  const createBanner = useCallback(
    (files, params) =>
      apiMiddleware.post('/banner', files, {
        params,
      }),
    [],
  );

  const editBanner = useCallback(
    (files, params) =>
      apiMiddleware.put('/banner', files, {
        params,
      }),
    [],
  );

  const getFile = useCallback(
    name =>
      name && {
        uid: -1,
        name,
        status: 'done',
        url: `/banners/${name}`,
      },
    [],
  );

  const defaultMainFile = useMemo(
    () => getFile(bannerToEdit?.fileName),
    [bannerToEdit?.fileName, getFile],
  );

  const defaultDetailFile = useMemo(
    () => getFile(bannerToEdit?.fileDetailName),
    [bannerToEdit?.fileDetailName, getFile],
  );

  const handleClose = useCallback(() => {
    onClose();
    form?.resetFields();
  }, [form, onClose]);

  const handleOnFinish = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const { type, addInfoEnabled, file, fileDetail, ...values } = formValues;
      const files = new FormData();
      files.append('file', file);

      if (addInfoEnabled) {
        files.append('fileDetail', fileDetail);
      }

      if (isEdition) {
        await editBanner(files, {
          ...values,
          bannerId: bannerToEditTreated?.id,
        }).then(({ data }) => mutate(data));
      } else {
        await createBanner(files, values).then(({ data }) => mutate(data));
      }
      message.success(
        t(`msgs.success-when-${isEdition ? 'editing' : 'creating'}`),
      );
      handleClose();
    } catch {
      message.error(t(`msgs.error-when-${isEdition ? 'editing' : 'creating'}`));
    }
    setConfirmLoading(false);
  }, [
    createBanner,
    editBanner,
    formValues,
    handleClose,
    bannerToEditTreated?.id,
    isEdition,
    mutate,
    t,
  ]);

  const footer = useMemo(
    () => (
      <div style={{ textAlign: 'right', marginTop: 40 }}>
        <Divider />
        <Button type="primary" htmlType="submit" loading={confirmLoading}>
          {t('buttons.confirm')}
        </Button>
      </div>
    ),
    [t, confirmLoading],
  );

  const handleOnFinishFailed = useCallback(() => {
    const modalWrapEl = document.querySelector('.ant-modal-wrap');
    modalWrapEl.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Modal
      title={t(`modal.title-${isEdition ? 'edit' : 'new'}`)}
      open={visible}
      onCancel={handleClose}
      bodyStyle={{ padding: 0 }}
      footer={false}
    >
      <Form
        form={form}
        style={{ padding: '32px 40px' }}
        layout="vertical"
        onFinish={handleOnFinish}
        onFinishFailed={handleOnFinishFailed}
      >
        <BannerUpload defaultFile={defaultMainFile} />
        {formValues?.addInfoEnabled && (
          <BannerExtraDetails defaultFile={defaultDetailFile} />
        )}
        <BannerPreview
          form={form}
          files={{
            main: defaultMainFile,
            details: defaultDetailFile,
          }}
        />
        {footer}
      </Form>
    </Modal>
  );
};

export default BannerModal;
