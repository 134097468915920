import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Steps, Button, Radio, message, Typography, Table, Card } from 'antd';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { FiLock, FiUnlock } from 'react-icons/fi';
import * as Styled from './styles';
import SuperList from '@src/components/SuperList/SuperList';
import useSwr from '@src/hooks/useSwr';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import apiMiddleware from '@src/services/apiMiddleware';
import ActionTypes from '@src/utils/deviceActions';

const { Step } = Steps;
const { Paragraph } = Typography;

const CustomerActionsModal = ({
  visible,
  activeAction,
  onClose,
  selectedCustomers,
}) => {
  const { t } = useTranslation('customers');
  const { keycloak } = useContext(KeycloakContext);
  const customerId = keycloak.idTokenParsed?.customerId;
  const [current, setCurrent] = useState(0);
  const [preSelectedCustomers, setPreSelectedCustomers] = useState();
  const [allSelectedCustomers, setAllSelectedCustomers] = useState();
  const [sendActionLoading, setSendActionLoading] = useState(false);
  const [actionType, setActionType] = useState();
  const [errorList, setErrorList] = useState();

  const navigate = useNavigate();

  const { data: customersData } = useSwr('/customers/');

  const prepareDataForSuperList = useCallback(
    list =>
      list?.map(({ id, name, cpfCnpj }) => ({
        label: name,
        value: id,
        cpfCnpj,
      })),
    [],
  );

  const superListItems = useMemo(
    () =>
      prepareDataForSuperList(customersData)?.filter(
        ({ value }) => value !== customerId,
      ),
    [prepareDataForSuperList, customersData, customerId],
  );
  const superListPreSelectedItems = useMemo(
    () =>
      visible
        ? prepareDataForSuperList(selectedCustomers)?.filter(
            ({ value }) => value !== customerId,
          )
        : [],
    [prepareDataForSuperList, selectedCustomers, customerId, visible],
  );

  useEffect(() => {
    if (superListPreSelectedItems) {
      setPreSelectedCustomers(superListPreSelectedItems);
    }
  }, [superListPreSelectedItems]);

  const tableErrors = useMemo(() => {
    if (!errorList || errorList?.length === 0) {
      return undefined;
    }

    const successes = allSelectedCustomers?.length - errorList?.length;
    const columns = [
      {
        title: 'Cliente',
        dataIndex: 'customerId',
        key: 'customerId',
      },
      {
        title: 'Erro',
        dataIndex: 'error',
        key: 'error',
      },
    ];

    const dataSource = errorList?.map((item, key) => ({
      key,
      customerId: allSelectedCustomers?.find(
        cst => cst.value === item.customerId,
      )?.label,
      error: t(`actions.errors.${item.error}`),
    }));

    return (
      <div style={{ padding: 16 }}>
        {successes ? (
          <p style={{ textAlign: 'center', color: 'green' }}>
            <CheckCircleOutlined style={{ margin: 8 }} />
            {t(`actions.financial-lock-unlock.msgs.success-${actionType}`, {
              count: successes,
            })}
          </p>
        ) : undefined}
        <Card>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.1rem',
              opacity: 0.8,
              color: 'red',
            }}
          >
            <CloseCircleOutlined style={{ margin: 8 }} />
            {t(`actions.financial-lock-unlock.msgs.error-when-${actionType}`, {
              count: errorList?.length,
            })}
          </p>
          <Table
            scroll={{
              y: 240,
            }}
            loading={!dataSource}
            size="small"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </Card>
      </div>
    );
  }, [t, errorList, allSelectedCustomers, actionType]);

  const financialLockUnlock = useMemo(
    () => [
      {
        title: t('actions.customer-selection'),
        content: (
          <SuperList
            maxNumOfItems={1000}
            columnName="cpfCnpj"
            items={superListItems}
            activateSelectAll={false}
            uploadAlternateTitle="CPF/CNPJ"
            preSelectedItems={preSelectedCustomers}
            onSelectedItemsChange={setAllSelectedCustomers}
          />
        ),
      },
      {
        title: t('actions.action'),
        content: (
          <div>
            <Radio.Group
              className="customers-action-radio-group"
              value={actionType}
              onChange={({ target: { value } }) => setActionType(value)}
            >
              <Radio.Button
                value="unlock"
                className="customers-action-radio-button"
              >
                <FiUnlock />
                <p>{t('actions.financial-lock-unlock.unlock')}</p>
              </Radio.Button>
              <Radio.Button
                value="block"
                className="customers-action-radio-button radio-button-secondary"
              >
                <FiLock />
                <p>{t('actions.financial-lock-unlock.block')}</p>
              </Radio.Button>
            </Radio.Group>
          </div>
        ),
      },
      {
        title: t('actions.validation'),
        content: tableErrors ?? (
          <div style={{ padding: 24, textAlign: 'center' }}>
            <span
              className="validation-exclamation-circle"
              style={{
                color: actionType === 'block' ? 'red' : 'green',
              }}
            >
              <ExclamationCircleOutlined />
            </span>
            <Paragraph className="validation-paragraph">
              {t(
                `actions.financial-lock-unlock.will-be-${
                  actionType === 'block' ? 'blocked' : 'unlocked'
                }`,
                {
                  count: allSelectedCustomers?.length,
                },
              )}
            </Paragraph>
          </div>
        ),
      },
    ],
    [
      superListItems,
      allSelectedCustomers,
      preSelectedCustomers,
      actionType,
      t,
      tableErrors,
    ],
  );

  const steps = useMemo(() => [...financialLockUnlock], [financialLockUnlock]);

  const handleCloseModal = useCallback(() => {
    setAllSelectedCustomers([]);
    setCurrent(0);
    setActionType(undefined);
    setErrorList(undefined);
    onClose();
  }, [onClose]);

  const navigateToActions = useCallback(
    action => {
      message.destroy('send-action');
      navigate(`/management/actions-queue?action=${action}`);
    },
    [navigate],
  );

  const handleConfirmAction = useCallback(async () => {
    const customerIds = allSelectedCustomers?.map(({ value }) => value);
    try {
      setSendActionLoading(true);
      const action = ActionTypes[`FINANCIAL_${actionType.toUpperCase()}`];
      const payload = {
        action,
        customerIds,
      };
      await apiMiddleware.post('/service-proxy/actions', payload);
      message.success({
        content: (
          <>
            {t('actions.msgs.success')}
            &nbsp;
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => navigateToActions(action)}
            >
              {t('actions.see-in-the-actions-queue')}
            </Button>
          </>
        ),
      });
      handleCloseModal();
    } catch {
      message.error(t('actions.msgs.error'));
    }
    setSendActionLoading(false);
  }, [
    allSelectedCustomers,
    handleCloseModal,
    actionType,
    navigateToActions,
    t,
  ]);

  const disabledButtonNext = useMemo(() => {
    return (
      (current === 0 && allSelectedCustomers?.length === 0) ||
      (current === 1 && !actionType)
    );
  }, [current, allSelectedCustomers, actionType]);

  const modalFooter = useMemo(
    () => (
      <div key={'0'} className={'steps-action'}>
        {current > 0 && (
          <Button
            className="back-button"
            onClick={() => setCurrent(current - 1)}
          >
            {t('buttons.backButton')}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            disabled={disabledButtonNext}
            type="primary"
            onClick={() => {
              setCurrent(current + 1);
              setPreSelectedCustomers(allSelectedCustomers);
            }}
          >
            {t('buttons.nextButton')}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            loading={sendActionLoading}
            type={'primary'}
            onClick={handleConfirmAction}
          >
            {t('buttons.confirmButton')}
          </Button>
        )}
      </div>
    ),
    [
      current,
      disabledButtonNext,
      handleConfirmAction,
      sendActionLoading,
      steps.length,
      t,
      allSelectedCustomers,
    ],
  );

  return (
    <Styled.ModalContainer
      footer={!errorList && modalFooter}
      title={t(`actions.${activeAction}.title`)}
      visible={visible}
      onCancel={handleCloseModal}
      width={'600px'}
    >
      <Steps current={current} size="small">
        {steps?.map(item => (
          <Step key={item?.title} title={item?.title} />
        ))}
      </Steps>
      <div className={'steps-content'}>{steps?.[current]?.content}</div>
    </Styled.ModalContainer>
  );
};

export default CustomerActionsModal;
