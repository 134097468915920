import React, { memo, useCallback, useMemo, useState } from 'react';
import { unixTimestampFormat } from '@utils/formatters';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import PrettyJSON from '@components/PrettyJSON';

import * as S from './styles';

const RealtimeListItem = ({ item }) => {
  const { t } = useTranslation('lorawan');
  const [isOpen, setIsOpen] = useState(false);
  const type = item?.type;

  const toggleOpen = useCallback(() => setIsOpen(crrOpen => !crrOpen), []);

  const typeColor = useMemo(() => {
    switch (type) {
      case 'join_request':
        return 'pink';
      case 'uplink':
        return 'green';
      case 'downlink':
        return 'purple';
      case 'error':
        return 'red';
      case 'warning':
        return 'yellow';
      case 'info':
        return 'blue';
      default:
        return 'gray';
    }
  }, [type]);

  return (
    <S.ListContainer>
      <S.ListHeader onClick={toggleOpen} isOpen={isOpen}>
        <span style={{ opacity: 0.8 }}>
          {unixTimestampFormat(item?.meta?.time)}
          <span
            style={{
              opacity: 0.8,
              fontWeight: 'bold',
              color: typeColor,
            }}
          >
            <span style={{ margin: '0 6px 0 16px' }}>
              <BsArrowRight />
            </span>
            {t(type)}
          </span>
        </span>
        <S.ArrowIcon isOpen={isOpen} />
      </S.ListHeader>
      {isOpen && (
        <S.ListContent>
          <PrettyJSON json={JSON.stringify(item)} keyColor={typeColor} />
        </S.ListContent>
      )}
    </S.ListContainer>
  );
};

export default memo(RealtimeListItem);
