import { createContext } from 'react';

const initialValue = {
  userRoles: undefined,
};

const SessionContext = createContext(initialValue);

export default SessionContext;
export { initialValue };
