import { Carousel as CarouselAntd, Button, Image } from 'antd';
import React from 'react';
import * as S from './styles';

const Carousel = ({ data, ...rest }) => (
  <CarouselAntd autoplay autoplaySpeed={5 * 1000} {...rest}>
    {data?.map(
      ({ key, imgSrc, allowButton, buttonText, imgOnClick, buttonOnClick }) => (
        <S.Container key={key}>
          <Image
            src={imgSrc}
            alt="Banner img"
            preview={false}
            onClick={imgOnClick}
            className={imgOnClick && 'carousel-img-click'}
          />
          {allowButton && (
            <Button type="primary" onClick={buttonOnClick}>
              {buttonText}
            </Button>
          )}
        </S.Container>
      ),
    )}
  </CarouselAntd>
);

export default Carousel;
