import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { Empty, List, Col, Row, Badge } from 'antd';
import { Chart, Tooltip, Legend, Point, Line, Interval } from 'bizcharts';
import _ from 'lodash';
import { format } from 'date-fns';
import { ptBR, enUS, es } from 'date-fns/locale';
import useSwr from '@hooks/useSwr';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const Usage = ({ customerId }) => {
  const { t } = useTranslation('dashboard');
  const { keycloak } = useContext(KeycloakContext);
  const locale = keycloak?.idTokenParsed?.locale;

  const [scale, setScale] = useState(undefined);
  let chartIns = null;

  const handleLocale = useCallback(() => {
    if (locale === 'pt') {
      return ptBR;
    } else if (locale === 'en') {
      return enUS;
    }
    return es;
  }, [locale]);

  const colors = ['#6394f9', '#62daaa'];

  const { data: dataBaseUse } = useSwr(
    `/service-proxy/sims/find-total-simcards-base-use/${customerId}`,
    {
      months: 12,
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const data = useMemo(() => {
    if (!dataBaseUse || dataBaseUse?.length === 0) {
      return [];
    }

    const newDataUse = dataBaseUse.map(item => ({
      ...item,
      monthYear: `${item.month}/${item.year}`,
    }));

    newDataUse.reverse();

    const { base: max } = newDataUse.reduce(function (prev, current) {
      return prev.base > current.base ? prev : current;
    });

    setScale({
      base: {
        min: 0,
        max: max + 2000,
      },
      used: {
        min: 0,
        max: max + 2000,
      },
    });

    return newDataUse;
  }, [dataBaseUse]);

  const dataSorted = useMemo(() => {
    const sorted = [...data];
    return sorted
      .sort((a, b) => (a.used < b.used ? 1 : -1))
      .map(item => {
        return {
          ...item,
          date: _.capitalize(
            format(new Date(item.year, item.month - 1, 1), 'MMMM yyyy', {
              locale: handleLocale(),
            }),
          ),
        };
      });
  }, [data, handleLocale]);

  useEffect(() => {
    const handleResize = () => {
      if (chartIns) {
        chartIns.forceFit();
      }
    };

    const debounce = _.debounce(handleResize, 300);

    window.addEventListener('resize', debounce);

    return () => window.removeEventListener('resize', debounce);
  }, [chartIns]);

  return (
    <Card loading={!dataBaseUse} title={t('usage.title')}>
      {data?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Row gutter={[48, 16]}>
          <Col lg={12} md={24} style={{ marginBottom: 15 }} xl={16} xs={24}>
            <div>
              <Chart
                data={data}
                height={400}
                scale={scale}
                onGetG2Instance={chart => {
                  chartIns = chart;
                }}
                autoFit
              >
                <Legend
                  items={[
                    {
                      value: 'used',
                      name: t('usage.used'),
                      marker: {
                        symbol: 'square',
                        style: { fill: colors[0], r: 5 },
                      },
                    },
                    {
                      value: 'base',
                      name: t('usage.base'),
                      marker: {
                        symbol: 'hyphen',
                        style: { stroke: colors[1], r: 5, lineWidth: 3 },
                      },
                    },
                  ]}
                  allowAllCanceled
                  custom
                  onChange={ev => {
                    const { item } = ev;
                    const { value } = item;
                    const checked = !item.unchecked;
                    const geoms = chartIns.geometries;

                    geoms.forEach(geom => {
                      if (geom.getYScale().field === value) {
                        if (checked) {
                          geom.show();
                        } else {
                          geom.hide();
                        }
                      }
                    });
                  }}
                />
                <Tooltip
                  itemTpl={
                    '<li style="margin-bottom:8px;" data-index={index}>' +
                    '<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>' +
                    '{value}' +
                    '</li>'
                  }
                  shared
                />
                <Interval color={colors[0]} position={'monthYear*used'} />
                <Line
                  color={colors[1]}
                  position={'monthYear*base'}
                  shape={'smooth'}
                  size={3}
                />
                <Point
                  color={colors[1]}
                  position={'monthYear*base'}
                  shape={'circle'}
                  size={3}
                />
              </Chart>
            </div>
          </Col>
          <Col lg={12} md={24} xl={8} xs={24}>
            <h3 style={{ marginBottom: 30 }}>{t('usage.top-months')}</h3>
            <List
              dataSource={dataSorted.slice(0, 5)}
              itemLayout={'horizontal'}
              renderItem={(item, index) => (
                <List.Item>
                  <div>
                    <Badge
                      className={'site-badge-count-109'}
                      count={index + 1}
                      style={{
                        backgroundColor: index > 2 ? '#fff' : '#6394f9',
                        color: index > 2 ? 'RGBA(0,0,0,0.65)' : '#fff',
                        marginRight: 10,
                      }}
                    />
                    {item.date}
                  </div>
                  {item.used}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default Usage;
