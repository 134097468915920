import useTransfers from './useTransfers';
import useImport from './useImport';
import useChangeDescription from './useChangeDescription';
import useChangeTags from './useChangeTags';
import useDeactivation from './useDeactivation';
import useDownlinkBlockUnlock from './useDownlinkBlockUnlock';
import useUplinkBlockUnlock from './useUplinkBlockUnlock';

const LoraWan = {
  useTransfers,
  useImport,
  useDeactivation,
  useUplinkBlockUnlock,
  useDownlinkBlockUnlock,
  useChangeDescription,
  useChangeTags,
};

export default LoraWan;
