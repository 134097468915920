import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Statistic, Progress, Empty, Card } from 'antd';
import { formatBRNumber } from '@utils/formatters';
import useSwr from '@hooks/useSwr';
import { getPercentage } from '@utils/getPercentage';

import { green, gold, red } from '@ant-design/colors';

const getColor = percent => {
  if (percent < 5) {
    return red[4];
  }
  if (percent < 10) {
    return gold[5];
  }
  if (percent < 30) {
    return '#00afef';
  }
  return green[5];
};

const OnlineOrOffline = ({
  handleSetSearchParams,
  isOnline = true,
  filters,
}) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const onlineOrOffline = isOnline ? 'online' : 'offline';

  const { data } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/count-online-offline`,
    {
      ...filters,
    },
    {
      method: 'post',
    },
  );

  const onlineOrOfflineData = useMemo(
    () => (isOnline ? data?.online : data?.offline),
    [data, isOnline],
  );

  const percentage = useMemo(
    () => (data ? getPercentage(onlineOrOfflineData, data.total) : 0),
    [data, onlineOrOfflineData],
  );

  return (
    <Card
      key={isOnline}
      loading={!data}
      className="card-click-filter"
      onClick={() => {
        if (handleSetSearchParams) {
          handleSetSearchParams({ online: isOnline });
        } else {
          navigate(
            `/management/inventory?showContracted=${filters.showContracted}&online=${isOnline}`,
          );
        }
      }}
    >
      {data?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Statistic
            precision={0}
            suffix={`/ ${formatBRNumber(data?.total)}`}
            title={t(`status-lines.${onlineOrOffline}`)}
            value={onlineOrOfflineData}
            valueStyle={{ color: getColor(percentage) }}
            formatter={value => {
              return formatBRNumber(value);
            }}
          />
          <Progress
            percent={percentage}
            showInfo={false}
            strokeColor={getColor(percentage)}
          />
        </>
      )}
    </Card>
  );
};

export default OnlineOrOffline;
