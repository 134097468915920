import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Button, Tooltip, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

const AdminActions = ({
  entityToExclude,
  ruleToNotExclude,
  onEdit,
  onDelete,
  alertDelete,
  extraActions,
  detailsIcon,
}) => {
  const { t } = useTranslation('admin-actions');

  const showDelConfirm = () => {
    confirm({
      title: <h3>{t('deleteModal.title', { entityToExclude })}</h3>,
      icon: <ExclamationCircleOutlined />,
      content: alertDelete,
      okText: t('buttons.deleteYes'),
      cancelText: t('buttons.deleteNo'),
      okButtonProps: { type: 'primary', danger: true },
      onOk: onDelete,
    });
  };

  return (
    <>
      {extraActions}
      <Tooltip
        title={
          detailsIcon ? t('buttons.detailsButton') : t('buttons.editButton')
        }
      >
        <Button
          size="large"
          type="link"
          className="button-edit"
          onClick={onEdit}
        >
          {detailsIcon ? <FileSearchOutlined /> : <EditOutlined />}
        </Button>
      </Tooltip>
      <Tooltip
        title={
          ruleToNotExclude
            ? t('buttons.cannotDeleteButton')
            : t('buttons.deleteButton')
        }
      >
        <Button
          size="large"
          type="link"
          danger
          onClick={e => {
            e.stopPropagation();
            showDelConfirm?.();
          }}
          disabled={ruleToNotExclude}
        >
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </>
  );
};

export default AdminActions;
