export default [
  'accounts',
  'admin-actions',
  'actions-queue',
  'batch-query',
  'customers',
  'dashboard',
  'device-actions',
  'device-actions-modal',
  'file-uploader',
  'invoices',
  'maps',
  'main-container',
  'inventory',
  'message-error',
  'modal-columns-settings',
  'not-found',
  'notification',
  'operators',
  'overflow-ranges',
  'permissions',
  'contracts',
  'pricing-plan-operator',
  'profile',
  'reports',
  'roles',
  'status',
  'table',
  'transfer',
  'translation',
  'requests',
  'simcards-table',
  'credits',
  'inventory-detailed',
  'invoices-actions',
  'sessions',
  'date-picker',
  'erp-config',
  'operational',
  'super-list',
  'general',
  'attributes',
  'placeholders',
  'banners',
  'schedules',
  'alerts',
];
