import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ActivationButton = ({ setIsBatch }) => {
  const { t } = useTranslation('lorawan');

  const menuItems = useMemo(
    () => ['unit', 'batch'].map(item => ({ key: item, label: t(item) })),
    [t],
  );

  return (
    <Dropdown
      menu={{
        onClick: ({ key }) => setIsBatch(key === 'batch'),
        items: menuItems,
      }}
    >
      <Tooltip placement="top" title={t('activation')}>
        <Button icon={<PlusCircleOutlined />} size="large" type="link" />
      </Tooltip>
    </Dropdown>
  );
};

export default ActivationButton;
