import React, { useMemo } from 'react';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { Statistic, Progress, Empty } from 'antd';
import { formatBRNumber, getDateInISOFormat } from '@utils/formatters';
import useSwr from '@hooks/useSwr';
import { getPercentage } from '@utils/getPercentage';
import { green, gold, red } from '@ant-design/colors';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const getColor = percent => {
  if (percent < 5) {
    return red[4];
  }
  if (percent < 10) {
    return gold[5];
  }
  if (percent < 30) {
    return '#00afef';
  }
  return green[5];
};

const StatusLines = ({ days, handleSetSearchParams, filters }) => {
  const { t } = useTranslation('dashboard');

  const navigate = useNavigate();

  const { data } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/count-by-seen-in-last-days?days=${days}`,
    {
      ...filters,
    },
    {
      method: 'post',
    },
  );

  const isNoData = data?.length === 0 || data?.total?.length === 0;

  const getLastDayOfMonth = useMemo(
    () =>
      getDateInISOFormat(
        moment()
          .subtract(days / 30, 'months')
          ._d.toISOString(),
      ),
    [days],
  );

  return (
    <Card
      loading={!data}
      className={!isNoData && 'card-click-filter'}
      onClick={() => {
        if (!isNoData) {
          if (handleSetSearchParams) {
            handleSetSearchParams({ lastConnectionFrom: getLastDayOfMonth });
          } else {
            navigate(
              `/management/inventory?showContracted=${filters.showContracted}&lastConnectionFrom=${getLastDayOfMonth}`,
            );
          }
        }
      }}
    >
      {isNoData ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div>
          <Statistic
            precision={0}
            suffix={`/ ${formatBRNumber(data?.total)}`}
            title={t('status-lines.connection_days', { count: days })}
            value={data?.matching}
            valueStyle={{
              color: getColor(getPercentage(data?.matching, data?.total)),
            }}
            formatter={value => {
              return formatBRNumber(value);
            }}
          />
          <Progress
            percent={getPercentage(data?.matching, data?.total)}
            showInfo={false}
            strokeColor={getColor(getPercentage(data?.matching, data?.total))}
          />
        </div>
      )}
    </Card>
  );
};

export default StatusLines;
