import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  .ant-tabs-tab:first-child {
    margin: 0;
  }

  .ip-header {
    padding: 8px;

    .ip-header-title {
      margin-bottom: 0;
    }
  }

  .ant-badge-status-dot {
    width: 18px;
    height: 18px;
  }

  .ant-badge-status {
    vertical-align: middle;
    height: 31px;
  }

  .stat-icon {
    color: var(--primary-color);
  }
`;
