import styled from 'styled-components';

const LoginInput = styled.input`
  background: #f7f6f9;
  border: none;
  border-radius: 40px;
  outline: none;
  line-height: 1rem;
  font-size: 1rem;
  padding: 1.5rem 1.5rem;
  width: 100%;
  display: block;
  box-sizing: border-box;
  color: #91899f;
  :focus {
    color: #776e87;
  }
  ::placeholder {
    color: #91899f;
  }
`;

export default LoginInput;
