import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Form, Row, Select } from 'antd';
import SelectCustomers from '@components/SelectCustomers';
import { useTranslation } from 'react-i18next';

const ContractStep = ({
  contractsByClient,
  customerId,
  onCustomerId,
  contractId,
  onContractId,
}) => {
  const { t } = useTranslation(['requests', 'attributes']);
  const [form] = Form.useForm();

  const hasNoContracts = contractsByClient?.length === 0;
  const disableSelectContract = !customerId || hasNoContracts;

  useEffect(() => {
    form.setFieldValue('contractId', contractId);
  }, [contractId, form]);

  const contractsByClientOptions = useMemo(
    () =>
      contractsByClient?.map(({ id, planDesc }) => ({
        label: planDesc,
        value: id,
      })),
    [contractsByClient],
  );

  const onContractSelected = useCallback(
    value => {
      onContractId(value?.contractId);
    },
    [onContractId],
  );

  return (
    <Form
      form={form}
      onValuesChange={onContractSelected}
      initialValues={{
        virtualPlanId: undefined,
      }}
      layout="vertical"
    >
      <Row gutter={[16, 16]} justify="center">
        <Col span={16}>
          <Form.Item name="customerId" label={t('attributes:client')}>
            <SelectCustomers
              value={customerId}
              onChange={value => {
                form.setFieldValue('virtualPlanId', undefined);
                onCustomerId(value);
              }}
            />
            {hasNoContracts && (
              <div
                style={{
                  marginTop: 4,
                  color: 'red',
                  fontSize: '0.8rem',
                  textAlign: 'left',
                }}
              >
                {`* ${t('clientWithoutContracts')}`}
              </div>
            )}
          </Form.Item>
        </Col>
        <Col
          span={16}
          style={{ marginTop: -16, opacity: disableSelectContract ? 0.4 : 1 }}
        >
          <Form.Item
            name="contractId"
            label={t('attributes:contract')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              optionFilterProp="label"
              options={contractsByClientOptions}
              disabled={disableSelectContract}
              showSearch
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ContractStep;
