import React, { memo, useCallback, useContext } from 'react';
import { Popover, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MoreOutlined } from '@ant-design/icons';

import SessionContext from '@src/store/SessionContext/SessionContext';

const TableMenu = ({ sim, isStock, showContracted }) => {
  const { t } = useTranslation('inventory');
  const navigate = useNavigate();

  const { userRoles } = useContext(SessionContext);

  const hasRole = useCallback(
    (roleToCheck, client) => userRoles?.[client]?.includes(roleToCheck),
    [userRoles],
  );

  return (
    <>
      <Popover
        content={
          <>
            {hasRole('location', 'portal') && (
              <div>
                <Button
                  disabled={sim.latitude === 0 && sim.longitude === 0}
                  type={'link'}
                  onClick={() => {
                    if (sim.latitude && sim.longitude) {
                      navigate(
                        `management/maps?type=coverage&lat=${sim.latitude}&lng=${sim.longitude}&zoom=15`,
                      );
                    } else {
                      message.error(t('menu-table.unknown-location'));
                    }
                  }}
                >
                  {t('menu-table.location')}
                </Button>
              </div>
            )}
          </>
        }
        placement={'bottom'}
        trigger={'click'}
      >
        <Button
          icon={<MoreOutlined />}
          size={'large'}
          type={'link'}
          style={{ transform: 'rotate(90deg)' }}
        />
      </Popover>
    </>
  );
};

export default memo(TableMenu);
