import styled from 'styled-components';
import { Popover } from 'antd';

export const ConsumeProgressPopover = styled(Popover)`
  cursor: pointer;

  .ant-progress-bg {
    background: ${({ percentage }) => {
      if (percentage <= 40) {
        return '#87d068';
      }
      if (percentage <= 80) {
        return '#2db7f5';
      }
      return '#f50';
    }};
  }
  .ant-progress-inner {
    border: 1px solid #dbdbdb;
  }
`;

export const ConsumeProgressPopoverContent = styled.div`
  font-size: 0.8rem;
`;

export const ConsumeProgressPopoverContentItem = styled.div`
  color: #575962;

  .ant-tag {
    margin-left: 4px;
  }
`;

export const PercentageBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  span,
  p {
    margin: 0;
    text-align: center;
    font-size: 0.85rem;
  }

  .ant-progress-text {
    margin-left: 6px;
  }

  p {
    width: 100%;
  }
`;
