import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Badge,
  Card,
  Col,
  Row,
  Space,
  Statistic,
  Progress,
  Typography,
  Button,
  Tooltip,
} from 'antd';

import Stat from '@components/Stat/Stat';

import {
  CalendarOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { VscRadioTower } from 'react-icons/vsc';

import { useDeviceFormatter } from '@hooks/useDeviceFormatter';
import {
  bytesFormat,
  dateFormat,
  extractDateInISOFormat,
} from '@utils/formatters';

import { useTranslation } from 'react-i18next';
import roles from '@utils/rolesConstants';
import SessionContext from '@src/store/SessionContext/SessionContext';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import './styles.less';

const { Paragraph } = Typography;

const statsFontSize = 20;

const TabPaneDetails = ({ deviceProps, showContracted, ...props }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('inventory-detailed');
  const { t: tStatus } = useTranslation('status');
  const { userRoles } = useContext(SessionContext);
  const { keycloak } = useContext(KeycloakContext);

  const customerType = keycloak?.idTokenParsed?.customerType;
  const isAllcom = customerType === 'SUPERBROKER';
  const isRealAccount = isAllcom && showContracted === true;

  const hasInventoryRole = useCallback(
    roleToCheck => userRoles?.inventory?.includes(roleToCheck),
    [userRoles],
  );
  // show if, true = operator, false = connected operator
  const [toggleOperator, setToggleOperator] = useState(true);
  const [device, setDevice] = useState(deviceProps);

  const {
    note,
    device: deviceInSWR,
    handleUpdateNote,
  } = useDeviceFormatter(deviceProps?.id, showContracted);

  // update device that comes from props, with what is in swr
  useEffect(() => {
    if (deviceInSWR) {
      setDevice(deviceInSWR);
    }
  }, [deviceInSWR]);

  const isDeviceConnected = device?.online === 'true';

  const renderConsumption = () => {
    const percentage = device?.consumptionPercent;
    if (!percentage) {
      return <div />;
    }
    let status = 'success';
    let strokeColor = '';
    if (percentage >= 80 && percentage <= 100) {
      strokeColor = 'orange';
    } else if (percentage > 100) {
      status = 'exception';
    }

    return (
      <Progress
        percent={percentage}
        strokeColor={strokeColor}
        status={status}
        showInfo={false}
      />
    );
  };

  return (
    <div className="tab-pane-details" {...props}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Space direction="vertical">
            {hasInventoryRole(roles.INVENT_STATUS) && (
              <Stat
                title={t('status')}
                value={`${tStatus(device?.status || '')}` || '-'}
                noStyleIcon
                icon={
                  <Badge
                    status={
                      device?.status
                        ? tStatus(`badgeStatus.${device?.status}`)
                        : 'error'
                    }
                  />
                }
                loading={!device}
              />
            )}
            {hasInventoryRole(roles.INVENT_OPERATOR) && (
              <div style={{ position: 'relative' }}>
                <Stat
                  title={
                    toggleOperator ? t('operator') : t('operator-connected')
                  }
                  icon={toggleOperator ? <PhoneOutlined /> : <VscRadioTower />}
                  value={
                    toggleOperator
                      ? device?.operator ?? '-'
                      : device?.connOperator ?? '-'
                  }
                  loading={!device}
                />
                {isDeviceConnected && (
                  <Tooltip
                    title={
                      !toggleOperator ? t('operator') : t('operator-connected')
                    }
                  >
                    <Button
                      icon={
                        !toggleOperator ? <PhoneOutlined /> : <VscRadioTower />
                      }
                      onClick={() => setToggleOperator(!toggleOperator)}
                      style={{
                        color: 'rgba(0, 166, 80, 1)',
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            )}
            {hasInventoryRole(roles.INVENT_LAST_CONNECTION) && (
              <Stat
                title={t('last-connection')}
                value={
                  device && device.lastConnection
                    ? new Date(device.lastConnection).toLocaleString(language)
                    : '-'
                }
                icon={<CalendarOutlined />}
                loading={!device}
              />
            )}
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            {hasInventoryRole(roles.INVENT_ONLINE) && (
              <Stat
                title={t('connection-status')}
                noStyleIcon
                icon={
                  <Badge
                    status={tStatus(
                      device?.online
                        ? 'badgeStatus.ONLINE'
                        : 'badgeStatus.OFFLINE',
                    )}
                  />
                }
                value={device?.online ? t('ONLINE') ?? '' : t('OFFLINE')}
                loading={!device}
              />
            )}
            {hasInventoryRole(roles.INVENT_CITY) ||
              (hasInventoryRole(roles.INVENT_STATE) && (
                <Stat
                  title={t('city')}
                  value={(() => {
                    if (device) {
                      if (device.city && device.state) {
                        return `${device.city}, ${device.state}`;
                      }
                      if (device.city) {
                        return device.city;
                      }
                      if (device.state) {
                        return device.state;
                      }
                    }
                    return '-';
                  })()}
                  icon={<EnvironmentOutlined />}
                  loading={!device}
                />
              ))}
            {hasInventoryRole(roles.INVENT_ACTIVATION_DATE) && (
              <Stat
                title={t('activation-date')}
                value={
                  device?.activationDate
                    ? extractDateInISOFormat(device.activationDate)
                    : '-'
                }
                icon={<CalendarOutlined />}
                loading={!device}
              />
            )}
          </Space>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col
          span={24}
          style={{ display: 'flex', flexDirection: 'column', marginTop: 12 }}
        >
          <Stat
            title={t(isRealAccount ? 'plan' : 'request')}
            icon={<FileTextOutlined />}
            value={(isRealAccount ? device?.plan : device?.accountDesc) || '-'}
            loading={!device}
          />
          {hasInventoryRole(roles.INVENT_CONSUMPTION) && (
            <Card>
              <div style={{ display: 'flex', fontSize: '1.125rem' }}>
                <div style={{ flex: '1' }}>Consumo</div>
                <div style={{ textAlign: 'right' }}>
                  {bytesFormat(device?.consumptionTotal ?? '0')}
                  {' / '}
                  {device?.franchise}
                  MB
                </div>
              </div>
              {renderConsumption()}
            </Card>
          )}
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ marginTop: 12 }}>
        <Col span={12}>
          <Space direction="vertical">
            {hasInventoryRole(roles.INVENT_ICCID) && (
              <Card>
                <Statistic
                  valueStyle={{ fontSize: statsFontSize }}
                  title="ICCID"
                  value={device?.iccid}
                  groupSeparator=""
                  loading={!device}
                />
              </Card>
            )}
            {hasInventoryRole(roles.INVENT_IMEI) && (
              <Card>
                <Statistic
                  valueStyle={{ fontSize: statsFontSize }}
                  title="IMEI"
                  value={device?.imei || '-'}
                  groupSeparator=""
                  loading={!device}
                />
              </Card>
            )}
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            {hasInventoryRole(roles.INVENT_MSISDN) && (
              <Card>
                <Statistic
                  valueStyle={{ fontSize: statsFontSize }}
                  title="MSISDN"
                  value={device?.msisdn}
                  groupSeparator=""
                  loading={!device}
                />
              </Card>
            )}
            {hasInventoryRole(roles.INVENT_IMSI) && (
              <Card>
                <Statistic
                  valueStyle={{ fontSize: statsFontSize }}
                  title="IMSI"
                  value={device?.imsi || '-'}
                  groupSeparator=""
                  loading={!device}
                />
              </Card>
            )}
          </Space>
        </Col>
      </Row>

      {hasInventoryRole(roles.INVENT_OBSERVATION) && (
        <Row gutter={[12, 12]} style={{ marginTop: 12, marginBottom: 24 }}>
          <Col lg={24} md={24} xl={24} xs={24}>
            <Card
              loading={!note}
              title={t('note')}
              headStyle={{ color: 'rgba(0,0,0,0.45)' }}
            >
              <Paragraph editable={{ onChange: handleUpdateNote }}>
                {note}
              </Paragraph>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TabPaneDetails;
