import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Modal,
  Steps,
  Button,
  message,
  Typography,
  Space,
  Tag,
  Tooltip,
  Table,
  Spin,
  Row,
  Select,
  Divider,
  Input,
  Tabs,
  Col,
  Alert,
  Result,
  InputNumber,
  Checkbox,
  TimePicker,
  DatePicker,
  Radio,
  Skeleton,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ActionTypes from '@utils/deviceActions';
import FileUploader from '../FileUploader';
import Papa from 'papaparse';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import {
  DownloadOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import CsvExport from '../CsvExport/CsvExport';
import { getModelData, getModelDataColumns } from './modelData';
import useSwr from '@hooks/useSwr';
import { Label } from '@components/ui';
import { useNavigate, useLocation } from 'react-router-dom';
import ChooseLockOrUnlock from './StepsComponents/DoubleActions/ChooseLockOrUnlock';

import * as Styled from './styles';
import NetworkBlockUploadHelp from './StepsComponents/NetworkBlockUnlock/NetworkBlockUploadHelp/index';
import ParametrizedDatePicker from '@src/components/DatePicker/DatePicker';
import moment from 'moment';
import {
  getDateInISOFormat,
  getDateWithISOFormat,
  trimInObjectKeys,
} from '@src/utils/formatters';
import Stat from '../Stat/Stat';
import apiMiddleware from '@src/services/apiMiddleware';
import NetworkBlockUnlockDate from './StepsComponents/NetworkBlockUnlock/NetworkBlockUnlockDate/index';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { cleanObject } from '@src/utils/manageSearchParams';
import EditableTable from '../EditableTable/EditableTable';
import DataNotFoundStep from '../DataNotFoundStep/DataNotFoundStep';
import ChooseCancellationOrSubstitution from './StepsComponents/Cancellation/index';

const { TextArea } = Input;
const { Step } = Steps;
const { Text, Title, Paragraph } = Typography;

const IS_LAST_STEP_DEFAULT = {
  visible: false,
  type: undefined, // 'success' || 'error'
};

export const sendDeviceAction = ({
  axios,
  payload,
  username,
  smsSend = false,
}) => {
  return axios.post(
    smsSend ? '/service-proxy/sms-request' : '/service-proxy/actions',
    payload,
    {
      params: {
        username,
      },
    },
  );
};

const DeviceActionModal = ({
  visible,
  actionKey,
  onClose,
  devices,
  showContracted,
  doubleActions,
  availableOperators,
  dataContracts,
}) => {
  const { t } = useTranslation([
    'device-actions-modal',
    'device-actions',
    'transfer',
    'attributes',
    'placeholders',
  ]);
  const { axios, keycloak } = useContext(KeycloakContext);
  const {
    customer: { customerLogged, customersOptions },
  } = useContext(SessionContext);
  const username = keycloak?.idTokenParsed?.preferred_username;
  const customerId = keycloak?.idTokenParsed?.customerId;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [current, setCurrent] = useState(0);
  const [uploadedFile, setUploadedFile] = useState();
  const [parsingFile, setParsingFile] = useState(false);
  const [uploadedDevices, setUploadedDevices] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [selectedRequestId, setSelectedRequestId] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [selectedContractId, setSelectedContractId] = useState();
  const [sendActionLoading, setSendActionLoading] = useState(false);
  const [lockUnlockType, setLockUnlockType] = useState();
  // Nickname change
  const [chosenNickname, setChosenNickname] = useState();
  // Network block or unlock
  const [blockUnlockInitialDate, setBlockUnlockInitialDate] = useState(); // YYYYMMDD
  const [blockUnlockFinalDate, setBlockUnlockFinalDate] = useState(); // YYYYMMDD
  const [blockType, setBlockType] = useState('1'); // 1 = 'permanent' | 2 = 'until renovation' | 3 = 'temporary'
  const [cancelationType, setCancelationType] = useState('cancell'); // 1 = 'cancellation' | 2 = 'remove cancellation' | 3 = 'change lines'

  // Suspension
  const [suspensionEligibility, setSuspensionEligibility] = useState();
  // Exchange actions
  const [devicesToExchangeData, setDevicesToExchangeData] = useState();
  const [isLastStep, setIsLastStep] = useState(IS_LAST_STEP_DEFAULT);
  // Sms-send

  const [from, setFrom] = useState();
  const [flash, setFlash] = useState('normal');
  const [schedule, setSchedule] = useState('normal');
  const [specifyDate, setSpecifyDate] = useState('false');

  const [text, setText] = useState('');
  const [validityPeriod, setValidityPeriod] = useState();
  const [sendAt, setSendAt] = useState();
  const [days, setDays] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const { data: contractsByClient } = useSwr(
    selectedCustomerId
      ? `/service-proxy/broker/${selectedCustomerId}/virtual-plan`
      : null,
  );

  const { data: sendersData } = useSwr(`/service-proxy/sms-senders`, {
    customerId,
  });

  const { data: requestsByContract } = useSwr(
    selectedContractId
      ? `/service-proxy/broker/virtual-account?contractId=${selectedContractId}&linesPerPage=${99999}`
      : null,
  );

  const { data: numMaxData } = useSwr(
    actionKey === ActionTypes.HLR_REPROG
      ? '/service-proxy/configurations/subscriber'
      : null,
  );

  const transfersContractsOptions = useMemo(() => {
    if (contractsByClient === '') {
      return [];
    }
    return contractsByClient
      ? contractsByClient?.map(({ id, planDesc }) => ({
          label: planDesc,
          value: id,
        }))
      : undefined;
  }, [contractsByClient]);

  const transfersRequestsOptions = useMemo(
    () =>
      requestsByContract
        ? requestsByContract?.content?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        : undefined,
    [requestsByContract],
  );
  const sendersDataFilter = useMemo(
    () => sendersData?.filter(sender => sender.customerId === customerId),
    [sendersData, customerId],
  );
  const isUpload = useMemo(() => !devices || devices?.length === 0, [devices]);

  const selectedDevices = useMemo(
    () => (isUpload ? uploadedDevices : devices),
    [isUpload, uploadedDevices, devices],
  );

  const contracts = useMemo(() => {
    if (dataContracts) {
      return dataContracts?.map(({ assetNumber, planName, nickname }) => ({
        value: assetNumber,
        label: `${assetNumber}${
          nickname ? ` - ${nickname}` : ''
        } - ${planName}`,
      }));
    }
  }, [dataContracts]);

  const dataModelColumns = useMemo(
    () => getModelDataColumns(actionKey, t, lockUnlockType),
    [actionKey, t, lockUnlockType],
  );

  const dataModelData = useMemo(
    () => getModelData(actionKey, lockUnlockType, t),
    [actionKey, lockUnlockType, t],
  );

  useEffect(() => {
    if (uploadedFile) {
      setParsingFile(true);
      Papa.parse(uploadedFile, {
        skipEmptyLines: 'greedy',
        header: true,
        complete(results) {
          const csvHeaderReference = dataModelColumns.map(item => item.title);

          if (
            !csvHeaderReference.every(v =>
              results.meta.fields.map(f => f?.trim()).includes(v),
            ) &&
            dataModelColumns?.length !== 0
          ) {
            message.error(t('invalid-header'));
            setParsingFile(false);
            return;
          }

          const devicesToSend = results.data.map(device => {
            device = trimInObjectKeys(device);
            const rowObject = {};
            dataModelColumns.forEach(header => {
              rowObject[header.dataIndex] = device[header.title];
            });
            return rowObject;
          });

          setUploadedDevices(devicesToSend);
          setParsingFile(false);
        },
      });
    } else {
      setUploadedDevices([]);
    }
  }, [uploadedFile, dataModelColumns, t]);

  const columnHelp = useCallback(
    dataIndex => {
      switch (dataIndex) {
        case 'vendor':
          return (
            availableOperators && (
              <p key={dataIndex} style={{ paddingLeft: 6 }}>
                {t('availableOperators')}: &nbsp;
                {availableOperators.map(operator => (
                  <Tag key={operator.id}>{operator.name}</Tag>
                ))}
              </p>
            )
          );
        case 'newCustomerVirtualAccountId':
          return (
            <p style={{ padding: 6 }}>
              {t('steps.request.transfer-to-stock-help')}
              &nbsp;&nbsp;
              <Tag style={{ margin: 0 }}>0</Tag>
            </p>
          );
        case 'tpBloqueio':
          return NetworkBlockUploadHelp(t);
        case 'dtDesbloqueio':
          return (
            <p style={{ color: 'red' }}>
              * {t('steps.network-block-unlock.help-date-not-selected')}
            </p>
          );
        case 'processData':
          return (
            <p style={{ color: 'red' }}>
              * {t('steps.imei-lock-unlock.imei-help')}
            </p>
          );
        default:
          break;
      }
    },
    [availableOperators, t],
  );

  const info = useCallback(() => {
    Modal.info({
      title: t('csv-format-example'),
      width: 700,
      content: (
        <>
          <Table
            columns={dataModelColumns}
            dataSource={dataModelData}
            pagination={false}
            size={'small'}
            tableLayout={'auto'}
            bordered
            style={{ margin: '12px 0' }}
          />
          {dataModelColumns?.map(column => {
            return column.help && columnHelp(column.dataIndex);
          })}
          <CsvExport
            columns={dataModelColumns}
            data={dataModelData}
            filename={'model-import.csv'}
            separator={','}
            style={{ background: 'red' }}
          >
            <Button
              className={'button-download'}
              icon={<DownloadOutlined />}
              size={'large'}
              type={'link'}
            >
              {t('download-model')}
            </Button>
          </CsvExport>
        </>
      ),
    });
  }, [dataModelColumns, dataModelData, t, columnHelp]);

  const clearStates = useCallback(() => {
    setUploadedDevices([]);
    setCurrent(0);
    setSelectedRequestId(undefined);
    setParsingFile(false);
    setUploadedFile(undefined);
    setSelectedContract(undefined);
    setChosenNickname(undefined);
    setLockUnlockType(undefined);
    setBlockUnlockInitialDate(undefined);
    setBlockUnlockFinalDate(undefined);
    setBlockType('1');
    setSuspensionEligibility(undefined);
    setDevicesToExchangeData(undefined);
    setSelectedCustomerId(undefined);
    setSelectedContractId(undefined);
    setFrom();
    setFlash('normal');
    setSchedule('normal');
    setSpecifyDate('false');
    setText('');
    setValidityPeriod();
    setSendAt();
    setDays([]);
    setFromDate();
    setToDate();
  }, []);

  // --------------------------------------------------
  // --------------- DOUBLE ACTIONS -------------------
  // --------------------------------------------------

  const lockUnlockSelectDevices = useMemo(() => {
    let devicesData;
    doubleActions?.forEach(
      ({
        actionRole,
        selectedDevices: actionSelectDevices,
        actionBlock,
        actionUnlock,
      }) => {
        if (actionKey === ActionTypes[actionRole]) {
          devicesData = actionSelectDevices;
          if (lockUnlockType) {
            devicesData.selectedActionKey =
              lockUnlockType === 'block'
                ? ActionTypes[actionBlock]
                : ActionTypes[actionUnlock];
          } else {
            devicesData.selectedActionKey = ActionTypes[actionRole];
          }
        }
      },
    );
    return devicesData;
  }, [actionKey, doubleActions, lockUnlockType]);

  const lockUnlockSelectDevicesByType = useMemo(() => {
    const type =
      lockUnlockType?.charAt(0).toUpperCase() + lockUnlockType?.slice(1);
    const devicesSelected = lockUnlockSelectDevices?.[`devicesTo${type}`]
      ? `devicesTo${type}`
      : 'devices';
    return lockUnlockSelectDevices?.[devicesSelected];
  }, [lockUnlockSelectDevices, lockUnlockType]);

  const isDoubleAction = useMemo(
    () => !!lockUnlockSelectDevices,
    [lockUnlockSelectDevices],
  );

  const actionKeyTitle = useMemo(
    () =>
      isDoubleAction ? lockUnlockSelectDevices?.selectedActionKey : actionKey,
    [actionKey, isDoubleAction, lockUnlockSelectDevices?.selectedActionKey],
  );

  const lockUnlockStep = useMemo(
    () => ({
      title: t('steps.lock-unlock.action'),
      content: (
        <ChooseLockOrUnlock
          actionKey={actionKey}
          lockUnlockType={lockUnlockType}
          onChange={setLockUnlockType}
          showContracted={showContracted}
          lockUnlockSelectDevices={lockUnlockSelectDevices}
        />
      ),
    }),
    [t, lockUnlockSelectDevices, showContracted, lockUnlockType, actionKey],
  );

  // --------------------------------------------------
  // ---------- SPECIFIC STEPS OF ACTIONS -------------
  // --------------------------------------------------

  const activationSteps = useMemo(
    () => [
      {
        title: t('steps.contract.name'),
        content: (
          <div className="action-activation-in-devices">
            <Paragraph style={{ textAlign: 'left' }}>
              {t('steps.contract.description')}
            </Paragraph>
            <Select
              loading={!contracts}
              optionFilterProp={'label'}
              options={contracts}
              placeholder={t('steps.contract.placeholder')}
              value={selectedContract}
              allowClear
              showSearch
              onChange={value => setSelectedContract(value)}
            />
          </div>
        ),
      },
    ],
    [contracts, selectedContract, t],
  );

  const transfersCustomersOptions = useMemo(() => {
    const options = [
      {
        label: `(${t('steps.request.stock')}) ${customerLogged?.name ?? ' '}`,
        value: 0,
      },
    ];

    if (customersOptions) {
      options.push({
        label: t('attributes:client'),
        options: customersOptions ?? [
          {
            label: (
              <div style={{ textAlign: 'center' }}>
                <LoadingOutlined />
              </div>
            ),
          },
        ],
      });
    }

    return options;
  }, [t, customersOptions, customerLogged]);

  const transfersSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    const allSteps = [
      {
        title: t('attributes:client'),
        content: (
          <div>
            <Divider style={{ marginTop: 24 }} />
            <div className="action-transfer">
              <Select
                options={transfersCustomersOptions}
                loading={!transfersCustomersOptions}
                disabled={!transfersCustomersOptions}
                optionFilterProp={'label'}
                placeholder={t('placeholders:client')}
                value={selectedCustomerId}
                allowClear
                showSearch
                style={{ width: '100%' }}
                onChange={value => {
                  setSelectedCustomerId(value);
                  setSelectedContractId(undefined);
                  setSelectedRequestId(undefined);
                }}
              />
            </div>
          </div>
        ),
      },
    ];

    if (selectedCustomerId !== 0) {
      allSteps.push(
        ...[
          {
            title: t('attributes:contract'),
            content:
              transfersContractsOptions?.length > 0 ? (
                <div>
                  <Divider style={{ marginTop: 24 }} />
                  <div className="action-transfer">
                    <Select
                      optionFilterProp={'label'}
                      options={transfersContractsOptions}
                      loading={!transfersContractsOptions}
                      disabled={!transfersContractsOptions}
                      placeholder={t('placeholders:contract')}
                      value={selectedContractId}
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      onChange={value => {
                        setSelectedContractId(value);
                        setSelectedRequestId(undefined);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <DataNotFoundStep
                  data={transfersContractsOptions}
                  color="red"
                  text={t('steps.contract.not-found')}
                />
              ),
          },
          {
            title: t('attributes:request'),
            content:
              transfersRequestsOptions?.length > 0 ? (
                <div>
                  <Divider style={{ marginTop: 24 }} />
                  <div className="action-transfer">
                    <Select
                      optionFilterProp={'label'}
                      options={transfersRequestsOptions}
                      loading={!transfersRequestsOptions}
                      disabled={!transfersRequestsOptions}
                      placeholder={t('placeholders:request')}
                      value={selectedRequestId}
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      onChange={value => setSelectedRequestId(value)}
                    />
                  </div>
                </div>
              ) : (
                <DataNotFoundStep
                  data={transfersRequestsOptions}
                  color="red"
                  text={t('steps.request.not-found')}
                />
              ),
          },
        ],
      );
    } else {
      setSelectedRequestId(0);
    }

    return allSteps;
  }, [
    t,
    isUpload,
    selectedRequestId,
    selectedCustomerId,
    selectedContractId,
    transfersRequestsOptions,
    transfersCustomersOptions,
    transfersContractsOptions,
  ]);

  const networkUnlockSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.lock-unlock.unlock'),
        content: (
          <div style={{ padding: 24, textAlign: 'center' }}>
            <NetworkBlockUnlockDate
              lockUnlockType="unlock"
              blockUnlockInitialDate={blockUnlockInitialDate}
              setBlockUnlockInitialDate={setBlockUnlockInitialDate}
            />
          </div>
        ),
      },
    ];
  }, [t, blockUnlockInitialDate, isUpload]);

  const networkBlockSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.lock-unlock.block'),
        content: (
          <Tabs
            defaultActiveKey="1"
            activeKey={blockType}
            className="block-types-tabs"
            type="card"
            onChange={setBlockType}
            style={{ marginTop: 16 }}
          >
            <Tabs.TabPane
              key="1"
              tab={
                <>
                  {t('steps.network-block-unlock.block-types.permanent')}
                  <Tooltip
                    title={t(
                      'steps.network-block-unlock.block-types.permanent-help',
                    )}
                  >
                    <QuestionCircleOutlined style={{ margin: '0 0 0 8px' }} />
                  </Tooltip>
                </>
              }
            >
              <NetworkBlockUnlockDate
                lockUnlockType="block"
                blockUnlockInitialDate={blockUnlockInitialDate}
                setBlockUnlockInitialDate={setBlockUnlockInitialDate}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="2"
              tab={
                <>
                  {t('steps.network-block-unlock.block-types.until-renovation')}
                  <Tooltip
                    title={t(
                      'steps.network-block-unlock.block-types.until-renovation-help',
                    )}
                  >
                    <QuestionCircleOutlined style={{ margin: '0 0 0 8px' }} />
                  </Tooltip>
                </>
              }
            >
              <NetworkBlockUnlockDate
                lockUnlockType="block"
                blockUnlockInitialDate={blockUnlockInitialDate}
                setBlockUnlockInitialDate={setBlockUnlockInitialDate}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              key="3"
              tab={
                <>
                  {t('steps.network-block-unlock.block-types.temporary')}
                  <Tooltip
                    title={t(
                      'steps.network-block-unlock.block-types.temporary-help',
                    )}
                  >
                    <QuestionCircleOutlined style={{ margin: '0 0 0 8px' }} />
                  </Tooltip>
                </>
              }
            >
              <div
                style={{
                  padding: 24,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '49%' }}>
                  <Label
                    color={'#575962'}
                    htmlFor={'name'}
                    style={{
                      display: 'block',
                    }}
                  >
                    {`${t('steps.network-block-unlock.initial-validity')}:`}
                  </Label>
                  <ParametrizedDatePicker
                    allowClear
                    disabledDate={d =>
                      d < moment() ||
                      (blockUnlockFinalDate &&
                        d > moment(getDateWithISOFormat(blockUnlockFinalDate)))
                    }
                    showToday={false}
                    value={
                      blockUnlockInitialDate &&
                      moment(getDateWithISOFormat(blockUnlockInitialDate))
                    }
                    onChange={value => {
                      if (value) {
                        setBlockUnlockInitialDate(
                          getDateInISOFormat(value.toISOString()),
                        );
                      } else {
                        setBlockUnlockInitialDate(undefined);
                      }
                    }}
                  />
                  {!blockUnlockInitialDate && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '0.8rem',
                        marginTop: '12px',
                      }}
                    >
                      {`* ${t(
                        'steps.network-block-unlock.help-date-not-selected',
                      )}`}
                    </p>
                  )}
                </div>
                <div style={{ width: '49%' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Label
                      color={'#575962'}
                      htmlFor={'name'}
                      style={{
                        display: 'block',
                      }}
                    >
                      {`${t('steps.network-block-unlock.end-validity')}:`}
                    </Label>
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  </div>

                  <ParametrizedDatePicker
                    allowClear
                    disabledDate={d =>
                      blockUnlockInitialDate
                        ? d <
                          moment(getDateWithISOFormat(blockUnlockInitialDate))
                        : d < moment()
                    }
                    showToday={false}
                    value={
                      blockUnlockFinalDate &&
                      moment(getDateWithISOFormat(blockUnlockFinalDate))
                    }
                    onChange={value => {
                      if (value) {
                        setBlockUnlockFinalDate(
                          getDateInISOFormat(value.toISOString()),
                        );
                      } else {
                        setBlockUnlockFinalDate(undefined);
                      }
                    }}
                  />
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        ),
      },
      {
        title: t('steps.network-block-unlock.informative'),
        content: (
          <Alert
            style={{ padding: 8, marginTop: 32 }}
            description={t('steps.network-block-unlock.billing-information')}
            type="info"
            showIcon
          />
        ),
      },
    ];
  }, [blockType, blockUnlockFinalDate, blockUnlockInitialDate, t, isUpload]);

  const brokerCancellationSteps = useMemo(() => {
    return lockUnlockType === 'unlock'
      ? [
          {
            title: t('steps.suspension-withdrawal.informative.name'),
            content: (
              <div style={{ padding: 16 }}>
                <Paragraph>
                  {t('steps.broker-cancellation.withdrawal-cancellation')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 'bold', opacity: 0.8 }}>
                  {t('steps.broker-cancellation.time-message-withdrawal')}
                </Paragraph>
              </div>
            ),
          },
        ]
      : [
          {
            title: t('steps.network-block-unlock.informative'),
            content: (
              <ChooseCancellationOrSubstitution
                selectedDevices={selectedDevices}
                cancelationType={cancelationType}
                onChange={value => setCancelationType(value)}
              />
            ),
          },
        ];
  }, [cancelationType, t, selectedDevices, lockUnlockType]);

  const nicknameChangeSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.nickname-change.name'),
        content: (
          <div style={{ padding: 16 }}>
            <Input
              allowClear
              placeholder={t('steps.nickname-change.new-nickname')}
              onChange={({ target: { value } }) => setChosenNickname(value)}
            />
          </div>
        ),
      },
    ];
  }, [t, isUpload]);

  const handleGetSuspensionEligibility = useCallback(async () => {
    const getData = selectedMsisdn =>
      apiMiddleware.post(
        '/service-proxy/inventory/MOT/suspension-eligibility',
        selectedMsisdn,
      );

    const { data } = await getData(
      selectedDevices.map(device => device.msisdn),
    );
    setSuspensionEligibility(data);
  }, [selectedDevices]);

  useEffect(() => {
    const isInformativeStep = isUpload ? current === 2 : current === 1;
    if (
      actionKey === ActionTypes['SUSPENSION_WITHDRAWAL'] &&
      isInformativeStep
    ) {
      handleGetSuspensionEligibility();
    }
  }, [handleGetSuspensionEligibility, actionKey, current, isUpload]);

  const suspensionSteps = useMemo(
    () => [
      {
        title: t('steps.suspension-withdrawal.informative.name'),
        content: (
          <div style={{ padding: '0 16px' }}>
            {suspensionEligibility ? (
              <>
                <Alert
                  style={{ padding: 8 }}
                  description={t(
                    'steps.suspension-withdrawal.informative.confirmation',
                    {
                      count: selectedDevices?.length,
                    },
                  )}
                  type="info"
                  showIcon
                />
                <p
                  style={{
                    margin: '8px 0',
                    marginTop: 24,
                    textAlign: 'center',
                    opacity: 0.6,
                  }}
                >
                  {t('steps.suspension-withdrawal.informative.total', {
                    count: selectedDevices?.length,
                  })}
                </p>
                <Row gutter={[16, 16]}>
                  <Col xl={12} xs={24}>
                    <Stat
                      title={t(
                        'steps.suspension-withdrawal.informative.eligible',
                        {
                          count: suspensionEligibility?.nbSuspendable,
                        },
                      )}
                      icon={<CheckCircleOutlined />}
                      value={suspensionEligibility?.nbSuspendable}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Stat
                      title={t(
                        'steps.suspension-withdrawal.informative.not-eligible',
                        {
                          count: suspensionEligibility?.nbNotSuspendable,
                        },
                      )}
                      icon={<CloseCircleOutlined />}
                      value={suspensionEligibility?.nbNotSuspendable}
                      iconColor="red"
                    />
                  </Col>
                  <Paragraph
                    style={{
                      marginTop: 16,
                    }}
                  >
                    {t(
                      'steps.suspension-withdrawal.informative.confirmation2',
                      {
                        count: selectedDevices?.length,
                      },
                    )}
                  </Paragraph>
                </Row>
              </>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Spin size="large" style={{ margin: 16 }} />
                <p style={{ maxWidth: 200, margin: '0 auto' }}>
                  {t('steps.suspension-withdrawal.informative.loading', {
                    count: selectedDevices?.length,
                  })}
                </p>
              </div>
            )}
          </div>
        ),
      },
    ],
    [t, suspensionEligibility, selectedDevices],
  );

  const withdrawalSteps = useMemo(
    () => [
      {
        title: t('steps.suspension-withdrawal.informative.name'),
        content: (
          <div style={{ padding: 16 }}>
            <Paragraph>
              {t(
                'steps.suspension-withdrawal.informative.description-withdrawal',
              )}
            </Paragraph>
            <Paragraph style={{ fontWeight: 'bold', opacity: 0.8 }}>
              {t(
                'steps.suspension-withdrawal.informative.description-withdrawal-2',
              )}
            </Paragraph>
          </div>
        ),
      },
    ],
    [t],
  );

  const ExchangeComponent = useCallback(
    ({ onChange, field, devicesToExchange, columns, operator = false }) =>
      devicesToExchange?.length > 1 ? (
        <EditableTable
          data={devicesToExchange}
          columns={columns}
          oneLine
          tableData={setDevicesToExchangeData}
        />
      ) : (
        <div style={{ padding: 16 }}>
          <div style={{ textAlign: 'right' }}>
            <span>{t('steps.exchange.current')}: </span>
            <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
              {devicesToExchange?.[0]?.[field]}
            </span>
          </div>
          {operator ? (
            <Select
              style={{
                width: '100%',
              }}
              onChange={valueToChange => {
                const findItem = devicesToExchange?.[0];
                const itemWithNewOperator = {
                  ...findItem,
                  processData: valueToChange,
                };
                setDevicesToExchangeData([itemWithNewOperator]);
              }}
              placeholder={t('steps.mot-operator-change.new-operator')}
              options={
                availableOperators?.map(({ name }) => ({
                  label: name,
                  value: name,
                })) ?? []
              }
            />
          ) : (
            <Input
              allowClear
              placeholder={t('steps.exchange.name', {
                field: field?.toUpperCase(),
              })}
              onChange={({ target: { value } }) =>
                onChange([{ ...devicesToExchange?.[0], processData: value }])
              }
            />
          )}
        </div>
      ),
    [t, availableOperators],
  );

  const AddCreditComponent = useCallback(
    ({ onChange, field, devicesToExchange, columns }) =>
      devicesToExchange?.length > 1 ? (
        <EditableTable
          data={devicesToExchange}
          columns={columns}
          oneLine
          tableData={setDevicesToExchangeData}
        />
      ) : (
        <div style={{ padding: 16 }}>
          <div style={{ textAlign: 'right' }}>
            <span>{t('steps.add-credit.franchiseeMB')}: </span>
            <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
              {devicesToExchange?.[0]?.[field]}
            </span>
          </div>
          <Input
            allowClear
            addonAfter="MB"
            onChange={({ target: { value } }) =>
              onChange([{ ...devicesToExchange?.[0], processData: value }])
            }
          />
        </div>
      ),
    [t],
  );

  const smsSendSteps = useMemo(() => {
    return [
      {
        title: t('steps.sms-send.first-step'),
        content: (
          <div style={{ padding: 16 }}>
            <Row gutter={[16, 16]}>
              {sendersDataFilter && sendersDataFilter.length > 0 && (
                <Col xl={24} xs={24}>
                  <Label
                    color={'#575962'}
                    htmlFor={'name'}
                    style={{
                      display: 'block',
                    }}
                  >
                    {`${t('steps.sms-send.from')}`}{' '}
                  </Label>

                  <Select
                    value={from}
                    onChange={valueToChange => setFrom(valueToChange)}
                    style={{ width: '100%' }}
                    allowClear
                  >
                    {sendersDataFilter?.map(sender => (
                      <Select.Option key={sender.id} value={sender.sender}>
                        {sender.sender}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              )}

              <Col xl={24} xs={24}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label
                    color={'#575962'}
                    htmlFor={'name'}
                    style={{
                      display: 'block',
                    }}
                  >
                    {`${t('steps.sms-send.type')}`}{' '}
                  </Label>
                  <Tooltip title={t('steps.sms-send.flash-info')}>
                    <Button type="link">
                      <InfoCircleOutlined />
                    </Button>
                  </Tooltip>
                </div>
                <Radio.Group
                  onChange={event => setFlash(event.target.value)}
                  defaultValue="normal"
                  value={flash}
                >
                  <Radio.Button value="normal">
                    {' '}
                    {t('steps.sms-send.normal')}
                  </Radio.Button>
                  <Radio.Button value="flash">
                    {' '}
                    {t('steps.sms-send.flash')}
                  </Radio.Button>
                </Radio.Group>
              </Col>
              <Col xl={24} xs={24}>
                <Label
                  color={'#575962'}
                  htmlFor={'name'}
                  style={{
                    display: 'block',
                  }}
                >
                  {`${t('steps.sms-send.text')}`}{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <TextArea
                  onChange={({ target: { value } }) => setText(value)}
                  value={text}
                />
              </Col>
            </Row>
          </div>
        ),
      },
      {
        title: t('steps.sms-send.second-step'),
        content: (
          <div style={{ padding: 16 }}>
            <Row gutter={[16, 16]}>
              <Col xl={24} xs={24}>
                <Label
                  color={'#575962'}
                  htmlFor={'name'}
                  style={{
                    display: 'block',
                  }}
                >
                  {`${t('steps.sms-send.typeDate')}`}
                </Label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Radio.Group
                    onChange={event => {
                      setDays([]);
                      setValidityPeriod();
                      setFromDate();
                      setToDate();
                      setSendAt();
                      setSchedule(event.target.value);
                      setSpecifyDate('false');
                    }}
                    value={schedule}
                  >
                    <Radio.Button value="normal">
                      {t('steps.sms-send.normal')}
                    </Radio.Button>
                    <Radio.Button value="schedule">
                      {t('steps.sms-send.schedule')}
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </Col>

              {schedule === 'normal' ? (
                <>
                  <Col xl={24} xs={24}>
                    <Label
                      color={'#575962'}
                      htmlFor={'type'}
                      style={{
                        display: 'block',
                      }}
                    >
                      {`${t('steps.sms-send.specidyDate')}`}
                    </Label>

                    <Radio.Group
                      defaultValue="false"
                      value={specifyDate}
                      onChange={event => {
                        setDays([]);
                        setValidityPeriod();
                        setFromDate();
                        setToDate();
                        setSendAt();
                        setSpecifyDate(event.target.value);
                      }}
                    >
                      <Radio.Button value="false">
                        {t('steps.sms-send.no')}
                      </Radio.Button>
                      <Radio.Button value="true">
                        {t('steps.sms-send.yes')}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                  {specifyDate === 'true' && (
                    <>
                      <Col xl={24} xs={24}>
                        <Label
                          color={'#575962'}
                          htmlFor={'name'}
                          style={{
                            display: 'block',
                          }}
                        >
                          {`${t('steps.sms-send.deliveryTimeWindow')}`}
                        </Label>

                        <Checkbox.Group
                          options={[
                            'monday',
                            'tuesday',
                            'wednesday',
                            'thursday',
                            'friday',
                            'saturday',
                            'sunday',
                          ].map(day => ({
                            label: t(`steps.sms-send.options.${day}`),
                            value: day.toUpperCase(),
                          }))}
                          value={days}
                          onChange={days => setDays(days)}
                        />
                      </Col>

                      {days?.length > 0 && (
                        <>
                          <Col xl={24} xs={24}>
                            <Label
                              color={'#575962'}
                              htmlFor={'name'}
                              style={{
                                display: 'block',
                              }}
                            >
                              {`${t('steps.sms-send.hoursInterval')}`}
                            </Label>
                          </Col>
                          <Col xl={12} xs={24}>
                            <Label
                              color={'#575962'}
                              htmlFor={'name'}
                              style={{
                                display: 'block',
                              }}
                            >
                              {`${t('steps.sms-send.fromDate')}`}
                            </Label>
                            <TimePicker
                              value={fromDate}
                              format={'HH:mm'}
                              onChange={timeChange => setFromDate(timeChange)}
                            />
                          </Col>

                          <Col xl={12} xs={24}>
                            <Label
                              color={'#575962'}
                              htmlFor={'name'}
                              style={{
                                display: 'block',
                              }}
                            >
                              {`${t('steps.sms-send.toDate')}`}
                            </Label>
                            <TimePicker
                              value={toDate}
                              format={'HH:mm'}
                              onChange={timeChange => setToDate(timeChange)}
                            />
                          </Col>
                        </>
                      )}
                      <Col xl={24} xs={24}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Label
                            color={'#575962'}
                            htmlFor={'name'}
                            style={{
                              display: 'block',
                            }}
                          >
                            {`${t('steps.sms-send.validityPeriod')}`}{' '}
                          </Label>
                          <Tooltip
                            title={t('steps.sms-send.validityPeriod-info')}
                          >
                            <Button type="link">
                              <InfoCircleOutlined />
                            </Button>
                          </Tooltip>
                        </div>

                        <InputNumber
                          value={validityPeriod}
                          onChange={value => setValidityPeriod(value)}
                          max={48}
                          style={{
                            width: '150px',
                          }}
                        />
                      </Col>
                    </>
                  )}
                </>
              ) : (
                <Col xl={12} xs={24}>
                  <Label
                    color={'#575962'}
                    htmlFor={'name'}
                    style={{
                      display: 'block',
                    }}
                  >
                    {`${t('steps.sms-send.sendAt')}`}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <DatePicker
                    value={sendAt}
                    showTime={{ format: 'HH:mm' }}
                    onChange={date => setSendAt(date)}
                  />
                </Col>
              )}
            </Row>
          </div>
        ),
      },
    ];
  }, [
    t,
    fromDate,
    toDate,
    days,
    schedule,
    text,
    flash,
    from,
    sendersDataFilter,
    specifyDate,
    sendAt,
    validityPeriod,
  ]);

  useEffect(() => {
    if (actionKey === ActionTypes.MOT_OPERATOR_CHANGE && selectedDevices) {
      setDevicesToExchangeData(
        selectedDevices.map(device => ({
          key: device.id,
          msisdn: device.msisdn,
          operator: device.subOperator || device.operator,
          processData: undefined,
        })),
      );
    }

    if (
      (actionKey === ActionTypes.PLASTIC_EXCHANGE ||
        actionKey === ActionTypes.MSISDN_EXCHANGE ||
        actionKey === ActionTypes.ADD_CREDIT_TO_SIMCARD) &&
      selectedDevices
    ) {
      setDevicesToExchangeData(
        selectedDevices.map(device => ({
          key: device.id,
          msisdn: device.msisdn,
          iccid: device.iccid,
          processData: undefined,
        })),
      );
    }
  }, [actionKey, selectedDevices]);

  const plasticExchangeSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.exchange.name', { field: 'ICCID' }),
        content: (
          <ExchangeComponent
            devicesToExchange={devicesToExchangeData}
            onChange={setDevicesToExchangeData}
            columns={[
              {
                title: 'ICCID',
                key: 'iccid',
                dataIndex: 'iccid',
              },
              {
                title: t('steps.exchange.name', { field: 'ICCID' }),
                key: 'processData',
                dataIndex: 'processData',
                editable: true,
                isRequired: false,
              },
            ]}
            field="iccid"
          />
        ),
      },
    ];
  }, [t, isUpload, devicesToExchangeData]);

  const msidnExchangeSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.exchange.name', { field: 'MSISDN' }),
        content: (
          <ExchangeComponent
            devicesToExchange={devicesToExchangeData}
            onChange={setDevicesToExchangeData}
            columns={[
              {
                title: 'MSISDN',
                key: 'msisdn',
                dataIndex: 'msisdn',
              },
              {
                title: t('steps.exchange.name', { field: 'MSISDN' }),
                key: 'processData',
                dataIndex: 'processData',
                editable: true,
                isRequired: false,
              },
            ]}
            field="msisdn"
          />
        ),
      },
    ];
  }, [t, isUpload, devicesToExchangeData]);

  const availableOperatorsOptions = useMemo(
    () =>
      availableOperators?.map(({ name }) => ({
        label: name,
        value: name,
      })),
    [availableOperators],
  );

  const operatorChangeSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.mot-operator-change.new-operator'),
        content: (
          <ExchangeComponent
            devicesToExchange={devicesToExchangeData}
            onChange={setDevicesToExchangeData}
            columns={[
              {
                title: 'MSISDN',
                key: 'msisdn',
                dataIndex: 'msisdn',
              },
              {
                title: t('steps.mot-operator-change.operator'),
                key: 'operator',
                dataIndex: 'operator',
              },
              {
                title: t('steps.mot-operator-change.new-operator'),
                key: 'processData',
                dataIndex: 'processData',
                render: (value, record) => (
                  <Select
                    style={{
                      width: '100%',
                    }}
                    onChange={valueToChange => {
                      const findItem = devicesToExchangeData.findIndex(
                        item => item.key === record.key,
                      );
                      const itemWithNewOperator = {
                        ...record,
                        processData: valueToChange,
                      };
                      const newArray = devicesToExchangeData;
                      newArray[findItem] = itemWithNewOperator;
                      setDevicesToExchangeData(newArray);
                    }}
                    options={availableOperatorsOptions ?? []}
                  />
                ),
                isRequired: true,
              },
            ]}
            operator={true}
            field="operator"
          />
        ),
      },
    ];
  }, [t, isUpload, devicesToExchangeData, availableOperatorsOptions]);

  const addCreditSteps = useMemo(() => {
    if (isUpload) {
      return [];
    }

    return [
      {
        title: t('steps.add-credit.name', { field: 'MSISDN' }),
        content: (
          <AddCreditComponent
            devicesToExchange={devicesToExchangeData}
            onChange={setDevicesToExchangeData}
            columns={[
              {
                title: 'MSISDN',
                key: 'msisdn',
                dataIndex: 'msisdn',
              },
              {
                title: t('steps.add-credit.franchiseeMB'),
                key: 'processData',
                dataIndex: 'processData',
                editable: true,
                isRequired: true,
              },
            ]}
            field="MB"
          />
        ),
      },
    ];
  }, [t, isUpload, devicesToExchangeData]);

  const renderNumOfDevices = useMemo(
    () => (
      <div>
        <p
          style={{
            opacity: 0.8,
            textAlign: 'center',
            marginTop: 16,
            fontSize: '0.9rem',
            color: 'var(--primary-color)',
          }}
        >
          <span style={{ marginRight: 8, fontSize: '0.9rem' }}>
            <InfoCircleOutlined />
          </span>

          <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
            {t('Operator-info')}
          </span>
        </p>
        <Skeleton
          loading={!numMaxData}
          paragraph={false}
          active
          style={{ marginTop: 24 }}
        >
          <p
            style={{
              opacity: 0.8,
              textAlign: 'center',
              marginTop: 16,
              fontSize: '0.8rem',
              color:
                selectedDevices?.length >
                  numMaxData?.subscriberReprogrammingFileLength ?? 0
                  ? 'red'
                  : 'var(--primary-color)',
            }}
          >
            <span style={{ marginRight: 8, fontSize: '0.9rem' }}>
              <InfoCircleOutlined />
            </span>
            {`${t('max-num-of-devices')}: `}

            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
              {t('num-of-devices', {
                count: numMaxData?.subscriberReprogrammingFileLength ?? 0,
              })}
            </span>
          </p>
        </Skeleton>
      </div>
    ),
    [numMaxData, t, selectedDevices],
  );

  const selectedMoreThenTenStep = useMemo(() => {
    return {
      title: 'Informação',
      content: renderNumOfDevices,
    };
  }, [renderNumOfDevices]);

  // --------------------------------------------------
  // ---------------- DEFAULT STEPS -------------------
  // --------------------------------------------------

  const uploadStep = useMemo(
    () => ({
      title: t('steps.upload'),
      content: (
        <>
          <Space>
            <Text>
              {t('select-devices')}
              <Tooltip placement={'right'} title={t('help')}>
                <Button
                  icon={<QuestionCircleOutlined />}
                  size={'small'}
                  type={'link'}
                  onClick={info}
                />
              </Tooltip>
            </Text>
          </Space>
          <FileUploader
            fileList={uploadedFile ? [uploadedFile] : undefined}
            style={{ marginTop: 10 }}
            onUpload={setUploadedFile}
          />
          {actionKey === ActionTypes.HLR_REPROG && renderNumOfDevices}
        </>
      ),
    }),
    [info, t, uploadedFile, renderNumOfDevices, actionKey],
  );

  const validationStep = useMemo(() => {
    const count =
      isDoubleAction && !isUpload
        ? lockUnlockSelectDevicesByType?.length
        : selectedDevices?.length;

    let paragraphModel = 'action-confirm-message';

    const isManualActivation = actionKey === ActionTypes.MANUAL_ACTIVATION;
    const isForceReactivation = actionKey === ActionTypes.FORCE_REACTIVATION;

    if (isManualActivation) {
      paragraphModel = 'steps.manual-activation.validation-description';
    }
    if (isForceReactivation) {
      paragraphModel = 'steps.force-reactivation.validation-description';
    }

    return {
      title: t('steps.validation'),
      content: (
        <Spin spinning={parsingFile} tip={'Processing file'}>
          <div className="action-validation">
            <Title level={4}>
              {t('selected-device', {
                count,
              })}
            </Title>
            <Paragraph style={{ textAlign: 'center' }}>
              {t(paragraphModel, {
                actionKey: t(`device-actions:labels.${actionKeyTitle}`),
                count,
              })}
            </Paragraph>
          </div>
        </Spin>
      ),
    };
  }, [
    parsingFile,
    selectedDevices?.length,
    t,
    isDoubleAction,
    lockUnlockSelectDevicesByType?.length,
    isUpload,
    actionKeyTitle,
    actionKey,
  ]);

  const handleCloseModal = useCallback(() => {
    clearStates();
    onClose(true);
    setIsLastStep(IS_LAST_STEP_DEFAULT);
  }, [clearStates, onClose]);

  const hasLastStep = useMemo(
    () =>
      [ActionTypes.SUSPENSION, ActionTypes.FORCE_REACTIVATION].includes(
        actionKeyTitle,
      ),
    [actionKeyTitle],
  );

  const lastStep = useMemo(() => {
    let title = t('device-actions:messages.action-success', {
      action: t(`device-actions:labels.${actionKeyTitle}`),
    });

    let subTitle = t(`steps.last-step.success.sub-titles.${actionKeyTitle}`);

    if (isLastStep?.type === 'error') {
      title = t('device-actions:messages.action-error', {
        action: t(`device-actions:labels.${actionKeyTitle}`),
      });
      subTitle = t(`steps.last-step.error.sub-titles.${actionKeyTitle}`);
    }

    return (
      <Result
        status={isLastStep?.type}
        title={title}
        className={`device-actions-last-step${
          isLastStep?.type === 'error' ? '-error' : ''
        }`}
        subTitle={subTitle}
        extra={[
          <Button key="finish" onClick={handleCloseModal}>
            {t('steps.last-step.buttons.finish')}
          </Button>,
          isLastStep?.type === 'success' && (
            <Button
              type="primary"
              key="console"
              onClick={() => {
                navigate(`/management/actions-queue?action=${actionKeyTitle}`);
              }}
            >
              {t('steps.last-step.buttons.go-to-action-queue')}
            </Button>
          ),
        ]}
      />
    );
  }, [actionKeyTitle, t, handleCloseModal, isLastStep, navigate]);
  // --------------------------------------------------
  // --------------------------------------------------
  // --------------------------------------------------

  const specificStepsOfActions = useMemo(() => {
    switch (actionKey) {
      case ActionTypes.ACTIVATION:
        return activationSteps;
      case ActionTypes.TRANSFER:
        return transfersSteps;
      case ActionTypes.NICKNAME_CHANGE:
        return nicknameChangeSteps;
      case ActionTypes.NETWORK_BLOCK:
        return networkBlockSteps;
      case ActionTypes.NETWORK_UNLOCK:
        return networkUnlockSteps;
      case ActionTypes.NETWORK_BLOCK_UNLOCK:
        if (lockUnlockType === 'block') {
          return networkBlockSteps;
        }
        if (lockUnlockType === 'unlock') {
          return networkUnlockSteps;
        }
        return [];
      case ActionTypes.SUSPENSION_WITHDRAWAL:
        if (lockUnlockType === 'block') {
          return suspensionSteps;
        }
        if (lockUnlockType === 'unlock') {
          return withdrawalSteps;
        }
        return [];

      case ActionTypes.MSISDN_EXCHANGE:
        return msidnExchangeSteps;
      case ActionTypes.MOT_OPERATOR_CHANGE:
        return operatorChangeSteps;
      case ActionTypes.PLASTIC_EXCHANGE:
        return plasticExchangeSteps;
      case ActionTypes.ADD_CREDIT_TO_SIMCARD:
        return addCreditSteps;
      case ActionTypes.BROKER_CANCELLATION:
      case ActionTypes.BROKER_SUBSTITUTION:
      case ActionTypes.BROKER_CANCELLATION_WAIVER:
        return brokerCancellationSteps;
      case ActionTypes.SMS_SEND:
        return smsSendSteps;
      case ActionTypes.HLR_REPROG:
        if (devices) {
          return [selectedMoreThenTenStep];
        }
        return [];
      default:
        return [];
    }
  }, [
    actionKey,
    activationSteps,
    transfersSteps,
    nicknameChangeSteps,
    networkUnlockSteps,
    lockUnlockType,
    networkBlockSteps,
    suspensionSteps,
    withdrawalSteps,
    plasticExchangeSteps,
    addCreditSteps,
    msidnExchangeSteps,
    brokerCancellationSteps,
    operatorChangeSteps,
    smsSendSteps,
    selectedMoreThenTenStep,
    devices,
  ]);

  const steps = useMemo(() => {
    const modalSteps = [];

    if (isUpload) {
      if (isDoubleAction) {
        modalSteps.push(lockUnlockStep);
      }
      modalSteps.push(uploadStep);
    } else {
      if (isDoubleAction) {
        modalSteps.push(lockUnlockStep);
      }
    }
    modalSteps.push(...specificStepsOfActions);
    validationStep && modalSteps.push(validationStep);
    return modalSteps;
  }, [
    specificStepsOfActions,
    isUpload,
    uploadStep,
    validationStep,
    isDoubleAction,
    lockUnlockStep,
  ]);

  // if attributes = ['msisdn', 'iccid']
  // output [{ msisdn: xxx...xxx, iccid: xxx...xxx }]
  const setDevicesToPayload = useCallback(
    attributes =>
      selectedDevices?.map(device => {
        let attrInObj = {};
        attributes.forEach(attr => {
          attrInObj = {
            ...attrInObj,
            [attr]: device[attr],
          };
        });
        return attrInObj;
      }),
    [selectedDevices],
  );

  const handlePrepareNetworkBlockAction = useCallback(
    (type, payload) => {
      payload.action = type === 'Block' ? 20 : 21;

      const currentDate = getDateInISOFormat(moment().toISOString());

      if (isUpload) {
        payload.devices = selectedDevices.map(({ msisdn, ...device }) => {
          cleanObject(device);
          if (!device.tpBloqueio) {
            device.dtDesbloqueio = device.dtDesbloqueio ?? currentDate;
          } else {
            device.dtInicioBloqueio = device.dtInicioBloqueio ?? currentDate;
          }
          return {
            msisdn,
            processData: JSON.stringify({ ...device }),
          };
        });
        return payload;
      }

      if (type === 'Block') {
        payload.devices = setDevicesToPayload(['msisdn']);
        payload.devices = selectedDevices.map(device => ({
          msisdn: device.msisdn,
          processData: JSON.stringify({
            tpBloqueio: blockType,
            dtInicioBloqueio: blockUnlockInitialDate ?? currentDate,
            dtFimBloqueio: blockUnlockFinalDate,
          }),
        }));
      } else {
        payload.devices = selectedDevices.map(device => ({
          msisdn: device.msisdn,
          processData: JSON.stringify({
            dtDesbloqueio: blockUnlockInitialDate ?? currentDate,
          }),
        }));
      }
      return payload;
    },
    [
      blockType,
      blockUnlockFinalDate,
      blockUnlockInitialDate,
      setDevicesToPayload,
      selectedDevices,
      isUpload,
    ],
  );

  const handlePrepareImeiLockUnlockAction = useCallback(
    devicesData =>
      devicesData?.map(device => {
        const devicePayload = {
          msisdn: device.msisdn,
        };

        if (device.imei) {
          devicePayload.processData = device.imei;
        }

        return devicePayload;
      }),
    [],
  );

  const prepareAndSendAction = useCallback(async () => {
    let payload = {};
    payload.action = actionKey;

    switch (actionKey) {
      case ActionTypes.ACTIVATION:
        payload.devices = uploadedDevices;
        payload.contractId = selectedContract;
        await sendDeviceAction({ axios, payload, username });
        break;

      case ActionTypes.NETWORK_RESET:
      case ActionTypes.MANUAL_ACTIVATION:
      case ActionTypes.FORCE_REACTIVATION:
        payload.devices = setDevicesToPayload(['msisdn']);
        await sendDeviceAction({ axios, payload, username });
        break;

      case ActionTypes.SIMCARD_RESET:
        payload.devices = setDevicesToPayload(['msisdn', 'iccid']);
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.TRANSFER:
        payload = isUpload
          ? selectedDevices
          : selectedDevices?.map(device => ({
              msisdn: device.msisdn,
              iccid: device.iccid,
              newCustomerVirtualAccountId: selectedRequestId,
            }));
        await axios.patch('/service-proxy/sims/transfer', payload);
        break;
      case ActionTypes.NETWORK_BLOCK_UNLOCK:
        if (lockUnlockType === 'block') {
          payload = handlePrepareNetworkBlockAction('Block', payload);
        } else {
          payload = handlePrepareNetworkBlockAction('Unlock', payload);
        }
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.NICKNAME_CHANGE:
        payload.devices = !isUpload
          ? selectedDevices.map(device => ({
              msisdn: device.msisdn,
              iccid: device.iccid,
              processData: chosenNickname,
            }))
          : uploadedDevices;
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.IMEI_LOCK_UNLOCK:
        payload.action =
          lockUnlockType === 'block'
            ? ActionTypes.IMEI_LOCK
            : ActionTypes.IMEI_UNLOCK;
        payload.devices = handlePrepareImeiLockUnlockAction(selectedDevices);
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.SUSPENSION_WITHDRAWAL:
        if (lockUnlockType === 'block') {
          payload.action = ActionTypes.SUSPENSION;
        } else if (lockUnlockType === 'cancell') {
          payload.action = ActionTypes.SUSPENSION_CANCELL;
        } else {
          payload.action = ActionTypes.WITHDRAWAL;
        }
        payload.devices = selectedDevices?.map(({ msisdn }) => ({
          msisdn,
        }));
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.MOT_OPERATOR_CHANGE:
        payload.devices = !isUpload
          ? devicesToExchangeData.map(({ msisdn, processData }) => ({
              msisdn,
              vendor: processData,
            }))
          : uploadedDevices;
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.ADD_CREDIT_TO_SIMCARD:
        payload.devices = !isUpload
          ? devicesToExchangeData.map(({ msisdn, processData }) => ({
              msisdn,
              processData,
            }))
          : uploadedDevices;
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.MSISDN_EXCHANGE:
      case ActionTypes.PLASTIC_EXCHANGE:
        payload.devices = !isUpload
          ? devicesToExchangeData.map(({ msisdn, iccid, processData }) => ({
              msisdn,
              iccid,
              processData,
            }))
          : uploadedDevices;
        await sendDeviceAction({ axios, payload, username });
        break;
      case ActionTypes.MOT_DEACTIVATION:
        payload.contractId = selectedContract;
        payload.devices = !isUpload
          ? selectedDevices.map(device => ({
              msisdn: device.msisdn,
              iccid: device.iccid,
            }))
          : uploadedDevices;
        await sendDeviceAction({ axios, payload, username });
        break;

      case ActionTypes.BROKER_CANCELLATION:
        if (lockUnlockType === 'block') {
          payload.action = ActionTypes.BROKER_CANCELLATION;
        }
        if (lockUnlockType === 'unlock') {
          payload.action = ActionTypes.BROKER_CANCELLATION_WAIVER;
        }
        if (cancelationType === 'substitution') {
          payload.action = ActionTypes.BROKER_SUBSTITUTION;
        }
        payload.devices = !isUpload
          ? selectedDevices.map(device => ({
              msisdn: device.msisdn,
            }))
          : uploadedDevices;
        await sendDeviceAction({ axios, payload });
        break;
      case ActionTypes.SMS_SEND:
        payload.msisdns = !isUpload
          ? selectedDevices.map(device => device.msisdn)
          : uploadedDevices.map(device => device.msisdn);
        payload.from = from;
        payload.flash = flash === 'flash';
        payload.text = text;
        payload.action = undefined;

        if (validityPeriod || validityPeriod === 0) {
          payload.validityPeriod = validityPeriod > 0 ? validityPeriod * 60 : 0;
        }
        if (sendAt) {
          payload.sendAt = sendAt.toISOString();
        }
        if (days && days.length > 0) {
          payload.deliveryTimeWindow = {
            days: days,
            from: moment(fromDate).format('HH:mm'),
            to: moment(toDate).format('HH:mm'),
          };
        }
        await sendDeviceAction({ axios, payload, smsSend: true });
        break;

      case ActionTypes.HLR_REPROG:
        payload.devices = !isUpload
          ? selectedDevices.map(device => {
              return { msisdn: device.msisdn };
            })
          : uploadedDevices.map(device => {
              return { msisdn: device.msisdn };
            });
        await sendDeviceAction({ axios, payload });
        break;
      default:
        break;
    }

    setSendActionLoading(false);
  }, [
    actionKey,
    selectedRequestId,
    axios,
    selectedContract,
    selectedDevices,
    uploadedDevices,
    username,
    setDevicesToPayload,
    handlePrepareNetworkBlockAction,
    lockUnlockType,
    chosenNickname,
    isUpload,
    handlePrepareImeiLockUnlockAction,
    devicesToExchangeData,
    cancelationType,
    from,
    flash,
    text,
    validityPeriod,
    sendAt,
    days,
    fromDate,
    toDate,
  ]);

  const navigateToTransfers = useCallback(() => {
    message.destroy('send-action');
    navigate(`/management/transfer`);
  }, [navigate]);

  const handleConfirmAction = useCallback(async () => {
    setSendActionLoading(true);
    try {
      message.loading({
        content: t('device-actions:messages.action-loading', {
          action: t(`device-actions:labels.${actionKeyTitle}`),
        }),
        key: 'send-action',
      });
      await prepareAndSendAction();
      if (hasLastStep) {
        message.destroy('send-action');
        setIsLastStep({ visible: true, type: 'success' });
      } else {
        message.success({
          content:
            actionKey === ActionTypes.TRANSFER ? (
              <>
                {t('device-actions:messages.action-transfer-success')}
                {pathname !== '/management/transfer' && (
                  <>
                    {', '}
                    <Button
                      type="link"
                      style={{ padding: 0 }}
                      onClick={navigateToTransfers}
                    >
                      {t('device-actions:messages.followHere')}
                    </Button>
                  </>
                )}
              </>
            ) : (
              t('device-actions:messages.action-success', {
                action: t(`device-actions:labels.${actionKeyTitle}`),
              })
            ),
          key: 'send-action',
        });
      }
    } catch {
      if (hasLastStep) {
        message.destroy('send-action');
        setIsLastStep({ visible: true, type: 'error' });
      } else {
        message.error({
          content: t('device-actions:messages.action-error', {
            action: t(`device-actions:labels.${actionKeyTitle}`),
          }),
          key: 'send-action',
        });
      }
    }
    setSendActionLoading(false);
    if (!hasLastStep) {
      clearStates();
      onClose(true);
    }
  }, [
    prepareAndSendAction,
    t,
    actionKeyTitle,
    actionKey,
    hasLastStep,
    navigateToTransfers,
    pathname,
    clearStates,
    onClose,
  ]);

  const disabledButtonNext = useMemo(() => {
    const isSelectDevicesEmpty = selectedDevices?.length === 0;
    const isAllFieldsFilledInDataExchange = devicesToExchangeData?.every(
      device => device.processData,
    );

    switch (actionKey) {
      case ActionTypes.NETWORK_BLOCK:
        return blockType === '3' && !blockUnlockFinalDate;
      case ActionTypes.NETWORK_BLOCK_UNLOCK:
        return (
          (current === 0 && !lockUnlockType) ||
          (current === 1 && isSelectDevicesEmpty) ||
          (current === 1 && blockType === '3' && !blockUnlockFinalDate)
        );
      case ActionTypes.IMEI_LOCK_UNLOCK:
      case ActionTypes.SUSPENSION_WITHDRAWAL:
        return (
          (current === 0 && !lockUnlockType) ||
          (current === 1 && isSelectDevicesEmpty)
        );
      case ActionTypes.NICKNAME_CHANGE:
        return isSelectDevicesEmpty || (!isUpload && !chosenNickname);
      case ActionTypes.ADD_CREDIT_TO_SIMCARD:
      case ActionTypes.MOT_OPERATOR_CHANGE:
        return isSelectDevicesEmpty;
      case ActionTypes.MSISDN_EXCHANGE:
      case ActionTypes.PLASTIC_EXCHANGE:
        return (
          isSelectDevicesEmpty ||
          (!isUpload && !isAllFieldsFilledInDataExchange)
        );
      case ActionTypes.ACTIVATION:
        return isSelectDevicesEmpty || (current === 1 && !selectedContract);
      case ActionTypes.MOT_DEACTIVATION:
        return (
          isSelectDevicesEmpty ||
          (!isUpload && current === 0 && !selectedContract) ||
          (current === 1 && !selectedContract)
        );
      case ActionTypes.TRANSFER:
        return (
          isSelectDevicesEmpty ||
          (!isUpload &&
            current === 0 &&
            !selectedCustomerId &&
            selectedRequestId !== 0) ||
          (current === 1 && !selectedContractId) ||
          (current === 2 && !selectedRequestId)
        );
      case ActionTypes.BROKER_CANCELLATION_WAIVER:
      case ActionTypes.BROKER_CANCELLATION:
        return (
          (current === 0 && !lockUnlockType) ||
          (current === 1 && isSelectDevicesEmpty)
        );
      case ActionTypes.SMS_SEND:
        return (
          (current === 0 && text?.length < 1 && !isUpload) ||
          (current === 0 && isSelectDevicesEmpty && !isUpload) ||
          (current === 0 && uploadedDevices?.length === 0 && isUpload) ||
          (current === 1 && schedule === 'schule' && !sendAt) ||
          (current === 1 && specifyDate === 'true' && days.length === 0)
        );
      case ActionTypes.HLR_REPROG:
        return (
          !selectedDevices?.length ||
          selectedDevices?.length === 0 ||
          selectedDevices?.length >
            (numMaxData?.subscriberReprogrammingFileLength ?? 0)
        );
      default:
        return current === 0 && isSelectDevicesEmpty;
    }
  }, [
    actionKey,
    blockType,
    current,
    selectedDevices?.length,
    blockUnlockFinalDate,
    lockUnlockType,
    chosenNickname,
    selectedContract,
    isUpload,
    selectedContractId,
    selectedCustomerId,
    selectedRequestId,
    devicesToExchangeData,
    text,
    schedule,
    sendAt,
    specifyDate,
    days,
    uploadedDevices,
    numMaxData,
  ]);

  const modalFooter = useMemo(
    () => (
      <div key={'0'} className={'steps-action'}>
        {current > 0 && (
          <Button
            className={'button-previous'}
            onClick={() => setCurrent(current - 1)}
          >
            {t('previous')}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            disabled={disabledButtonNext}
            type={'primary'}
            onClick={() => setCurrent(current + 1)}
          >
            {t('next')}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            disabled={isUpload && parsingFile}
            loading={sendActionLoading}
            type={'primary'}
            onClick={handleConfirmAction}
          >
            {t('confirm')}
          </Button>
        )}
      </div>
    ),
    [
      current,
      disabledButtonNext,
      handleConfirmAction,
      isUpload,
      parsingFile,
      sendActionLoading,
      steps.length,
      t,
    ],
  );

  return (
    <Styled.ModalContainer
      footer={isLastStep?.visible ? false : modalFooter}
      title={t(`device-actions:labels.${actionKeyTitle}`)}
      visible={visible}
      onCancel={handleCloseModal}
      width={actionKeyTitle === ActionTypes.SUSPENSION ? '680px' : '600px'}
    >
      {isLastStep?.visible
        ? lastStep
        : actionKey > -1 && (
            <>
              <Steps current={current} size={'small'}>
                {steps?.map(item => (
                  <Step key={item?.title} title={item?.title} />
                ))}
              </Steps>
              <div className={'steps-content'}>{steps?.[current]?.content}</div>
            </>
          )}
    </Styled.ModalContainer>
  );
};

export default DeviceActionModal;
