import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';

const CsvExport = ({
  data,
  filename,
  separator = ';',
  columns,
  children = undefined,
  disable = false,
}) => {
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const csvInstance = useRef();

  useEffect(() => {
    const instance = csvInstance.current;
    if (csvData.length > 0 && instance) {
      setTimeout(() => {
        instance?.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  return (
    <>
      <div
        style={{
          display: 'inline',
        }}
        onClick={async () => {
          if (disable) {
            return;
          }

          const head = columns.map(col => ({ label: col.title, key: col.key }));
          setHeaders(head);

          setCsvData(data);
        }}
      >
        {children}
      </div>
      {csvData && (
        <CSVLink
          ref={csvInstance}
          data={csvData}
          filename={filename}
          headers={headers}
          separator={separator}
          style={{ background: 'red' }}
        />
      )}
    </>
  );
};

export default CsvExport;
