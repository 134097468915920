import styled, { css } from 'styled-components';

export const ModalContent = styled.div`
  .ant-form-item {
    margin: 0;
  }

  .ant-steps-item-wait {
    opacity: 0.5;

    .ant-select-selection-search-input {
      pointer-events: none !important;
      outline: none;
      text-indent: -9999px;
    }
  }

  .action-modal-item-error,
  .ant-form-item-explain-error {
    font-size: 0.8rem;
    color: red;
  }

  .action-modal-description-text {
    font-size: 0.85rem;
  }

  .ant-divider {
    margin: 16px 0;
  }

  .ant-alert {
    opacity: 0.8;
    .anticon {
      font-size: 1.3rem;
    }
    .ant-alert-description {
      font-size: 0.8rem;
    }
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ totalDevices }) =>
    totalDevices > 0 ? 'space-between' : 'flex-end'};

  .action-modal-footer-total-devices {
    font-size: 0.85rem;
    opacity: 0.6;
  }

  @media (max-width: 360px) {
    ${({ totalDevices }) =>
      totalDevices > 0 &&
      css`
        justify-content: center;
      `}

    .action-modal-footer-total-devices {
      margin-bottom: 8px;
    }
  }
`;
