import { formatCurrency } from '@src/utils/formatters';

export const getModelDataColumns = t => [
  {
    title: t('items-modal.model-columns.customerId'),
    key: 'customerId',
    dataIndex: 'customerId',
    editable: true,
  },
  {
    title: t('items-modal.model-columns.invoiceId'),
    key: 'invoiceId',
    dataIndex: 'invoiceId',
    editable: true,
  },
  {
    title: t('items-modal.model-columns.itemDesc'),
    key: 'description',
    dataIndex: 'description',
    editable: true,
  },
  {
    title: t('items-modal.model-columns.quantity'),
    key: 'quantity',
    dataIndex: 'quantity',
    render: value => Number(value),
    editable: true,
  },
  {
    title: t('items-modal.model-columns.price'),
    key: 'value',
    dataIndex: 'value',
    render: value => formatCurrency(Number(value)),
    editable: true,
  },
  {
    title: t('items-modal.model-columns.totalValue'),
    key: 'totalValue',
    dataIndex: 'totalValue',
    render: value => formatCurrency(Number(value)),
  },
];

export const getModelData = t => [
  {
    key: '1',
    invoiceId: '0',
    customerId: 0,
    description: t('items-modal.model-columns.dsPlaceholder'),
    quantity: 1,
    value: 0,
    totalValue: 0,
  },
];
