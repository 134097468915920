import React, { useEffect, useMemo } from 'react';
import { LorawanActionTypes } from '@utils/deviceActions';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import TagsManagement from '@components/TagsManagement';
import rolesConstants from '@utils/rolesConstants';

const useChangeTags = ({ devices, isUpload, form }) => {
  const { t } = useTranslation(['lorawan', 'device-actions']);
  const tags = Form.useWatch('tags', form);

  useEffect(() => {
    if (devices?.length === 1 && devices?.[0]?.tags) {
      form.setFieldValue('tags', devices[0].tags);
    }
  }, [devices, form]);

  const steps = useMemo(
    () => [
      {
        title: t('newTags'),
        name: 'tags',
        content: (
          <TagsManagement
            style={{ padding: '0 4px' }}
            tags={tags}
            onChange={newTags => {
              form.setFieldValue('tags', newTags);
            }}
          />
        ),
      },
    ],
    [form, t, tags],
  );

  const payloadToSend = useMemo(
    () => ({
      action: LorawanActionTypes.CHANGE_TAGS,
      devices: isUpload
        ? devices
        : devices?.map(device => ({
            devEui: device.devEui,
            tags,
          })),
    }),
    [tags, devices, isUpload],
  );

  return {
    steps,
    payloadToSend,
    title: t(
      `device-actions:labels.lorawan.${rolesConstants.LORAWAN_CHANGE_TAGS}`,
    ),
    totalDevices: devices?.length,
  };
};

export default useChangeTags;
