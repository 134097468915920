import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import SortableList from '../SortableList';

const ModalSelectColumns = ({ visible, onClose, columns, updateColumns }) => {
  const { t } = useTranslation('modal-columns-settings');

  const [saveLoading, setSaveLoading] = useState(false);
  const [listSwitch, setListSwitch] = useState(columns);

  useEffect(() => {
    setListSwitch(columns);
  }, [columns]);

  const onFinish = useCallback(() => {
    setSaveLoading(true);
    updateColumns(listSwitch);
    setSaveLoading(false);
    onClose();
  }, [listSwitch, updateColumns, onClose]);

  const handleListChange = useCallback(newList => {
    setListSwitch(newList);
  }, []);

  const handleClose = useCallback(() => {
    setListSwitch(columns);
    onClose();
  }, [columns, onClose]);

  const handleListItemChange = useCallback(
    indexToChange => {
      setListSwitch([
        ...listSwitch.map((item, indexItem) =>
          indexItem === indexToChange
            ? { ...item, visible: !item.visible }
            : item,
        ),
      ]);
    },
    [listSwitch],
  );

  return (
    <Modal
      bodyStyle={{ minHeight: 200 }}
      footer={
        <>
          <Button onClick={() => onClose()}>{t('cancel-button')}</Button>
          <Button loading={saveLoading} type={'primary'} onClick={onFinish}>
            {t('save-button')}
          </Button>
        </>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerForm', htmlType: 'submit' }}
      style={{ maxWidth: 900 }}
      title={t('columns')}
      visible={visible}
      width={'70%'}
      centered
      onCancel={handleClose}
    >
      <Row>
        <SortableList
          items={listSwitch}
          switchItemChange={handleListItemChange}
          onChange={handleListChange}
        />
      </Row>
    </Modal>
  );
};

export default ModalSelectColumns;
