import { Card } from '@components/ui';
import BackToListing from '@src/components/BackToListing/BackToListing';
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreditsBuyTab } from './CreditsBuyTab';
import { HistoricTab } from './HistoricTab';

const { TabPane } = Tabs;

const Credits = () => {
  const { t: translate } = useTranslation('schedules');

  return (
    <Card title={<BackToListing title={translate('credits')} />}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={translate('purchaseCredits')} key="1">
          <CreditsBuyTab />
        </TabPane>
        <TabPane tab={translate('history')} key="2">
          <HistoricTab />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default Credits;
