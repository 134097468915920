import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { Input, ConfigProvider, Col, Tag, Button, Tooltip, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/ui';
import Table from '@components/Table';

import {
  dateFormat,
  extractDateInISOFormat,
  formatBRNumber,
  getDateInISOFormat,
  getDateToBRPattern,
  getDateWithISOFormat,
} from '@utils/formatters';
import { Filters, FiltersCard } from '@components/Filters';
import 'moment/locale/pt-br';
import { getAntLocale } from '@utils/locales';
import useSwr from '@hooks/useSwr';
import { actionTypeOptions } from '@utils/deviceActions';
import moment from 'moment';
import useSearchParams from '@src/hooks/useSearchParams';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import { useNavigate } from 'react-router-dom';

import StatusMenu from '@src/components/StatusMenu/StatusMenu';
import SessionContext from '@src/store/SessionContext/SessionContext';
import BlockStatusTag from '@src/components/BlockStatusTag/BlockStatusTag';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const ActionsQueue = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['actions-queue', 'device-actions']);

  const {
    mainColor,
    customer: { customersOptions },
  } = useContext(SessionContext);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [actionValue, setActionValue] = useState();
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [msisdnValue, setMsisdnValue] = useState('');
  const [iccidValue, setIccidValue] = useState('');
  const [imsiValue, setImsiValue] = useState('');
  const [createdByValue, setCreatedByValue] = useState('');
  const [selectedClient, setSelectedClient] = useState();

  const [msisdn, setMsisdn] = useState('');
  const [iccid, setIccid] = useState('');
  const [imsi, setImsi] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [status, setStatus] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const defaultParams = useMemo(() => {
    return {
      startDate: initialDate !== '' ? initialDate : undefined,
      endDate: endDate !== '' ? endDate : undefined,
      msisdn: msisdn !== '' ? msisdn : undefined,
      iccid: iccid !== '' ? iccid : undefined,
      imsi: imsi !== '' ? imsi : undefined,
      createdBy: createdBy !== '' ? createdBy : undefined,
      customerIdDestination: selectedClient,
    };
  }, [initialDate, endDate, msisdn, imsi, createdBy, selectedClient, iccid]);

  const { data } = useSwr(
    '/service-proxy/actions',
    {
      action: actionValue,
      page: pagination?.current - 1,
      linesPerPage: pagination.pageSize,
      status,
      ...defaultParams,
    },
    { refreshInterval: 10 * 1000, revalidateOnFocus: false },
  );

  const { data: dataCountByType } = useSwr(
    '/service-proxy/actions/count-by-type',
    undefined,
    { refreshInterval: 10 * 1000 },
  );

  const { data: dataCountByStatus } = useSwr(
    actionValue ? '/service-proxy/actions/count-by-status' : null,
    {
      action: actionValue,
      ...defaultParams,
    },
    { refreshInterval: 10 * 1000 },
  );

  // convert processData attr in new names, 'actionsWithProcessDataToShow'
  const dataTreated = useMemo(() => {
    if (!data) return;

    const actionsWithProcessDataToShow = {
      16: 'nickname',
      20: 'networkBlockType',
      21: 'networkUnlockDate',
      22: 'imei',
      23: 'imei',
      24: 'suspensionDate',
      25: 'suspensionDate',
      26: 'newIccid',
      28: 'newMsisdn',
      31: 'blockedCustomer',
      32: 'unlockedCustomer',
      33: 'franchiseeMB',
      41: 'cancellationDate',
      43: 'substitutionDate',
    };

    return {
      ...data,
      content: data.content?.map(currentAction => {
        if (currentAction.processData) {
          const dataInProcessDataName =
            actionsWithProcessDataToShow[currentAction.actionType];

          if (dataInProcessDataName) {
            const { processData, ...action } = currentAction;
            return {
              ...action,
              [dataInProcessDataName]: processData,
            };
          }
        }
        return currentAction;
      }),
    };
  }, [data]);

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'action',
        setState: setActionValue,
        inTheFilters: false,
      },
      {
        name: 'initialDate',
        setState: setInitialDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
      {
        name: 'msisdn',
        setState: setMsisdn,
        inTheFilters: true,
      },
      {
        name: 'iccid',
        setState: setIccid,
        inTheFilters: true,
      },
      {
        name: 'imsi',
        setState: setImsi,
        inTheFilters: true,
      },
      {
        name: 'createdBy',
        setState: setCreatedBy,
        inTheFilters: true,
      },
      {
        name: 'customerIdDestination',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setMsisdnValue(msisdn);
  }, [msisdn]);

  useEffect(() => {
    setIccidValue(iccid);
  }, [iccid]);

  useEffect(() => {
    setImsiValue(imsi);
  }, [imsi]);

  useEffect(() => {
    setCreatedByValue(createdBy);
  }, [createdBy]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [
    msisdn,
    iccid,
    imsi,
    createdBy,
    initialDate,
    endDate,
    actionValue,
    status,
    selectedClient,
  ]);

  useEffect(() => {
    if (dataTreated) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: dataTreated.totalElements,
      }));
    }
  }, [dataTreated]);

  const formatterOperator = useCallback(
    operators =>
      operators.replaceAll(',', ' ').replace('[', '').replace(']', ''),
    [],
  );

  const allColumnsByAction = useMemo(() => {
    const allDataIndex = [];
    if (dataTreated) {
      dataTreated?.content?.forEach(line => {
        allDataIndex.push(...Object.keys(line));
      }, []);
    }

    return [...new Set(allDataIndex)]; // Remove duplicated values
  }, [dataTreated]);

  const handleNetworkBlockType = useCallback(value => {
    if (!value) return;
    const { tpBloqueio, dtInicioBloqueio, dtFimBloqueio } = JSON.parse(value);

    return (
      <BlockStatusTag
        blockType={tpBloqueio}
        blockingInitialDate={dtInicioBloqueio}
        blockingFinalDate={dtFimBloqueio}
      />
    );
  }, []);

  const handleSuspensionDate = useCallback(
    value => {
      if (!value) return;
      const { dtInicioSuspensao, dtTerminoSuspensao } = JSON.parse(value);

      const start = dtInicioSuspensao && getDateToBRPattern(dtInicioSuspensao);
      const end = dtTerminoSuspensao && getDateToBRPattern(dtTerminoSuspensao);

      if (start && end) {
        return `${start} ${t('to')} ${end}`;
      }
      if (start) {
        return `${t('from')} ${start}`;
      }

      return `${t('upUntil')} ${end}`;
    },
    [t],
  );
  const navigate = useNavigate();
  const allColumns = useMemo(
    () => [
      { title: t('id'), dataIndex: 'id' },
      {
        title: t('actionType'),
        dataIndex: 'actionType',
        render: action => t(`device-actions:labels.${action}`),
      },
      {
        title: t('networkBlockType'),
        dataIndex: 'networkBlockType',
        render: handleNetworkBlockType,
      },
      {
        title: t('cancellationDate'),
        dataIndex: 'cancellationDate',
        render: value => {
          if (!value) return;
          return extractDateInISOFormat(getDateWithISOFormat(value));
        },
      },
      {
        title: t('substitutionDate'),
        dataIndex: 'substitutionDate',
        render: value => {
          if (!value) return;

          return extractDateInISOFormat(getDateWithISOFormat(value));
        },
      },
      {
        title: t('networkUnlockDate'),
        dataIndex: 'networkUnlockDate',
        render: value => {
          if (!value) return;
          const unlockDate = JSON.parse(value).dtDesbloqueio;
          return extractDateInISOFormat(getDateWithISOFormat(unlockDate));
        },
      },
      {
        title: t('suspensionDate'),
        dataIndex: 'suspensionDate',
        render: handleSuspensionDate,
      },
      { title: t('protocol'), dataIndex: 'protocol' },
      { title: t('nickname'), dataIndex: 'nickname' },
      { title: t('imei'), dataIndex: 'imei' },
      { title: t('idContract'), dataIndex: 'idContract' },
      { title: t('iccid'), dataIndex: 'iccid' },
      { title: t('imsi'), dataIndex: 'imsi' },
      { title: t('msisdn'), dataIndex: 'msisdn' },
      { title: t('newIccid'), dataIndex: 'newIccid' },
      { title: t('newMsisdn'), dataIndex: 'newMsisdn' },
      {
        title: t('franchiseeMB'),
        dataIndex: 'franchiseeMB',
        render: value => value && value !== 'null' && `${value} MB`,
      },
      {
        title: t('blockedCustomer'),
        dataIndex: 'blockedCustomer',
        render: value => value && JSON.parse(value)?.name,
      },
      {
        title: t('unlockedCustomer'),
        dataIndex: 'unlockedCustomer',
        render: value => value && JSON.parse(value)?.name,
      },
      {
        title: t('vendor'),
        dataIndex: 'operator',
        render: (_, record) =>
          record.operator ? formatterOperator(record.operator) : record.vendor,
      },
      {
        title: t('processingDate'),
        dataIndex: 'processingDate',
        render: value => dateFormat(value),
      },
      {
        title: t('createdAt'),
        dataIndex: 'createdAt',
        render: value => dateFormat(value),
      },
      { title: t('createdBy'), dataIndex: 'createdBy' },
      {
        title: t('status'),
        dataIndex: 'flagProcessed',
        render: (status, action) =>
          t(`device-actions:status.${action.actionType}.${status}`),
      },
      {
        title: t('virtualAccountDest'),
        dataIndex: 'virtualAccountDest',
        render: (_, record) => {
          if (!record.virtualAccountDest) return;
          return (
            <Tooltip title={t('view-request')}>
              <Button
                type="link"
                onClick={() =>
                  navigate(
                    `/financial/requests/${record?.virtualAccountDest?.id}`,
                  )
                }
              >
                {record?.virtualAccountDest?.name}
              </Button>
            </Tooltip>
          );
        },
      },
      {
        title: t('virtualContractDest'),
        dataIndex: 'virtualContractDest',
        render: (_, record) => {
          if (!record.virtualContractDest) return;

          return (
            <Tooltip title={t('view-contract')}>
              <Button
                type={'link'}
                onClick={() =>
                  navigate(
                    `/financial/contracts/${record?.virtualContractDest?.id}`,
                  )
                }
              >
                {record?.virtualContractDest?.planDesc}
              </Button>
            </Tooltip>
          );
        },
      },
      {
        title: t('customerDest'),
        dataIndex: 'customerDest',
        render: (_, record) => {
          if (!record.customerDest) return;
          return `${record.customerDest.name} `;
        },
      },
    ],
    [
      t,
      formatterOperator,
      handleNetworkBlockType,
      handleSuspensionDate,
      navigate,
    ],
  );
  const columns = useMemo(() => {
    if (dataTreated?.content?.length > 0) {
      return allColumns.filter(column =>
        allColumnsByAction.includes(column.dataIndex),
      );
    }
    return allColumns;
  }, [dataTreated?.content?.length, allColumnsByAction, allColumns]);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const initial = getDateInISOFormat(initialDate);
      const end = getDateInISOFormat(endDate);
      handleSetSearchParams({ initialDate: initial, endDate: end });
    },
    [handleSetSearchParams],
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['action'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const getColorStatus = useCallback(statusNum => {
    const treatedStatus = Number(statusNum);
    if (treatedStatus === 0) {
      return '#52c41a';
    }
    if (treatedStatus === 1 || treatedStatus < 0) {
      return '#00afef';
    }
    if (treatedStatus > 1) {
      return '#ff4d4f';
    }
  }, []);

  const actionsOptions = useMemo(() => actionTypeOptions(t), [t]);

  const statusMenuActionsData = useMemo(() => {
    const typeData = actionsOptions
      ?.map(({ label, value }) => {
        const getActionCount = dataCountByType?.find(
          el => Number(el.item) === value,
        )?.value;

        if (!getActionCount) {
          return;
        }

        return {
          label,
          item: value.toString(),
          value: getActionCount ?? 0,
          color: mainColor ?? '#5867dd',
        };
      })
      ?.sort((a, b) => a.value - b.value)
      ?.filter(el => !!el);

    return typeData?.reverse();
  }, [dataCountByType, actionsOptions, mainColor]);

  const statusMenuData = useMemo(() => {
    const statusData = dataCountByStatus
      ?.map(({ item, value }) => ({
        label: t(`device-actions:status.${actionValue}.${item}`),
        item,
        value,
        color: getColorStatus(item),
      }))
      ?.sort((a, b) => a.value - b.value);

    return statusData?.reverse();
  }, [dataCountByStatus, actionValue, getColorStatus, t]);

  const actionsTotal = useMemo(
    () =>
      statusMenuActionsData?.reduce(
        (accumulator, item) => accumulator + (item?.value ?? 0),
        0,
      ),
    [statusMenuActionsData],
  );

  return (
    <div>
      <div
        style={{
          padding: 8,
          background: '#ffffff',
          borderRadius: 6,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type={!actionValue && 'primary'}
            style={{
              margin: '6px 4px',
              display: 'flex',
              justifyContent: 'space-between',
              borderColor: '#f1f1f1',
              fontSize: '0.9rem',
            }}
            onClick={() => handleClearParams(['action', 'status'])}
          >
            {t('device-actions:all-actions')}
          </Button>
          {actionsTotal ? (
            <Tag
              style={{
                fontSize: '1rem',
                padding: '4px 8px',
                marginLeft: 2,
              }}
              color="success"
            >
              {formatBRNumber(actionsTotal)}
            </Tag>
          ) : undefined}
        </div>
        <StatusMenu
          style={{ marginBottom: 16 }}
          itemActive={actionValue}
          data={statusMenuActionsData}
          handleSelectedCard={item =>
            handleSetSearchParams({ action: item }, ['status'])
          }
        />
      </div>
      <Table
        columns={columns}
        allColumns={allColumns}
        data={dataTreated?.content}
        loading={!dataTreated?.content}
        pagination={pagination}
        tableKey={'actions-queue'}
        title={
          <>
            {actionValue
              ? t(`device-actions:labels.${actionValue}`)
              : t('actions')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        onChange={handleTableChange}
        tabsFilters={
          actionValue &&
          statusMenuData?.length > 0 && (
            <div
              style={{
                padding: 8,
                marginBottom: 6,
                background: !showFilters && '#f8f8f8',
              }}
            >
              <StatusMenu
                itemActive={status}
                data={statusMenuData}
                handleSelectedCard={value =>
                  handleSetSearchParams({ status: value })
                }
              />
            </div>
          )
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('dateRange')}</Label>
                </div>
                <ConfigProvider locale={getAntLocale(language)}>
                  <QuickRangePicker
                    onChange={handleDateChange}
                    defaultValue={
                      initialDate
                        ? [
                            moment(initialDate, 'YYYYMMDD'),
                            moment(endDate, 'YYYYMMDD'),
                          ]
                        : undefined
                    }
                    style={{ width: '100%' }}
                  />
                </ConfigProvider>
              </Col>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('msisdn')}</Label>
                </div>
                <Search
                  placeholder={t('msisdn')}
                  onSearch={value => handleSetSearchParams({ msisdn: value })}
                  onChange={({ target: { value } }) => setMsisdnValue(value)}
                  value={msisdnValue}
                  allowClear
                />
              </Col>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('iccid')}</Label>
                </div>
                <Search
                  placeholder={t('iccid')}
                  onSearch={value => handleSetSearchParams({ iccid: value })}
                  onChange={({ target: { value } }) => setIccidValue(value)}
                  value={iccidValue}
                  allowClear
                />
              </Col>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('imsi')}</Label>
                </div>
                <Search
                  placeholder={t('imsi')}
                  onSearch={value => handleSetSearchParams({ imsi: value })}
                  onChange={({ target: { value } }) => setImsiValue(value)}
                  value={imsiValue}
                  allowClear
                />
              </Col>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('createdBy')}</Label>
                </div>
                <Search
                  placeholder={t('createdBy')}
                  onSearch={value =>
                    handleSetSearchParams({ createdBy: value })
                  }
                  onChange={({ target: { value } }) => setCreatedByValue(value)}
                  value={createdByValue}
                  allowClear
                />
              </Col>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('customerDest')}</Label>
                </div>
                <Select
                  loading={!customersOptions}
                  disabled={!customersOptions}
                  optionFilterProp={'label'}
                  options={customersOptions}
                  placeholder={t('customerDest')}
                  style={{ width: '100%' }}
                  value={selectedClient ? Number(selectedClient) : undefined}
                  allowClear
                  showSearch
                  onChange={value =>
                    handleSetSearchParams({ customerIdDestination: value })
                  }
                />
              </Col>
            </FiltersCard>
          )
        }
      />
    </div>
  );
};

export default ActionsQueue;
