import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Input, Divider, Select, Typography, message } from 'antd';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import apiMiddleware from '@src/services/apiMiddleware';
import useSwr from '@src/hooks/useSwr';
import { formatCurrency } from '@src/utils/formatters';

const { Option } = Select;
const { Title } = Typography;

export const CreditsBuyTab = () => {
  const [cart, setCart] = useState({});
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit_card');
  const { t: translate } = useTranslation('schedules');

  const { data: packagesToList } = useSwr(
    `/service-proxy/astro/credit/list-packages`,
    {
      page: 0,
      per_page: 100,
    },
  );

  const handleFinishCart = async () => {
    setLoading(true);

    try {
      const payload = {
        products: Object.entries(cart).map(([idProduct, quantity]) => ({
          idProduct: parseInt(idProduct),
          quantity: quantity,
        })),
        paymentMethod,
      };
      await apiMiddleware
        .post('/service-proxy/astro/credit/buy', payload)
        .then(response => {
          if (response.data.paymentUrl) {
            message.success({
              content: (
                <>
                  {translate('msgs.success-credit')}
                  &nbsp;
                  <Button type="link" style={{ padding: 0 }}>
                    <a
                      href={response.data.paymentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {translate('msgs.payOnRedirect')}
                    </a>
                  </Button>
                </>
              ),
              duration: 30,
              onClick: () => {
                window.open(response.data.paymentUrl, '_blank');
                message.destroy();
              },
            });
            window.open(response.data.paymentUrl, '_blank');
          }
        });
      setCart({});
      localStorage.setItem('cart', JSON.stringify({}));
    } catch {
      message.error(translate('msgs.error'));
    }
    setLoading(false);
  };

  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleAddToCart = (packageId, quantity) => {
    const updatedCart = { ...cart };
    updatedCart[packageId] = quantity;
    setCart(updatedCart);
  };

  const handleRemoveFromCart = packageId => {
    const updatedCart = { ...cart };
    delete updatedCart[packageId];
    setCart(updatedCart);
  };

  const handleQuantityChange = (packageId, newQuantity) => {
    const updatedCart = { ...cart };
    updatedCart[packageId] = newQuantity;
    setCart(updatedCart);
  };

  const getPriceForPackage = useCallback(
    packageId => {
      const findPackage = packagesToList?.content?.find(
        packageItem => packageItem.idProduct === Number(packageId),
      );
      return findPackage?.price || 0;
    },
    [packagesToList],
  );

  const getNameForPackage = packageId => {
    const findPackage = packagesToList?.content?.find(
      packageItem => packageItem.idProduct === Number(packageId),
    );

    return findPackage?.name || '';
  };
  const totalPrice = useMemo(() => {
    return Object.keys(cart).reduce((total, packageId) => {
      const packagePrice = getPriceForPackage(packageId);
      const quantity = cart[packageId];
      return total + Number(packagePrice) * quantity;
    }, 0);
  }, [cart, getPriceForPackage]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          gap: '20px',
        }}
      >
        {packagesToList?.content?.map(packageItem => {
          return (
            <Card
              title={
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography>{packageItem.name}</Typography>
                  <Typography>{`${formatCurrency(
                    Number(packageItem.price),
                  )}`}</Typography>
                </div>
              }
              style={{ width: '30%' }}
              key={packageItem.idProduct}
            >
              <Typography>{packageItem.name}</Typography>
              <Button
                onClick={() => handleAddToCart(packageItem.idProduct, 1)}
                style={{ width: '100%', marginTop: '20px' }}
                type="primary"
              >
                {translate('addToCart')}
              </Button>
            </Card>
          );
        })}
      </div>
      <Divider />
      <Title level={4}>{translate('yourCart')}</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px',
        }}
      >
        <Card style={{ width: '50%' }}>
          {Object.keys(cart).map(packageId => (
            <div
              key={packageId}
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid #f0f0f0',
                padding: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p>{getNameForPackage(packageId)}</p>

                <Button
                  onClick={() => handleRemoveFromCart(packageId)}
                  icon={<CloseCircleOutlined />}
                  type="link"
                />
              </div>

              <div>
                <Input
                  type="number"
                  value={cart[packageId]}
                  onChange={e =>
                    handleQuantityChange(packageId, parseInt(e.target.value))
                  }
                  style={{ width: '80px' }}
                />
              </div>
            </div>
          ))}
        </Card>
        <Card style={{ width: '50%' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <Title level={3}>{translate('cartResume')}</Title>
            <Title level={5}>
              {translate('totalQuantity')}
              {`: ${
                Object.keys(cart)?.reduce((total, packageId) => {
                  const quantity = cart[packageId];
                  return total + quantity;
                }, 0) || 0
              }`}
            </Title>
            <Title level={5}>
              {translate('totalprice')}
              {`: ${formatCurrency(totalPrice || 0)}`}
            </Title>
            <Title level={5}>{translate('paymentMethod')}</Title>

            <Select
              defaultValue="credit_card"
              style={{ width: '100%' }}
              onChange={value => setPaymentMethod(value)}
            >
              <Option value="credit_card">
                {translate('options.creditCard')}
              </Option>
              <Option value="ticket">{translate('options.ticket')}</Option>
            </Select>

            <Button
              onClick={handleFinishCart}
              style={{ width: '100%' }}
              loading={loading}
              type="primary"
            >
              {translate('button.finish')}
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};
