import React, { useMemo } from 'react';

import { Modal, Row, Col, Button, Tooltip } from 'antd';
import {
  LoadingOutlined,
  ContainerOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import Stat from '@src/components/Stat/Stat';
import {
  dateFormat,
  formatBRNumber,
  formatCurrency,
} from '@src/utils/formatters';
import Table from '@components/Table';
import { useTranslation } from 'react-i18next';

function SyncStatus({ visible, onClose, syncData }) {
  const { t } = useTranslation('invoices');

  const syncStatus = useMemo(
    () => ({
      ...syncData?.status,
    }),
    [syncData],
  );

  const columns = useMemo(
    () => [
      {
        title: t('synchronization.table-columns.invoiceId'),
        dataIndex: 'invoiceId',
        render: (value, record) => (
          <Tooltip title={t('synchronization.view-invoice')}>
            <Button
              type="link"
              icon={<FileSearchOutlined />}
              onClick={() => {
                const url = `/financial/invoices/${record.invoiceId}/customer/${record.customerId}`;
                window.open(url, '_blank').focus();
              }}
            />
          </Tooltip>
        ),
      },
      {
        title: t('synchronization.table-columns.createdBy'),
        dataIndex: 'createdBy',
      },
      {
        title: t('synchronization.table-columns.createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: t('synchronization.table-columns.totalValue'),
        dataIndex: 'totalValue',
        render: formatCurrency,
      },
      {
        title: t('synchronization.table-columns.result.title'),
        dataIndex: 'result',
        render: value => t(`synchronization.table-columns.result.${value}`),
      },
    ],
    [t],
  );

  return (
    <Modal
      maskClosable={false}
      title={t('synchronization.sync-status')}
      footer={false}
      visible={visible}
      width={'80%'}
      centered
      onCancel={onClose}
    >
      <Row gutter={[16, 16]}>
        <Col xl={6} xs={24}>
          <Stat
            title="Total"
            icon={<ContainerOutlined />}
            value={formatBRNumber(syncStatus?.total)}
            loading={!syncStatus}
          />
        </Col>
        <Col xl={6} xs={24}>
          <Stat
            title={t('synchronization.status.synchronizing')}
            icon={<LoadingOutlined />}
            value={formatBRNumber(syncStatus?.synchronizing)}
            loading={!syncStatus}
          />
        </Col>
        <Col xl={6} xs={24}>
          <Stat
            title={t('synchronization.status.success')}
            icon={<CheckCircleOutlined />}
            value={formatBRNumber(syncStatus?.success)}
            loading={!syncStatus}
          />
        </Col>
        <Col xl={6} xs={24}>
          <Stat
            title={t('synchronization.status.error')}
            iconColor="red"
            icon={<CloseCircleOutlined />}
            value={formatBRNumber(syncStatus?.error)}
            loading={!syncStatus}
          />
        </Col>
      </Row>
      {syncData?.errors?.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <Table
            maxHeight={420}
            title={t('synchronization.sync-errors')}
            columns={columns}
            data={syncData?.errors || []}
            loading={!syncData?.errors}
            tableKey="idSyncInvoices"
            pagination={false}
          />
        </div>
      )}
    </Modal>
  );
}

export default SyncStatus;
