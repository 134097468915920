import React, { useEffect, useMemo, useState } from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const FileUploader = ({ style, onUpload, fileList, allFiles = false }) => {
  const [filesList, setFilesList] = useState(fileList || []);
  const { t } = useTranslation('file-uploader');

  useEffect(() => {
    setFilesList(fileList || []);
  }, [fileList]);

  useEffect(() => {
    if (filesList.length > 0) {
      onUpload(filesList[0]);
    } else {
      onUpload(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesList]);

  const uploaderProps = useMemo(
    () => ({
      name: 'file',
      multiple: false,
      accept: allFiles ? '.pdf' : '.csv',
      fileList: filesList,
      beforeUpload: file => {
        setFilesList([file]);
        return false;
      },
      onRemove: () => {
        setFilesList([]);
      },
    }),
    [filesList, allFiles],
  );

  return (
    <Dragger style={style} {...uploaderProps}>
      <p className={'ant-upload-drag-icon'}>
        <InboxOutlined />
      </p>
      <p className={'ant-upload-text'}>{t('uploader-title')}</p>
      {!allFiles && (
        <p className={'ant-upload-hint'}>{t('uploader-subtitle')}</p>
      )}
    </Dragger>
  );
};

export default FileUploader;
