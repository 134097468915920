import React from 'react';
import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const ButtonHelp = ({ text }) => {
  return (
    <Tooltip title={text}>
      <Button type="link" size="small" icon={<QuestionCircleOutlined />} />
    </Tooltip>
  );
};

export default ButtonHelp;
