import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

export const Container = styled.div`
  position: relative;
  width: ${({ size }) => `${size + 4}px`};
  height: ${({ size }) => `${size - 4}px`};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SignalBar = styled.div`
  width: ${({ size }) => `${size / 5}px`};
  border-radius: 1px;
  height: ${({ height }) => `${height}%`};
  background: ${({ color }) => color};
  opacity: ${({ barNumber, numberOfBars }) => {
    return barNumber > numberOfBars ? 0.3 : 1;
  }};
`;

export const SignalOff = styled(FaTimes)`
  position: absolute;
  bottom: -4px;
  right: -4px;
  color: ${({ color }) => color};
`;
