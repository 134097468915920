import { Button, Checkbox, Popover, Space, Tooltip, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import { AiOutlineCheck } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

import '../styles.less';

const { Text } = Typography;

export const QuerySelectOptions = ({
  queryFilters,
  handleUpdateFilters,
  selectedFilters,
}) => {
  const { t: translate } = useTranslation(['dashboard', 'inventory']);
  const [visible, setVisible] = useState(false);
  const handleButtonClick = () => {
    setVisible(!visible);
  };
  const defaultFilters = useMemo(
    () => selectedFilters || [],
    [selectedFilters],
  );
  const withPermissionAndVisible = queryFilters?.filters.filter(
    item => item.visible && item.inTheFilters,
  );

  const handleChange = (value, add) => {
    const findItem = withPermissionAndVisible?.find(
      filterItem => filterItem.name === value,
    );

    if (add && findItem) {
      queryFilters?.setitemsToMap(previous =>
        previous ? [...previous, findItem] : [findItem],
      );
      handleUpdateFilters([...defaultFilters, findItem.name]);
    } else {
      const removeItem = queryFilters?.itemsToMap?.filter(
        filterItem => filterItem.name !== value,
      );
      const removePrefrencesItem = defaultFilters.filter(
        item => item !== value,
      );
      queryFilters?.clearSearchParams([value]);
      queryFilters?.setitemsToMap(removeItem);
      handleUpdateFilters(removePrefrencesItem);
    }
  };

  const options = useMemo(() => {
    const grouped = withPermissionAndVisible?.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});
    return (
      grouped &&
      Object.keys(grouped).map(key => {
        return {
          group: key,
          items: grouped[key]

            .map(item => {
              return {
                label: translate(`inventory:table.${item?.name}`),
                value: item?.name,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label)),
        };
      })
    );
  }, [translate, withPermissionAndVisible]);

  const handleSelectAll = () => {
    const order = [
      'msisdn',
      'iccid',
      'imsi',
      'networkBlockStatus',
      'online',
      'status',
      'operator',
      'connectedOperator',
      'lastConnectionFrom',
      'customerName',
      'automaticBlocking',
      'technology',
      'apn',
      'activationDateFrom',
      'franchise',
      'nickname',
      'imei',
      'imeiLocked',
    ];

    const customSort = (a, b) => {
      const indexA = order.indexOf(a.name);
      const indexB = order.indexOf(b.name);

      if (indexA === -1 && indexB === -1) {
        return 0;
      } else if (indexA === -1) {
        return 1;
      } else if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    };

    const sortedArray = withPermissionAndVisible.sort(customSort);
    const itemsToSaveOnLocalStorage = sortedArray?.map(item => item.name);

    queryFilters?.clearSearchParams();
    queryFilters?.setitemsToMap(sortedArray);

    handleUpdateFilters(
      selectedFilters
        ? [...selectedFilters, ...itemsToSaveOnLocalStorage]
        : itemsToSaveOnLocalStorage,
    );
  };

  const content = (
    <div>
      <Space className="query-builder-dropdown-menu">
        <Tooltip title={translate('query-builder.select-all')}>
          <Button
            onClick={() => handleSelectAll()}
            type="ghost"
            style={{
              padding: '1px',
            }}
            className="button-select"
          >
            <AiOutlineCheck />
          </Button>
        </Tooltip>
      </Space>
      <div className="options-wrapper">
        {options?.map(option => (
          <>
            <Text strong style={{ width: '100%' }}>
              {translate(`query-builder.options-title.${option.group}`)}
            </Text>
            <div className="options-container">
              {option?.items.map(itemOption => (
                <Checkbox
                  key={itemOption.label}
                  value={itemOption.value}
                  checked={Boolean(
                    queryFilters?.itemsToMap?.find(
                      item => item.name === itemOption.value,
                    ),
                  )}
                  onChange={
                    e => handleChange(itemOption?.value || '', e.target.checked)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  {itemOption.label}
                </Checkbox>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );

  return (
    <div className="custom-select" style={{ marginLeft: '8px ' }}>
      <div>
        <Tooltip title={translate('see-options')}>
          <Button
            onClick={handleButtonClick}
            type="ghost"
            className="button-select"
            style={{
              padding: '1px',
            }}
          >
            <IoFilterSharp />
          </Button>
        </Tooltip>
      </div>

      <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={() => setVisible(previous => !previous)}
        placement="topRight"
      />
    </div>
  );
};
