import React, { useState, useCallback, useEffect, useMemo } from 'react';

import Papa from 'papaparse';
import FileUploader from '@src/components/FileUploader/index';
import { getModelData, getModelDataColumns } from '../modelDataInvoice';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Button,
  message,
  Space,
  Typography,
  Tooltip,
  Table,
  Tabs,
} from 'antd';
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CsvExport from '@src/components/CsvExport/CsvExport';
import EditableTable from '@src/components/EditableTable/EditableTable';
import apiMiddleware from '@src/services/apiMiddleware';
import { leaveOnlyNumbers, trimInObjectKeys } from '@src/utils/formatters';
import { useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;

function AddItemsInvoice({ visible, onClose, invoiceId, customerId }) {
  const { t } = useTranslation('device-actions-modal');
  const { t: tInvoices } = useTranslation('invoices');

  const [uploadedFile, setUploadedFile] = useState();
  const [items, setItems] = useState();
  const [stepModal, setStepModal] = useState('manually');
  const [dataModel, setDataModel] = useState(getModelData(tInvoices));

  const navigate = useNavigate();

  const navigateActionsInvoices = useCallback(
    protocol => {
      message.destroy('protocol-invoice');
      navigate(`/financial/invoices/actions?protocol=${protocol}`);
    },
    [navigate],
  );

  const postItems = useCallback(
    payload =>
      apiMiddleware
        .post(`/service-proxy/broker/invoice-actions`, {
          type: 'ADD_ITEM',
          actions: payload,
        })
        .then(response => {
          if (response.data.code === '200') {
            message.success({
              content: (
                <span>
                  {tInvoices('actions.request-success')}
                  <Button
                    onClick={() =>
                      navigateActionsInvoices(response.data.operationProtocol)
                    }
                    type="link"
                    style={{ padding: 3 }}
                  >
                    {tInvoices('actions.go-to')}
                  </Button>
                  {tInvoices('actions.check-progress')}
                </span>
              ),
              key: 'protocol-invoice',
              duration: 5,
            });
          }
        }),
    [tInvoices, navigateActionsInvoices],
  );

  const handleClose = useCallback(() => {
    onClose();
    setItems(undefined);
    setDataModel(getModelData(tInvoices));
  }, [onClose, tInvoices]);

  const dataModelColumns = useMemo(() => {
    let columns = getModelDataColumns(tInvoices);
    if (stepModal === 'upload') {
      columns.pop();
      // eslint-disable-next-line no-unused-vars
      columns = columns.map(({ render, ...column }) => ({ ...column }));
    }
    if (invoiceId && customerId) {
      columns.shift();
      columns.shift();
    }
    return columns;
  }, [tInvoices, stepModal, invoiceId, customerId]);

  useEffect(() => {
    if (uploadedFile) {
      Papa.parse(uploadedFile, {
        skipEmptyLines: 'greedy',
        header: true,
        complete(results) {
          const csvHeaderReference = dataModelColumns.map(item => item.title);

          if (
            !csvHeaderReference.every(v =>
              results.meta.fields.map(f => f?.trim()).includes(v),
            )
          ) {
            message.error(t('invalid-header'));
            return;
          }

          const devicesToSend = results.data.map(device => {
            device = trimInObjectKeys(device);
            const rowObject = {};
            dataModelColumns.forEach(header => {
              rowObject[header.dataIndex] = device[header.title];
            });
            return rowObject;
          });

          setItems(devicesToSend);
        },
      });
    } else {
      setItems(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  const info = useCallback(() => {
    Modal.info({
      title: t('csv-format-example'),
      width: 700,
      content: (
        <>
          <Table
            columns={dataModelColumns}
            dataSource={dataModel}
            pagination={false}
            size={'small'}
            tableLayout={'auto'}
            bordered
            style={{ margin: '12px 0' }}
          />
          <CsvExport
            columns={dataModelColumns}
            data={dataModel}
            filename={'model-import.csv'}
            separator={','}
            style={{ background: 'red' }}
          >
            <Button
              className={'button-download'}
              icon={<DownloadOutlined />}
              size={'large'}
              type={'link'}
            >
              {t('download-model')}
            </Button>
          </CsvExport>
        </>
      ),
    });
  }, [dataModelColumns, dataModel, t]);

  const handleAddItems = useCallback(async () => {
    const payload = items.map(item => {
      if (invoiceId && customerId) {
        item = {
          ...item,
          invoiceId: Number(invoiceId),
          customerId: Number(customerId),
        };
      }
      const { key, totalValue, ...newItem } = item;
      return newItem;
    });

    try {
      await postItems(payload);
      handleClose();
    } catch {
      message.error(tInvoices('invoiceItem.msgs.error-to-creating'));
    }
  }, [items, invoiceId, postItems, tInvoices, customerId, handleClose]);

  const AddItemsConfirmation = useCallback(() => {
    Modal.info({
      title: tInvoices('items-modal.confirmation.count', {
        count: items?.length,
      }),
      width: 440,
      closable: true,
      content: (
        <Paragraph>{tInvoices('items-modal.confirmation.paragraph')}</Paragraph>
      ),
      onOk: handleAddItems,
      okText: tInvoices('items-modal.buttons.confirm'),
    });
  }, [handleAddItems, items?.length, tInvoices]);

  const handleTabsChange = useCallback(
    tab => {
      setStepModal(tab);
      setDataModel(getModelData(tInvoices));
      setItems(undefined);
    },
    [tInvoices],
  );

  const handleInsertCurrencyNumber = useCallback(
    currency =>
      currency && String(currency).replaceAll(' ', '')?.replaceAll(',', '.'),
    [],
  );

  const handleEditableTableChange = useCallback(
    tableData => {
      const newData = tableData.map(item => ({
        ...item,
        quantity: leaveOnlyNumbers(item.quantity),
        value: handleInsertCurrencyNumber(item.value),
        totalValue:
          leaveOnlyNumbers(item.quantity) *
          handleInsertCurrencyNumber(item.value),
      }));
      setDataModel(newData);
      setItems(newData);
    },
    [handleInsertCurrencyNumber],
  );

  return (
    <Modal
      bodyStyle={{
        paddingTop: 0,
      }}
      footer={
        <>
          <Button onClick={handleClose}>
            {tInvoices('items-modal.buttons.cancel')}
          </Button>
          <Button
            type={'primary'}
            disabled={!items}
            onClick={AddItemsConfirmation}
          >
            {tInvoices('items-modal.buttons.next')}
          </Button>
        </>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerForm', htmlType: 'submit' }}
      style={{
        maxWidth: 900,
      }}
      title={
        invoiceId
          ? tInvoices('items-modal.new-items')
          : tInvoices('items-modal.new-items-in-list')
      }
      visible={visible}
      width={'80%'}
      centered
      onCancel={handleClose}
    >
      <Tabs
        tabBarStyle={{ marginBottom: 32 }}
        defaultActiveKey={stepModal}
        onChange={handleTabsChange}
      >
        <TabPane key="manually" tab={tInvoices('items-modal.steps.manually')}>
          <Space
            style={{
              display: 'block',
            }}
          >
            <EditableTable
              allowDelete
              columns={dataModelColumns}
              data={dataModel}
              tableData={handleEditableTableChange}
            />
          </Space>
        </TabPane>
        <TabPane key="upload" tab={tInvoices('items-modal.steps.upload')}>
          <Space>
            <Text>
              {tInvoices('items-modal.select-items')}
              <Tooltip placement={'right'} title={t('help')}>
                <Button
                  icon={<QuestionCircleOutlined />}
                  size={'small'}
                  type={'link'}
                  onClick={info}
                />
              </Tooltip>
            </Text>
          </Space>
          <Space>
            <FileUploader
              fileList={uploadedFile ? [uploadedFile] : undefined}
              style={{ marginTop: 10, padding: 14, height: '800px' }}
              onUpload={setUploadedFile}
            />
          </Space>
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default AddItemsInvoice;
