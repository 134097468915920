/* eslint-disable no-magic-numbers */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Select, Table, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  SaveOutlined,
  UndoOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { bytesToMbytes, mbytesToBytes } from '@utils/formatters';
import { operatorsRange as operators } from '@utils/operators';

const { Option } = Select;

const OverflowRangesConfig = ({
  overflowRanges,
  setOverflowRanges,
  setOnNewRowFunction,
  editable,
  noTitle,
  noInteraction,
  handleDelete,
  handleSave,
}) => {
  const { t } = useTranslation('overflow-ranges');

  const [editingRow, setEditingRow] = useState(undefined);
  const [isCreatingNewRange, setIsCreatingNewRange] = useState(false);

  const [editedOperator, setEditedOperator] = useState(undefined);
  const [editedStartRange, setEditedStartRange] = useState(undefined);
  const [editedEndRange, setEditedEndRange] = useState(undefined);
  const [editedMbExcPrice, setEditedMbExcPrice] = useState(undefined);
  // const [editedMinChargeBytes, setEditedMinChargeBytes] = useState(undefined);
  // const [editedIncrChargeBytes, setEditedIncrChargeBytes] = useState(undefined);

  const handleSaveEditing = useCallback(
    index => {
      setEditingRow(undefined);
      setIsCreatingNewRange(false);

      const itemToSave = {
        idOperadora: editedOperator || overflowRanges[index].idOperadora,
        nuFaixaInicialBytes:
          editedStartRange || overflowRanges[index].nuFaixaInicialBytes,
        nuFaixaFinalBytes:
          editedEndRange || overflowRanges[index].nuFaixaFinalBytes,
        nuValorNegociadoMbyte:
          editedMbExcPrice || overflowRanges[index].nuValorNegociadoMbyte,
        nuCobrancaMinimaBytes: 1024,
        nuCobrancaIncrementalBytes: 1024,
      };
      if (handleSave) {
        handleSave(itemToSave, index);
      } else {
        setOverflowRanges(oldArray =>
          oldArray?.map((item, itemIndex) =>
            itemIndex === index ? itemToSave : item,
          ),
        );
      }

      setEditedOperator(undefined);
      setEditedStartRange(undefined);
      setEditedEndRange(undefined);
      setEditedMbExcPrice(undefined);
      // setEditedMinChargeBytes(undefined);
      // setEditedIncrChargeBytes(undefined);
    },
    [
      editedEndRange,
      editedMbExcPrice,
      editedOperator,
      editedStartRange,
      overflowRanges,
      handleSave,
      setOverflowRanges,
    ],
  );

  const handleCancelEditing = useCallback(() => {
    if (isCreatingNewRange) {
      setOverflowRanges(oldArray =>
        oldArray.filter((item, index) => index !== oldArray.length - 1),
      );
      setIsCreatingNewRange(false);
    }
    setEditingRow(undefined);
    setEditedOperator(undefined);
    setEditedStartRange(undefined);
    setEditedEndRange(undefined);
    setEditedMbExcPrice(undefined);
    // setEditedMinChargeBytes(undefined);
    // setEditedIncrChargeBytes(undefined);
  }, [isCreatingNewRange, setOverflowRanges]);

  const handleNewRange = useCallback(() => {
    if (!isCreatingNewRange) {
      setEditingRow(overflowRanges?.length);
      setIsCreatingNewRange(true);
      setOverflowRanges(oldArray => [
        ...oldArray,
        {
          idOperadora: 1,
          nuFaixaInicialBytes: '0',
          nuFaixaFinalBytes: '10485760',
          nuValorNegociadoMbyte: '0.50',
          nuCobrancaMinimaBytes: 1024,
          nuCobrancaIncrementalBytes: 1024,
        },
      ]);
    }
  }, [overflowRanges, setOverflowRanges, isCreatingNewRange]);

  const handleDeleteRow = useCallback(
    async indexToDelete => {
      if (handleDelete !== null) {
        handleDelete(indexToDelete);
      } else {
        setOverflowRanges(oldArray =>
          oldArray.filter((item, index) => index !== indexToDelete),
        );
      }
    },
    [handleDelete, setOverflowRanges],
  );

  const adminActions = useCallback(
    (text, record, index) => (
      <>
        {!noInteraction && (
          <>
            {editingRow === index ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  icon={<SaveOutlined />}
                  type={'link'}
                  onClick={() => handleSaveEditing(index)}
                />
                <Button
                  icon={<UndoOutlined />}
                  type={'link'}
                  onClick={() => handleCancelEditing()}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {editable && (
                  <Button
                    disabled={!!editingRow}
                    icon={<EditOutlined />}
                    type={'link'}
                    onClick={() => setEditingRow(index)}
                  />
                )}
                <Button
                  disabled={!!editingRow}
                  icon={<DeleteOutlined />}
                  type={'link'}
                  onClick={() => handleDeleteRow(index)}
                />
              </div>
            )}
          </>
        )}
      </>
    ),
    [
      editable,
      editingRow,
      handleCancelEditing,
      handleDeleteRow,
      handleSaveEditing,
      noInteraction,
    ],
  );

  const columns = useMemo(
    () => [
      {
        title: t('operator'),
        dataIndex: 'idOperadora',
        render: (value, record, index) => {
          if (index === editingRow) {
            return (
              <Select
                defaultValue={value}
                style={{ width: '100%' }}
                onChange={value => setEditedOperator(value)}
              >
                {Array.from(operators).map(([key, value]) => (
                  <Option key={key} value={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            );
          }
          return operators.get(value);
        },
      },
      {
        title: t('start'),
        dataIndex: 'nuFaixaInicialBytes',
        render: (value, record, index) => {
          const valueMB = bytesToMbytes(parseFloat(value.replace('"', '')));
          if (index === editingRow) {
            return (
              <InputNumber
                defaultValue={valueMB}
                formatter={value => `${value} MB`}
                min={0}
                parser={value => value.replace(' MB', '')}
                step={0.5}
                onChange={value => setEditedStartRange(mbytesToBytes(value))}
              />
            );
          }
          return `${valueMB} MB`;
        },
      },
      {
        title: t('end'),
        dataIndex: 'nuFaixaFinalBytes',
        render: (value, record, index) => {
          const valueMB = bytesToMbytes(parseFloat(value.replace('"', '')));
          if (index === editingRow) {
            return (
              <InputNumber
                defaultValue={valueMB}
                formatter={value => `${value} MB`}
                min={0}
                parser={value => value.replace(' MB', '')}
                step={0.5}
                onChange={value => setEditedEndRange(mbytesToBytes(value))}
              />
            );
          }
          return `${valueMB} MB`;
        },
      },
      {
        title: t('valueMB'),
        dataIndex: 'nuValorNegociadoMbyte',
        render: (value, record, index) => {
          const valueFormatted = parseFloat(value.replace(',', '.')).toFixed(2);
          if (index === editingRow) {
            return (
              <InputNumber
                defaultValue={valueFormatted}
                formatter={value => `R$ ${value}`}
                min={0}
                parser={value => value.replace('.', ',').replace('R$ ', '')}
                step={0.01}
                onChange={value => setEditedMbExcPrice(value.toString())}
              />
            );
          }
          return `R$ ${valueFormatted}`;
        },
      },
      // {
      //   title: t('minChargedBytes'),
      //   dataIndex: 'nuCobrancaMinimaBytes',
      //   render: (value, record, index) => {
      //     const valueMB = bytesToMbytes(value);
      //     if (index === editingRow){
      //       return (
      //         <InputNumber defaultValue={valueMB}
      //                      formatter={value => `${value} MB`}
      //                      min={0}
      //                      parser={value => value.replace(' MB', '')}
      //                      step={0.5}
      //                      onChange={(value) => setEditedMinChargeBytes(mbytesToBytes(value))} />
      //       );
      //     }
      //     return `${valueMB} MB`;
      //   }
      // },
      // {
      //   title: t('incrChargeBytes'),
      //   dataIndex: 'nuCobrancaIncrementalBytes',
      //   render: (value, record, index) => {
      //     const valueMB = bytesToMbytes(value);
      //     if (index === editingRow){
      //       return (
      //         <InputNumber defaultValue={valueMB}
      //                      formatter={value => `${value} MB`}
      //                      min={0}
      //                      parser={value => value.replace(' MB', '')}
      //                      step={0.5}
      //                      onChange={(value) => setEditedIncrChargeBytes(mbytesToBytes(value))} />
      //       );
      //     }
      //     return `${valueMB} MB`;
      //   }
      // },
      {
        title: '',
        render: adminActions,
        align: 'center',
      },
    ],
    [t, adminActions, editingRow],
  );

  useEffect(() => {
    if (setOnNewRowFunction) {
      setOnNewRowFunction(() => handleNewRange);
    }
  }, [handleNewRange]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={overflowRanges}
        pagination={false}
        rowKey={({ idFaixa }) => idFaixa}
        size={'small'}
        style={{ overflowY: 'auto', width: '100%', padding: 10 }}
        title={
          setOnNewRowFunction || noInteraction
            ? null
            : () => (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }}
                >
                  {noTitle ? <span /> : t('title')}
                  <Button
                    disabled={isCreatingNewRange}
                    icon={<PlusOutlined />}
                    shape={'circle'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => handleNewRange()}
                  />
                </div>
              )
        }
      />
    </>
  );
};

OverflowRangesConfig.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  overflowRanges: PropTypes.array.isRequired,
  setOverflowRanges: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  noInteraction: PropTypes.bool,
  noTitle: PropTypes.bool,
  setOnNewRowFunction: PropTypes.func,
};

OverflowRangesConfig.defaultProps = {
  editable: false,
  noTitle: false,
  noInteraction: false,
  handleDelete: null,
  handleSave: null,
  setOnNewRowFunction: null,
};

export default OverflowRangesConfig;
