import React, { Suspense, lazy, useContext } from 'react';
import { Routes as ReactRoutes, Route, Redirect } from 'react-router-dom';

import { Spinner } from './components/ui';
import RoleChecker from './components/RoleChecker/RoleChecker';
import KeycloakContext from './store/KeycloakContext/KeycloakContext';
import routes from './config/routes';

const MainContainer = lazy(() => import('./pages/MainContainer'));

const suspenseProvider = Component => (
  <Suspense fallback={<Spinner />}>{Component}</Suspense>
);

const Routes = () => {
  const { keycloak } = useContext(KeycloakContext);

  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';

  return (
    <ReactRoutes>
      {keycloak.authenticated ? (
        <Route element={suspenseProvider(<MainContainer />)} path={'*'}>
          <Redirect from={'/'} to={'/management'} exact />
          {routes.map(({ role, element, path }) => {
            const isPlansAndEndCustomer =
              ['/financial/plans'].includes(path) && isEndCustomer;
            if (isPlansAndEndCustomer) {
              return;
            }

            return role ? (
              <RoleChecker key={path} role={role}>
                <Route
                  key={path}
                  element={suspenseProvider(element)}
                  path={path}
                />
              </RoleChecker>
            ) : (
              <Route
                key={path}
                element={suspenseProvider(element)}
                path={path}
              />
            );
          })}
        </Route>
      ) : (
        <Redirect from={'*'} to={'/management'} />
      )}
    </ReactRoutes>
  );
};

Routes.propTypes = {};

export default Routes;
