import React, { useState, useCallback, useMemo, useContext } from 'react';
import { Label } from '@components/ui';
import {
  Col,
  DatePicker,
  ConfigProvider,
  Button,
  Select,
  Tag,
  Tooltip,
  Badge,
  Space,
  Divider,
} from 'antd';
import {
  FileSearchOutlined,
  FileSyncOutlined,
  LoadingOutlined,
  CopyOutlined,
  ControlOutlined,
  ArrowsAltOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  formatCurrency,
  handleFirstAndLastDayOfMonth,
} from '@utils/formatters';
import ptBR from 'antd/es/locale/pt_BR';
import 'moment/locale/pt-br';
import useSwr from '@src/hooks/useSwr';
import Table from '@components/Table';
import { Filters, FiltersCard } from '@components/Filters';
import useSearchParams from '@src/hooks/useSearchParams';
import moment from 'moment';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import RoleChecker from '@src/components/RoleChecker/RoleChecker';
import rolesConstants from '@src/utils/rolesConstants';
import SessionContext from '@src/store/SessionContext/SessionContext';
import SyncStatus from './SyncStatus/SyncStatus';
import ButtonIndividualSync from './ButtonIndividualSync/ButtonIndividualSync';
import RequestInvoiceReport from './RequestInvoiceReport/RequestInvoiceReport';
import CustomPopover from '@src/components/CustomPopover/CustomPopover';

const Invoices = () => {
  const { t } = useTranslation('invoices');

  const { userRoles, hasCustomersRoles } = useContext(SessionContext);
  const hasRole = useCallback(
    role => userRoles?.portal?.includes(role),
    [userRoles],
  );

  const { keycloak } = useContext(KeycloakContext);
  const {
    customer: { customersOptions },
  } = useContext(SessionContext);
  const isSuperBroker = keycloak.idTokenParsed?.customerType === 'SUPERBROKER';
  const isCustomer = keycloak.idTokenParsed?.customerType === 'CUSTOMER';
  const customerIdLogged = keycloak?.idTokenParsed?.customerId;

  const navigate = useNavigate();
  const [initialDate, setInitialDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState();
  const [customerId, setCustomerId] = useState(
    isCustomer ? customerIdLogged : undefined,
  );
  const [showFilters, setShowFilters] = useState(true);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showRequestReport, setShowRequestReport] = useState(false);

  const isStatusClosed = status === 'C';
  const handleAttrEmpty = attr => (attr === '' ? undefined : attr);

  const invoicesCustomersOptions = useMemo(() => {
    if (isSuperBroker) return customersOptions;

    const options = [
      {
        label: t('my-invoices'),
        value: customerIdLogged,
      },
    ];

    if (hasCustomersRoles && customersOptions?.length > 0) {
      options.push({
        label: t('customers-invoices'),
        options: customersOptions ?? [
          {
            label: (
              <div style={{ textAlign: 'center' }}>
                <LoadingOutlined />
              </div>
            ),
          },
        ],
      });
    }

    return options;
  }, [t, customerIdLogged, isSuperBroker, customersOptions, hasCustomersRoles]);

  const { data, mutate } = useSwr(
    customerId ? `/service-proxy/broker/${customerId}/invoices` : null,
    {
      status: handleAttrEmpty(status),
      initialDate: handleAttrEmpty(initialDate),
      endDate: handleAttrEmpty(endDate),
    },
  );

  const { data: syncData, mutate: mutateSyncData } = useSwr(
    hasRole(rolesConstants.VIEW_INVOICES_SYNC_STATUS)
      ? '/service-proxy/omie/sync-status'
      : null,
  );

  const paramsAttributes = useMemo(
    () => [
      ...(!isCustomer
        ? [
            {
              name: 'customer',
              setState: setCustomerId,
              inTheFilters: true,
            },
          ]
        : []),
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: true,
      },
      {
        name: 'initialDate',
        setState: setInitialDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
    ],
    [isCustomer],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const statusOptions = useMemo(
    () => [
      { label: t('searchResultItem.opened'), value: 'O' },
      { label: t('searchResultItem.closed'), value: 'C' },
    ],
    [t],
  );

  const handleDateChange = useCallback(
    date => {
      if (date) {
        const { firstDay, lastDay } = handleFirstAndLastDayOfMonth(date._d);
        handleSetSearchParams({ initialDate: firstDay, endDate: lastDay });
      } else {
        setInitialDate('');
        setEndDate('');
      }
    },
    [handleSetSearchParams],
  );

  const mutateIndividualSync = useCallback(
    invoiceId => {
      mutate({
        ...data,
        listaFaturasVirtuais: data?.listaFaturasVirtuais?.map(dataInvoice =>
          dataInvoice.idFatura === invoiceId
            ? {
                ...dataInvoice,
                synchronized: true,
              }
            : dataInvoice,
        ),
      });
      mutateSyncData();
    },
    [data, mutate, mutateSyncData],
  );

  const colummnTotalPrice = useCallback(
    (nuValorFatura, record) => {
      const {
        faturamentoMvno,
        nuValorMvnoAlgar,
        nuValorMvnoAllcom,
        nuValorConectividadeLorawan,
        nuValorServicoAgregadoRedeMovel,
      } = record;

      const dataPopover = [
        {
          title: t('searchResultItem.nuValorServicoAgregadoRedeMovel'),
          value: formatCurrency(Number(nuValorServicoAgregadoRedeMovel)),
          show: nuValorServicoAgregadoRedeMovel,
        },
        {
          title: t('searchResultItem.nuValorConectividadeLorawan'),
          value: formatCurrency(Number(nuValorConectividadeLorawan)),
          show: nuValorConectividadeLorawan,
        },
        {
          title: t('searchResultItem.nuValorMvnoAlgar'),
          value: formatCurrency(Number(nuValorMvnoAlgar)),
          show: faturamentoMvno,
        },
        {
          title: t('searchResultItem.nuValorMvnoAllcom'),
          value: formatCurrency(Number(nuValorMvnoAllcom)),
          show: faturamentoMvno,
        },
      ];

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ margin: 0 }}>{formatCurrency(Number(nuValorFatura))}</p>
          {dataPopover.filter(item => item.show).length > 0 && (
            <CustomPopover
              title={t('searchResultItem.totalValue')}
              titleValue={formatCurrency(Number(nuValorFatura))}
              titleContent=""
              dataContent={dataPopover.filter(item => item.show)}
            >
              <Button
                type="link"
                style={{
                  padding: 0,
                  margin: 0,
                  marginLeft: 6,
                  fontSize: '18px',
                }}
              >
                <ArrowsAltOutlined />
              </Button>
            </CustomPopover>
          )}
        </div>
      );
    },
    [t],
  );

  const defaultColumns = useMemo(
    () => [
      { title: t('searchResultItem.id'), dataIndex: 'idFatura' },
      {
        title: t('searchResultItem.status'),
        dataIndex: 'idStatusFatura',
        render: status => (
          <span>
            <Tag color={status === 'O' ? 'green' : 'volcano'}>
              {status === 'O'
                ? t('searchResultItem.opened')
                : t('searchResultItem.closed')}
            </Tag>
          </span>
        ),
      },
      { title: t('searchResultItem.terminals'), dataIndex: 'nuTerminais' },
      {
        title: t('searchResultItem.totalValue'),
        dataIndex: 'nuValorFatura',
        render: (value, record) => record && colummnTotalPrice(value, record),
      },
      {
        title: t('searchResultItem.totalTraffic'),
        dataIndex: 'nuTrafegoTotal',
      },

      {
        title: t('searchResultItem.endDate'),
        dataIndex: 'dataTermino',
        render: value => value.replaceAll('-', '/'),
      },
      {
        title: t('searchResultItem.expirationDate'),
        dataIndex: 'dataVencimento',
        render: value => value.replaceAll('-', '/'),
      },
      status !== 'O' && {
        title: t('synchronization.title'),
        dataIndex: 'synchronized',
        render: (value, record) => {
          if (record.idStatusFatura === 'O') {
            return;
          }

          return (
            <ButtonIndividualSync
              value={value}
              customerId={customerId}
              invoiceId={record.idFatura}
              mutate={mutateIndividualSync}
              t={t}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        align: 'center',
        render: (_, record) => {
          return (
            <Button
              type="link"
              color={'#5867dd'}
              icon={<FileSearchOutlined />}
              onClick={() =>
                navigate(
                  `/financial/invoices/${record.idFatura}/customer/${customerId}`,
                )
              }
            />
          );
        },
      },
    ],
    [customerId, mutateIndividualSync, navigate, status, t, colummnTotalPrice],
  );
  const columns = useMemo(() => {
    const hasRoleSync = hasRole(rolesConstants.INVOICES_SYNC);

    return defaultColumns.filter(column => {
      const columnIsTheSync = column.dataIndex === 'synchronized';

      return !columnIsTheSync || (columnIsTheSync && hasRoleSync);
    });
  }, [hasRole, defaultColumns]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: !isCustomer,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('search.customerPlaceholder'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={invoicesCustomersOptions || []}
            placeholder={t('search.customerPlaceholder')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            loading={!invoicesCustomersOptions}
            disabled={!invoicesCustomersOptions}
            value={customerId ? Number(customerId) : undefined}
            onChange={value => {
              if (value) {
                handleSetSearchParams({
                  customer: value,
                });
              } else {
                handleClearParams();
              }
            }}
          />
        ),
      },
      {
        visible: customerId,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('search.statusPlaceholder'),
        item: (
          <Select
            options={statusOptions}
            placeholder={t('search.statusPlaceholder')}
            style={{ width: '100%' }}
            allowClear
            value={status}
            onChange={value => {
              handleSetSearchParams({
                status: value,
              });
              if (!value || value === 'O') {
                handleSetSearchParams({
                  status: value,
                  initialDate: undefined,
                  endDate: undefined,
                });
              }
            }}
          />
        ),
      },
      {
        visible: isStatusClosed && customerId,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('search.datesRangePlaceholder'),
        item: (
          <ConfigProvider locale={ptBR}>
            <DatePicker
              format={'MMMM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={d =>
                d && d.add(1, 'months') >= moment().endOf('day')
              }
              defaultValue={endDate ? moment(endDate, 'YYYYMMDD') : undefined}
              onChange={handleDateChange}
              placeholder={t('search.datesRangePlaceholder')}
              picker="month"
            />
          </ConfigProvider>
        ),
      },
    ],
    [
      customerId,
      endDate,
      handleDateChange,
      handleSetSearchParams,
      invoicesCustomersOptions,
      isCustomer,
      isStatusClosed,
      status,
      statusOptions,
      handleClearParams,
      t,
    ],
  );

  return (
    <>
      <RoleChecker role={rolesConstants.VIEW_INVOICES_SYNC_STATUS}>
        <SyncStatus
          syncData={syncData}
          visible={showSyncModal}
          onClose={() => setShowSyncModal(false)}
        />
      </RoleChecker>
      <RoleChecker role={rolesConstants.REQUEST_FINANCIAL_REPORT}>
        <RequestInvoiceReport
          visible={showRequestReport}
          invoicesCustomersOptions={invoicesCustomersOptions}
          statusOptions={statusOptions}
          onClose={() => setShowRequestReport(false)}
        />
      </RoleChecker>
      <Table
        title={
          <Space>
            {t('search.invoices')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </Space>
        }
        columns={columns}
        allColumns={defaultColumns}
        data={data?.listaFaturasVirtuais}
        loading={!data && data !== '' && !!customerId}
        tableKey="idFatura"
        style={{ overflowY: 'auto' }}
        extraActions={
          <>
            <RoleChecker
              role={rolesConstants.VIEW_INVOICES_SYNC_STATUS}
              style={{ position: 'relative' }}
            >
              <Badge
                count={syncData?.errors?.length}
                size="small"
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 14,
                }}
              >
                <Tooltip title={t('synchronization.sync-status')}>
                  <Button
                    type="link"
                    size="large"
                    loading={!syncData}
                    icon={<FileSyncOutlined />}
                    onClick={() => {
                      setShowSyncModal(true);
                    }}
                  />
                </Tooltip>
              </Badge>
            </RoleChecker>
            <RoleChecker role={rolesConstants.REQUEST_FINANCIAL_REPORT}>
              <Tooltip title={t('report.request')}>
                <Button
                  icon={<CopyOutlined />}
                  type="link"
                  onClick={() => setShowRequestReport(true)}
                />
              </Tooltip>
            </RoleChecker>
          </>
        }
        mainExtraAction={
          <Space>
            <Divider orientation="center" type="vertical" />
            <Tooltip title={t('invoice-actions')}>
              <Button
                icon={<ControlOutlined />}
                onClick={() => navigate(`/financial/invoices/actions`)}
                type="primary"
              />
            </Tooltip>
          </Space>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Invoices;
