import React, { useMemo } from 'react';
import { Form, Row, Col, Select, Radio, Tooltip } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TagsManagement from '@components/TagsManagement';
import InputUploader from '@components/InputUploader';

const { Item } = Form;

const FilterStep = ({ form, filterType, setFilterType }) => {
  const { t } = useTranslation('lorawan');
  const tags = Form.useWatch('tags', form);

  const typesOptions = useMemo(
    () =>
      [
        'join_request',
        'uplink',
        'downlink',
        'downlink_request',
        'error',
        'warning',
        'info',
        'location',
        'status_response',
      ].map(type => ({
        label: type,
        value: type,
      })),
    [],
  );

  const filtersItems = useMemo(
    () => [
      {
        label: 'tags',
        name: 'tags',
        element: (
          <TagsManagement
            tags={tags ?? []}
            onChange={newTags => {
              form.setFieldValue('tags', newTags);
            }}
          />
        ),
      },
      {
        label: 'devEui',
        name: 'devices',
        element: (
          <InputUploader
            itemName="devEui"
            onUpload={values => {
              form.setFieldValue('devices', values?.join(';'));
            }}
          />
        ),
      },
      {
        label: 'appEui',
        name: 'applications',
        element: (
          <InputUploader
            itemName="appEui"
            onUpload={values => {
              form.setFieldValue('applications', values?.join(';'));
            }}
          />
        ),
      },
    ],
    [form, tags],
  );

  const selectedFilterType = useMemo(() => {
    switch (filterType) {
      case 'tags':
        return filtersItems[0];
      case 'devices':
        return filtersItems[1];
      case 'applications':
        return filtersItems[2];
      default:
        return filtersItems;
    }
  }, [filterType, filtersItems]);

  return (
    <Form
      colon={false}
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError
      layout="vertical"
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Item label={t('msgsTypes')} name="types">
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              options={typesOptions}
            />
          </Item>
        </Col>
        <Radio.Group
          onChange={({ target: { value } }) => {
            if (value !== 'all') {
              form?.resetFields(
                ['tags', 'devices', 'applications']?.filter(
                  item => item !== value,
                ),
              );
            }
            setFilterType(value);
          }}
          value={filterType}
          style={{ margin: '0 4px 16px 4px' }}
        >
          <Radio.Button value="tags">{t('tags')}</Radio.Button>
          <Radio.Button value="devices">{t('devEui')}</Radio.Button>
          <Radio.Button value="applications">{t('appEui')}</Radio.Button>
          <Tooltip title={t('buttons.showAll')}>
            <Radio.Button value="all">
              <MenuOutlined />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
        {filterType === 'all' ? (
          selectedFilterType?.map(filter => (
            <Col key={filter.name} span={24}>
              <Item label={t(filter.label)} name={filter?.name}>
                {filter?.element}
              </Item>
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Item name={selectedFilterType?.name}>
              {selectedFilterType?.element}
            </Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default FilterStep;
