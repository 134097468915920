import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`
  max-width: 900px;
  position: relative;

  .ant-divider {
    font-size: 0.8rem;
    margin-bottom: 32px;

    .ant-divider-inner-text {
      opacity: 0.6;
    }
  }
`;
