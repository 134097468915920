import React, { useContext } from 'react';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import moment from 'moment';

const { RangePicker } = DatePicker;

const ParametrizedDatePicker = ({
  value,
  allowClear,
  onChange,
  disabledDate,
  range,
  ...rest
}) => {
  const { i18n } = useTranslation();

  const { keycloak } = useContext(KeycloakContext);

  const whichFormat = locale => {
    const localeDictionary = {
      pt: 'DD/MM/YYYY',
      es: 'DD/MM/YYYY',
      en: 'MM/DD/YYYY',
    };
    return localeDictionary[locale];
  };

  const config = {
    ...rest,
    onChange,
    value,
    disabledDate: disabledDate ?? (d => d && d > moment().endOf('day')),
    allowClear,
    format: whichFormat(
      i18n?.language ?? keycloak?.idTokenParsed?.locale ?? 'en',
    ),
  };

  return (
    <>{range ? <RangePicker {...config} /> : <DatePicker {...config} />}</>
  );
};

export default ParametrizedDatePicker;
