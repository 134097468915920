import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Tooltip,
  message,
  Empty,
  Col,
  Row,
  Tabs,
  Typography,
  Card,
  Tag,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import Table from '@components/Table';
import ContractModal from './ContractModal';
import { useTranslation } from 'react-i18next';
import useSwr from '@src/hooks/useSwr';
import rolesConstants from '@src/utils/rolesConstants';
import { formatCurrency } from '@src/utils/formatters';
import AdminActions from '@src/components/AdminActions/index';
import apiMiddleware from '@src/services/apiMiddleware';
import HistoricModal from './HistoricModal';
import SessionContext from '@src/store/SessionContext/SessionContext';
import HiringContractsCard from './HiringContractsCard/index';
import CancelContract from './CancelContract/index';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 20,
  pageSize: 20,
  pageSizeOptions: [20],
  total: 1,
};

const { TabPane } = Tabs;

const SmsContracts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdition, setIsEdition] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedContract, setSelectedContract] = useState(null);
  const [tableToShow, setTableToShow] = useState('active');
  const { userRoles } = useContext(SessionContext);

  const { t: translate } = useTranslation(['sms-send']);

  const haveManageContract = useMemo(() => {
    const hasRole = userRoles?.['portal']?.includes(
      rolesConstants.MANAGE_SMS_CONTRACT,
    );
    return hasRole;
  }, [userRoles]);

  const { data, mutate } = useSwr(
    haveManageContract && tableToShow === 'active'
      ? `/service-proxy/sms-contract`
      : null,
    {
      page: pagination?.current - 1,
      perPage: pagination?.pageSize,
    },
  );

  const { data: dataTrashed } = useSwr(
    haveManageContract && tableToShow !== 'active'
      ? `/service-proxy/sms-contract/deleted`
      : null,
    {
      page: pagination?.current - 1,
      perPage: pagination?.pageSize,
    },
  );

  const { data: dataToHiring } = useSwr(
    !haveManageContract ? `/service-proxy/sms-contract/for-hiring` : null,
    {
      page: pagination?.current - 1,
      perPage: 50,
    },
  );

  const { data: balanceData } = useSwr(
    !haveManageContract ? `/service-proxy/sms-hiring/balance` : null,
  );

  const deleteContract = useCallback(
    idToDelete =>
      apiMiddleware.delete(`/service-proxy/sms-contract/${idToDelete}`),
    [],
  );

  const showEditModal = useCallback(
    contract => {
      setSelectedContract(contract);
      setIsModalVisible(true);
    },
    [setSelectedContract, setIsModalVisible],
  );

  const handleAddNew = () => {
    setSelectedContract(null);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedContract(null);
    setIsEdition(false);
  };

  const handleActions = useCallback(
    async (actionType, contract) => {
      const isUpdate = actionType === 'UPDATE';

      if (isUpdate) {
        setIsEdition(true);
        showEditModal(contract);
      } else {
        try {
          await deleteContract(contract.id);
          message.success(translate('msgs.contract-deleted'));
        } catch {
          message.error(translate('msgs.contract-error'));
        }
        mutate();
      }
    },
    [deleteContract, translate, showEditModal, mutate],
  );

  const columns = [
    {
      title: translate('table.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: translate('table.type'),
      dataIndex: 'type',
      key: 'type',
      render: type => type && translate(`status-type.${type}`),
    },
    {
      title: translate('table.franchise'),
      dataIndex: 'franchise',
      key: 'franchise',
      render: franchise => franchise && `${franchise} SMS`,
    },
    {
      title: translate('table.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price, record) =>
        price && (
          <>
            {record.type === 'PAY_PER_USE'
              ? `${formatCurrency(Number(price))} / SMS`
              : formatCurrency(Number(price))}
          </>
        ),
    },
    {
      title: translate('columns.actions'),
      key: 'action',
      render: (text, record) => (
        <>
          <HistoricModal contractId={record.id} />
          {tableToShow === 'active' && (
            <AdminActions
              alertDelete={translate('msgs.delete-contract')}
              entityToExclude={record.id}
              onEdit={() => {
                setIsEdition(true);
                setSelectedContract(record);
                setIsModalVisible(true);
              }}
              onDelete={() => handleActions('DELETE', record)}
            />
          )}
        </>
      ),
    },
  ];

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const handleTabsChange = useCallback(key => {
    setTableToShow(key);
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total:
          tableToShow === 'active'
            ? data.totalElements
            : dataTrashed.totalElements,
      }));
    }
  }, [data, dataToHiring, haveManageContract, tableToShow, dataTrashed]);

  return (
    <>
      {!haveManageContract ? (
        <>
          {(dataToHiring?.content && dataToHiring?.content.length > 0) ||
          balanceData?.smsPackage?.availableSMS > 0 ? (
            <Row gutter={[16, 16]}>
              {balanceData?.ppu?.status === 'active' && (
                <Col xs={24} lg={12} xl={8} md={12}>
                  <Card
                    title={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography>
                          {translate(`status-type.PAY_PER_USE`)}
                        </Typography>
                        <Tag color="green">
                          {translate('status-type.ACTIVE')}
                        </Tag>
                      </div>
                    }
                    style={{ width: '100%' }}
                  >
                    <Typography>
                      {balanceData?.ppu?.consumedSMS} SMS{' '}
                      {translate('hire.used')}
                    </Typography>

                    <CancelContract mutate={mutate} />
                  </Card>
                </Col>
              )}
              {dataToHiring?.content.map(item => (
                <Col key={item.id} xs={24} lg={12} xl={8} md={12}>
                  <HiringContractsCard contract={item} mutate={mutate} />
                </Col>
              ))}
            </Row>
          ) : (
            <Empty
              description={translate('not-found')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </>
      ) : (
        <>
          <Tabs
            type="card"
            defaultActiveKey="receive"
            activeKey={tableToShow}
            onChange={handleTabsChange}
            style={{ background: '#ffffff' }}
          >
            <TabPane key="active" tab={translate('tabs.active')} />
            <TabPane key="trashed" tab={translate('tabs.trashed')} />
          </Tabs>
          <Table
            title={
              <>
                {tableToShow === 'active'
                  ? translate('title.contract')
                  : translate('title.trashedContracs')}

                {data?.content && tableToShow === 'active' && (
                  <span
                    style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
                  >
                    {` (${data?.content?.length} ${translate('of', {
                      total: pagination.total,
                    })})`}
                  </span>
                )}
                {dataTrashed?.content && tableToShow === 'trashed' && (
                  <span
                    style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
                  >
                    {` (${dataTrashed?.content?.length} ${translate('of', {
                      total: dataTrashed?.totalElements,
                    })})`}
                  </span>
                )}
              </>
            }
            columns={columns}
            data={
              tableToShow === 'active' ? data?.content : dataTrashed?.content
            }
            loading={
              tableToShow === 'active' ? !data?.content : !dataTrashed?.content
            }
            tableKey="sms-send"
            pagination={pagination}
            onChange={handleTableChange}
            extraActions={
              <>
                {tableToShow === 'active' && (
                  <>
                    {' '}
                    <Tooltip
                      placement="top"
                      title={translate('title.newContract')}
                    >
                      <Button
                        icon={<PlusCircleOutlined />}
                        size="large"
                        type="link"
                        onClick={() => handleAddNew()}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            }
          />
        </>
      )}

      <ContractModal
        visible={isModalVisible}
        contract={selectedContract}
        mutate={mutate}
        isEdition={isEdition}
        setSelectedContract={setSelectedContract}
        onCancel={handleModalClose}
      />
    </>
  );
};

export default SmsContracts;
