import React, { memo, useCallback, useMemo, useState } from 'react';

import { Label } from '@components/ui';
import SingleRangePicker from '@src/components/DatePicker/SingleRangePicker/SingleRangePicker';
import SelectContextItem from '@src/components/SelectContext/SelectContextItem/SelectContextItem';
import apiMiddleware from '@src/services/apiMiddleware';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { Button, Col, Modal, Row, Select, message } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function RequestCancelReports({ visible, onClose }) {
  const { t, i18n } = useTranslation('invoices');
  const navigate = useNavigate();

  const [params, setParams] = useState({});
  const [typeOfReport, setTypeOfReport] = useState('0');

  const [loadingRequest, setLoadingRequest] = useState();
  const { customer } = useContext(SessionContext);

  const requestReport = useCallback(
    () =>
      apiMiddleware.get(
        `/service-proxy/broker/cancellation/${
          typeOfReport === '1' ? 'report-detailed' : 'report'
        }?locale=${i18n.language}`,
        { params },
      ),
    [params, i18n, typeOfReport],
  );

  const handleSetPayload = useCallback(
    newValue =>
      setParams(oldPayload => ({
        ...oldPayload,
        ...newValue,
      })),
    [],
  );

  const handleClose = useCallback(() => {
    onClose();
    setParams({});
    setTypeOfReport('0');
  }, [onClose, setTypeOfReport, setParams]);

  const navigateToReports = useCallback(() => {
    message.destroy('invoice-report');
    typeOfReport === '1'
      ? navigate(`/management/reports?type=CANCELLATION_MANAGEMENT_DETAILED`)
      : navigate(`/management/reports?type=CANCELLATION_MANAGEMENT`);
  }, [navigate, typeOfReport]);

  const handleRequestReport = useCallback(async () => {
    try {
      setLoadingRequest(true);
      await requestReport();
      message.success({
        content: (
          <span>
            {t('report.msgs.request-success')}
            <Button
              onClick={navigateToReports}
              type="link"
              style={{ padding: 3 }}
            >
              {t('report.msgs.go-to')}
            </Button>
            {t('report.msgs.check-progress-and-download')}
          </span>
        ),
      });
      setTypeOfReport('0');
    } catch {
      message.error(t('report.msgs.check-progress-and-download'));
    }
    handleClose();
    setLoadingRequest(false);
  }, [t, navigateToReports, requestReport, handleClose]);

  const mapOptions = useCallback(
    options =>
      options?.map(item => ({
        value: item.id,
        label: (
          <SelectContextItem id={item.id} cpfCnpj={item.cpfCnpj} {...item} />
        ),
        filterProps: `${item.name};${item.tradingName};${item.cpfCnpj};${item.id}`,
      })),
    [],
  );

  const modalInputs = useMemo(() => {
    const inputs = [
      {
        col: {
          lg: 24,
          xl: 24,
          xs: 24,
        },
        label: t('search.customer'),
        item: (
          <Select
            value={params.customerId}
            onSelect={value => {
              handleSetPayload({
                customerId: value,
              });
            }}
            style={{ width: '100%' }}
            allowClear
            filterOption={(input, option) => {
              const verifyString = string =>
                String(string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
              return verifyString(option?.filterProps);
            }}
            showSearch
            options={mapOptions(customer?.customersData)}
          />
        ),
      },
      {
        col: {
          lg: 12,
          xl: 12,
          xs: 24,
        },
        label: t('search.type'),
        item: (
          <Select
            defaultValue={'0'}
            onChange={value => {
              setTypeOfReport(value);
            }}
            style={{ width: '100%' }}
            options={[
              { label: t('search.type-one'), value: '0' },
              { label: t('search.type-two'), value: '1' },
            ]}
          />
        ),
      },
      {
        col: {
          lg: 12,
          xl: 12,
          xs: 24,
        },
        label: t('search.date'),
        item: (
          <SingleRangePicker
            onChange={({ startDate, endDate }) => {
              handleSetPayload({
                requestDateFrom: startDate,
                requestDateTo: endDate,
              });
            }}
            value={[params.requestDateFrom, params.requestDateTo]}
          />
        ),
      },
    ];

    return inputs;
  }, [t, handleSetPayload, customer, mapOptions, params, setTypeOfReport]);

  return (
    <Modal
      bodyStyle={{
        paddingTop: 0,
      }}
      footer={
        <>
          <Button onClick={handleClose}>{t('report.buttons.cancel')}</Button>
          <Button
            type={'primary'}
            loading={loadingRequest}
            disabled={loadingRequest}
            onClick={handleRequestReport}
          >
            {t('report.buttons.request')}
          </Button>
        </>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerForm', htmlType: 'submit' }}
      style={{
        maxWidth: 900,
      }}
      destroyOnClose={true}
      title={t('report.request')}
      visible={visible}
      width={'80%'}
      centered
      onCancel={handleClose}
    >
      <Row gutter={[16, 16]} style={{ padding: 16 }}>
        {modalInputs?.map(({ col: { lg, xl, xs }, label, item }) => (
          <Col key={label} lg={lg} xl={xl} xs={xs}>
            {label && (
              <div>
                <Label color={'#575962'} htmlFor={label}>
                  {label}
                </Label>
              </div>
            )}
            {item}
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default memo(RequestCancelReports);
