import React, { useMemo } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import useSwr from '@hooks/useSwr';

const DashboardTopTable = ({ title, path }) => {
  const { t } = useTranslation('lorawan');

  const { data } = useSwr(
    `/service-proxy/lorawanDevices/${path}?product=LORAWAN_MOT`,
  );

  const columns = useMemo(
    () => [
      {
        title: t('devEui'),
        dataIndex: 'devEui',
      },
      {
        title: t('total'),
        dataIndex: 'value',
        align: 'center',
      },
    ],
    [t],
  );

  return (
    <Card
      title={title}
      headStyle={{
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: 'rgb(87, 89, 98)',
      }}
      bodyStyle={{
        padding: 16,
        height: '100%',
      }}
      style={{ width: '100%' }}
    >
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
      />
    </Card>
  );
};

export default DashboardTopTable;
