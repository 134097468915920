import React, { useCallback, useMemo } from 'react';
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CsvExport from '@components/CsvExport/CsvExport';
import FileUpload from '@components/FileUpload';
import { Button, Space, Tooltip, Modal, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

const UploadStep = ({ onUpload }) => {
  const { t } = useTranslation('lorawan');

  const uploadModel = useMemo(
    () => ({
      column: [
        'devEui',
        'appEui',
        'description',
        'tags',
        'appKey',
        'devAddr',
        'nwksKey',
        'appsKey',
        'blockUplink',
        'blockDownlink',
      ]?.map(columnIndex => ({
        title: t(columnIndex),
        dataIndex: columnIndex,
        key: columnIndex,
      })),
      data: [
        {
          key: '1',
          devEui: '89553202000000159564',
          appEui: '961d6dcaca82d376',
          description: 'description 1',
          tags: 'tag;tag_2;tag_3',
          appKey: '47656a4f03140234d4692118f29733fa',
          devAddr: '8ddfd55d',
          nwksKey: 'b151027bf933a237f78094da53d2f3fe',
          appsKey: '09d6f14c80f69583b530fdb93e27d453',
          blockUplink: 'true',
          blockDownlink: 'false',
        },
        {
          key: '2',
          devEui: 'c21e9a2fd9c2b6ab',
          appEui: '175a25d6bc77f459',
          description: 'description 2',
          tags: 'tag4;tag_5;tag_7',
          appKey: '47656a4f03140234d4692118f29733fa',
          devAddr: '26cbe703',
          nwksKey: '9294482105a7e285fb0bd34cbcf213aa',
          appsKey: '3deed6e0f46a933e537e254dd41a5ce3',
          blockUplink: 'true',
          blockDownlink: 'false',
        },
      ],
    }),
    [t],
  );

  const info = useCallback(() => {
    Modal.info({
      title: t('csv-format-example'),
      width: 500,
      content: (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Table
            bordered
            tableLayout="auto"
            size="small"
            columns={uploadModel?.column}
            pagination={false}
            dataSource={uploadModel?.data}
            style={{ width: '100%', overflow: 'auto' }}
          />
          <CsvExport
            data={uploadModel?.data}
            filename="activation-model.csv"
            columns={uploadModel?.column}
            separator=","
          >
            <Button icon={<DownloadOutlined />} size="large" type="link">
              {t('download-model')}
            </Button>
          </CsvExport>
        </Space>
      ),

      onOk() {
        document.body.style.removeProperty('overflow');
      },
    });
  }, [t, uploadModel?.column, uploadModel?.data]);

  return (
    <>
      <Space>
        <Text style={{ opacity: 0.8 }}>
          {t('select-devices')}
          <Tooltip title={t('help')} placement="right">
            <Button
              type="link"
              size="small"
              icon={<QuestionCircleOutlined />}
              onClick={info}
            />
          </Tooltip>
        </Text>
      </Space>
      <FileUpload
        style={{ marginTop: 10 }}
        onUpload={onUpload}
        uploadColumns={uploadModel?.column}
      />
    </>
  );
};

export default UploadStep;
