import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Checkbox,
  Tabs,
  Row,
  Col,
  Divider,
  Tooltip,
  Button,
  Radio,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  view,
  actions,
  inventory,
  inventColumns,
  inventMoreInfo,
  publicApi,
} from '@utils/rolesConstants';

const { TabPane } = Tabs;

function PermissionsCard({ groupedRoles, selectedRoles, setSelectedRoles }) {
  const { t } = useTranslation(['permissions', 'roles', 'inventory']);
  const [checkedList, setCheckedList] = useState(selectedRoles);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [publicApiGroupedRoles, setPublicApiGroupedRoles] = useState();
  const [clientActive, setClientActive] = useState('portal');
  const [activeTab, setActiveTab] = useState();
  const [hasPublicApiRoles, setHasPublicApiRoles] = useState(false);

  useEffect(() => {
    if (publicApiGroupedRoles) {
      const objects = publicApiGroupedRoles[1];
      const verifyIfHasPublicApi = Object.keys(objects)?.some(
        name => objects[name]?.length > 0,
      );
      if (verifyIfHasPublicApi) {
        setHasPublicApiRoles(true);
      }
    }
  }, [publicApiGroupedRoles]);

  const permissionsOptions = useMemo(() => {
    const allPermissions = [];
    const allGroups = groupedRoles?.map(([, permissions]) => permissions);
    allGroups?.forEach(group => {
      group.forEach(permission => allPermissions.push(permission.id));
    });
    return allPermissions;
  }, [groupedRoles]);

  useEffect(() => {
    setCheckedList(selectedRoles);
  }, [selectedRoles]);

  useEffect(() => {
    if (selectedRoles?.length > 0) {
      setIndeterminate(true);
    }
    if (selectedRoles?.length >= permissionsOptions?.length) {
      setIndeterminate(false);
      setCheckAll(true);
    }
  }, [permissionsOptions, selectedRoles]);

  const onCheckAllChange = useCallback(
    e => {
      const newList = e.target.checked ? permissionsOptions : [];
      setCheckedList(newList);
      setSelectedRoles(newList);
      setIndeterminate(false);
      setCheckAll(e.target.checked);
    },
    [permissionsOptions, setSelectedRoles],
  );

  const onChange = useCallback(
    list => {
      setCheckedList(list);
      setSelectedRoles(list);
      setIndeterminate(
        !!list.length && list.length < permissionsOptions.length,
      );
      setCheckAll(list.length === permissionsOptions.length);
    },
    [permissionsOptions.length, setSelectedRoles],
  );

  const categorizedSectionsRoles = useCallback(permissions => {
    const roles = {
      viewRoles: [],
      othersRoles: [],
      manageRoles: [],
      actionsRoles: [],
      batchRoles: [],
      inventColumnsRoles: [],
      inventMoreInfoRoles: [],
    };

    const allViewRoles = Object.values(view);
    const allActionsRoles = Object.values(actions);
    const allInventoryColumnsRoles = Object.values(inventColumns);
    const allInventoryMoreInfoRoles = Object.values(inventMoreInfo);

    permissions.forEach(role => {
      if (allViewRoles.includes(role.name) || role.name.includes('view')) {
        roles.viewRoles.push(role);
      } else if (role.name.includes('manage') && role.name.length <= 10) {
        roles.manageRoles.push(role);
      } else if (allActionsRoles.includes(role.name)) {
        roles.actionsRoles.push(role);
      } else if (role.name.includes('batch')) {
        roles.batchRoles.push(role);
      } else if (allInventoryColumnsRoles.includes(role.name)) {
        roles.inventColumnsRoles.push(role);
      } else if (allInventoryMoreInfoRoles.includes(role.name)) {
        roles.inventMoreInfoRoles.push(role);
      } else {
        roles.othersRoles.push(role);
      }
    });

    return roles;
  }, []);

  const categorizedRoles = useMemo(() => {
    const groups = [];
    groupedRoles?.forEach(([category, permissions]) => {
      if (category === 'general') {
        const inventoryRoles = [];
        const publicApiRoles = [];

        const allInventoryRoles = Object.values(inventory);
        const allPublicApiRoles = Object.values(publicApi);

        permissions.forEach(role => {
          if (allInventoryRoles.includes(role.name)) {
            inventoryRoles.push(role);
          } else if (allPublicApiRoles.includes(role.name)) {
            publicApiRoles.push(role);
          }
        });
        groups.push([
          'inventory',
          {
            ...categorizedSectionsRoles(inventoryRoles),
          },
        ]);
        setPublicApiGroupedRoles([
          'public-api',
          {
            ...categorizedSectionsRoles(publicApiRoles),
          },
        ]);
      } else {
        groups.push([
          category,
          {
            ...categorizedSectionsRoles(permissions),
          },
        ]);
      }
    });

    return groups;
  }, [groupedRoles, categorizedSectionsRoles]);

  const rolesSections = useMemo(
    () => [
      {
        title: t('sections.view'),
        listName: 'viewRoles',
      },
      {
        title: t('sections.manage'),
        listName: 'manageRoles',
      },
      {
        title: t('sections.actions'),
        listName: 'actionsRoles',
      },
      {
        title: t('sections.batch-actions'),
        listName: 'batchRoles',
      },
      {
        title: t('sections.inventory-columns'),
        listName: 'inventColumnsRoles',
      },
      {
        title: t('sections.inventory-more-info'),
        listName: 'inventMoreInfoRoles',
        help: t('sections.inventory-more-info-help'),
      },
      {
        title: t('sections.others'),
        listName: 'othersRoles',
      },
    ],
    [t],
  );

  const sortAlphabetically = useCallback(
    list =>
      list.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    [],
  );

  const orderedPermissions = useCallback(
    (permissions, category) => {
      const allPermissions = permissions?.map(({ id, name }) => ({
        id,
        name: t(`${category}.permissions.${name}`),
      }));

      sortAlphabetically(allPermissions);

      return allPermissions;
    },
    [t, sortAlphabetically],
  );

  const handleTabPane = useCallback(
    (groups, disabled) =>
      groups?.map(([category, permissions]) => (
        <TabPane
          key={category}
          tab={t(`${category}.title`)}
          disabled={disabled}
          forceRender
        >
          {rolesSections?.map(
            ({ title, listName, help }) =>
              permissions?.[listName]?.length > 0 && (
                <Row key={title}>
                  <Divider
                    orientation="left"
                    style={{
                      fontSize: '0.9rem',
                      opacity: '0.7',
                    }}
                  >
                    {title.toUpperCase()}
                    {help && (
                      <Tooltip title={help}>
                        <Button type="link" icon={<InfoCircleOutlined />} />
                      </Tooltip>
                    )}
                  </Divider>
                  {orderedPermissions(permissions?.[listName], category)?.map(
                    ({ id, name }) => (
                      <Col key={id} lg={12} xs={24}>
                        <Checkbox value={id}>{name}</Checkbox>
                      </Col>
                    ),
                  )}
                </Row>
              ),
          )}
        </TabPane>
      )),
    [orderedPermissions, rolesSections, t],
  );

  const clients = useMemo(
    () => [
      {
        name: 'portal',
        permissions: disabled =>
          categorizedRoles && handleTabPane(categorizedRoles, disabled),
      },
      {
        name: 'public-api',
        permissions: disabled =>
          publicApiGroupedRoles &&
          hasPublicApiRoles &&
          handleTabPane([publicApiGroupedRoles], disabled),
      },
    ],
    [categorizedRoles, handleTabPane, publicApiGroupedRoles, hasPublicApiRoles],
  );

  useEffect(() => {
    if (clientActive === 'portal') {
      setActiveTab('assets');
    } else if (clientActive === 'public-api') {
      setActiveTab('public-api');
    }
  }, [clientActive]);

  return (
    <>
      <div
        style={{
          textAlign: 'right',
          marginTop: !hasPublicApiRoles && '-10px',
          display: hasPublicApiRoles ? 'flex' : 'block',
          justifyContent: 'space-between',
          marginBottom: hasPublicApiRoles && 24,
        }}
      >
        {hasPublicApiRoles && (
          <Radio.Group
            defaultValue={clientActive}
            onChange={({ target: { value } }) => setClientActive(value)}
            size="small"
          >
            {clients?.map(({ name }) => (
              <Radio.Button key={name} value={name}>
                {t(`${name}.title`)}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {t('roles:modal.select-all')}
        </Checkbox>
      </div>
      <Checkbox.Group
        style={{ width: '100%' }}
        value={checkedList}
        onChange={onChange}
      >
        <Tabs
          style={{ minHeight: 250 }}
          activeKey={activeTab}
          tabBarGutter={30}
          onChange={setActiveTab}
        >
          {clients?.map(({ name, permissions }) =>
            permissions(name !== clientActive),
          )}
        </Tabs>
      </Checkbox.Group>
    </>
  );
}

export default memo(PermissionsCard);
