import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
  padding: 16px;
  border: 2px dashed #dbdbdb;
  background: #fafafa;
  border-radius: 8px;

  .ant-divider {
    margin: 0 0 16px 0;
    text-align: center;
    opacity: 0.8;
    font-size: 0.9rem;
  }
`;
export const Content = styled.div`
  position: relative;
  outline: 2px dashed gray;
  border-radius: 8px;
  background: #dbdbdb;
  min-height: 124px;

  .ant-image-img {
    border-radius: 8px;
  }

  .banner-preview-empty-image {
    font-size: 2.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: gray;
    opacity: 0.4;
  }

  .ant-btn {
    position: absolute;
    font-size: 0.8rem;
    right: 16px;
    bottom: 16px;
    outline: 2px dashed green;
  }
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  background: linear-gradient(0deg, #222 -80%, rgba(0, 0, 0, 0) 80%);
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
