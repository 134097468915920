import React from 'react';
import ParagraphTitle from '@src/components/ParagraphTitle/index';
import { Form, Radio, Input, Divider, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import ImageUpload from '@src/components/ImageUpload/index';
import ButtonHelp from '@src/components/ButtonHelp/index';

const BannerExtraDetails = ({ form, defaultFile }) => {
  const { t } = useTranslation('banners');
  const type = Form.useWatch('type', form);
  const enabledDefaultButton = Form.useWatch('enabledDefaultButton', form);

  return (
    <>
      <Divider
        style={{
          margin: '40px 0',
        }}
      />
      <ParagraphTitle
        title={t('modal.details.title')}
        style={{
          marginBottom: 16,
        }}
      />
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: t('msgs.required'),
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="url">{t('modal.details.link')}</Radio.Button>
          <Radio.Button value="img">{t('modal.details.img')}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {type === 'url' && (
        <Form.Item
          name="link"
          label="URL"
          rules={[
            {
              required: true,
              message: t('msgs.required'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      )}
      {type === 'img' && (
        <Form.Item
          name="fileDetail"
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: t('msgs.required'),
            },
          ]}
        >
          <ImageUpload defaultFile={defaultFile} />
        </Form.Item>
      )}

      <Form.Item
        name="enabledDefaultButton"
        valuePropName="checked"
        style={{ margin: '8px 0 0 0' }}
      >
        <Checkbox>
          {t('modal.enabled-default-button')}
          <ButtonHelp text={t('modal.default-button-help')} />
        </Checkbox>
      </Form.Item>
      {enabledDefaultButton && (
        <>
          <Divider
            style={{
              margin: '40px 0',
            }}
          />
          <ParagraphTitle
            title={t('modal.button-text-title')}
            subtitle={t('modal.button-text-subtitle')}
          />
          <Form.Item name="buttonText">
            <Input placeholder={t('modal.button-default-text')} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default BannerExtraDetails;
