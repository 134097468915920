import React, { useCallback, useMemo } from 'react';

import {
  CalendarOutlined,
  CodeOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';

import Stat from '@components/Stat/Stat';
import { Col, Descriptions, Row, Tag } from 'antd';
import { Card } from '@components/ui';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DeviceStats = ({ data, style }) => {
  const { t } = useTranslation('lorawan');

  const mainDetails = useMemo(
    () => [
      {
        name: 'devEui',
        icon: <DatabaseOutlined />,
        value: data?.devEui,
      },
      {
        name: 'appEui',
        icon: <DatabaseOutlined />,
        value: data?.appEui,
      },
      {
        name: 'activationDate',
        icon: <CalendarOutlined />,
        value:
          data?.activationDate &&
          moment(data?.activationDate)?.format('DD/MM/YY'),
      },
    ],
    [data],
  );

  const activationDetails = useMemo(
    () => [
      {
        name: 'activation',
        icon: <CodeOutlined />,
        value: data?.activationType,
      },
      {
        name: 'encryption',
        icon: <CodeOutlined />,
        value: data?.encryptionType,
      },
      {
        name: 'status',
        icon: (
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: 16,
              background:
                data?.status === 'ACTIVE' ? 'var(--primary-color)' : 'red',
            }}
          />
        ),
        value: t(data?.status === 'ACTIVE' ? 'activated' : 'deactivated'),
      },
    ],
    [data, t],
  );

  const renderBlocked = useCallback(
    isBlocked => (
      <Tag color={isBlocked ? 'green' : 'red'}>
        {t(isBlocked ? 'activated' : 'deactivated')}
      </Tag>
    ),
    [t],
  );

  const txPowerOptions = useMemo(
    () =>
      // Do not change the order of the elements.
      [30, 28, 26, 24, 22, 20, 18, 16, 14, 12, 10]?.map(tx => `${tx}dbm`),
    [],
  );

  const renderTxPower = useMemo(() => {
    if (data?.adrTxPower || data?.adrTxPower === 0) {
      return txPowerOptions[data?.adrTxPower];
    }
    return undefined;
  }, [data?.adrTxPower, txPowerOptions]);

  const renderAdrMode = useMemo(() => {
    const adrModeLabels = {
      on: t('activated'),
      off: t('deactivated'),
      static: t('static'),
    };

    return data?.adrMode && adrModeLabels[data?.adrMode];
  }, [data?.adrMode, t]);

  const detailsDescriptions = useMemo(
    () => [
      {
        label: t('description'),
        value: data?.description,
      },
      {
        label: t('tags'),
        value: data?.tags?.map(tag => <Tag key={tag}>{tag}</Tag>),
      },
      {
        label: t('devAddr'),
        value: data?.devAddr,
      },
      {
        label: t('adrMode'),
        value: renderAdrMode,
      },
      {
        label: t('adrTxPower'),
        value: renderTxPower,
      },
      {
        label: t('devClass'),
        value: data?.devClass,
      },
      {
        label: t('countersSize'),
        value: data?.countersSize,
      },
      {
        label: t('appKey'),
        value: data?.appkey,
      },
      {
        label: t('blockDownlink'),
        value: renderBlocked(data?.blockDownlink),
      },
      {
        label: t('blockUplink'),
        value: renderBlocked(data?.blockUplink),
      },
    ],
    [
      t,
      data?.description,
      data?.tags,
      data?.devAddr,
      data?.blockUplink,
      data?.blockDownlink,
      data?.devClass,
      data?.countersSize,
      data?.appkey,
      renderAdrMode,
      renderTxPower,
      renderBlocked,
    ],
  );

  const largeDescriptions = useMemo(
    () => [
      {
        label: t('appsKey'),
        value: data?.appskey,
      },
      {
        label: t('nwksKey'),
        value: data?.nwkskey,
      },
    ],
    [data, t],
  );

  return (
    <Row gutter={[16, 16]} style={style}>
      {mainDetails?.map(({ name, icon, value }) => (
        <Col key={name} lg={8} md={8} xl={8} xs={24}>
          <Stat
            title={t(name)}
            icon={icon}
            value={value ?? ''}
            loading={!data}
          />
        </Col>
      ))}
      {activationDetails?.map(({ name, icon, value }) => (
        <Col key={name} lg={8} md={8} xl={8} xs={24}>
          <Stat
            title={t(name)}
            icon={icon}
            value={value ?? ''}
            loading={!data}
          />
        </Col>
      ))}
      <Col span={24}>
        <Card bodyStyle={{ padding: 24, paddingBottom: 8 }} loading={!data}>
          <Descriptions column={2}>
            {largeDescriptions?.map(
              ({ label, value }) =>
                value && (
                  <Descriptions.Item key={label} label={label}>
                    {value}
                  </Descriptions.Item>
                ),
            )}
          </Descriptions>
          <Descriptions>
            {detailsDescriptions?.map(
              ({ label, value }) =>
                value && (
                  <Descriptions.Item key={label} label={label}>
                    {value}
                  </Descriptions.Item>
                ),
            )}
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

export default DeviceStats;
