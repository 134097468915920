import React, { useContext, useEffect, useMemo } from 'react';
import { Form, Row, Col, Input, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import TagsManagement from '@components/TagsManagement';
import useSwr from '@hooks/useSwr';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

import UploadStep from './UploadStep';
import RequestContractForm from '@src/pages/Lorawan/Requests/NewRequestModal/RequestContractForm';

const { Item } = Form;

const DetailsStep = ({
  form,
  isBatch,
  onUpload,
  renderItemLabel,
  renderInputItem,
  activation,
  encryption,
}) => {
  const { t } = useTranslation('lorawan');
  const { keycloak } = useContext(KeycloakContext);
  const customerId = keycloak?.idTokenParsed?.customerId;
  const formValues = Form.useWatch([], form);

  const isActivationOtaa = activation === 'OTAA';
  const isActivationAbp = activation === 'ABP';
  const isEncryptionNs = encryption === 'NS';
  const isEncryptionApp = encryption === 'APP';

  const { data: dataContracts } = useSwr(
    customerId ? '/service-proxy/contracts' : null,
    {
      customerId,
      product: 'LORAWAN',
    },
  );

  const contractOptions = useMemo(
    () =>
      dataContracts &&
      dataContracts?.map(({ planName, assetNumber }) => ({
        value: assetNumber,
        label: `${assetNumber} - ${planName}`,
      })),
    [dataContracts],
  );

  useEffect(() => {
    if (contractOptions?.length === 1) {
      form.setFieldValue('contractId', contractOptions[0].value);
    }
  }, [contractOptions, form]);

  return (
    <Form
      colon={false}
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError
      layout="vertical"
    >
      <RequestContractForm
        onlyFormItems
        parentForm={form}
        contractMotId={formValues?.contractMotId}
        contractLorawanId={formValues?.contractLorawanId}
        requestId={formValues?.requestId}
        span={24}
      />
      <Divider style={{ marginTop: 16 }} />
      {isBatch ? (
        <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
          <Col span={24}>
            <Item
              name="uploadedDevices"
              rules={[
                {
                  required: true,
                  message: t('fieldIsRequired'),
                },
              ]}
            >
              <UploadStep
                onUpload={values => {
                  onUpload(values);
                  form.setFieldValue('uploadedDevices', values);
                }}
              />
            </Item>
          </Col>
        </Row>
      ) : (
        <>
          <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
            <Col xs={24} sm={12}>
              {renderInputItem('devEui', 16, true)}
            </Col>
            <Col xs={24} sm={12}>
              {renderInputItem('appEui', 16, true)}
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Item label={t('description')} name="description">
                <Input allowClear />
              </Item>
            </Col>
            <Col span={24}>
              <Item label={renderItemLabel('tags')} name="tags">
                <TagsManagement
                  tags={formValues?.tags ?? []}
                  onChange={newTags => {
                    form.setFieldValue('tags', newTags);
                  }}
                />
              </Item>
            </Col>
          </Row>
          {/*  
      -----------------------------------------------------
      ATTRIBUTES FROM THE TYPE OF ACTIVATION AND ENCRYPTION
      -----------------------------------------------------
      OTAA + NS = Only 'appKey'
      OTAA + APP = None
      ABP + NS = 'devAddr', 'nwksKey' and 'appsKey'
      ABP + APP = 'devAddr' and 'nwksKey'
      -----------------------------------------------------
      -----------------------------------------------------
      */}
          {!(isActivationOtaa && isEncryptionApp) && (
            <Divider orientation="left">{t('security')}</Divider>
          )}
          <Row gutter={[8, 8]} style={{ marginTop: 24 }}>
            {isActivationOtaa && isEncryptionNs && (
              <Col span={24}>{renderInputItem('appKey', 32, true)}</Col>
            )}
            {isActivationAbp && (
              <>
                <Col span={24}>{renderInputItem('devAddr', 8, true)}</Col>
                <Col span={24}>{renderInputItem('nwksKey', 32, true)}</Col>
                {isEncryptionNs && (
                  <Col span={24}>{renderInputItem('appsKey', 32, true)}</Col>
                )}
              </>
            )}
          </Row>
        </>
      )}
    </Form>
  );
};

export default DetailsStep;
