import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { Button, Input, Tabs, Tooltip, Select, Col, Tag } from 'antd';
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/ui';
import NewRequestModal from './NewRequestModal/NewRequestModal';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import RoleChecker, { useCheckRole } from '@components/RoleChecker/RoleChecker';
import roles from '@utils/rolesConstants';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import SessionContext from '@src/store/SessionContext/SessionContext';
import AdminActionsRequest from './AdminActionsRequest/AdminActionsRequest';
import { extractDateInISOFormat, formatCurrency } from '@src/utils/formatters';
import SelectCustomers from '@src/components/SelectCustomers/index';
import './styles.less';

const { Search } = Input;
const { TabPane } = Tabs;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Requests = ({
  isInTheContractDetails,
  defaultCustomerId,
  defaultContractId,
  defaultFilters,
  defaultColumns,
}) => {
  const { keycloak } = useContext(KeycloakContext);
  const { hasCustomersRoles } = useContext(SessionContext);
  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';
  const isBroker = ['SUPERBROKER', 'BROKER'].includes(customerType);
  const canManage = useCheckRole({ role: roles.MANAGE_ACCOUNTS });

  const navigate = useNavigate();
  const searchLocation = useLocation().search;

  const { t } = useTranslation(['requests', 'attributes', 'contracts']);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [modalVisible, setModalVisible] = useState(false);
  const [showContracted, setShowContracted] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [description, setDescription] = useState();
  const [descriptionValue, setDescriptionValue] = useState();
  const [contractId, setContractId] = useState();

  const treatedShowContracted = useMemo(() => {
    if (isEndCustomer) {
      return 'true';
    }
    return showContracted !== 'archived' ? showContracted : undefined;
  }, [isEndCustomer, showContracted]);

  const { data, mutate } = useSwr(
    `/service-proxy/broker/virtual-account${
      showContracted === 'archived' ? '/archived' : ''
    }`,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      showContracted: treatedShowContracted,
      name: description,
      customerId: selectedClient,
      contractId,
      ...defaultFilters,
    },
  );

  const { data: contractsByClient } = useSwr(
    selectedClient
      ? `/service-proxy/broker/${selectedClient}/virtual-plan`
      : null,
  );

  const contractsByClientOptions = useMemo(
    () =>
      contractsByClient !== '' &&
      contractsByClient?.map(({ id, planDesc }) => ({
        label: planDesc,
        value: id,
      })),
    [contractsByClient],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'showContracted',
        setState: setShowContracted,
      },
      {
        name: 'customerId',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'description',
        setState: setDescription,
        setStateValue: setDescriptionValue,
        inTheFilters: true,
      },
      {
        name: 'contractId',
        setState: setContractId,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['showContracted', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [showContracted, selectedClient, description, contractId]);

  useEffect(() => {
    if (!isEndCustomer) {
      const showContractedInParams = new URLSearchParams(searchLocation).get(
        'showContracted',
      );
      if (showContractedInParams === null) {
        handleSetSearchParams({ showContracted: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetSearchParams, isEndCustomer]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const adminActions = useCallback(
    (_, record) => (
      <AdminActionsRequest
        data={data}
        mutate={mutate}
        showArchived
        item={record}
        showContracted={showContracted}
        isInTheContractDetails={isInTheContractDetails}
      />
    ),
    [data, mutate, showContracted, isInTheContractDetails],
  );

  const contractDescriptionLink = useCallback(
    (_, record) =>
      record.virtualContract?.id ? (
        <Tooltip title={t('view-contract')}>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/financial/contracts/${record.virtualContract?.id}${
                  showContracted ? `?showContracted=${showContracted}` : ''
                }`,
              )
            }
          >
            {record.virtualContract?.planDesc}
          </Button>
        </Tooltip>
      ) : (
        record.virtualContract?.planDesc
      ),
    [t, navigate, showContracted],
  );

  const sharingType = useCallback(
    (_, record) => {
      const virtualContract = record.virtualContract;
      if (virtualContract?.payPerUseFlag === 'S') {
        return t('attributes:type.payPerUse');
      }
      const type = t(`attributes:type.${virtualContract?.sharingType}`);

      return t(`attributes:${type}`);
    },
    [t],
  );

  const chargingType = useCallback(
    (_, record) => {
      const virtualContract = record.virtualContract;
      const operatorDiffChargeFlag = virtualContract?.operatorDiffChargeFlag;

      if (operatorDiffChargeFlag === 'S') {
        return t('attributes:chargingType.difPerOp');
      } else if (operatorDiffChargeFlag === 'N') {
        return t('attributes:chargingType.fixed');
      }
    },
    [t],
  );

  const isErrorStatus = useCallback(status => status === 'ERROR', []);

  const activatedColumn = useCallback(
    value => {
      const [color, text] = value
        ? ['green', 'activated']
        : ['red', 'disabled'];

      if (value || value === false) {
        return (
          <Tag color={color}>
            {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
          </Tag>
        );
      }
    },
    [t],
  );

  const handleHasTag = useCallback(
    value => {
      const [color, text] = value ? ['green', 'yes'] : ['red', 'no'];
      return (
        <Tag color={color}>{t(`attributes:hasTag.${text}`).toUpperCase()}</Tag>
      );
    },
    [t],
  );

  const allColumns = useMemo(
    () => [
      {
        title: t('columns.id'),
        dataIndex: 'id',
        render: (value, record) => {
          const isError = record.status === 'ERROR';

          if (isError) {
            return (
              <>
                <Tooltip title={t('error-creating-order')}>
                  <Button
                    danger
                    type="link"
                    icon={<ExclamationCircleOutlined />}
                    style={{
                      position: 'absolute',
                      left: 12,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                </Tooltip>
                <span>{value}</span>
              </>
            );
          }
          return value;
        },
      },
      { title: t('columns.description'), dataIndex: 'name' },
      {
        title: t('columns.customerName'),
        dataIndex: 'customerName',
        render: (_, record) => record.customer?.name,
        visible: showContracted !== 'true',
      },
      {
        title: t('columns.planDesc'),
        dataIndex: 'planDesc',
        align: 'center',
        render: contractDescriptionLink,
      },
      {
        title: t('attributes:type.title'),
        dataIndex: 'sharingType',
        align: 'center',
        render: sharingType,
      },
      {
        title: t('attributes:chargingType.title'),
        dataIndex: 'operatorDiffChargeFlag',
        align: 'center',
        render: chargingType,
      },
      {
        title: t('attributes:franchise'),
        dataIndex: 'franchise',
        align: 'center',
        render: (_, record) =>
          record.virtualContract?.franchise &&
          `${record.virtualContract?.franchise} MB`,
      },
      {
        title: t('attributes:monthlyPayment'),
        dataIndex: 'value',
        align: 'center',
        render: value => formatCurrency(Number(value ?? 0)),
      },
      {
        title: t('attributes:activationPrice'),
        dataIndex: 'activationPrice',
        align: 'center',
        render: value => formatCurrency(Number(value ?? 0)),
      },
      {
        title: t('attributes:excMbValue'),
        dataIndex: 'megabyteExcPrice',
        align: 'center',
        render: value => formatCurrency(Number(value ?? 0)),
      },
      {
        title: t('attributes:activationDate'),
        dataIndex: 'activationDate',
        align: 'center',
        render: value => value && extractDateInISOFormat(value),
      },
      {
        title: t('attributes:nbPreActivationDays'),
        dataIndex: 'nbPreActivationDays',
        align: 'center',
        render: value =>
          !value || value === 0
            ? t('attributes:noPreActivation')
            : t('attributes:day', {
                count: value,
              }),
      },
      {
        title: t('attributes:automaticBlocking.title'),
        dataIndex: 'automaticBlocking',
        align: 'center',
        render: activatedColumn,
      },
      {
        title: t('attributes:roaming'),
        dataIndex: 'roaming',
        align: 'center',
        render: activatedColumn,
      },
      {
        title: t('columns.actions'),
        dataIndex: 'actions',
        align: 'center',
        render: adminActions,
      },
      {
        title: t('attributes:isca'),
        dataIndex: 'isca',
        align: 'center',
        render: handleHasTag,
      },
    ],
    [
      t,
      showContracted,
      contractDescriptionLink,
      sharingType,
      chargingType,
      activatedColumn,
      adminActions,
      handleHasTag,
    ],
  );

  const columns = useMemo(() => {
    if (defaultColumns) {
      return allColumns
        ?.filter(col => col.visible !== false)
        .filter(column => defaultColumns.includes(column.dataIndex));
    }

    return allColumns.filter(col => col.visible !== false);
  }, [defaultColumns, allColumns]);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const handleNewRequestMutate = useCallback(
    newRequest => {
      mutate({
        ...data,
        content: [newRequest, ...data.content].slice(0, 10),
      });
      setPagination(oldPagination => ({
        ...oldPagination,
        totalElements: oldPagination.totalElements + 1,
      }));
    },
    [data, mutate],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible:
          hasCustomersRoles &&
          !isInTheContractDetails &&
          showContracted !== 'true',
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('columns.customerName'),
        item: (
          <SelectCustomers
            value={selectedClient && Number(selectedClient)}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
      {
        visible: contractsByClientOptions,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('columns.planDesc'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={contractsByClientOptions}
            loading={!contractsByClientOptions}
            disabled={!contractsByClientOptions}
            placeholder={t('columns.planDesc')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={contractId && Number(contractId)}
            onChange={value => handleSetSearchParams({ contractId: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('columns.description'),
        item: (
          <Search
            placeholder={t('columns.description')}
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      handleSetSearchParams,
      hasCustomersRoles,
      selectedClient,
      descriptionValue,
      contractId,
      contractsByClientOptions,
      isInTheContractDetails,
      showContracted,
    ],
  );

  const handleTabsChange = useCallback(
    key => {
      handleSetSearchParams({ showContracted: key });
      setPagination(oldPagination => ({
        ...oldPagination,
        current: 1,
      }));
    },
    [handleSetSearchParams],
  );

  const rowClassName = useCallback(
    record =>
      isErrorStatus(record?.status) && 'request-creation-error-table-cell',
    [isErrorStatus],
  );

  return (
    <>
      {modalVisible && (
        <RoleChecker role={roles.MANAGE_ACCOUNTS}>
          <NewRequestModal
            visible={modalVisible}
            newRequestMutate={handleNewRequestMutate}
            onClose={() => setModalVisible(false)}
            defaultContractId={defaultContractId}
            defaultCustomerId={defaultCustomerId}
            showContracted={showContracted}
          />
        </RoleChecker>
      )}
      {isBroker && !isInTheContractDetails && (
        <Tabs
          type="card"
          defaultActiveKey="receive"
          activeKey={showContracted}
          onChange={handleTabsChange}
          style={{ background: '#ffffff' }}
        >
          <TabPane key="false" tab={t('visions-steps.receive')} />
          {customerType === 'BROKER' && (
            <TabPane key="true" tab={t('visions-steps.pay')} />
          )}
          <TabPane key="archived" tab={t('visions-steps.archived')} />
        </Tabs>
      )}
      <Table
        title={
          <Filters
            title={t('title')}
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
          />
        }
        columns={columns}
        allColumns={allColumns}
        data={data?.content}
        loading={!data?.content}
        pagination={pagination}
        tableKey={isInTheContractDetails ? 'contractDetails' : 'requests'}
        onChange={handleTableChange}
        rowClassName={rowClassName}
        extraActions={
          !isEndCustomer &&
          canManage && (
            <Tooltip title={t('newRequest')}>
              <Button
                icon={<PlusCircleOutlined />}
                size="large"
                type="link"
                onClick={() => setModalVisible(true)}
              />
            </Tooltip>
          )
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Requests;
