import React, { useCallback, useMemo } from 'react';
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CsvExport from '@components/CsvExport/CsvExport';
import FileUploader from './FileUploader';
import { Button, Space, Tooltip, Modal, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import { getUploadModels } from './UploadModels';

const ActionsUpload = ({
  onUpload,
  actionKey,
  onRemove,
  csvFilename,
  customInfo,
  disabled,
  ...props
}) => {
  const { t } = useTranslation('device-actions-modal');

  const uploadModels = useMemo(
    () =>
      getUploadModels({
        t,
        actionKey,
        columnsNames: props?.columnsNames,
      }),
    [t, actionKey, props?.columnsNames],
  );

  const info = useCallback(() => {
    Modal.info({
      title: t('upload.csv-format-example'),
      width: 700,
      content: (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Table
            bordered
            tableLayout="auto"
            size="small"
            columns={uploadModels?.columns}
            pagination={false}
            dataSource={uploadModels?.data}
            style={{ width: '100%' }}
          />
          <CsvExport
            data={uploadModels?.data}
            filename={`${csvFilename ?? 'import-model'}.csv`}
            columns={uploadModels?.columns}
            separator=","
          >
            <Button icon={<DownloadOutlined />} type="primary">
              {t('upload.download-model')}
            </Button>
          </CsvExport>
          {customInfo}
        </Space>
      ),
      okButtonProps: {
        type: 'default',
      },
      onOk() {
        document.body.style.removeProperty('overflow');
      },
    });
  }, [t, uploadModels?.columns, uploadModels?.data, customInfo, csvFilename]);

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Text>
          {t('upload.select-devices')}
          <Tooltip title={t('upload.help')} placement="right">
            <Button
              type="link"
              size="small"
              icon={<QuestionCircleOutlined />}
              onClick={info}
              disabled={disabled}
            />
          </Tooltip>
        </Text>
      </Space>
      <FileUploader
        onUpload={onUpload}
        uploadColumns={uploadModels?.columns}
        onRemove={onRemove}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default ActionsUpload;
