import React from 'react';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';

import './styles.less';
import OnlineOrOffline from '../../Dashboard/OnlineOrOffline/OnlineOrOffline';
import StatusLines from '../../Dashboard/StatusLines/StatusLines';

const Widgets = ({
  handleSetSearchParams,
  filters,
  handleChange,
  ...props
}) => {
  const { t } = useTranslation('inventory');

  return (
    <Card title={t('widgets.line-info')} {...props}>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'stretch' }}
        className="widgets-wrapper"
      >
        <div style={{ flex: 1, padding: '0 10px' }}>
          <OnlineOrOffline
            handleSetSearchParams={values => {
              handleChange('online', true, 'true');
              handleSetSearchParams(values);
            }}
            filters={filters}
          />
        </div>
        {/* <div style={{ flex: 1, padding: '0 10px' }}>
          <OnlineOrOffline
            handleSetSearchParams={values => {
              handleChange('online', true, 'false');
              handleSetSearchParams(values);
            }}
            isOnline={false}
            filters={filters}
          />
        </div> */}
        <div style={{ flex: 1, padding: '0 10px' }}>
          <StatusLines
            days={30}
            handleSetSearchParams={value => {
              handleSetSearchParams(value);
              handleChange(
                'lastConnectionFrom',
                true,
                value.lastConnectionFrom,
              );
            }}
            filters={filters}
          />
        </div>
        <div style={{ flex: 1, padding: '0 10px' }}>
          <StatusLines
            days={60}
            handleSetSearchParams={value => {
              handleSetSearchParams(value);
              handleChange(
                'lastConnectionFrom',
                true,
                value.lastConnectionFrom,
              );
            }}
            filters={filters}
          />
        </div>
        <div style={{ flex: 1, padding: '0 10px' }}>
          <StatusLines
            days={90}
            handleSetSearchParams={value => {
              handleSetSearchParams(value);
              handleChange(
                'lastConnectionFrom',
                true,
                value.lastConnectionFrom,
              );
            }}
            filters={filters}
          />
        </div>
      </div>
    </Card>
  );
};

export default Widgets;
