import React from 'react';

import * as S from './styles';

const ParagraphTitle = ({ title, subtitle, ...rest }) => (
  <S.Container {...rest}>
    <h2>{title}</h2>
    {subtitle && <p>{subtitle}</p>}
  </S.Container>
);

export default ParagraphTitle;
