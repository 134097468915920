import styled from 'styled-components';

import backgroundImage from '@images/bg-3.jpg';

const Background = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export default Background;
