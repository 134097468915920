import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './styles.less';
import { useTranslation } from 'react-i18next';

const BackToListing = ({ title, noPadding = false }) => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();

  return (
    <div
      className="back-to-listing-container"
      style={noPadding ? { padding: '0px' } : { padding: '8px' }}
    >
      <Button
        type="link"
        icon={<ArrowLeftOutlined style={{ fontSize: '1rem' }} />}
        className="back-to-listing-button"
        onClick={() => {
          navigate(-1);
        }}
      >
        {t('components.back-to-listing.description')}
      </Button>
      <span className="back-to-listing-divider">|</span>
      <span
        className="back-to-listing-title"
        style={noPadding ? { marginRight: '0px' } : { marginRight: '8px' }}
      >
        {title}
      </span>
    </div>
  );
};

export default BackToListing;
