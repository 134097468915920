import React, { useContext, useMemo, useState } from 'react';
import { Select, Radio, Space, Row, Col } from 'antd';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import useSwr from '@hooks/useSwr';

import './styles.less';
import Stat from '@src/components/Stat/Stat';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import SessionContext from '@src/store/SessionContext/SessionContext';
import SelectCustomers from '@src/components/SelectCustomers/index';
import { bytesToMbytes } from '@src/utils/formatters';

const SharedContracts = ({ filters }) => {
  const { t } = useTranslation(['dashboard', 'inventory']);
  const { keycloak } = useContext(KeycloakContext);
  const {
    customer: { customerLogged },
    hasCustomersRoles,
  } = useContext(SessionContext);
  const customerType = keycloak?.idTokenParsed?.customerType;

  const { data: operatorsData } = useSwr('/service-proxy/mot-operators');

  const operatorsResponse = useMemo(
    () =>
      operatorsData?.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    [operatorsData],
  );

  const [operator, setOperator] = useState(null);
  const [selectCustomerId, setSelectedCustomerId] = useState(null);
  const [contractId, setContractId] = useState(null);

  const { data: virtualPlans } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/virtual-plans?showContracted=${
      filters.showContracted
    }${selectCustomerId ? `&customerId=${selectCustomerId}` : ''}`,
  );

  const { data: sharedContractsSummary } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/shared-contracts-summary?showContracted=${
      filters.showContracted
    }${selectCustomerId ? `&customerId=${selectCustomerId}` : ''}${
      operator ? `&operator=${operator}` : ''
    }${contractId ? `&contractId=${contractId}` : ''}`,
  );

  const handleOperatorChange = value => {
    setOperator(value);
    setSelectedCustomerId(null);
    setContractId(null);
  };

  const handleCustomerChange = value => {
    setSelectedCustomerId(value);
    setContractId(null);
  };

  const handleContractChange = value => {
    setContractId(value);
  };

  const showContractFilter = useMemo(() => {
    if (customerType === 'BROKER') {
      return filters.showContracted || selectCustomerId;
    }
    return (
      (filters.showContracted &&
        keycloak?.idTokenParsed?.customerType === 'CUSTOMER') ||
      selectCustomerId
    );
  }, [keycloak, filters.showContracted, selectCustomerId]);

  return (
    <Card title={t('shared-contracts.title')}>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        <Col lg={12} md={24} xl={12} xs={24}>
          <span>{t('shared-contracts.operator')}</span>
          <Select
            value={operator}
            onChange={handleOperatorChange}
            options={operatorsResponse}
            style={{ width: '100%' }}
            allowClear
          />
        </Col>

        {(customerType === 'BROKER' || customerType === 'SUPERBROKER') &&
          !filters.showContracted &&
          hasCustomersRoles && (
            <Col lg={12} md={24} xl={12} xs={24}>
              <span>{t('shared-contracts.customer')}</span>
              <SelectCustomers
                value={selectCustomerId && Number(selectCustomerId)}
                onChange={value => handleCustomerChange(value)}
              />
            </Col>
          )}

        {showContractFilter && (
          <Col lg={12} md={24} xl={12} xs={24}>
            <span>{t('shared-contracts.contract')}</span>
            <Select
              value={contractId}
              onChange={handleContractChange}
              options={virtualPlans?.map(contract => ({
                label: contract.planDesc,
                value: contract.id,
              }))}
              disabled={!showContractFilter}
              style={{ width: '100%' }}
              allowClear
            />
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]} style={{ width: '100%', marginTop: '12px' }}>
        <Col lg={112} md={12} xl={12} xs={24}>
          <Stat
            className={`consumption-stat`}
            title={
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>{t('shared-contracts.simCardQuantity')}</p>
                </div>
              </>
            }
            value={sharedContractsSummary?.simCardQty || 0}
            loading={!sharedContractsSummary}
          />
        </Col>
        <Col lg={12} md={12} xl={12} xs={24}>
          <Stat
            className={`consumption-stat`}
            title={
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>{t('shared-contracts.simCardQuantityActive')}</p>
                </div>
              </>
            }
            value={sharedContractsSummary?.activeSimCardQty || 0}
            loading={!sharedContractsSummary}
          />
        </Col>
        <Col lg={12} md={12} xl={12} xs={24}>
          <Stat
            className={`consumption-stat`}
            title={
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>{t('shared-contracts.totalFrahchise')}</p>
                </div>
              </>
            }
            value={
              sharedContractsSummary?.totalHiredFranchise
                ? `${bytesToMbytes(
                    sharedContractsSummary?.totalHiredFranchise,
                    2,
                  )} MB`
                : '0MB'
            }
            loading={!sharedContractsSummary}
          />
        </Col>
        <Col lg={12} md={12} xl={12} xs={24}>
          <Stat
            className={`consumption-stat`}
            title={
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>{t('shared-contracts.totalConsumption')}</p>
                </div>
              </>
            }
            value={
              sharedContractsSummary?.totalConsumption
                ? `${bytesToMbytes(
                    sharedContractsSummary?.totalConsumption,
                    2,
                  )} MB`
                : '0MB'
            }
            loading={!sharedContractsSummary}
          />
        </Col>
        <Col lg={12} md={12} xl={12} xs={24}>
          <Stat
            className={`consumption-stat`}
            title={
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p>{t('shared-contracts.excTotal')}</p>
                </div>
              </>
            }
            value={
              sharedContractsSummary?.exceedingConsumption
                ? `${bytesToMbytes(
                    sharedContractsSummary?.exceedingConsumption,
                    2,
                  )} MB`
                : '0MB'
            }
            loading={!sharedContractsSummary}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SharedContracts;
