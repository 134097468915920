import React from 'react';
import { Label } from '@components/ui';

const LabelModel = ({ text, isRequired }) => (
  <div
    style={{
      marginBottom: 5,
      marginTop: 10,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Label color={'#575962'}>{text}:</Label>
    {isRequired && <span style={{ color: 'red' }}>&nbsp;*</span>}
  </div>
);

export default LabelModel;
