import React, { useCallback, useMemo, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import SearchBoxGoogle from '@components/Maps/SearchBoxGoogle/SearchBoxGoogle';

const CENTER_DEFAULT = {
  lat: -14.235004,
  lng: -51.92528,
};

const ZOOM_DEFAULT = 4;

const HeatMap = ({
  data,
  allowSearch,
  mapHeight,
  mapsZoom,
  setMapsZoom,
  mapsCenter,
  setMapsCenter,
  searchValue,
  setSearchValue,
  clearLocation,
  handleSetSearchParams,
}) => {
  const [mapsProps, setMapsProps] = useState();
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState();

  const processedData = useMemo(
    () =>
      data?.length > 0 && {
        positions: data?.map(({ lat, lng }) => ({ lat, lng })),
        options: {
          radius: 16,
          opacity: 0.6,
        },
      },
    [data],
  );

  const handleSetZoom = useCallback(
    value => {
      if (setMapsZoom) {
        setMapsZoom(value);
      } else {
        setZoom(value);
      }
    },
    [setMapsZoom],
  );

  const handleSetCenter = useCallback(
    values => {
      if (setMapsCenter) {
        setMapsCenter(values);
      } else {
        setCenter(values);
      }
    },
    [setMapsCenter],
  );

  const handleGoogleApiLoaded = useCallback((mapProps, map) => {
    map?.setOptions({
      fullscreenControl: false,
    });
    setMapsProps(mapProps);
  }, []);

  return (
    <div style={{ height: mapHeight }}>
      <div
        style={{
          height: '100%',
          width: '100%',
          borderRadius: 5,
          position: 'relative',
        }}
      >
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ maps, map }) =>
            handleGoogleApiLoaded(maps, map)
          }
          onClick={({ lat, lng }) => {
            const newCoordinates = {
              lat,
              lng,
            };
            handleSetCenter(newCoordinates);
          }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS || '',
            libraries: ['places', 'visualization'],
          }}
          heatmap={processedData}
          defaultCenter={CENTER_DEFAULT}
          center={center ?? mapsCenter}
          defaultZoom={ZOOM_DEFAULT}
          zoom={zoom ?? mapsZoom}
          onZoomAnimationEnd={handleSetZoom}
        />
        {allowSearch && (
          <SearchBoxGoogle
            googleMaps={mapsProps}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onPlacesChanged={handleSetSearchParams}
            clearLocation={clearLocation}
          />
        )}
      </div>
    </div>
  );
};

export default HeatMap;
