import React from 'react';

import { Label } from '@components/ui';
import DatePicker from '@components/DatePicker/DatePicker';
import moment from 'moment';
import {
  getDateInISOFormat,
  getDateWithISOFormat,
} from '@src/utils/formatters';
import { useTranslation } from 'react-i18next';

const NetworkBlockUnlockDate = ({
  lockUnlockType,
  blockUnlockInitialDate,
  setBlockUnlockInitialDate,
}) => {
  const { t } = useTranslation('device-actions-modal');
  return (
    <div style={{ padding: 24, textAlign: 'center' }}>
      <Label
        color={'#575962'}
        htmlFor={'name'}
        style={{
          display: 'block',
          textAlign: 'left',
          maxWidth: '280px',
          margin: '0 auto',
          width: '100%',
        }}
      >
        {`${
          lockUnlockType === 'block'
            ? t('steps.network-block-unlock.initial-validity')
            : t('steps.network-block-unlock.unlock-date')
        }:`}
      </Label>
      <DatePicker
        style={{ maxWidth: '280px', width: '100%' }}
        disabledDate={d => d < moment()}
        value={
          blockUnlockInitialDate &&
          moment(getDateWithISOFormat(blockUnlockInitialDate))
        }
        allowClear
        showToday={false}
        onChange={value => {
          if (value) {
            setBlockUnlockInitialDate(getDateInISOFormat(value.toISOString()));
          } else {
            setBlockUnlockInitialDate(undefined);
          }
        }}
      />
      {!blockUnlockInitialDate && (
        <p
          style={{
            color: 'red',
            fontSize: '0.8rem',
            maxWidth: '280px',
            width: '100%',
            margin: '0 auto',
            marginTop: '12px',
            marginBottom: '-16px',
          }}
        >
          {`* ${t('steps.network-block-unlock.help-date-not-selected')}`}
        </p>
      )}
    </div>
  );
};

export default NetworkBlockUnlockDate;
