import React, { memo, useMemo, useState } from 'react';
import { Modal, Steps, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const StepsModal = ({
  title,
  isOpen,
  steps,
  completionStep,
  confirmLoading,
  confirmDisabled,
  onConfirm,
  onClose,
  width,
  alternativeConfirmTextType,
  allowStepsLine,
}) => {
  const { t } = useTranslation('general');
  const [currentStep, setCurrentStep] = useState(0);

  const stepsAvailable = useMemo(
    () => steps?.filter(item => !item.doNotDisplay) ?? [],
    [steps],
  );

  const buttons = useMemo(
    () => [
      {
        textType: 'return',
        visible: currentStep > 0,
        onClick: () => setCurrentStep(currentStep - 1),
      },
      {
        textType: 'next',
        visible: currentStep < stepsAvailable.length - 1,
        loading: stepsAvailable?.[currentStep]?.loading,
        disabled: stepsAvailable?.[currentStep]?.disabled,
        onClick: () => setCurrentStep(currentStep + 1),
      },
      {
        textType: alternativeConfirmTextType ?? 'confirm',
        visible: currentStep === stepsAvailable.length - 1,
        loading: confirmLoading,
        disabled: confirmDisabled,
        onClick: () => {
          onConfirm();
          completionStep && setCurrentStep(currentStep + 1);
        },
      },
    ],
    [
      alternativeConfirmTextType,
      completionStep,
      confirmDisabled,
      confirmLoading,
      currentStep,
      onConfirm,
      stepsAvailable,
    ],
  );

  const footer = useMemo(
    () =>
      currentStep < stepsAvailable?.length &&
      buttons?.map(
        ({ textType, visible, ...rest }) =>
          visible && (
            <Button
              key={textType}
              type={
                ['next', 'confirm'].includes(textType) ||
                (alternativeConfirmTextType &&
                  textType === alternativeConfirmTextType)
                  ? 'primary'
                  : 'default'
              }
              {...rest}
            >
              {t(`buttons.${textType}`)}
            </Button>
          ),
      ),
    [alternativeConfirmTextType, buttons, currentStep, stepsAvailable, t],
  );

  return (
    <Modal
      title={title}
      open={isOpen}
      width={width ?? '600px'}
      onCancel={onClose}
      footer={footer}
    >
      {allowStepsLine && currentStep < stepsAvailable?.length && (
        <>
          <Steps
            current={currentStep}
            size="small"
            items={stepsAvailable?.map(item => ({ title: item.title }))}
            style={{ marginBottom: 24 }}
          />
        </>
      )}
      <div className="modal-of-steps-content" style={{ padding: 24 }}>
        {currentStep === stepsAvailable?.length &&
          completionStep &&
          completionStep}
        {currentStep < stepsAvailable?.length &&
          stepsAvailable?.[currentStep]?.content}
      </div>
    </Modal>
  );
};

export default memo(StepsModal);
