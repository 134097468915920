import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Steps,
  Button,
  message,
  Typography,
  Space,
  Tooltip,
  Table,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import FileUploader from '../FileUploader';
import Papa from 'papaparse';
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CsvExport from '../CsvExport/CsvExport';
import { getModelData, getModelDataColumns } from './modelData';

import * as Styled from './styles';
import { trimInObjectKeys } from '@src/utils/formatters';

const { Step } = Steps;
const { Text, Title, Paragraph } = Typography;

const SearchUploader = ({
  visible,
  onClose,
  setList,
  columnName,
  description,
  alternateTitle,
  maxNumOfItems,
}) => {
  const { t } = useTranslation([
    'device-actions-modal',
    'device-actions',
    'transfer',
  ]);

  const [current, setCurrent] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [parsingFile, setParsingFile] = useState(false);
  const [uploadedDevices, setUploadedDevices] = useState([]);
  const [sendActionLoading, setSendActionLoading] = useState(false);

  const dataModelColumns = useMemo(
    () => getModelDataColumns(columnName),
    [columnName],
  );

  const dataModelData = useMemo(() => getModelData(columnName), [columnName]);

  const info = useCallback(() => {
    Modal.info({
      title: t('csv-format-example'),
      width: 700,
      content: (
        <>
          <Table
            columns={dataModelColumns}
            dataSource={dataModelData}
            pagination={false}
            size={'small'}
            tableLayout={'auto'}
            bordered
            style={{ margin: '12px 0' }}
          />
          <CsvExport
            columns={dataModelColumns}
            data={dataModelData}
            filename={'model-import.csv'}
            separator={','}
            style={{ background: 'red' }}
          >
            <Button
              className={'button-download'}
              icon={<DownloadOutlined />}
              size={'large'}
              type={'link'}
            >
              {t('download-model')}
            </Button>
          </CsvExport>
        </>
      ),
    });
  }, [dataModelColumns, dataModelData, t]);

  const uploadStep = useMemo(
    () => ({
      title: t('steps.upload'),
      content: (
        <>
          <Space>
            <Text>
              {description ?? t('select-devices')}
              <Tooltip placement={'right'} title={t('help')}>
                <Button
                  icon={<QuestionCircleOutlined />}
                  size={'small'}
                  type={'link'}
                  onClick={info}
                />
              </Tooltip>
            </Text>
          </Space>
          <FileUploader
            fileList={uploadedFile ? [uploadedFile] : undefined}
            style={{ marginTop: 10 }}
            onUpload={setUploadedFile}
          />
        </>
      ),
    }),
    [info, t, uploadedFile, description],
  );

  const validationStep = useMemo(
    () => ({
      title: t('steps.validation'),
      content: (
        <Spin spinning={parsingFile} tip={'Processing file'}>
          <div className={'action-network-reset'}>
            <Title level={4}>
              {t('selected-device', {
                count: uploadedDevices?.length,
              })}
            </Title>
            <Paragraph>
              {t('upload-confirm-description', {
                field: alternateTitle ?? columnName,
              })}
            </Paragraph>
          </div>
        </Spin>
      ),
    }),
    [columnName, parsingFile, t, uploadedDevices?.length, alternateTitle],
  );

  const clearStates = useCallback(() => {
    setUploadedDevices([]);
    setCurrent(0);
    setParsingFile(false);
    setUploadedFile(undefined);
  }, []);

  useEffect(() => {
    if (uploadedFile) {
      setParsingFile(true);
      Papa.parse(uploadedFile, {
        skipEmptyLines: 'greedy',
        header: true,
        complete(results) {
          const csvHeaderReference = dataModelColumns.map(item => item.title);

          if (maxNumOfItems) {
            const resultsLength = results?.data?.length;
            if (resultsLength > maxNumOfItems) {
              message.error(
                t('maximum-number-reached', { count: maxNumOfItems }),
              );
              setParsingFile(false);
              return;
            }
          }

          if (
            !csvHeaderReference.every(v =>
              results.meta.fields.map(f => f?.trim()).includes(v),
            ) &&
            dataModelColumns?.length !== 0
          ) {
            message.error(t('invalid-header'));
            setParsingFile(false);
            return;
          }

          const devicesToSend = results.data.map(device => {
            device = trimInObjectKeys(device);
            const rowObject = {};
            dataModelColumns.forEach(header => {
              rowObject[header.dataIndex] = device[header.title];
            });
            return rowObject;
          });

          setUploadedDevices(devicesToSend);
          setParsingFile(false);
        },
      });
    } else {
      setUploadedDevices([]);
    }
  }, [uploadedFile, dataModelColumns, t, maxNumOfItems]);

  const steps = [uploadStep, validationStep];

  const handleConfirmAction = useCallback(async () => {
    setSendActionLoading(true);
    try {
      setList(uploadedDevices);
    } catch {
      message.error({
        content: t('upload-error'),
      });
    } finally {
      setSendActionLoading(false);
    }

    clearStates();
    onClose(false);
  }, [onClose, t, clearStates, setList, uploadedDevices]);

  const disabledButtonNext = useMemo(() => {
    return current === 0 && uploadedDevices?.length === 0;
  }, [current, uploadedDevices?.length]);

  const modalFooter = useMemo(
    () => (
      <div key={'0'} className={'steps-action'}>
        {current > 0 && (
          <Button
            className={'button-previous'}
            onClick={() => setCurrent(current - 1)}
          >
            {t('previous')}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            disabled={disabledButtonNext}
            type={'primary'}
            onClick={() => setCurrent(current + 1)}
          >
            {t('next')}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            disabled={parsingFile}
            loading={sendActionLoading}
            type={'primary'}
            onClick={handleConfirmAction}
          >
            {t('confirm')}
          </Button>
        )}
      </div>
    ),
    [
      current,
      handleConfirmAction,
      parsingFile,
      steps?.length,
      t,
      sendActionLoading,
      disabledButtonNext,
    ],
  );

  return (
    <Styled.ModalContainer
      footer={modalFooter}
      title={t('upload-search', { field: alternateTitle ?? columnName })}
      visible={visible}
      onCancel={() => {
        clearStates();
        onClose(true);
      }}
      onOk={() => {
        clearStates();
        onClose(false);
      }}
      width={'520px'}
    >
      <>
        <Steps current={current} size={'small'}>
          {steps?.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={'steps-content'}>{steps[current].content}</div>
      </>
    </Styled.ModalContainer>
  );
};

export default SearchUploader;
