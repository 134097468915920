import styled from 'styled-components';

export const FinancialBlock = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 24px;

  .financial-lock-tag-wrapper {
    display: inline-flex;
    align-items: center;

    .financial-lock-tag {
      display: grid;
      place-items: center;
      margin: 0;
      height: 24px;
    }
  }

  .financial-lock-button-arrow {
    vertical-align: middle;
    margin: 0;
    padding: 0 4px;
  }

  &:hover .financial-lock-button {
    display: block;
  }

  .financial-lock-button {
    display: none;
    transition: all 0.3s;
  }
`;
