import React, { useMemo } from 'react';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import rolesConstants from '@utils/rolesConstants';
import { LorawanActionTypes } from '@utils/deviceActions';

const useDownlinkBlockUnlock = ({ devices, isUpload, form }) => {
  const { t } = useTranslation(['lorawan', 'device-actions']);
  const downlinkAction = Form.useWatch('downlinkAction', form);

  const actionTypeOptions = useMemo(
    () => [
      {
        label: t('block'),
        value: LorawanActionTypes.DOWNLINK_BLOCKING,
      },
      {
        label: t('unlock'),
        value: LorawanActionTypes.DOWNLINK_UNBLOCKING,
      },
    ],
    [t],
  );

  const steps = useMemo(
    () => [
      {
        title: t('whatDoYouWantToDo'),
        name: 'downlinkAction',
        showWhenUpload: true,
        content: (
          <Radio.Group optionType="button" options={actionTypeOptions} />
        ),
      },
    ],
    [actionTypeOptions, t],
  );

  const payloadToSend = useMemo(
    () => ({
      action: downlinkAction,
      devices: isUpload
        ? devices
        : devices?.map(device => ({
            devEui: device.devEui,
          })),
    }),
    [devices, downlinkAction, isUpload],
  );

  const title = useMemo(() => {
    const labelPrefix = downlinkAction ? 'by-key.' : '';
    const action =
      downlinkAction || rolesConstants.LORAWAN_DOWNLINK_BLOCK_UNLOCK;
    return t(`device-actions:labels.${labelPrefix}lorawan.${action}`);
  }, [t, downlinkAction]);

  return {
    steps,
    payloadToSend,
    title,
    totalDevices: devices?.length,
    uploadPosition: 1,
  };
};

export default useDownlinkBlockUnlock;
