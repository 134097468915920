import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Upload, Radio } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { usePalette } from 'color-thief-react';
import { useTranslation } from 'react-i18next';

import './styles.less';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { getBase64Image } from '@src/utils/formatters';

const LogoUpload = ({ setMainColor, file, isEditing = false }) => {
  const { t } = useTranslation('customers');
  const {
    mainColor,
    customer: { customerLogged },
  } = useContext(SessionContext);

  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState();
  const [whenEditing, setWhenEditing] = useState();

  const [chosenColor, setChosenColor] = useState();

  const countColors = 4;

  const { data: colors } = usePalette(
    fileList[0]?.thumbUrl || fileList[0]?.url,
    countColors,
    'hex',
  );

  useEffect(() => {
    if (chosenColor) {
      setMainColor(chosenColor);
    }
  }, [chosenColor, setMainColor]);

  useEffect(() => {
    if (mainColor) {
      setChosenColor(mainColor);
    }
  }, [mainColor]);

  useEffect(() => {
    if (isEditing && customerLogged) {
      const { protocol, origin, hostname } = location;
      const url =
        hostname === 'localhost'
          ? `${origin}/images/${hostname}`
          : `${protocol}//${customerLogged?.domain}/images/${customerLogged?.domain}`;
      getBase64Image(url, img => setWhenEditing({ url, imgInBase64: img }));
    }
  }, [customerLogged, isEditing]);

  useEffect(() => {
    if (whenEditing?.imgInBase64) {
      setFileList([
        {
          uid: '-1',
          name: 'logo.png',
          status: 'done',
          url: whenEditing.url,
          thumbUrl: whenEditing?.imgInBase64,
        },
      ]);
    }
  }, [whenEditing]);

  useEffect(() => {
    if (fileList.length === 0) {
      file(undefined);
    } else if (image && chosenColor) {
      file(image);
      setMainColor(chosenColor);
    }
  }, [chosenColor, file, image, setMainColor, fileList.length]);

  useEffect(() => {
    if (colors && !isEditing) {
      setChosenColor(colors[0]);
    }
  }, [colors, isEditing]);

  const onPreview = useCallback(async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }, []);

  const uploadImage = useCallback(async options => {
    const { file, onSuccess } = options;
    setImage(file);
    onSuccess();
  }, []);

  const onChange = useCallback(({ fileList: newFileList }) => {
    setFileList([]);
    setFileList(newFileList);
  }, []);

  const handleChangeColor = useCallback(({ target: { value } }) => {
    setChosenColor(value);
  }, []);

  return (
    <div className="logo-upload-container">
      <div className="logo-upload-wrapper">
        <p>{t('personalization.logo-upload.logo')}:</p>
        <div>
          <Upload
            maxCount={1}
            listType="picture-card"
            fileList={fileList}
            onPreview={onPreview}
            onChange={onChange}
            customRequest={uploadImage}
            accept=".jpg, .png"
            onDelete
          >
            <FileAddOutlined
              size="large"
              style={{ fontSize: 32, opacity: '0.4' }}
            />
          </Upload>
        </div>
      </div>
      {colors && (
        <div style={{ width: '100%' }}>
          <p>{t('personalization.logo-upload.choose-a-color')}:</p>
          <div className="radio-group-wrapper">
            <div
              className="chosen-color"
              style={{
                background: chosenColor ?? '#dbdbdb',
              }}
            />
            <Radio.Group
              onChange={handleChangeColor}
              className="radio-group-colors"
              defaultValue={chosenColor}
            >
              {colors?.map(color => (
                <Radio.Button
                  key={color}
                  value={color}
                  className="radio-button"
                  style={{
                    background: color,
                    borderRadius: 2,
                  }}
                />
              ))}
            </Radio.Group>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoUpload;
