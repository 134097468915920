import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`
  .back-button {
    margin: 0 8px;
  }

  .button-download {
    padding: 0;
    margin-top: 8px;
  }

  .ant-steps {
    margin-bottom: 15px;
  }

  .validation-exclamation-circle {
    font-size: 3rem;
    font-weight: bold;
    padding: 4px 8px;
  }

  .validation-paragraph {
    width: 60%;
    margin: 0 auto;
    margin-top: 16px;
    font-size: 1.1rem;
    opacity: 0.8;
  }

  .action-activation {
    .ant-row,
    .ant-select {
      width: 100%;
    }
  }

  .action-operator-restriction {
    .ant-select {
      width: 90%;
    }
  }

  .block-types-tabs {
    .ant-tabs-tab {
      border-color: #dbdbdb;
    }
  }

  .device-actions-last-step,
  .device-actions-last-step-error {
    padding: 32px;
    .ant-result-title {
      font-size: 1.3rem;
      margin-bottom: 4px;
    }
  }
  .device-actions-last-step .ant-result-subtitle {
    text-align: justify;
  }
  .device-actions-last-step-error .ant-result-subtitle {
    text-align: center;
  }

  .customers-action-radio-group {
    padding: 16px 8px;
    width: 100%;
    text-align: center;

    .customers-action-radio-button {
      padding: 16px;
      max-width: 200px;
      width: 100%;
      height: 100%;

      > span {
        color: green;
        font-size: 1.4rem;
      }
      p {
        opacity: 0.8;
        color: black;
        font-size: 0.9rem;
      }
    }

    .radio-button-secondary {
      > span {
        color: red;
      }
    }
  }
`;
