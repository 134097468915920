import React from 'react';
import { AutoSizer, List as VirtuList } from 'react-virtualized';

const VirtualizedList = ({
  listItems,
  itemIsSelected,
  itemEl: ItemEl,
  rowHeight,
}) => (
  <AutoSizer>
    {({ height, width }) => (
      <VirtuList
        height={height}
        width={width}
        style={{ paddingBottom: 16 }}
        rowCount={listItems ? listItems.length : 1}
        rowHeight={rowHeight ?? 40}
        rowRenderer={({ key, index, style }) => {
          if (listItems && listItems[index]) {
            return (
              <div key={key} style={style}>
                <ItemEl isSelected={itemIsSelected} {...listItems[index]} />
              </div>
            );
          }
          return undefined;
        }}
      />
    )}
  </AutoSizer>
);

export default VirtualizedList;
