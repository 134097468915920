import React, { useCallback, useState } from 'react';
import { Input, Button, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import SearchUploader from '../SearchUploader/index';
import { useTranslation } from 'react-i18next';
const { Search } = Input;

export const InputSearchFile = ({
  valueOfItem,
  setSelectedThirdValue,
  selectedThirdValue,
  setSearchParams,
  clearSearchParams,
  selectedSecondValue,
}) => {
  const { t } = useTranslation('inventory');

  const [modalSearchUploaderVisible, setModalSearchUploaderVisible] =
    useState(false);

  const searchUploadedDevicesApply = useCallback(
    uploadedDevices => {
      switch (valueOfItem.name) {
        case 'msisdn':
          setSelectedThirdValue(
            uploadedDevices.map(device => device.msisdn).join(','),
          );
          break;
        case 'iccid':
          setSelectedThirdValue(
            uploadedDevices.map(device => device.iccid).join(','),
          );
          break;
        case 'imsi':
          setSelectedThirdValue(
            uploadedDevices.map(device => device.imsi).join(','),
          );
          break;
      }
    },
    [setSelectedThirdValue, valueOfItem],
  );

  const suffixUpload = useCallback(() => {
    return (
      <Tooltip title={t('file-search')}>
        <Button
          type="link"
          icon={<UploadOutlined />}
          size={'small'}
          style={{ height: 22, width: 22, alignItems: 'center' }}
          onClick={() => {
            setModalSearchUploaderVisible(true);
          }}
        />
      </Tooltip>
    );
  }, []);

  return (
    <>
      <Search
        suffix={suffixUpload()}
        onSearch={value =>
          setSearchParams({
            [`${valueOfItem?.name}`]: value,
          })
        }
        disabled={!selectedSecondValue}
        style={{ width: '100%' }}
        onChange={event => {
          if (event.target.value === '') {
            clearSearchParams([`${valueOfItem?.name}`]);
            setSelectedThirdValue(undefined);
          } else {
            setSelectedThirdValue(event.target.value);
          }
        }}
        value={selectedThirdValue}
        onClear={() => {
          clearSearchParams([`${valueOfItem?.name}`]);
          setSelectedThirdValue(undefined);
        }}
        allowClear
      />
      <SearchUploader
        visible={modalSearchUploaderVisible}
        onClose={() => setModalSearchUploaderVisible(false)}
        columnName={valueOfItem?.name}
        setList={searchUploadedDevicesApply}
      />
    </>
  );
};
