import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { Tooltip, Button, message, Modal, Dropdown, Menu } from 'antd';
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './styles.less';
import apiMiddleware from '@src/services/apiMiddleware';
import { useNavigate } from 'react-router-dom';
import SessionContext from '@src/store/SessionContext/SessionContext';

const { confirm } = Modal;

const RequestReport = ({ items }) => {
  const { t, i18n } = useTranslation('reports');
  const navigate = useNavigate();
  const { userRoles } = useContext(SessionContext);

  const [isRequesting, setIsRequesting] = useState(false);

  const hasRole = useCallback(
    role => userRoles?.portal?.includes(role),
    [userRoles],
  );

  const itemsFiltered = useMemo(
    () => items?.filter(item => hasRole(item.role)),
    [hasRole, items],
  );

  const navigateToReports = useCallback(
    type => {
      message.destroy('report-requested');
      navigate(`/management/reports?type=${type}`);
    },
    [navigate],
  );

  const handleRequest = useCallback(
    ({ type, path, filters, isParams, method = 'get' }) => {
      setIsRequesting(true);
      let finalPath = `${path}?locale=${i18n.language}`;
      if (isParams) {
        Object.entries(filters).forEach(([key, value]) => {
          if (value || value === false) {
            finalPath += `&${key}=${value}`;
          }
        });
      }
      apiMiddleware({
        method,
        url: finalPath,
        data: !isParams && {
          ...filters,
        },
      })
        .then(() => {
          message.success({
            content: (
              <span>
                {t('request.report-success')}
                <Button
                  onClick={() => navigateToReports(type)}
                  type="link"
                  style={{ padding: 3 }}
                >
                  {t('request.go-to-reports')}
                </Button>
                {t('request.check-report-progress-and-download')}
              </span>
            ),
            key: 'report-requested',
            duration: 5,
          });
        })
        .catch(() => message.error(t('request.report-error')))
        .finally(() => setIsRequesting(false));
    },
    [i18n?.language, navigateToReports, t],
  );

  const showExportConfirm = useCallback(
    params => {
      confirm({
        title: <h3>{t('request.report')}</h3>,
        icon: <QuestionCircleOutlined />,
        content: t(
          `request.${
            params.secondaryDescription
              ? 'secondary-description'
              : 'description'
          }`,
          {
            type: t(`types.${params.type}`),
          },
        ),
        okText: t('request.buttons.confirm'),
        cancelText: t('request.buttons.cancel'),
        okButtonProps: { type: 'primary' },
        onOk: () => handleRequest(params),
      });
    },
    [handleRequest, t],
  );

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.ItemGroup title={t('title')}>
          {itemsFiltered?.map(item => (
            <Menu.Item
              key={item.type}
              onClick={() =>
                item?.action ? item?.action() : showExportConfirm(item)
              }
            >
              {t(`types.${item.type.toUpperCase()}`)}
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    ),
    [itemsFiltered, showExportConfirm, t],
  );

  const button = useMemo(
    () => (
      <Button
        icon={<CopyOutlined />}
        type="link"
        disabled={isRequesting}
        loading={isRequesting}
        onClick={e => {
          e.stopPropagation();
          if (itemsFiltered?.length === 1 && itemsFiltered?.[0]?.action) {
            itemsFiltered?.[0]?.action();
            return;
          }
          if (itemsFiltered?.length === 1) {
            showExportConfirm(itemsFiltered?.[0]);
          }
        }}
        className="report-request-button"
      />
    ),
    [isRequesting, showExportConfirm, itemsFiltered],
  );

  if (itemsFiltered?.length === 0) {
    return <></>;
  }

  return (
    <Tooltip title={t('request.report')}>
      {itemsFiltered?.length > 1 ? (
        <Dropdown overlay={menu} arrow overlayClassName={'overlay-menu'}>
          {button}
        </Dropdown>
      ) : (
        button
      )}
    </Tooltip>
  );
};

export default memo(RequestReport);
