import React from 'react';
import styled from 'styled-components';
import { BsDot } from 'react-icons/bs';
import { formatCpfCnpj } from '@src/utils/formatters';
import { Tooltip } from 'antd';

import './styles.less';

const Title = styled.p`
  margin: 0;
  opacity: 0.8;
  font-size: 12px;
`;

const Content = styled.div`
  margin-top: -6px;
  span {
    opacity: 0.5;
    font-size: 10px;
  }
`;

const SelectContextItem = ({ id, name, tradingName, cpfCnpj }) => {
  return (
    <Tooltip title={name || tradingName} mouseEnterDelay={0.6}>
      <div
        style={{
          overflow: 'hidden',
        }}
      >
        <Title>
          {id}
          <BsDot style={{ opacity: '0.8', margin: '0 2px' }} />
          {name}
        </Title>
        <Content>
          <span>{cpfCnpj && formatCpfCnpj(cpfCnpj)}</span>
          {tradingName && tradingName !== name && (
            <>
              <span>
                {cpfCnpj && (
                  <BsDot style={{ opacity: '0.8', margin: '0 2px' }} />
                )}
                {tradingName}
              </span>
            </>
          )}
        </Content>
      </div>
    </Tooltip>
  );
};

export default SelectContextItem;
