import styled from 'styled-components';

const LoginCheckbox = styled.input`
  margin: 4px;
  position: relative;
  top: 2px;
`;

LoginCheckbox.defaultProps = {
  type: 'checkbox',
};

export default LoginCheckbox;
