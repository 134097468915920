import React from 'react';
import { Statistic, Tabs } from 'antd';
import { formatBRNumber } from '@src/utils/formatters';

import './styles.less';

const StatusMenu = ({ data, handleSelectedCard, itemActive, ...props }) => (
  <div className="status-menu" {...props}>
    <Tabs
      activeKey={itemActive}
      className="status-menu-tabs"
      style={{ marginTop: 16 }}
    >
      {data?.map(({ label, value, item, color }) => (
        <Tabs.TabPane
          key={item}
          tab={
            <div
              className="status-menu-card"
              onClick={() => handleSelectedCard(item)}
              style={{
                borderBottomColor:
                  item && itemActive === item && (color ?? '#5867dd'),
              }}
            >
              {value && (
                <Statistic
                  precision={0}
                  value={formatBRNumber(value)}
                  valueStyle={{ color }}
                  formatter={formatBRNumber}
                />
              )}
              <span className="status-menu-title">{label}</span>
            </div>
          }
        />
      ))}
    </Tabs>
  </div>
);

export default StatusMenu;
