import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Divider, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@src/hooks/useSwr';
import apiMiddleware from '@src/services/apiMiddleware';
import SessionContext from '@src/store/SessionContext/SessionContext';

const CompanyRegister = () => {
  const { t: translate } = useTranslation('schedules');
  const [form] = Form.useForm();
  const { data: companyData, mutate } = useSwr('/service-proxy/astro/company');
  const [loading, setLoading] = useState(false);
  const {
    customer: { customerLogged },
  } = useContext(SessionContext);

  useEffect(() => {
    if (!customerLogged?.isRegisteredInAstro) {
      form.setFieldsValue({
        email: customerLogged?.contact?.email,
        phoneNumber: customerLogged?.contact?.phoneNumber,
        corporateName: customerLogged?.name || customerLogged?.contact?.name,
        fantasyName: customerLogged?.name || customerLogged?.contact?.name,
        cpfCnpj: customerLogged?.cpfCnpj,
        streetName: customerLogged?.address?.streetName,
        streetNumber: customerLogged?.address?.number,
        complement: customerLogged?.address?.neighborhood,
        number: customerLogged?.address?.number,
        city: customerLogged?.address?.city,
        country: customerLogged?.address?.country,
        state: customerLogged?.address?.state,
      });
    }
    if (companyData || customerLogged?.isRegisteredInAstro) {
      form.setFieldsValue(companyData);
    }
  }, [companyData, form, customerLogged]);

  const handleSubmit = async formData => {
    setLoading(true);
    try {
      if (customerLogged?.isRegisteredInAstro) {
        await apiMiddleware.put(`/service-proxy/astro/company`, formData);
        mutate('/service-proxy/astro/company');
        mutate('/self');
        message.success(translate('messages.success'));
      } else {
        await apiMiddleware.post('/service-proxy/astro/company', formData);
        mutate('/service-proxy/astro/company');
        mutate('/self');
        message.success(translate('messages.success'));
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
    } catch (error) {
      message.error(translate('messages.error'));
    }
    setLoading(false);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <Divider orientation="left">{translate('info')}</Divider>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="cpfCnpj"
            label={translate('cpfCnpj')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="corporateName"
            label={translate('corporateName')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="fantasyName"
            label={translate('fantasyName')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="ocupation"
            label={translate('ocupation')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="email"
            label={translate('emailAddress')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="phoneNumber" label={translate('telphone')}>
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="wppNumber" label={translate('cellphone')}>
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">{translate('addres')}</Divider>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="cep"
            label={translate('CEP')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="streetName"
            label={translate('address')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="streetNumber"
            label={translate('number')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="complement" label={translate('complement')}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="country"
            label={translate('country')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="state"
            label={translate('state')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="city"
            label={translate('city')}
            rules={[
              {
                required: true,
                message: translate('msgs.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button type="primary" htmlType="submit" loading={loading}>
            {!customerLogged?.isRegisteredInAstro
              ? translate('buttons.create')
              : translate('buttons.save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CompanyRegister;
