export const getPercentage = (part, total) =>
  part === 0 && total === 0 ? 0 : (part / total) * 100;

export const progressPercentageConsumption = (consumption, franchising) => {
  let part = Number(consumption);
  let percent = 0;

  if (franchising !== 0) {
    percent = (part / Number(franchising)) * 100;
  }

  return percent;
};
