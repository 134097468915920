import { Select, Input, Row, Col, Typography, DatePicker, Tag } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SingleRangePicker from '@src/components/DatePicker/SingleRangePicker/SingleRangePicker';
import { useTranslation } from 'react-i18next';
import SliderRange from '@src/components/SliderRange/SliderRange';
import { InputSearchFile } from '@src/components/InputSearchFile/index';
import SessionContext from '@src/store/SessionContext/SessionContext';
import SelectContextItem from '@src/components/SelectContext/SelectContextItem/SelectContextItem';
import moment from 'moment';

const { Search } = Input;

export const QueryItem = ({
  setSearchParams,
  clearSearchParams,
  valueOfItem,
}) => {
  const { t: translate } = useTranslation('inventory');

  const { customer } = useContext(SessionContext);

  const selectedSecondValue = useMemo(
    () => (valueOfItem?.rules ? valueOfItem.rules[0] : undefined),
    [valueOfItem],
  );

  const formatItem = useMemo(
    () => (valueOfItem?.rules?.length === 2 ? valueOfItem.rules[1] : undefined),
    [valueOfItem],
  );

  const [selectedThirdValue, setSelectedThirdValue] = useState(
    valueOfItem.defaultValue || undefined,
  );

  const mapOptions = useCallback(
    options =>
      options?.map(item => ({
        value: item.id,
        label: (
          <SelectContextItem id={item.id} cpfCnpj={item.cpfCnpj} {...item} />
        ),
        filterProps: `${item.name};${item.tradingName};${item.cpfCnpj};${item.id}`,
      })),
    [],
  );

  useEffect(() => {
    setSelectedThirdValue(valueOfItem.defaultValue);
  }, [valueOfItem]);

  return (
    <Row align="bottom">
      {selectedSecondValue === 'Equals' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          {valueOfItem.name === 'iccid' ||
          valueOfItem.name === 'imsi' ||
          valueOfItem.name === 'msisdn' ? (
            <InputSearchFile
              valueOfItem={valueOfItem}
              setSelectedThirdValue={setSelectedThirdValue}
              selectedThirdValue={selectedThirdValue}
              setSearchParams={setSearchParams}
              clearSearchParams={clearSearchParams}
              selectedSecondValue={selectedSecondValue}
            />
          ) : (
            <Search
              disabled={!selectedSecondValue}
              value={selectedThirdValue}
              style={{ width: '100%' }}
              onChange={event => {
                if (event.target.value === '') {
                  clearSearchParams([`${valueOfItem?.name}`]);
                  setSelectedThirdValue(undefined);
                } else {
                  setSelectedThirdValue(event.target.value);
                }
              }}
              onSearch={
                () => {
                  setSearchParams({
                    [`${valueOfItem?.name}`]: selectedThirdValue,
                  });
                }
                // eslint-disable-next-line react/jsx-curly-newline
              }
              allowClear
              onClear={() => {
                clearSearchParams([`${valueOfItem?.name}`]);
                setSelectedThirdValue(undefined);
              }}
            />
          )}
        </Col>
      )}

      {selectedSecondValue === 'RangeValues' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          <SliderRange
            value={
              selectedThirdValue
                ? [
                    selectedThirdValue?.split(' ')[0],
                    selectedThirdValue?.split(' ')[1],
                  ]
                : []
            }
            onClear={() => {
              clearSearchParams([
                `${valueOfItem?.range?.from}`,
                `${valueOfItem?.range?.to}`,
              ]);
              setSelectedThirdValue(undefined);
            }}
            onChange={
              ({ valueFrom, valueTo }) => {
                setSearchParams({
                  [`${valueOfItem?.range?.from}`]:
                    valueFrom && Number(valueFrom),
                  [`${valueOfItem?.range?.to}`]: valueTo && Number(valueTo),
                });
              }

              // eslint-disable-next-line react/jsx-curly-newline
            }
            format={formatItem || ''}
            max={formatItem === '%' ? 100 : 1000}
          />
        </Col>
      )}

      {selectedSecondValue === 'Options' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          {valueOfItem.name === 'customerName' ? (
            <Select
              disabled={!selectedSecondValue}
              onSelect={value => {
                setSelectedThirdValue(value);
                setSearchParams({
                  [`${valueOfItem?.name}`]: value,
                });
              }}
              style={{ width: '100%' }}
              allowClear
              onClear={() => {
                clearSearchParams([`${valueOfItem?.name}`]);
                setSelectedThirdValue(undefined);
              }}
              value={selectedThirdValue}
              filterOption={(input, option) => {
                const verifyString = string =>
                  String(string).toLowerCase().indexOf(input.toLowerCase()) >=
                  0;
                return verifyString(option?.filterProps);
              }}
              showSearch
              options={
                valueOfItem.name === 'customerName'
                  ? mapOptions(customer?.customersData)
                  : []
              }
            />
          ) : (
            <Select
              disabled={!selectedSecondValue}
              onSelect={value => {
                setSelectedThirdValue(value);
                setSearchParams({
                  [`${valueOfItem?.name}`]: value,
                });
              }}
              style={{ width: '100%' }}
              allowClear
              onClear={() => {
                clearSearchParams([`${valueOfItem?.name}`]);
                setSelectedThirdValue(undefined);
              }}
              value={selectedThirdValue}
              optionFilterProp={'label'}
              showSearch
              options={valueOfItem?.options}
            />
          )}
        </Col>
      )}

      {selectedSecondValue === 'DateRange' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          <SingleRangePicker
            onChange={({ startDate, endDate }) => {
              setSearchParams({
                [`${valueOfItem?.date?.start}`]: startDate,
                [`${valueOfItem?.date?.end}`]: endDate,
              });
              setSelectedThirdValue(`${startDate}/${endDate}`);
            }}
            disableEndDateLimit={valueOfItem?.disableEndDateLimit}
            value={
              selectedThirdValue
                ? [
                    selectedThirdValue.split(' ')[0],
                    selectedThirdValue.split(' ')[1],
                  ]
                : []
            }
          />
        </Col>
      )}

      {selectedSecondValue === 'Date' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          <DatePicker
            showTime
            value={
              selectedThirdValue &&
              moment(new Date(selectedThirdValue), 'YYYYMMDD HH:mm:ss')
            }
            format="DD/MM/YYYY HH:mm"
            style={{ width: '100%' }}
            allowClear
            onChange={value => {
              setSelectedThirdValue(value);
              setSearchParams({
                [`${valueOfItem?.name}`]: value ? value.toISOString() : null,
              });
            }}
            renderExtraFooter={() => (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  flexWrap: 'wrap',
                  padding: '10px 0px 10px 0px',
                }}
              >
                {valueOfItem?.options.map((item, index) => (
                  <Tag
                    key={`${item.value}-${index}`}
                    style={{
                      cursor: 'pointer',
                      color: '#1890ff',
                      fontSize: '0.8rem',
                    }}
                    onClick={() => {
                      setSelectedThirdValue(item.value);
                      setSearchParams({
                        [`${valueOfItem?.name}`]: item.value,
                      });
                    }}
                  >
                    {item.label}
                  </Tag>
                ))}
              </div>
            )}
          />
        </Col>
      )}
    </Row>
  );
};
