import React, { memo, useCallback, useContext, useState } from 'react';

import { Tooltip, Tag, Button, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { FiLock, FiUnlock } from 'react-icons/fi';

import * as Styled from './styles';
import apiMiddleware from '@src/services/apiMiddleware';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SessionContext from '@src/store/SessionContext/SessionContext';
import rolesConstants from '@src/utils/rolesConstants';
import ActionTypes from '@src/utils/deviceActions';

function FinancialBlockStatusColumn({ value, customerId, mutate }) {
  const { t } = useTranslation('customers');
  const { userRoles } = useContext(SessionContext);

  const [loading, setLoading] = useState();

  const navigate = useNavigate();

  const checkRole = useCallback(
    role => userRoles?.portal?.includes(role),
    [userRoles],
  );
  const isCustomerCanBlockOrUnlock = checkRole(
    rolesConstants.FINANCIAL_LOCK_UNLOCK,
  );

  const colorFinancialTag = value ? 'red' : 'green';
  const iconFinancialTag = value ? <FiLock /> : <FiUnlock />;
  const tooltipFinancialTag = value
    ? t('actions.financialBlock.blocked')
    : t('actions.financialBlock.unlocked');
  const buttonFinancialBlock = value
    ? t('actions.financialBlock.unlock')
    : t('actions.financialBlock.block');

  const navigateToActions = useCallback(
    actionType => {
      message.destroy('send-action');
      navigate(
        `/management/actions-queue?action=${
          ActionTypes[`FINANCIAL_${actionType.toUpperCase()}`]
        }`,
      );
    },
    [navigate],
  );

  const messageContent = useCallback(
    (msg, blockOrUnlock) => (
      <>
        {t(`actions.msgs.${msg}`)}
        &nbsp;
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={() => navigateToActions(blockOrUnlock)}
        >
          {t('actions.see-in-the-actions-queue')}
        </Button>
      </>
    ),
    [navigateToActions, t],
  );

  const handleFinancialBlock = useCallback(
    async (cstId, financialBlock) => {
      const blockOrUnlock = financialBlock ? 'unlock' : 'block';
      setLoading(true);
      try {
        const payload = {
          action: ActionTypes[`FINANCIAL_${blockOrUnlock.toUpperCase()}`],
          customerIds: [cstId],
        };
        const { status } = await apiMiddleware.post(
          `/service-proxy/actions`,
          payload,
        );
        mutate();
        // 200 = success | 204 = alreadyInProgress
        const msgType = status === 200 ? 'success' : 'alreadyInProgress';
        message.success({
          key: 'send-action',
          content: messageContent(msgType, blockOrUnlock),
        });
      } catch {
        message.error(t(`actions.msgs.error`));
      }
      setLoading(false);
    },
    [mutate, t, messageContent],
  );

  if (!isCustomerCanBlockOrUnlock) {
    return <Tag color={colorFinancialTag}>{tooltipFinancialTag}</Tag>;
  }

  return (
    <Styled.FinancialBlock>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="financial-lock-tag-wrapper">
            <Tooltip title={tooltipFinancialTag}>
              <Tag className="financial-lock-tag" color={colorFinancialTag}>
                {iconFinancialTag}
              </Tag>
            </Tooltip>
            <Button type="link" className="financial-lock-button-arrow">
              &gt;
            </Button>
          </div>
          <Button
            size="small"
            onClick={() => handleFinancialBlock(customerId, value)}
            className="financial-lock-button"
            style={{
              borderColor: value && '#389e0d',
              color: value && '#389e0d',
            }}
            danger={!value}
          >
            {buttonFinancialBlock}
          </Button>
        </>
      )}
    </Styled.FinancialBlock>
  );
}

export default memo(FinancialBlockStatusColumn);
