import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';

import { useTranslation } from 'react-i18next';
import {
  Col,
  Input,
  Select,
  ConfigProvider,
  Button,
  Tag,
  Modal,
  Table as AntdTable,
} from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import { Label } from '@components/ui';

import Table from '@components/Table';
import { Filters, FiltersCard } from '@components/Filters';
import { dateFormat, getDateInISOFormat } from '@src/utils/formatters';
import useSearchParams from '@src/hooks/useSearchParams';
import SessionContext from '@src/store/SessionContext/SessionContext';
import moment from 'moment';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import { getAntLocale } from '@src/utils/locales';
import useSwr from '@src/hooks/useSwr';
import { allAttributesWithValue } from '@src/utils/allAttributesWithValue';
import StatusMenu from '@src/components/StatusMenu/StatusMenu';

const { Search } = Input;

const getStatus = t => [
  { label: t('status.PENDING'), value: 'PENDING' },
  { label: t('status.PROCESSING'), value: 'PROCESSING' },
  { label: t('status.COMPLETED'), value: 'COMPLETED' },
];

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const Operational = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('operational');
  const { t: tDevices } = useTranslation('device-actions');
  const {
    customer: { customersOptions },
  } = useContext(SessionContext);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [showFilters, setShowFilters] = useState(false);
  const [showDevicesModal, setShowDevicesModal] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState();
  // filters
  const [customerId, setCustomerId] = useState();
  const [status, setStatus] = useState();
  const [protocol, setProtocol] = useState();
  const [protocolValue, setProtocolValue] = useState();
  const [operationType, setOperationType] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [createdByValue, setCreatedByValue] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { data } = useSwr('/service-proxy/broker/operations', {
    page: pagination?.current - 1,
    linesPerPage: pagination.pageSize,
    customerId,
    status: status && status.toUpperCase(),
    protocol,
    operationType: operationType && operationType.toUpperCase(),
    createdBy,
    startDate,
    endDate,
  });

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customer',
        setState: setCustomerId,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: true,
      },
      {
        name: 'protocol',
        setState: setProtocol,
        inTheFilters: true,
      },
      {
        name: 'createdBy',
        setState: setCreatedBy,
        inTheFilters: true,
      },
      {
        name: 'startDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
      {
        name: 'operation',
        setState: setOperationType,
        inTheFilters: false,
      },
    ],
    [],
  );

  useEffect(() => {
    setProtocolValue(protocol);
  }, [protocol]);

  useEffect(() => {
    setCreatedByValue(createdBy);
  }, [createdBy]);

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['operation'], 'except');
      }
    },
    [handleClearParams, showFilters],
  );

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [
    customerId,
    status,
    protocol,
    operationType,
    createdBy,
    startDate,
    endDate,
  ]);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const allColumnsByActions = useMemo(
    () => allAttributesWithValue(data),
    [data],
  );
  const allColumns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: t('table.operationType'),
        dataIndex: 'operationType',
        render: value => t(`types.${value}`),
      },
      {
        title: t('table.protocol'),
        dataIndex: 'protocol',
      },
      {
        title: t('table.customerName'),
        dataIndex: 'customerName',
      },
      {
        title: t('table.createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: t('table.createdBy'),
        dataIndex: 'createdBy',
      },
      {
        title: t('table.updatedAt'),
        dataIndex: 'updatedAt',
        render: dateFormat,
      },
      {
        title: t('table.updatedBy'),
        dataIndex: 'updatedBy',
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        render: value => t(`status.${value}`),
      },
      {
        title: t('table.nbDevices'),
        dataIndex: 'devices',
        align: 'center',
        render: (value, record) => (
          <div>
            <Tag>{record.nbDevices}</Tag>
            {value && (
              <Button
                icon={<FileSearchOutlined />}
                type={'link'}
                onClick={() => {
                  setShowDevicesModal(true);
                  setSelectedDevices(record.devices);
                }}
              />
            )}
          </div>
        ),
      },
    ],
    [t],
  );
  const columns = useMemo(() => {
    if (data?.content?.length > 0) {
      return allColumns.filter(column =>
        allColumnsByActions.includes(column.dataIndex),
      );
    }
    return allColumns;
  }, [allColumnsByActions, data?.content?.length, allColumns]);

  const devicesColumns = useMemo(
    () => [
      {
        title: 'MSISDN',
        dataIndex: 'msisdn',
      },
      {
        title: t('table.status'),
        dataIndex: 'flagProcessed',
        render: (status, action) =>
          tDevices(`status.${action.actionType}.${status}`),
      },
    ],
    [t, tDevices],
  );

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      const initial = getDateInISOFormat(initialDate);
      const final = getDateInISOFormat(endDate);
      handleSetSearchParams({ startDate: initial, endDate: final });
    },
    [handleSetSearchParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('table.customerName'),
        item: (
          <Select
            loading={!customersOptions}
            disabled={!customersOptions}
            optionFilterProp={'label'}
            options={customersOptions}
            placeholder={t('table.customerName')}
            style={{ width: '100%' }}
            value={customerId ? Number(customerId) : undefined}
            allowClear
            showSearch
            onChange={value => handleSetSearchParams({ customer: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('table.createdAt'),
        item: (
          <ConfigProvider locale={getAntLocale(language)}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                startDate
                  ? [moment(startDate, 'YYYYMMDD'), moment(endDate, 'YYYYMMDD')]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('table.createdBy'),
        item: (
          <Search
            placeholder={t('table.createdBy')}
            onSearch={value => handleSetSearchParams({ createdBy: value })}
            onChange={({ target: { value } }) => setCreatedByValue(value)}
            value={createdByValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('table.protocol'),
        item: (
          <Search
            placeholder={t('table.protocol')}
            onSearch={value => handleSetSearchParams({ protocol: value })}
            onChange={({ target: { value } }) => setProtocolValue(value)}
            value={protocolValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 4,
          xs: 24,
        },
        label: t('table.status'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={getStatus(t).map(statusOption => ({
              label: statusOption.label,
              value: statusOption.value,
            }))}
            placeholder={t('table.status')}
            style={{ width: '100%' }}
            value={status?.toUpperCase()}
            allowClear
            showSearch
            onChange={value =>
              handleSetSearchParams({ status: value?.toLowerCase() })
            }
          />
        ),
      },
    ],
    [
      customerId,
      customersOptions,
      handleSetSearchParams,
      protocolValue,
      status,
      t,
      createdByValue,
      endDate,
      handleDateChange,
      language,
      startDate,
    ],
  );

  const statusMenuOperations = useMemo(
    () => [
      {
        label: t('types.SUSPENSION'),
        item: 'suspension',
      },
      {
        label: t('types.SUSPENSION_REVERSE'),
        item: 'suspension_reverse',
      },
      {
        label: t('types.CANCELLATION'),
        item: 'cancellation',
      },
      {
        label: t('types.SUBSTITUTION'),
        item: 'substitution',
      },
    ],
    [t],
  );

  const handleCloseModal = useCallback(() => {
    setShowDevicesModal(false);
    setSelectedDevices(undefined);
  }, []);

  return (
    <div>
      <div
        style={{
          padding: 8,
          background: '#ffffff',
          borderRadius: 6,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type={!operationType && 'primary'}
            style={{
              margin: 6,
              display: 'flex',
              justifyContent: 'space-between',
              borderColor: '#f1f1f1',
              fontSize: '0.9rem',
            }}
            onClick={() => handleClearParams(['operation'])}
          >
            {t('all-operations')}
          </Button>
        </div>
        <StatusMenu
          itemActive={operationType}
          data={statusMenuOperations}
          handleSelectedCard={item =>
            handleSetSearchParams({ operation: item })
          }
        />
      </div>
      <Table
        title={
          <>
            {t('title')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        tableKey={'operational'}
        data={data?.content || []}
        allColumns={allColumns}
        columns={columns}
        loading={!data?.content}
        pagination={pagination}
        onChange={handleTableChange}
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
      <Modal
        bodyStyle={{ padding: 24 }}
        footer={false}
        visible={showDevicesModal}
        centered
        title={'Simcards'}
        onCancel={handleCloseModal}
      >
        <AntdTable
          dataSource={selectedDevices || []}
          columns={devicesColumns}
          loading={!selectedDevices}
          pagination={false}
          style={{ width: '100%' }}
          scroll={{ y: 600 }}
        />
      </Modal>
    </div>
  );
};

export default Operational;
