import React, { useEffect, useMemo } from 'react';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import apiMiddleware from '@src/services/apiMiddleware';

const { Option } = Select;

const ContractModal = ({ visible, contract, mutate, onCancel, isEdition }) => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation(['sms-send']);

  const defaultValues = useMemo(() => {
    if (!isEdition) {
      return { type: 'PACKAGE' };
    }
    return contract;
  }, [contract, isEdition]);

  const handleOk = async () => {
    form.validateFields().then(async values => {
      try {
        if (contract) {
          await apiMiddleware.put(
            `/service-proxy/sms-contract/${contract.id}`,
            values,
          );

          message.success(translate('msgs.contract-updated'));
        } else {
          await apiMiddleware.post('/service-proxy/sms-contract', values);
          message.success(translate('msgs.contract-created'));
        }
      } catch (err) {
        message.error(translate('msgs.contract-error'));
      }
      onClose();
      mutate();
      form.resetFields();
    });
  };

  const onClose = () => {
    onCancel();
    form.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const selectedType = Form.useWatch('type', form);

  return (
    <>
      <Modal
        title={translate(contract ? 'title.editContract' : 'title.newContract')}
        visible={visible}
        onOk={handleOk}
        onCancel={onClose}
      >
        <Form form={form} layout="vertical">
          <Col span={24}>
            <Form.Item
              name="type"
              label={translate('table.type')}
              rules={[
                { required: true, message: translate('sender.required') },
              ]}
            >
              <Select disabled={isEdition}>
                <Option value="PACKAGE">
                  {translate('status-type.PACKAGE')}
                </Option>
                <Option value="PAY_PER_USE">
                  {translate('status-type.PAY_PER_USE')}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Row gutter={[16, 16]}>
            {selectedType !== 'PAY_PER_USE' && (
              <Col span={12}>
                <Form.Item
                  name="franchise"
                  label={translate('table.franchise')}
                  rules={[
                    {
                      required: selectedType !== 'PAY_PER_USE',
                      message: translate('sender.required'),
                    },
                  ]}
                >
                  <InputNumber
                    formatter={value => `${value} SMS`}
                    min={0}
                    parser={value => value.replace(' SMS', '')}
                    step={0.5}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name="price"
                label={translate(
                  selectedType !== 'PAY_PER_USE'
                    ? 'table.price'
                    : 'table.priceUnity',
                )}
                rules={[
                  { required: true, message: translate('sender.required') },
                ]}
              >
                <InputNumber
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="description"
                label={translate('table.description')}
                rules={[
                  { required: true, message: translate('sender.required') },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ContractModal;
