import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Card, Row, Form, Button, message, Skeleton } from 'antd';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import Personalization from '@components/Personalization/Personalization';
import { useTranslation } from 'react-i18next';
import apiMiddleware from '@src/services/apiMiddleware';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import { removeEmptyAttributes } from '@src/utils/removeEmptyAttributes';
import SessionContext from '@src/store/SessionContext/SessionContext';
import rolesConstants from '@src/utils/rolesConstants';

const cardStyle = {
  borderRadius: '5px',
  boxSizing: 'border-box',
  margin: '1rem',
  boxShadow: '0 1px 15px 1px rgba(81,77,92,.08)',
};

const Profile = () => {
  const { t } = useTranslation('profile');
  const { keycloak } = useContext(KeycloakContext);
  const {
    userRoles,
    setMainColor,
    setWhatsapp,
    setWhatsappMessage,
    customer: { customerLogged },
  } = useContext(SessionContext);
  const idTokenParsed = keycloak?.idTokenParsed;
  const customerId = idTokenParsed?.customerId;
  const form = Form.useForm()[0];

  const [activeTabKey, setActiveTabKey] = useState('personalInfo');
  const [isSavingPersonalization, setIsSavingPersonalization] = useState(false);
  const [personalization, setPersonalization] = useState();

  const customerPersonalization = useCallback(
    formData => apiMiddleware.put(`/customers/customizationTheme/`, formData),
    [],
  );

  const hasPersonalizationRole = useMemo(
    () => userRoles?.portal?.includes(rolesConstants.MANAGE_PERSONALIZATION),
    [userRoles?.portal],
  );

  useEffect(() => {
    if (customerLogged) {
      const { whatsapp, whatsappMessage, domain, mainColor } = customerLogged;

      form.setFieldsValue({
        domain,
        whatsapp,
        whatsappMessage,
        mainColor,
      });
    }
  }, [form, customerLogged]);

  const handleMutatePersonalization = useCallback(
    data => {
      const { mainColor, whatsapp, whatsappMessage } = data;
      setWhatsapp(whatsapp);
      setWhatsappMessage(whatsappMessage);
      setMainColor(mainColor);
    },
    [setWhatsapp, setWhatsappMessage, setMainColor],
  );

  const handleSavePersonalization = useCallback(async () => {
    try {
      setIsSavingPersonalization(true);
      const formPersonalization = new FormData();

      formPersonalization.append('customerId', customerId);
      Object.entries(removeEmptyAttributes(personalization)).forEach(
        ([name, value]) => formPersonalization.append(name, value),
      );
      await customerPersonalization(formPersonalization);
      const currentHostname =
        location.hostname === 'localhost'
          ? 'allcom.iot.algartelecom.com.br'
          : location.hostname;
      if (currentHostname === customerLogged?.domain) {
        handleMutatePersonalization(removeEmptyAttributes(personalization));
      }
      message.success(t('msgs.successfulSubmit'));
    } catch {
      message.error(t('msgs.failedSubmit'));
    }
    setIsSavingPersonalization(false);
  }, [
    personalization,
    customerId,
    customerPersonalization,
    t,
    handleMutatePersonalization,
    customerLogged?.domain,
  ]);

  const tabList = useMemo(
    () =>
      [
        {
          key: 'personalInfo',
          tab: t('tabs.personalInfo'),
        },
        hasPersonalizationRole && {
          key: 'personalization',
          tab: t('tabs.personalization'),
        },
      ]?.filter(item => item),
    [t, hasPersonalizationRole],
  );

  const tabsContent = useMemo(
    () => ({
      personalInfo: <PersonalInfo />,
      personalization: (
        <Skeleton loading={!customerLogged} active>
          <Personalization
            form={form}
            onFormChange={setPersonalization}
            domainIsRequired={false}
            isEditing
          />

          <div style={{ textAlign: 'right' }}>
            <Button
              loading={isSavingPersonalization}
              type={'primary'}
              onClick={handleSavePersonalization}
            >
              {t('buttons.save')}
            </Button>
          </div>
        </Skeleton>
      ),
    }),
    [
      form,
      handleSavePersonalization,
      isSavingPersonalization,
      customerLogged,
      t,
    ],
  );

  return (
    <Row>
      <Col xs={24}>
        <Card
          activeTabKey={activeTabKey}
          bodyStyle={{ paddingTop: '4em' }}
          bordered={false}
          headStyle={{ paddingTop: '.5em' }}
          style={cardStyle}
          tabList={tabList}
          tabProps={{ tabBarGutter: 30 }}
          onTabChange={setActiveTabKey}
        >
          {tabsContent[activeTabKey]}
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
