export const operatorsRange = new Map();
operatorsRange.set(1, 'Algar');
operatorsRange.set(2, 'Tim');
operatorsRange.set(4, 'Oi');
operatorsRange.set(8, 'Claro');
operatorsRange.set(16, 'Vivo');

export const operatorsDif = new Map();
operatorsDif.set(1, 'Algar');
operatorsDif.set(2, 'Tim');
operatorsDif.set(3, 'Tim + Algar');
operatorsDif.set(4, 'Oi');
operatorsDif.set(5, 'Oi + Algar');
operatorsDif.set(6, 'Oi + Tim');
operatorsDif.set(7, 'Oi + Tim + Algar');
operatorsDif.set(8, 'Claro');
operatorsDif.set(9, 'Claro + Algar');
operatorsDif.set(10, 'Claro + Tim');
operatorsDif.set(11, 'Claro + Tim + Algar');
operatorsDif.set(12, 'Claro + Oi');
operatorsDif.set(13, 'Claro + Oi + Algar');
operatorsDif.set(14, 'Claro + Oi + Tim');
operatorsDif.set(15, 'Claro + Oi + Tim + Algar');
operatorsDif.set(16, 'Vivo');
operatorsDif.set(17, 'Vivo + Algar');
operatorsDif.set(18, 'Vivo + Tim');
operatorsDif.set(19, 'Vivo + Tim + Algar');
operatorsDif.set(20, 'Vivo + Oi');
operatorsDif.set(21, 'Vivo + Oi + Algar');
operatorsDif.set(22, 'Vivo + Oi + Tim');
operatorsDif.set(23, 'Vivo + Oi + Tim + Algar');
operatorsDif.set(24, 'Vivo + Claro');
operatorsDif.set(25, 'Vivo + Claro + Algar');
operatorsDif.set(26, 'Vivo + Claro + Tim');
operatorsDif.set(27, 'Vivo + Claro + Tim + Algar');
operatorsDif.set(28, 'Vivo + Claro + Oi');
operatorsDif.set(29, 'Vivo + Claro + Oi + Algar');
operatorsDif.set(30, 'Vivo + Claro + Oi + Tim');
operatorsDif.set(31, 'Vivo + Claro + Oi + Tim + Algar');
operatorsDif.set(32, 'Sem uso');

const operatorsDictionary = {};

export const reverseOrder = {
  Tim: { color: '#01428d', ids: ['72402', '72403', '72404'] },
  Algar: { color: '#00a14e', ids: ['72432', '72433', '72434'] },
  Oi: { color: '#f7c80f', ids: ['72416', '72424', '72431'] },
  Claro: { color: '#db201b', ids: ['72405'] },
  Vivo: {
    color: '#630094',
    ids: ['72406', '72410', '72411', '72423', '72419', '72428'],
  },
};

Object.keys(reverseOrder).forEach(operator => {
  const current = reverseOrder[operator];
  current.ids.forEach(prefix => {
    operatorsDictionary[prefix] = { operator, color: current.color };
  });
});
