import styled from 'styled-components';

export const Container = styled.div`
  > .ant-card {
    border-radius: 0;
    padding: 16px 0;
  }

  .ant-row {
    margin: 10px 0;
  }

  .ant-tabs-tab-btn {
    margin-left: 15px;
  }
`;
