import apiMiddleware from '@services/apiMiddleware';
import useSwr from '@src/hooks/useSwr';
import {
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Radio,
  Button,
  Typography,
  Modal,
  Steps,
  DatePicker,
  Alert,
} from 'antd';
import { DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import { formatCurrency, getDateToUsPattern } from '@src/utils/formatters';
import { statesOfBrazil } from '@src/utils/statesToList';
import moment from 'moment';

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography;
const { Step } = Steps;

const ScheduleModal = ({
  visible,
  onClose,
  mutateData,
  creditsData,
  mutateCredits,
}) => {
  const { t: translate } = useTranslation('schedules');
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [confirmPaymentModalVisible, setConfirmPaymentModalVisible] =
    useState(false);
  const [paymentConfirmationUrl, setPaymentConfirmationUrl] = useState('');
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedUF, setSelectedUF] = useState();
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedCityCustomer, setSelectedCityCustomer] = useState();

  const next = useCallback(() => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  }, [currentStep, form]);

  const prev = () => setCurrentStep(currentStep - 1);

  const handleProductSelect = value => {
    const product = productsData?.content?.find(item => item.name === value);
    setSelectedProducts(prevState => [...prevState, product]);
  };

  const handleRemoveProduct = productId => {
    setSelectedProducts(prevState =>
      prevState.filter(product => product.idProduct !== productId),
    );
  };

  const calculateTotalPrice = () => {
    return selectedProducts.reduce(
      (total, product) => total + Number(product.price),
      0,
    );
  };

  const calculateTotalCredits = () => {
    return selectedProducts.reduce(
      (total, product) => total + Number(product.creditPrice),
      0,
    );
  };

  const { data: customersData } = useSwr('/service-proxy/astro/clients', {
    page: 0,
    per_page: 100,
  });

  const { data: citiesData } = useSwr(
    selectedUF ? `/service-proxy/astro/cities?uf=${selectedUF}` : null,
  );

  const { data: vehiclesData } = useSwr(
    selectedCustomer
      ? `/service-proxy/astro/vehicles?clientId=${selectedCustomer}`
      : null,
    {
      page: 0,
      per_page: 100,
    },
  );

  const { data: productsData } = useSwr(
    selectedUF && selectedCity
      ? `/service-proxy/astro/appointment/services?uf=${selectedUF}&city=${selectedCity}`
      : null,
  );

  const createSchedule = useCallback(
    (newSchedule, idClient) =>
      apiMiddleware.post(`/service-proxy/astro/appointment/${idClient}`, {
        ...newSchedule,
      }),
    [],
  );

  const close = useCallback(
    (cancelled = false) => {
      setSaveLoading(false);
      form.resetFields();
      onClose(cancelled);
      setCurrentStep(0);
      setSelectedProducts([]);
      setSelectedCustomer();
      setSelectedCity();
      setSelectedUF();
    },
    [form, onClose],
  );

  const onFinish = useCallback(async () => {
    if (currentStep < 1) {
      if (selectedCity !== selectedCityCustomer) {
        message.error(translate('msgs.error-different-city'));
        return;
      }
      next();
    } else {
      setSaveLoading(true);
      const newSchedule = form.getFieldValue();
      const { idClient, ...newData } = newSchedule;

      const newItem = {
        ...newData,
        products: selectedProducts.map(product => product.idProduct),
        city: selectedCity,
        state: selectedUF,
        data: getDateToUsPattern(newData.data),
      };

      try {
        const { data } = await createSchedule(newItem, idClient);
        const { id, paymentUrl } = data;
        mutateData('CREATE', {
          ...newItem,
          id,
          city: selectedCity,
          state: selectedUF,
        });

        if (paymentUrl) {
          setConfirmPaymentModalVisible(true);
          setPaymentConfirmationUrl(paymentUrl);
          close();
        } else {
          mutateCredits('/service-proxy/astro/credit/balance');
          close();
          message.success(translate('msgs.success-schedule'));
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          message.error(translate('msgs.error-schedule-exists'));
        } else {
          message.error(translate('msgs.error'));
        }
        close();
      }
      setSaveLoading(false);
    }
  }, [
    createSchedule,
    form,
    mutateData,
    translate,
    close,
    selectedProducts,
    currentStep,
    next,
    selectedCity,
    selectedUF,
    selectedCityCustomer,
    mutateCredits,
  ]);

  const disabledDate = current => {
    const day = current.day();
    const isBeforeToday = current && current < moment().startOf('day');
    return !availableDays.includes(day) || isBeforeToday;
  };

  return (
    <>
      <Styled.ModalContainer
        bodyStyle={{ minHeight: 500 }}
        maskClosable={false}
        title={translate('modal.title.newSchedule')}
        visible={visible}
        width="70%"
        centered
        forceRender
        footer={false}
        onCancel={() => close(true)}
      >
        <Steps current={currentStep} style={{ padding: '0 24px' }}>
          <Step title={translate('steps.schedule')} />
          <Step title={translate('steps.payment')} />
        </Steps>

        <Form
          colon={false}
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          scrollToFirstError
          onFinish={onFinish}
        >
          {currentStep === 0 ? (
            <>
              <Row gutter={[16, 16]}>
                <div style={{ width: '100%', opacity: '0.6' }}>
                  <Divider orientation="left">
                    {translate('dividers.period')}
                  </Divider>
                </div>
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.uf')}
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select
                      onChange={value => setSelectedUF(value)}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          String(option?.label).toLowerCase().indexOf(input) >=
                          0
                        );
                      }}
                      showSearch
                      options={statesOfBrazil.map(state => ({
                        label: state.label,
                        value: state.value,
                      }))}
                    />
                  </Item>
                </Col>
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.city')}
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select
                      onChange={value => {
                        const cityData = citiesData?.data?.find(
                          city => city.cidade === value,
                        );
                        if (cityData) {
                          setAvailableDays(
                            cityData?.diasAtendimento.map(Number),
                          );
                        }
                        setSelectedCity(value);
                      }}
                      optionFilterProp="children"
                      options={citiesData?.data?.map(city => ({
                        label: `${city.cidade}  - (${city.diasAtendimento.map(
                          day => translate(`modal.days.${day.toString()}`),
                        )})`,
                        value: city.cidade,
                      }))}
                      filterOption={(input, option) => {
                        return (
                          String(option?.label).toLowerCase().indexOf(input) >=
                          0
                        );
                      }}
                      showSearch
                      disabled={!citiesData}
                    />
                  </Item>
                  {!citiesData && selectedUF && (
                    <Alert
                      message={translate('msgs.no-attend-on-state')}
                      type="warning"
                      showIcon
                    />
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <div style={{ width: '100%', opacity: '0.6' }}>
                  <Divider orientation="left">
                    {translate('dividers.period')}
                  </Divider>
                </div>

                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.period')}
                    name="periodo"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select>
                      <Option value={'Manhã'}>
                        {translate('options.morning')}
                      </Option>
                      <Option value={'Tarde'}>
                        {translate('options.afternoon')}
                      </Option>
                    </Select>
                  </Item>
                </Col>
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.date')}
                    name="data"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                      disabled={!availableDays}
                    />
                  </Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <div style={{ width: '100%', opacity: '0.6' }}>
                  <Divider orientation="left">
                    {translate('dividers.info')}
                  </Divider>
                </div>
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.client')}
                    name="idClient"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select
                      onChange={value => setSelectedCustomer(value)}
                      optionFilterProp="children"
                      options={customersData?.content?.map(customer => {
                        return {
                          label: `${customer.corporateName}`,
                          value: customer.idClient,
                        };
                      })}
                      onSelect={value => {
                        setSelectedCityCustomer(
                          customersData?.content?.find(
                            item => item.idClient === value,
                          ).city,
                        );
                      }}
                    />
                  </Item>
                </Col>
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.vehicles')}
                    name="fkVeiculo"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select disabled={!selectedCustomer}>
                      {vehiclesData?.content &&
                        vehiclesData?.content?.map(vehicle => (
                          <Option
                            key={vehicle.idVeiculo}
                            value={vehicle.idVeiculo}
                          >
                            {vehicle.marca} - {vehicle.modelo} / {vehicle.placa}
                          </Option>
                        ))}
                    </Select>
                  </Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]} align="start" justify="start">
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.type')}
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select>
                      <Option value={'Interno'}>
                        {translate('options.inside')}
                      </Option>
                      <Option value={'Externo'}>
                        {translate('options.outside')}
                      </Option>
                    </Select>
                  </Item>
                </Col>
                <Col xl={12} xs={24}>
                  <Item
                    label={translate('table.products')}
                    name="products"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select
                      onChange={value => {
                        handleProductSelect(value);
                      }}
                      disabled={!productsData}
                    >
                      {productsData?.content &&
                        productsData?.content?.map(product => (
                          <Option key={product.idProduct} value={product.name}>
                            {product.name} -{' '}
                            {formatCurrency(Number(product.price))} -{' '}
                          </Option>
                        ))}
                    </Select>
                  </Item>
                </Col>
              </Row>

              <Divider orientation="left">
                {translate('dividers.selectedProducts')} -{' '}
                {formatCurrency(calculateTotalPrice())}
              </Divider>
              <Row
                gutter={[16, 16]}
                style={{ marginTop: '18px', marginBottom: '18px' }}
              >
                {selectedProducts.map(product => (
                  <Col key={product.idProduct} span={24}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #f0f0f0',
                        padding: '10px 0',
                      }}
                    >
                      <Typography style={{ fontSize: '1.3rem' }}>
                        {product.name} - {formatCurrency(Number(product.price))}
                        {' ('}
                        {translate('modal.price-credits')}
                        {': '}
                        {Number(product.creditPrice)} {')'}
                      </Typography>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveProduct(product.idProduct)}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <>
              <Row gutter={[16, 16]} style={{ marginTop: '18px' }}>
                <Col xl={18} xs={24}>
                  <Item
                    label={translate('table.paymentMethod')}
                    name="paymentMethod"
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                    initialValue="credit_card"
                  >
                    <Radio.Group defaultValue="credit_card" optionType="button">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '15px',
                        }}
                      >
                        <Radio value="credit_card" style={{ height: '40px' }}>
                          {translate('options.creditCard')}
                        </Radio>

                        <Radio
                          value="wallet"
                          disabled={
                            calculateTotalCredits() >
                            (creditsData?.balance || 0)
                          }
                          style={{ height: '40px' }}
                        >
                          {creditsData?.balance ? creditsData.balance : '0'}{' '}
                          {translate('options.credit')}
                        </Radio>
                      </div>
                    </Radio.Group>
                  </Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {selectedProducts.map(product => (
                  <Col key={product.idProduct} span={24}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #f0f0f0',
                        padding: '10px 0',
                      }}
                    >
                      <Typography style={{ fontSize: '1.3rem' }}>
                        {product.name} - {formatCurrency(Number(product.price))}{' '}
                        {'('}
                        {translate('modal.price-credits')}
                        {': '}
                        {Number(product.creditPrice)} {')'}
                      </Typography>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveProduct(product.idProduct)}
                      />
                    </div>
                  </Col>
                ))}
              </Row>

              <Row
                gutter={[16, 16]}
                justify="end"
                align="end"
                style={{ marginTop: '18px' }}
              >
                <Col span={24}>
                  <Title level={4}>
                    Total: {formatCurrency(calculateTotalPrice())} - {'('}
                    {translate('modal.price-credits')}
                    {': '}
                    {calculateTotalCredits()} {')'}
                  </Title>
                </Col>
              </Row>
              <Divider />
            </>
          )}
        </Form>

        <Row justify="end" align="end" gutter={16}>
          {currentStep === 1 && (
            <Col>
              <Button onClick={() => prev()}>
                {translate('buttons.back')}
              </Button>
            </Col>
          )}

          <Col>
            <Button
              type="primary"
              onClick={() => onFinish()}
              disabled={selectedProducts.length === 0}
            >
              {currentStep < 1
                ? translate('buttons.next')
                : translate('buttons.confirm')}
            </Button>
          </Col>
        </Row>
      </Styled.ModalContainer>
      <Modal
        title={translate('confirm-payment')}
        visible={confirmPaymentModalVisible}
        onCancel={() => {
          close();
          setConfirmPaymentModalVisible(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              close();
              setConfirmPaymentModalVisible(false);
            }}
          >
            {translate('buttons.cancel')}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            href={paymentConfirmationUrl}
            loading={saveLoading}
            target="_blank"
            onClick={() => {
              close();
              setConfirmPaymentModalVisible(false);
            }}
          >
            {translate('buttons.confirm-payment')}
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <CheckCircleOutlined style={{ fontSize: 64, color: '#52c41a' }} />
          <p>{translate('schedule-message')}</p>
          <p> {translate('schedule-confirmation')} </p>
          <a
            target="_blank"
            rel="noreferrer"
            href={paymentConfirmationUrl}
            onClick={() => {
              close();
              setConfirmPaymentModalVisible(false);
            }}
          >
            {paymentConfirmationUrl}
          </a>
        </div>
      </Modal>
    </>
  );
};

export default ScheduleModal;
