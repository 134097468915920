import { modelValuesByDataIndex } from './modelValuesByDataIndex';
import lorawanModels from './LorawanModels';

const DEFAULT_NUMBER_OF_LINES = 3;

// msisdn:newMsisdn ---> newMsisdn
const handleAlternativeDataIndex = dataIndex => {
  const dataIndexSplit = dataIndex.split(':');
  if (dataIndexSplit?.length === 2) {
    return dataIndexSplit;
  }
  return [dataIndex, dataIndex];
};

export const getUploadModels = ({ t, actionKey, columnsNames }) => {
  let actionDataIndexList = columnsNames ?? [];
  if (!columnsNames) {
    actionDataIndexList = lorawanModels(actionKey);
  }

  const actionModel = {
    columns: [],
    data: [],
  };

  // feeds the columns
  actionDataIndexList.forEach(dataIndex => {
    const [nameDataIndex, keyDataIndex] = handleAlternativeDataIndex(dataIndex);
    actionModel.columns.push({
      key: keyDataIndex,
      title: t(`lorawan:${nameDataIndex}`),
      dataIndex: keyDataIndex,
    });
  });

  // feeds data
  Object.keys(new Array(DEFAULT_NUMBER_OF_LINES).fill(null)).forEach(key => {
    const dataModel = {};
    actionDataIndexList?.forEach(dataIndex => {
      const [nameDataIndex, keyDataIndex] =
        handleAlternativeDataIndex(dataIndex);
      dataModel[keyDataIndex] =
        // @ts-ignore
        modelValuesByDataIndex()?.[nameDataIndex]?.[key];
    });
    actionModel.data.push({
      key,
      ...dataModel,
    });
  });

  return actionModel;
};
