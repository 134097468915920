/* eslint-disable no-magic-numbers */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Select, Table, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  SaveOutlined,
  UndoOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { operatorsDif as operators } from '@utils/operators';

const { Option } = Select;

const DifPerOperatorConfig = ({
  difPerOperator,
  setDifPerOperator,
  setOnNewRowFunction,
  editable,
  noInteraction,
  noTitle,
  handleSave,
  handleDelete,
}) => {
  const { t } = useTranslation('pricing-plan-operator');

  const [editingRow, setEditingRow] = useState(undefined);
  const [isCreatingNewRow, setIsCreatingNewRow] = useState(false);

  const [editedOperator, setEditedOperator] = useState(undefined);
  const [editedPrice, setEditedPrice] = useState(undefined);

  const handleSaveEditing = useCallback(
    index => {
      setEditingRow(undefined);
      setIsCreatingNewRow(false);

      const itemToSave = {
        idOperadora: editedOperator || difPerOperator[index]?.idOperadora,
        nuValorLiquido: editedPrice || difPerOperator[index]?.nuValorLiquido,
      };
      if (handleSave) {
        handleSave(itemToSave, index);
      } else {
        setDifPerOperator(oldArray =>
          oldArray?.map((item, itemIndex) =>
            itemIndex === index ? itemToSave : item,
          ),
        );
      }

      setEditedOperator(undefined);
      setEditedPrice(undefined);
    },
    [
      handleSave,
      editedOperator,
      difPerOperator,
      editedPrice,
      setDifPerOperator,
    ],
  );

  const handleCancelEditing = useCallback(() => {
    if (isCreatingNewRow) {
      setDifPerOperator(oldArray =>
        oldArray.filter((item, index) => index !== oldArray.length - 1),
      );
      setIsCreatingNewRow(false);
    }
    setEditingRow(undefined);
    setEditedOperator(undefined);
    setEditedPrice(undefined);
  }, [isCreatingNewRow, setDifPerOperator]);

  const handleNew = useCallback(() => {
    if (!isCreatingNewRow) {
      setEditingRow(difPerOperator.length);
      setIsCreatingNewRow(true);
      setDifPerOperator(oldArray => [
        ...oldArray,
        {
          idOperadora: Array.from(operators).find(
            ([opKey]) =>
              !difPerOperator.map(dif => dif['idOperadora']).includes(opKey),
          )[0],
          nuValorLiquido: '3.50',
        },
      ]);
    }
  }, [difPerOperator, isCreatingNewRow, setDifPerOperator]);

  const handleDeleteRow = useCallback(
    async indexToDelete => {
      if (handleDelete !== null) {
        handleDelete(indexToDelete);
      } else {
        setDifPerOperator(oldArray =>
          oldArray.filter((item, index) => index !== indexToDelete),
        );
      }
    },
    [handleDelete, setDifPerOperator],
  );

  const adminActions = useCallback(
    (text, record, index) => (
      <>
        {!noInteraction && (
          <>
            {editingRow === index ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  icon={<SaveOutlined />}
                  type={'link'}
                  onClick={() => handleSaveEditing(index)}
                />
                <Button
                  icon={<UndoOutlined />}
                  type={'link'}
                  onClick={() => handleCancelEditing()}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {editable && (
                  <Button
                    disabled={!!editingRow}
                    icon={<EditOutlined />}
                    type={'link'}
                    onClick={() => setEditingRow(index)}
                  />
                )}
                <Button
                  disabled={!!editingRow}
                  icon={<DeleteOutlined />}
                  type={'link'}
                  onClick={() => handleDeleteRow(index)}
                />
              </div>
            )}
          </>
        )}
      </>
    ),
    [
      editingRow,
      handleCancelEditing,
      handleDeleteRow,
      handleSaveEditing,
      editable,
      noInteraction,
    ],
  );

  const columns = useMemo(
    () => [
      {
        title: t('usedOperators'),
        dataIndex: 'idOperadora',
        render: (value, record, index) => {
          if (index === editingRow) {
            return (
              <Select
                defaultValue={value}
                style={{ width: '100%' }}
                onChange={value => setEditedOperator(value)}
              >
                {Array.from(operators)
                  .filter(
                    ([opKey]) =>
                      !difPerOperator
                        .map(dif => dif['idOperadora'])
                        .includes(opKey) || opKey === value,
                  )
                  .map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
              </Select>
            );
          }
          return operators.get(value);
        },
      },
      {
        title: t('chargeValue'),
        dataIndex: 'nuValorLiquido',
        render: (value, record, index) => {
          if (index === editingRow) {
            return (
              <InputNumber
                defaultValue={value}
                formatter={value => `R$ ${value}`}
                min={0}
                parser={value => value.replace('R$ ', '')}
                step={0.01}
                onChange={value => setEditedPrice(value)}
              />
            );
          }
          return `R$ ${value}`;
        },
      },
      {
        title: '',
        render: adminActions,
        align: 'center',
      },
    ],
    [t, adminActions, editingRow, difPerOperator],
  );

  useEffect(() => {
    if (setOnNewRowFunction) {
      setOnNewRowFunction(() => handleNew);
    }
  }, [handleNew]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={difPerOperator}
        pagination={false}
        rowKey={({ id }) => id}
        size={'small'}
        style={{ overflowY: 'auto', width: '100%', padding: 10 }}
        title={
          setOnNewRowFunction || noInteraction
            ? null
            : () => (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                  }}
                >
                  {noTitle ? <span /> : t('title')}
                  <Button
                    disabled={isCreatingNewRow}
                    icon={<PlusOutlined />}
                    shape={'circle'}
                    size={'small'}
                    type={'primary'}
                    onClick={() => handleNew()}
                  />
                </div>
              )
        }
      />
    </>
  );
};

DifPerOperatorConfig.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  difPerOperator: PropTypes.array.isRequired,
  setDifPerOperator: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleSave: PropTypes.func,
  noInteraction: PropTypes.bool,
  noTitle: PropTypes.bool,
  setOnNewRowFunction: PropTypes.func,
};

DifPerOperatorConfig.defaultProps = {
  editable: false,
  handleDelete: null,
  handleSave: null,
  setOnNewRowFunction: null,
  noInteraction: false,
  noTitle: false,
};

export default DifPerOperatorConfig;
