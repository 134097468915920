import React from 'react';

import { Typography, Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import Personalization from '@src/components/Personalization/Personalization';

const { Paragraph } = Typography;

function CustomerPersonalization({
  form,
  onFormChange,
  skipPersonalization,
  isSaving,
}) {
  const { t } = useTranslation('customers');

  return (
    <div>
      <Paragraph>{t('personalization.description')}</Paragraph>

      <Divider />
      <div style={{ width: '80%', margin: '0 auto' }}>
        <Personalization form={form} onFormChange={onFormChange} />

        <Divider />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={skipPersonalization} disabled={isSaving}>
            {t('personalization.skip')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomerPersonalization;
