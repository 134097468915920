import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ spinning, delay, fullscreen, children }) => {
  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: fullscreen ? 48 : 24 }} spin />
  );

  return (
    <Spin
      delay={delay}
      indicator={loadingIcon}
      spinning={spinning}
      style={{
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <div style={fullscreen && { height: '100vh' }}>{children}</div>
    </Spin>
  );
};

export default Spinner;
