import styled from 'styled-components';

export const LockUnlock = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 8px;

  .ant-btn {
    display: grid;
    place-items: center;
    margin: 0;
    padding: 0;
    height: 32px;
  }
`;
