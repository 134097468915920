import React, { useMemo, useState } from 'react';
import { Input, Tooltip, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import ActionsUpload from '@components/Actions/ActionsUpload';

const { Search } = Input;

const InputUploader = ({ onUpload, itemName, allowSearch, ...inputProps }) => {
  const { t } = useTranslation(['inventory', 'device-actions-modal']);
  const [modalVisible, setModalVisible] = useState(false);
  const [devices, setDevices] = useState();

  const suffix = useMemo(
    () => (
      <Tooltip title={t('file-search')}>
        <Button
          type="link"
          icon={<UploadOutlined />}
          size="small"
          style={{ height: 22, width: 22, alignItems: 'center' }}
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    ),
    [t],
  );

  const devicesValues = useMemo(
    () => devices?.map(device => Object.values(device)?.[0]),
    [devices],
  );

  const modalFooter = useMemo(
    () => (
      <Button
        type="primary"
        onClick={() => {
          onUpload?.(devicesValues);
          setModalVisible(false);
        }}
      >
        {t('device-actions-modal:confirm')}
      </Button>
    ),
    [devicesValues, onUpload, t],
  );

  return (
    <>
      {allowSearch ? (
        <Search suffix={suffix} allowClear {...inputProps} />
      ) : (
        <Input suffix={suffix} allowClear {...inputProps} />
      )}
      {modalVisible && (
        <Modal
          title={t('device-actions-modal:upload.title')}
          open={modalVisible}
          footer={modalFooter}
          onCancel={() => setModalVisible(false)}
        >
          <ActionsUpload
            onUpload={setDevices}
            columnsNames={[itemName]}
            csvFilename={`${itemName}-list`}
          />
        </Modal>
      )}
    </>
  );
};

export default InputUploader;
