export default obj => {
  const newObject = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (
      ((value || typeof value === 'boolean') && value !== '') ||
      value === 0
    ) {
      newObject[key] = value;
    }
  });
  return newObject;
};
