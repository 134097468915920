import styled from 'styled-components';

const Label = styled.label`
  color: ${({ color }) => (color ? color : '#aba5b6')};
  font-weight: 200;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  & [htmkFor] {
    cursor: pointer;
  }
`;

export default Label;
