import styled from 'styled-components';

const LoginContainer = styled.div`
  margin: 6% auto 0 auto;
  background: none;
  width: 430px;
  display: block;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export default LoginContainer;
