import React, { useEffect, useMemo, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { formatCurrency } from '@utils/formatters';
import { Card } from '@components/ui';
import { Col, Row, Skeleton, Descriptions, Tag, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { getDocumentTitle } from '@src/utils/changeDocumentTitle';
import useSwr from '@src/hooks/useSwr';
import SessionContext from '@src/store/SessionContext/SessionContext';
import BackToListing from '@src/components/BackToListing/BackToListing';
import Requests from '../../Requests/index';

const DetailedContract = () => {
  const { t } = useTranslation([
    'contracts',
    'requests',
    'lorawan',
    'attributes',
  ]);
  const { t: tMainContainer } = useTranslation('main-container');
  const { contractId } = useParams();

  const {
    customer: { customerLoggedName },
  } = useContext(SessionContext);

  const { data } = useSwr(`/service-proxy/lorawanVirtualPlans/${contractId}`);

  const customerDetails = useMemo(() => data?.customer, [data]);

  useEffect(() => {
    getDocumentTitle(customerLoggedName, tMainContainer, data?.planDesc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, data, customerLoggedName, tMainContainer]);

  const contractTypeFormatted = useMemo(() => {
    switch (data?.sharingType) {
      case 1:
        return t('attributes:type.sharedWithout');
      case 2:
        return t('attributes:type.sharedTotal');
      case 3:
        return t('attributes:type.sharedMixed');
      default:
        return '';
    }
  }, [data, t]);

  const handleActivatedTag = useCallback(
    value => {
      const [color, text] = value
        ? ['green', 'activated']
        : ['red', 'disabled'];
      return (
        <Tag color={color}>
          {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
        </Tag>
      );
    },
    [t],
  );

  const contractItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:activationPrice'),
        value: formatCurrency(parseFloat(data?.activationPrice)),
        showItem: data?.activationPrice,
      },
      {
        label: t('attributes:monthlyFee'),
        value: formatCurrency(parseFloat(data?.monthlyFee)),
        showItem: data?.monthlyFee,
      },
      {
        label: t('lorawan:status'),
        value: handleActivatedTag(data?.status),
        showItem: data?.status,
      },
      {
        label: t('requests:plan.sharingType'),
        value: contractTypeFormatted,
        showItem: data?.sharingType,
      },
      {
        label: t('attributes:downlinkCount'),
        value: data?.downlinkCount,
        showItem: data?.downlinkCount,
      },
      {
        label: t('attributes:excessDownlinkPrice'),
        value: formatCurrency(parseFloat(data?.excessDownlinkPrice)),
        showItem: data?.excessDownlinkPrice,
      },
      {
        label: t('attributes:uplinkCount'),
        value: data?.uplinkCount,
        showItem: data?.uplinkCount,
      },
      {
        label: t('attributes:excessUplinkPrice'),
        value: formatCurrency(parseFloat(data?.excessUplinkPrice)),
        showItem: data?.excessUplinkPrice,
      },
    ],
    [t, data, contractTypeFormatted, handleActivatedTag],
  );

  const clientItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:name'),
        value: customerDetails?.name,
        showItem: customerDetails?.name,
      },
      {
        label: t('attributes:nickname'),
        value: customerDetails?.nickname,
        showItem: customerDetails?.nickname,
      },
      {
        label: t('attributes:tradingName'),
        value: customerDetails?.tradingName,
        showItem: customerDetails?.tradingName,
      },
      {
        label: t('attributes:cpfCnpj'),
        value: customerDetails?.cpfCnpj,
        showItem: customerDetails?.cpfCnpj,
      },
      {
        label: t('attributes:customerType.title'),
        value: t(`attributes:customerType.${customerDetails?.type}`),
        showItem: customerDetails?.type,
      },
    ],
    [t, customerDetails],
  );

  const DividerModel = useCallback(
    ({ title }) => (
      <Divider
        style={{
          marginTop: 24,
          marginBottom: 32,
          fontSize: '0.85rem',
          opacity: 0.7,
          textTransform: 'uppercase',
        }}
        orientation="left"
      >
        {title}
      </Divider>
    ),
    [],
  );

  return (
    <Skeleton loading={!data} active>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col lg={24} sm={24} xs={24}>
          <Card
            title={
              <BackToListing
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {t('details.title')}
                  </div>
                }
              />
            }
          >
            <Descriptions
              column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
              style={{ marginTop: 24 }}
            >
              <Descriptions.Item
                key={t('attributes:planDesc')}
                label={t('attributes:planDesc')}
              >
                {data?.planDesc}
              </Descriptions.Item>

              {data?.externalId && (
                <Descriptions.Item
                  key={t('attributes:externalId')}
                  label={t('attributes:externalId')}
                >
                  {data?.externalId}
                </Descriptions.Item>
              )}
            </Descriptions>
            <DividerModel title={t('details.billingModel')} />
            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {contractItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  value &&
                  showItem && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>

            <DividerModel title={t('attributes:client')} />

            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {clientItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  value &&
                  showItem && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <div style={{ marginTop: 16 }}>
        <Requests
          isInTheContractDetails
          defaultFilters={{
            contractId: data?.id,
          }}
          defaultContractId={data?.id}
          defaultCustomerId={customerDetails?.id}
          defaultColumns={[
            'id',
            'name',
            'operatorDiffChargeFlag',
            'value',
            'activationPrice',
            'megabyteExcPrice',
            'automaticBlocking',
            'actions',
          ]}
        />
      </div>
    </Skeleton>
  );
};

export default DetailedContract;
