import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import {
  Button,
  Input,
  Modal,
  Tooltip,
  Col,
  Tabs,
  message,
  Tag,
  DatePicker,
} from 'antd';
import { Filters, FiltersCard } from '@components/Filters';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import NewContractModal from './NewContractModal/NewContractModal';
import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  PlusCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';
import RoleChecker, { useCheckRole } from '@components/RoleChecker/RoleChecker';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import SessionContext from '@src/store/SessionContext/SessionContext';
import apiMiddleware from '@src/services/apiMiddleware';
import { dateFormat, toISOStringWithOffset } from '@src/utils/formatters';
import rolesConstants from '@utils/rolesConstants';
import SelectCustomers from '@src/components/SelectCustomers/index';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import moment from 'moment';

const { confirm } = Modal;
const { Search } = Input;
const { TabPane } = Tabs;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Contracts = () => {
  const { t } = useTranslation(['contracts', 'attributes', 'device-actions']);
  const { hasCustomersRoles } = useContext(SessionContext);
  const { keycloak } = useContext(KeycloakContext);

  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';
  const isBroker = customerType === 'BROKER';

  const navigate = useNavigate();
  const searchLocation = useLocation().search;
  const canManage = useCheckRole({ role: rolesConstants.MANAGE_PLANS });
  const viewAlerts = useCheckRole({ role: rolesConstants.VIEW_ALERTS });

  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedClient, setSelectedClient] = useState();
  const [showContracted, setShowContracted] = useState(
    isEndCustomer ? 'true' : 'false',
  );
  const [description, setDescription] = useState('');
  const [alertDateFrom, setalertDateFrom] = useState();
  const [descriptionValue, setDescriptionValue] = useState('');
  const [externalId, setExternalId] = useState('');
  const [externalIdValue, setExternalIdValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGINATION.current);
  const [linesPerPage, setLinesPerPage] = useState(DEFAULT_PAGINATION.pageSize);

  const { data: contracts, mutate } = useSwr(
    `/service-proxy/broker/virtual-plans`,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      customerId: selectedClient,
      name: description,
      alertDateFrom,
      showContracted: isEndCustomer
        ? 'true'
        : showContracted === 'filed'
        ? false
        : showContracted === 'true'
        ? true
        : false,
      externalId,
    },
  );

  const { data: archivedContracts, mutate: mutateArchivedContracts } = useSwr(
    !isEndCustomer ? `/service-proxy/broker/virtual-plan/archived` : null,
    {
      page: pagination?.current - 1,
      linesPerPage: pagination?.pageSize,
      customerId: selectedClient,
      name: description,
      externalId,
    },
  );

  const data = useMemo(
    () => (showContracted === 'filed' ? archivedContracts : contracts),
    [archivedContracts, contracts, showContracted],
  );

  const fileTheContract = useCallback(
    id =>
      apiMiddleware.patch(`/service-proxy/broker/virtual-plan/${id}/archive`),
    [],
  );

  const handleMutateContract = useCallback(
    contract => {
      mutate({
        ...data,
        content: [contract, ...data.content].slice(0, 10),
      });
    },
    [data, mutate],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customerId',
        setState: setSelectedClient,
        inTheFilters: true,
      },
      {
        name: 'showContracted',
        setState: setShowContracted,
        inTheFilters: false,
      },
      {
        name: 'externalId',
        setState: setExternalId,
        setStateValue: setExternalIdValue,
        inTheFilters: true,
      },
      {
        name: 'description',
        setState: setDescription,
        setStateValue: setDescriptionValue,
        inTheFilters: true,
      },
      {
        name: 'page',
        setState: setPage,
        inTheFilters: false,
      },
      {
        name: 'linesPerPage',
        setState: setLinesPerPage,
        inTheFilters: false,
      },
      {
        name: 'alertDateFrom',
        setState: setalertDateFrom,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    const listingTypedInParams = new URLSearchParams(searchLocation).get(
      'listingType',
    );
    if (listingTypedInParams === null) {
      handleSetSearchParams({ listingType: 'active' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetSearchParams]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [selectedClient, externalId, description, linesPerPage, showContracted]);

  useEffect(() => {
    if (page && linesPerPage) {
      setPagination(oldPagination => ({
        ...oldPagination,
        current: parseInt(page),
        pageSize: parseInt(linesPerPage),
      }));
    }
  }, [page, linesPerPage]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data, handleSetSearchParams]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['page', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const showArchiveConfirmation = useCallback(
    (contractId, contractName) => {
      confirm({
        title: t('archiveModal.title'),
        icon: <ExclamationCircleOutlined />,
        content: t('archiveModal.alert', { contractName }),
        centered: true,
        okText: t('archiveModal.yes'),
        cancelText: t('archiveModal.no'),
        okButtonProps: { type: 'primary', danger: true },
        onOk: () =>
          fileTheContract(contractId)
            .then(() => {
              mutate({
                ...contracts,
                content: contracts?.content?.filter(
                  item => item.id !== contractId,
                ),
              });

              mutateArchivedContracts({
                ...archivedContracts,
                content: [
                  ...archivedContracts.content.filter(
                    contract => contract.id !== contractId,
                  ),
                ],
              });
              message.success(t('msgs.successfullyArchived'));
            })
            .catch(({ response: { status } }) => {
              if (status === 409) {
                message.error(t('msgs.errorWhenArchivingWithDependency'));
              } else {
                message.error(t('msgs.errorWhenArchiving'));
              }
            }),
      });
    },
    [
      t,
      mutateArchivedContracts,
      archivedContracts,
      fileTheContract,
      contracts,
      mutate,
    ],
  );

  const adminActions = useCallback(
    (_, record) => (
      <>
        <Tooltip title={t('buttons.details')}>
          <Button
            icon={<FileSearchOutlined />}
            type={'link'}
            onClick={() => navigate(`/financial/contracts/${record.id}`)}
          />
        </Tooltip>
        {showContracted !== 'filed' &&
          showContracted !== 'true' &&
          !isEndCustomer && (
            <RoleChecker role={rolesConstants.MANAGE_PLANS}>
              <Tooltip title={t('buttons.toFile')}>
                <Button
                  icon={<FolderOpenOutlined />}
                  type={'link'}
                  danger
                  onClick={() =>
                    showArchiveConfirmation(record.id, record.planDesc)
                  }
                />
              </Tooltip>
            </RoleChecker>
          )}
      </>
    ),
    [navigate, t, showContracted, showArchiveConfirmation, isEndCustomer],
  );

  const sharingType = useCallback(
    (value, record) => {
      if (record.payPerUseFlag === 'S') {
        return t('attributes:type.payPerUse');
      }
      const type = t(`attributes:type.${value}`);

      return t(`attributes:${type}`);
    },
    [t],
  );

  const chargingType = useCallback(
    (_, record) => {
      const operatorDiffChargeFlag = record?.operatorDiffChargeFlag;

      if (operatorDiffChargeFlag === 'S') {
        return t('attributes:chargingType.difPerOp');
      } else if (operatorDiffChargeFlag === 'N') {
        return t('attributes:chargingType.fixed');
      }
    },
    [t],
  );

  const handleHasTag = useCallback(
    value => {
      const [color, text] = value ? ['green', 'yes'] : ['red', 'no'];
      return (
        <Tag color={color}>{t(`attributes:hasTag.${text}`).toUpperCase()}</Tag>
      );
    },
    [t],
  );

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        render: (value, record) => {
          const haveLastAlert =
            Boolean(record.lastAlert) &&
            moment(record.lastAlert).isAfter(moment().subtract(7, 'days'));

          if (haveLastAlert) {
            return (
              <div>
                <Tooltip title={t('attributes:newAlert')}>
                  <Button
                    danger
                    type="link"
                    size={'large'}
                    icon={<WarningFilled />}
                  />
                </Tooltip>
                <span>{value}</span>
              </div>
            );
          }
          return value;
        },
      },
      {
        title: t('attributes:externalId'),
        dataIndex: 'externalId',
        align: 'center',
      },
      ...(showContracted !== 'true' && !isEndCustomer
        ? [
            {
              title: t('attributes:client'),
              dataIndex: 'customerId',
              render: (_, record) => record.customer?.name,
            },
          ]
        : []),
      { title: t('attributes:planDesc'), dataIndex: 'planDesc' },
      {
        title: t('attributes:type.title'),
        dataIndex: 'sharingType',
        align: 'center',
        render: sharingType,
      },
      {
        title: t('attributes:chargingType.title'),
        dataIndex: 'operatorDiffChargeFlag',
        align: 'center',
        render: chargingType,
      },
      {
        title: t('attributes:franchise'),
        dataIndex: 'franchise',
        align: 'center',
        render: value => `${value} MB`,
      },
      {
        title: t('attributes:createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: t('attributes:operator'),
        dataIndex: 'motOperators',
        align: 'center',
        render: value => value?.name,
      },
      {
        title: t('attributes:isca'),
        dataIndex: 'isca',
        align: 'center',
        render: handleHasTag,
      },
      ...(viewAlerts
        ? [
            {
              title: t('attributes:lastAlertDate'),
              dataIndex: 'lastAlert',
              align: 'center',
              render: value => value && dateFormat(value),
            },
          ]
        : []),
      ...(showContracted !== 'filled'
        ? [
            {
              title: t('attributes:nbVirtualAccounts'),
              dataIndex: 'nbVirtualAccounts',
              align: 'center',
            },
          ]
        : []),
      {
        title: t('attributes:actions'),
        dataIndex: 'actions',
        align: 'center',
        render: adminActions,
      },
    ],
    [
      t,
      sharingType,
      chargingType,
      handleHasTag,
      showContracted,
      adminActions,
      viewAlerts,
      isEndCustomer,
    ],
  );

  const ranges = useMemo(
    () => ({
      [t('attributes:lastAlertDateFrom.lastOneHour')]: [
        toISOStringWithOffset(moment().subtract(1, 'hours')),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastThreeHours')]: [
        toISOStringWithOffset(moment().subtract(3, 'hours')),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastSixHours')]: [
        toISOStringWithOffset(moment().subtract(6, 'hours')),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastTwelveHours')]: [
        toISOStringWithOffset(moment().subtract(12, 'hours')),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastDay')]: [
        toISOStringWithOffset(moment().subtract(1, 'day')),
        moment(),
      ],

      [t('attributes:lastAlertDateFrom.lastWeek')]: [
        toISOStringWithOffset(moment().subtract(7, 'days')),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastMonth')]: [
        toISOStringWithOffset(moment().subtract(1, 'months')),
        moment(),
      ],
    }),
    [t],
  );

  const handleDateChange = useCallback(
    initialDate => {
      handleSetSearchParams({
        alertDateFrom: initialDate ? toISOStringWithOffset(initialDate) : null,
      });
    },
    [handleSetSearchParams],
  );
  const tableFilters = useMemo(
    () => [
      {
        visible:
          hasCustomersRoles && showContracted !== 'true' && !isEndCustomer,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:client'),
        item: (
          <SelectCustomers
            value={selectedClient ? Number(selectedClient) : undefined}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:description'),
        item: (
          <Search
            placeholder={t('placeholders:description')}
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:externalId'),
        item: (
          <Search
            placeholder={t('placeholders:externalId')}
            onSearch={value => handleSetSearchParams({ externalId: value })}
            onChange={({ target: { value } }) => setExternalIdValue(value)}
            value={externalIdValue}
            allowClear
          />
        ),
      },
      showContracted !== 'archive' && {
        visible: viewAlerts,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:lastAlertDate'),
        item: (
          <DatePicker
            showTime
            value={alertDateFrom && moment(alertDateFrom, 'YYYYMMDD HH:mm:ss')}
            style={{ width: '100%' }}
            format="DD/MM/YYYY HH:mm"
            onChange={handleDateChange}
            allowClear
            renderExtraFooter={() => (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  flexWrap: 'wrap',
                  padding: '10px 0px 10px 0px',
                }}
              >
                {Object.entries(ranges).map(([label, range]) => (
                  <Tag
                    key={label}
                    style={{
                      cursor: 'pointer',
                      color: '#1890ff',
                      fontSize: '0.8rem',
                    }}
                    onClick={() => handleDateChange(range[0])}
                  >
                    {label}
                  </Tag>
                ))}
              </div>
            )}
          />
        ),
      },
    ],
    [
      t,
      handleSetSearchParams,
      hasCustomersRoles,
      selectedClient,
      descriptionValue,
      externalIdValue,
      viewAlerts,
      showContracted,
      handleDateChange,
      ranges,
      alertDateFrom,
      isEndCustomer,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      handleSetSearchParams({
        page: paginationObj.current,
        linesPerPage: paginationObj.pageSize,
      });
    }
  };

  return (
    <>
      {modalVisible && (
        <RoleChecker role={rolesConstants.MANAGE_PLANS}>
          <NewContractModal
            visible={modalVisible}
            mutateNewContract={handleMutateContract}
            onClose={() => setModalVisible(false)}
          />
        </RoleChecker>
      )}

      <Tabs
        type="card"
        defaultActiveKey="false"
        activeKey={showContracted}
        onChange={key => {
          handleSetSearchParams({
            showContracted: key,
          });
        }}
        style={{ background: '#ffffff' }}
      >
        {isEndCustomer ? (
          <TabPane key="true" tab={t('visions.true')} />
        ) : isBroker ? (
          <>
            <TabPane key="false" tab={t('visions.false')} />
            <TabPane key="true" tab={t('visions.true')} />
            <TabPane key="filed" tab={t('visions.filed')} />
          </>
        ) : (
          <>
            <TabPane key="false" tab={t('visions.false')} />
            <TabPane key="filed" tab={t('visions.filed')} />
          </>
        )}
      </Tabs>

      <Table
        title={
          <Filters
            title={t('title')}
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
          />
        }
        columns={columns}
        data={data?.content}
        loading={!data?.content}
        tableKey="contracts"
        pagination={pagination}
        onChange={handleTableChange}
        extraActions={
          <RoleChecker role={rolesConstants.MANAGE_PLANS}>
            <Tooltip title={t('buttons.newContract')}>
              <Button
                icon={<PlusCircleOutlined />}
                size={'large'}
                type={'link'}
                onClick={() => setModalVisible(true)}
              />
            </Tooltip>
          </RoleChecker>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Contracts;
