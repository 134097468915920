import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Typography, Drawer, Tabs, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import DeviceActionsMenu from '@components/DeviceActionsMenu';
import DeviceActionsTimeline from '@components/DeviceActionsTimeline/DeviceActionsTimeline';
import CoverageMap from '@src/components/Maps/CoverageMap/CoverageMap';

// import TrafficChart from '@components/TrafficChart/TrafficChart';

import DeviceActionModal from '@components/DeviceActionModal';

import TabPaneDetails from './TabPaneDetails/TabPaneDetails';

import { Container } from './styles';
import SessionContext from '@src/store/SessionContext/SessionContext';
import roles from '@utils/rolesConstants';
import Sessions from './Sessions/Sessions';
import { useDeviceFormatter } from '@src/hooks/useDeviceFormatter';
import Diagnosis from './TabDiagnosis/index';
import AlertsEvents from '@src/pages/Alerts/Events/index';

const { Title } = Typography;

const { TabPane } = Tabs;

const InventoryPreview = ({
  deviceProps,
  drawerVisible,
  setDrawerVisible,
  showContracted,
  doubleActions,
  stock,
  availableOperators,
  dataContracts,
}) => {
  const { t } = useTranslation('inventory-detailed');

  const {
    userRoles,
    customer: { isCustomerFinanciallyBlocked },
  } = useContext(SessionContext);
  const [modalDeviceActionsVisible, setModalDeviceActionsVisible] =
    useState(false);
  const [actionToExecute, setActionToExecute] = useState(-1);

  const hasRole = useCallback(
    (roleToCheck, client) => userRoles?.[client]?.includes(roleToCheck),
    [userRoles],
  );

  const { device, handleUpdateNickname } = useDeviceFormatter(
    deviceProps.id,
    showContracted,
  );

  const handleActionClick = useCallback(key => {
    setActionToExecute(Number(key));
    setModalDeviceActionsVisible(true);
  }, []);

  const allDetailsRoles = useMemo(
    () => [
      roles.INVENT_STATUS,
      roles.INVENT_OPERATOR,
      roles.INVENT_LAST_CONNECTION,
      roles.INVENT_ONLINE,
      roles.INVENT_CITY,
      roles.INVENT_ACTIVATION_DATE,
      roles.INVENT_PLAN,
      roles.INVENT_CONSUMPTION,
      roles.INVENT_ICCID,
      roles.INVENT_IMEI,
      roles.INVENT_MSISDN,
      roles.INVENT_IMSI,
      roles.INVENT_OBSERVATION,
    ],
    [],
  );

  const hasAnyDetailsRole = useMemo(
    () =>
      userRoles?.inventory?.some(roleName =>
        allDetailsRoles.includes(roleName),
      ),
    [allDetailsRoles, userRoles?.inventory],
  );

  return (
    <Drawer
      width="60%"
      placement="right"
      closable={false}
      bodyStyle={{ backgroundColor: '#f0f2f5' }}
      onClose={setDrawerVisible}
      destroyOnClose
      visible={drawerVisible}
    >
      <Container>
        <Row
          gutter={[16, 16]}
          justify="space-between"
          className="ip-header"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {hasRole(roles.INVENT_NICKNAME, 'inventory') && (
            <Title
              level={4}
              editable={{
                onChange: handleUpdateNickname,
              }}
              style={{ flex: 1 }}
            >
              {device?.nickname ?? t('default-nickname')}
            </Title>
          )}
          {!isCustomerFinanciallyBlocked && (
            <DeviceActionsMenu
              onActionClick={({ key }) => handleActionClick(key)}
              doubleActions={doubleActions}
              stock={stock}
            />
          )}
        </Row>

        <Tabs defaultActiveKey="1">
          {hasAnyDetailsRole && (
            <TabPane tab={t('preview-tabs.details')} key="1">
              <TabPaneDetails
                deviceProps={deviceProps}
                showContracted={showContracted}
              />
            </TabPane>
          )}

          {hasRole(roles.LOCATION, 'portal') &&
            device?.latitude &&
            device?.longitude && (
              <TabPane tab={t('preview-tabs.location')} key="3">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CoverageMap
                      mapHeight={500}
                      dataProps={
                        device?.latitude || device?.longitude
                          ? [
                              {
                                id: `${device?.id}`,
                                lat: Number(device.latitude),
                                lng: Number(device?.longitude),
                              },
                            ]
                          : undefined
                      }
                      allowCoverage
                    />
                  </Col>
                </Row>
              </TabPane>
            )}

          <TabPane tab={t('preview-tabs.sessions')} key="4">
            <Row gutter={[16, 16]}>
              <Col lg={24} md={24} xl={24} xs={24}>
                <Card loading={!device} bodyStyle={{ padding: 0 }}>
                  <Sessions msisdn={Number(device?.msisdn)} />
                </Card>
              </Col>
            </Row>
          </TabPane>

          {hasRole(roles.ACTIONS_QUEUE, 'portal') && (
            <TabPane tab={t('preview-tabs.commands')} key="6">
              <Row gutter={[16, 16]}>
                <Col lg={24} md={24} xl={24} xs={24}>
                  <Card
                    title={t('commands-history')}
                    loading={!device}
                    style={{ height: '100%' }}
                    bodyStyle={{ padding: 8 }}
                    headStyle={{ color: 'rgba(0,0,0,0.45)' }}
                  >
                    <DeviceActionsTimeline id={device?.id} />
                  </Card>
                </Col>
              </Row>
            </TabPane>
          )}

          {hasRole(roles.VIEW_DIAGNOSIS, 'portal') && (
            <TabPane tab={t('preview-tabs.diagnosis')} key="7">
              <Row gutter={[16, 16]}>
                <Col lg={24} md={24} xl={24} xs={24}>
                  <Card loading={!device} bodyStyle={{ padding: 0 }}>
                    <Diagnosis msisdn={Number(device?.msisdn)} />
                  </Card>
                </Col>
              </Row>
            </TabPane>
          )}

          {hasRole(roles.VIEW_ALERTS, 'portal') && (
            <TabPane tab={t('preview-tabs.alerts')} key="8">
              <Row gutter={[16, 16]}>
                <Col lg={24} md={24} xl={24} xs={24}>
                  <Card loading={!device} bodyStyle={{ padding: 0 }}>
                    <AlertsEvents
                      defaultFilters={{
                        msisdn: device?.msisdn,
                        showContracted,
                      }}
                      tableOnly
                    />
                  </Card>
                </Col>
              </Row>
            </TabPane>
          )}
        </Tabs>

        <DeviceActionModal
          actionKey={actionToExecute}
          devices={[device]}
          visible={modalDeviceActionsVisible}
          onClose={() => setModalDeviceActionsVisible(false)}
          doubleActions={doubleActions}
          availableOperators={availableOperators}
          dataContracts={dataContracts}
        />
      </Container>
    </Drawer>
  );
};

export default InventoryPreview;
