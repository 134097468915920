import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  ClearOutlined,
  CheckOutlined,
  UploadOutlined,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Tooltip, Input, Button, Tag, Divider, Empty } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchUploader from '../SearchUploader/index';
import VirtualizedList from '../VistualizedList/VirtualizedList';
import './styles.less';

const SuperList = ({
  items,
  preSelectedItems,
  columnName,
  onSelectedItemsChange,
  uploadAlternateTitle,
  maxNumOfItems,
  activateSelectAll = true,
}) => {
  const { t } = useTranslation('super-list');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (preSelectedItems) {
      setSelectedItems(preSelectedItems);
    }
  }, [preSelectedItems]);

  useEffect(() => {
    onSelectedItemsChange(selectedItems);
  }, [selectedItems, onSelectedItemsChange]);

  const searchResultItems = useMemo(() => {
    if (searchValue) {
      const resultsBySearch = items?.filter(item => {
        return item.label.match(new RegExp(searchValue, 'i'));
      });
      // remove already selected items
      return resultsBySearch?.filter(
        result => !selectedItems.find(item => item?.value === result.value),
      );
    }
    return undefined;
  }, [items, searchValue, selectedItems]);

  const removeSelectedItem = useCallback(
    value =>
      setSelectedItems(oldItems =>
        oldItems?.filter(item => item?.value !== value),
      ),
    [],
  );

  const addSelectedItem = useCallback(
    value => {
      const itemToAdd = items?.find(item => item.value === Number(value));
      setSelectedItems(oldItems => [...oldItems, itemToAdd]);
    },
    [items],
  );

  const addSentItemsList = useCallback(
    list => {
      const itemsByUpload = list
        .map(listItem =>
          items?.find(
            item => String(item[columnName]) === listItem[columnName],
          ),
        )
        ?.filter(listItem => listItem)
        ?.filter(
          listItem =>
            !selectedItems.find(item => item?.value === listItem?.value),
        );
      setSelectedItems([...selectedItems, ...itemsByUpload]);
    },
    [items, selectedItems, columnName],
  );

  const ItemEl = useCallback(
    ({ value, label, isSelected }) => (
      <Tag
        color={isSelected ? 'green' : ''}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: 4,
          margin: '1px 0',
          padding: '2px 12px',
          border: '1px solid #dbdbdb',
        }}
      >
        <p style={{ margin: 0, padding: 0 }}>{`${label} - ${value}`}</p>
        <Tooltip title={isSelected ? t('buttons.remove') : t('buttons.select')}>
          <Button
            type="link"
            icon={
              isSelected ? <CloseCircleOutlined /> : <CheckCircleOutlined />
            }
            style={{ color: isSelected ? 'red' : 'green' }}
            onClick={() => {
              if (isSelected) {
                removeSelectedItem(value);
              } else {
                addSelectedItem(value);
              }
            }}
          />
        </Tooltip>
      </Tag>
    ),
    [removeSelectedItem, addSelectedItem, t],
  );

  return (
    <div className="super-list-container">
      {items?.length !== selectedItems?.length && (
        <>
          {items && (
            <div className="select-wrapper">
              {activateSelectAll && (
                <Tooltip title={t('buttons.select-all')}>
                  <Button
                    className="select-all"
                    icon={<CheckOutlined />}
                    disabled={items?.length === selectedItems?.length}
                    onClick={() => {
                      if (items) {
                        setSelectedItems(items);
                      }
                      setSearchValue(undefined);
                    }}
                  />
                </Tooltip>
              )}
              <Input
                suffix={
                  <Tooltip title={t('upload.tooltip')}>
                    <Button
                      type="link"
                      icon={<UploadOutlined />}
                      size="small"
                      onClick={() => {
                        setIsUploadModalVisible(true);
                      }}
                    />
                  </Tooltip>
                }
                placeholder={t('search.placeholder')}
                onChange={({ target: { value } }) => setSearchValue(value)}
                value={searchValue}
                disabled={!items}
                allowClear
              />
            </div>
          )}
          <div
            style={{
              height: items ? 160 : 'auto',
              marginTop: 4,
            }}
          >
            <VirtualizedList listItems={searchResultItems} itemEl={ItemEl} />
            {items ? (
              <>
                {searchResultItems && searchResultItems.length === 0 && (
                  <div className="search-not-found">
                    <Empty
                      description={t('search.not-found')}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </div>
                )}
                {!searchValue && (
                  <div className="search-description">
                    <SearchOutlined className="search-description-icon" />
                    <p className="search-description-text">
                      {t('search.description')}
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="search-loading">
                <LoadingOutlined className="search-loading-icon" />
                <p>{t('search.loading')}</p>
              </div>
            )}
          </div>
        </>
      )}

      {selectedItems && selectedItems.length > 0 && (
        <div style={{ height: '100%' }}>
          <div className="selected-items">
            <Divider>
              {t('selected', { count: selectedItems.length })}
              <Tooltip title={t('buttons.clear')}>
                <Button
                  icon={<ClearOutlined />}
                  className="button-clear"
                  onClick={() => setSelectedItems([])}
                />
              </Tooltip>
            </Divider>
          </div>
          <div style={{ height: selectedItems.length * 40, maxHeight: 160 }}>
            <VirtualizedList
              listItems={selectedItems}
              itemEl={ItemEl}
              itemIsSelected
            />
          </div>
        </div>
      )}
      <SearchUploader
        visible={isUploadModalVisible}
        onClose={() => setIsUploadModalVisible(false)}
        columnName={columnName}
        infoModalWidth={480}
        validation={false}
        alternateTitle={uploadAlternateTitle}
        description={t('upload.description')}
        setList={addSentItemsList}
        maxNumOfItems={maxNumOfItems}
      />
    </div>
  );
};

export default React.memo(SuperList);
