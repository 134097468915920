import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useSwr from '@hooks/useSwr';
import { Spin } from 'antd';
import CoverageMiniTable from './CoverageMiniTable/CoverageMiniTable';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker';
import SearchBoxGoogle from '@components/Maps/SearchBoxGoogle/SearchBoxGoogle';

const CENTER_DEFAULT = {
  lat: -14.235004,
  lng: -51.92528,
};

const ZOOM_DEFAULT = 4;

const CoverageMap = ({
  allowSearch,
  allowCoverage,
  allowFullscreen,
  mapHeight,
  dataProps,
  mapsZoom,
  setMapsZoom,
  mapsCenter,
  setMapsCenter,
  searchValue,
  setSearchValue,
  clearLocation,
  handleSetSearchParams,
}) => {
  const [clickCoordinates, setClickCoordinates] = useState({
    lat: undefined,
    lng: undefined,
  });

  const [clickPlot, setClickPlot] = useState();
  const [dataPlot, setDataPlot] = useState();
  const [mapsProps, setMapsProps] = useState();
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState();

  const { data: clickCoverageData } = useSwr(
    clickCoordinates.lat ? '/service-proxy/coverage' : null,
    {
      latitude: clickCoordinates.lat,
      longitude: clickCoordinates.lng,
    },
  );

  const { data: coverageData } = useSwr(
    dataProps?.[0] ? '/service-proxy/coverage' : null,
    {
      latitude: dataProps?.[0]?.lat,
      longitude: dataProps?.[0]?.lng,
    },
  );

  const handleClearLocation = useCallback(() => {
    clearLocation();
    setClickPlot(undefined);
    setDataPlot(undefined);
    setClickCoordinates({
      lat: undefined,
      lng: undefined,
    });
  }, [clearLocation]);

  const clickModalInfo = useMemo(() => {
    return (
      <div>
        {clickCoverageData ? (
          <CoverageMiniTable data={clickCoverageData} />
        ) : (
          <div
            style={{
              height: '136px',
              width: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    );
  }, [clickCoverageData]);

  const handleGoogleApiLoaded = useCallback(
    (mapProps, map) => {
      map?.setOptions({
        draggableCursor: 'pointer',
        draggingCursor: 'grabbing',
        fullscreenControl: !!allowFullscreen,
      });

      setMapsProps(mapProps);
    },
    [allowFullscreen],
  );

  const dataModalInfoArr = useMemo(() => {
    return [
      <div key="info">
        {coverageData ? (
          <CoverageMiniTable data={coverageData} />
        ) : (
          <div
            style={{
              height: '136px',
              width: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </div>,
    ];
  }, [coverageData]);

  const handleSetZoom = useCallback(
    value => {
      if (setMapsZoom) {
        setMapsZoom(value);
      } else {
        setZoom(value);
      }
    },
    [setMapsZoom],
  );

  const handleSetCenter = useCallback(
    values => {
      if (setMapsCenter) {
        setMapsCenter(values);
      } else {
        setCenter(values);
      }
    },
    [setMapsCenter],
  );

  useEffect(() => {
    if (dataProps && allowCoverage) {
      setDataPlot(
        dataProps.map(({ id, lat, lng }, index) => (
          <Marker
            key={id || lat}
            lat={lat}
            lng={lng}
            text="My Marker"
            type="data"
          >
            {dataModalInfoArr?.[index]}
          </Marker>
        )),
      );
      const { lat, lng } = dataProps[0];
      handleSetCenter({ lat, lng });
    }
  }, [allowCoverage, dataModalInfoArr, dataProps, handleSetCenter]);

  useEffect(() => {
    if (clickCoordinates.lat && clickCoordinates.lng) {
      setClickPlot(
        <Marker
          lat={clickCoordinates.lat}
          lng={clickCoordinates.lng}
          text="Current"
          type="click"
        >
          {clickModalInfo}
        </Marker>,
      );
    }
  }, [clickCoordinates.lat, clickCoordinates.lng, clickModalInfo]);

  return (
    <div style={{ height: mapHeight }}>
      <div
        style={{
          height: '100%',
          width: '100%',
          borderRadius: 5,
          position: 'relative',
        }}
      >
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ maps, map }) =>
            handleGoogleApiLoaded(maps, map)
          }
          onClick={({ lat, lng }) => {
            const newCoordinates = {
              lat,
              lng,
            };
            handleSetCenter(newCoordinates);
            setClickCoordinates(newCoordinates);
          }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS || '',
            libraries: ['places', 'visualization'],
          }}
          defaultCenter={CENTER_DEFAULT}
          center={center ?? mapsCenter}
          defaultZoom={ZOOM_DEFAULT}
          zoom={zoom ?? mapsZoom}
          onZoomAnimationEnd={handleSetZoom}
        >
          {allowCoverage && [clickPlot, dataPlot]}
        </GoogleMapReact>

        {allowSearch && mapsProps && (
          <SearchBoxGoogle
            googleMaps={mapsProps}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onPlacesChanged={handleSetSearchParams}
            clearLocation={handleClearLocation}
          />
        )}
      </div>
    </div>
  );
};

export default CoverageMap;
