import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Col, Form, Input, Row, Modal, Skeleton, message } from 'antd';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import useSwr from '@hooks/useSwr';
import PermissionsCard from '@src/components/PermissionsCard/PermissionsCard';
import SessionContext from '@src/store/SessionContext/SessionContext';

const { Item } = Form;

const GroupViewerModal = ({ groupId, visible, onClose, mutateGroups }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['roles', 'permissions']);
  const { mainCustomerInContext } = useContext(SessionContext);

  const { axios, keycloak } = useContext(KeycloakContext);
  const customerId = keycloak.idTokenParsed?.customerId;
  const customerType = keycloak.idTokenParsed?.customerType;
  const isNewGroup = groupId === '';

  const [modalVisible, setModalVisible] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { data: groupToUpdate, mutate } = useSwr(
    groupId !== '' ? `/groups/${groupId}` : null,
  );

  const isGroupToUpdateAdmin = groupToUpdate?.name === 'ADMINISTRATORS';

  const { data: availableRoles } = useSwr(
    customerId ? `/customers/${customerId}/roles/` : null,
    {
      customerType,
    },
  );

  const { data: adminData } = useSwr(
    mainCustomerInContext
      ? `/customers/${mainCustomerInContext?.id}/roles/`
      : null,
    {
      customerType,
    },
  );

  const loading = useMemo(
    () => !availableRoles || (!isNewGroup && !groupToUpdate),
    [availableRoles, groupToUpdate, isNewGroup],
  );

  const updateGroupRoles = useCallback(
    (id, rolesToPut) => axios.put(`/groups/${id}/roles/`, rolesToPut),
    [axios],
  );

  const formatGroupedRoles = useCallback(
    roles =>
      roles?.reduce(
        (groups, item) => ({
          ...groups,
          [item.category]: [...(groups[item.category] || []), item],
        }),
        {},
      ),
    [],
  );

  const groupedRoles = useMemo(() => {
    const groupedBy = formatGroupedRoles(
      isGroupToUpdateAdmin ? adminData : availableRoles,
    );

    return groupedBy ? Object.entries(groupedBy) : undefined;
  }, [availableRoles, adminData, isGroupToUpdateAdmin, formatGroupedRoles]);

  const formInitialValues = useMemo(() => {
    if (!isNewGroup && groupToUpdate) {
      const fields = { ...groupToUpdate };
      fields.roles = fields?.roles?.map(r => r.id);
      return fields;
    }
    const fields = {};
    fields.customerId = customerId;
    return fields;
  }, [customerId, groupToUpdate, isNewGroup]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(formInitialValues);
    setModalVisible(visible);
  }, [form, formInitialValues, visible]);

  const close = useCallback(() => {
    setModalVisible(false);
    setSelectedRoles([]);
    form.resetFields();
    onClose();
  }, [form, onClose]);

  // clear permissions error
  useEffect(() => {
    if (selectedRoles?.length > 0) {
      form.setFields([
        {
          name: 'roles',
          errors: [],
        },
      ]);
    }
  }, [selectedRoles, form]);

  const onFinish = useCallback(
    async values => {
      if (selectedRoles.length === 0) {
        form.setFields([
          {
            name: 'roles',
            errors: [t('modal.form.msgs.chooseAtLeastOne')],
          },
        ]);
        return;
      }
      const newRoles = selectedRoles.map(r => ({ id: r }));

      const { roles, ...group } = values;

      setSaveLoading(true);
      if (isNewGroup) {
        try {
          const { data } = await axios.post('/groups/', {
            ...group,
            customerId,
          });

          await updateGroupRoles(data.id, newRoles);
          const { data: groupData } = await axios.get(`/groups/${data.id}`);
          mutateGroups(groupData);
          message.success(t('msgs.success-creating-profile'));
          close();
        } catch ({ response }) {
          if (response?.status === 409) {
            form.setFields([
              {
                name: 'prettyName',
                errors: [t('modal.form.msgs.profileAlreadyRegistered')],
              },
            ]);
          } else {
            message.error(t('msgs.error-creating-profile'));
          }
        }
      } else {
        try {
          await axios.put(`/groups/${groupId}`, {
            ...group,
            customerId,
          });
          await updateGroupRoles(groupId, newRoles);

          if (isGroupToUpdateAdmin) {
            setTimeout(() => {
              location.reload();
            }, 800);
          } else {
            mutate({ ...group, roles: newRoles });
          }
          message.success(t('msgs.success-updating-profile'));
          close();
        } catch {
          message.error(t('msgs.error-updating-profile'));
        }
      }
      setSaveLoading(false);
    },
    [
      axios,
      close,
      groupId,
      isNewGroup,
      t,
      form,
      updateGroupRoles,
      mutate,
      mutateGroups,
      selectedRoles,
      customerId,
      isGroupToUpdateAdmin,
    ],
  );

  return (
    <Modal
      cancelText={t('modal.buttons.cancelButton')}
      confirmLoading={saveLoading}
      maskClosable={false}
      okButtonProps={{
        form: 'groupForm',
        htmlType: 'submit',
      }}
      okText={t('modal.buttons.saveButton')}
      style={{ maxWidth: 800 }}
      title={isNewGroup ? t('modal.titles.newRole') : groupToUpdate?.prettyName}
      visible={modalVisible}
      width="70%"
      centered
      onCancel={() => {
        close();
        setSaveLoading(false);
      }}
    >
      <Form
        colon={false}
        form={form}
        hideRequiredMark={!isNewGroup}
        id="groupForm"
        layout="vertical"
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row>
          <Col sm={24} style={{ padding: '0 0 0 5px' }} xs={24}>
            {/* Name */}
            <Item
              label={t('modal.form.labels.name')}
              name="prettyName"
              rules={[
                {
                  required: isNewGroup,
                  message: t('modal.form.msgs.fillThisField'),
                },
              ]}
            >
              <Input
                disabled={!isNewGroup}
                placeholder={t('modal.form.placeholders.name')}
              />
            </Item>
          </Col>
        </Row>

        {/* Permissions */}
        <Skeleton loading={loading} title={false} active>
          <Item label={t('modal.form.labels.permissions')} name="roles">
            <PermissionsCard
              groupedRoles={groupedRoles}
              selectedRoles={formInitialValues?.roles}
              setSelectedRoles={setSelectedRoles}
              isNewGroup={isNewGroup}
            />
          </Item>
        </Skeleton>
      </Form>
    </Modal>
  );
};

export default GroupViewerModal;
