import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Button, Input, Tooltip, Col } from 'antd';
import { Filters, FiltersCard } from '@components/Filters';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NewContractModal from './NewContractModal';
import { PlusCircleOutlined, FileSearchOutlined } from '@ant-design/icons';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { dateFormat, formatCurrency } from '@src/utils/formatters';
import rolesConstants from '@utils/rolesConstants';
import SelectCustomers from '@src/components/SelectCustomers';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Contracts = () => {
  const { t } = useTranslation(['contracts', 'attributes', 'lorawan']);
  const { hasCustomersRoles } = useContext(SessionContext);
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [customerId, setCustomerId] = useState();
  const [description, setDescription] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [externalId, setExternalId] = useState('');
  const [externalIdValue, setExternalIdValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGINATION.current);
  const [linesPerPage, setLinesPerPage] = useState(DEFAULT_PAGINATION.pageSize);

  const { data, mutate } = useSwr(`/service-proxy/lorawanVirtualPlans`, {
    page: pagination?.current - 1,
    linesPerPage: pagination?.pageSize,
    customerId,
    name: description,
    externalId,
  });

  const handleMutateContract = useCallback(
    contract => {
      mutate({
        ...data,
        content: [contract, ...data?.content].slice(0, 10),
      });
    },
    [data, mutate],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'customerId',
        setState: setCustomerId,
        inTheFilters: true,
      },
      {
        name: 'externalId',
        setState: setExternalId,
        setStateValue: setExternalIdValue,
        inTheFilters: true,
      },
      {
        name: 'description',
        setState: setDescription,
        setStateValue: setDescriptionValue,
        inTheFilters: true,
      },
      {
        name: 'page',
        setState: setPage,
        inTheFilters: false,
      },
      {
        name: 'linesPerPage',
        setState: setLinesPerPage,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [customerId, externalId, description, linesPerPage]);

  useEffect(() => {
    if (page && linesPerPage) {
      setPagination(oldPagination => ({
        ...oldPagination,
        current: parseInt(page),
        pageSize: parseInt(linesPerPage),
      }));
    }
  }, [page, linesPerPage]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['page', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const sharingType = useCallback(
    (value, record) => {
      if (record.payPerUseFlag === 'S') {
        return t('attributes:type.payPerUse');
      }
      const type = t(`attributes:type.${value}`);

      return t(`attributes:${type}`);
    },
    [t],
  );

  const columns = useMemo(
    () => [
      {
        title: t('attributes:id'),
        dataIndex: 'id',
      },
      {
        title: t('attributes:externalId'),
        dataIndex: 'externalId',
      },
      {
        title: t('attributes:client'),
        dataIndex: 'customerId',
        render: (_, record) => record.customer?.name,
      },
      { title: t('attributes:planDesc'), dataIndex: 'planDesc' },
      {
        title: t('attributes:type.title'),
        dataIndex: 'sharingType',
        render: sharingType,
      },
      {
        title: t('attributes:activationPrice'),
        dataIndex: 'activationPrice',
        align: 'center',
        render: value => value && formatCurrency(Number(value)),
      },
      {
        title: t('attributes:monthlyFee'),
        dataIndex: 'monthlyFee',
        align: 'center',
        render: value => value && formatCurrency(Number(value)),
      },
      {
        title: t('attributes:uplinkCount'),
        dataIndex: 'uplinkCount',
        align: 'center',
      },
      {
        title: t('attributes:downlinkCount'),
        dataIndex: 'downlinkCount',
        align: 'center',
      },
      {
        title: t('attributes:createdAt'),
        dataIndex: 'createdAt',
        render: dateFormat,
      },
      {
        title: t('attributes:createdBy'),
        dataIndex: 'createdBy',
      },
      {
        title: t('attributes:actions'),
        dataIndex: 'actions',
        align: 'center',
        render: (_, record) => (
          <Tooltip title={t('buttons.details')}>
            <Button
              icon={<FileSearchOutlined />}
              type="link"
              onClick={() => navigate(`/lorawan/contracts/${record.id}`)}
            />
          </Tooltip>
        ),
      },
    ],
    [t, sharingType, navigate],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: hasCustomersRoles,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:client'),
        item: (
          <SelectCustomers
            value={customerId ? Number(customerId) : undefined}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:description'),
        item: (
          <Search
            placeholder={t('placeholders:description')}
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('attributes:externalId'),
        item: (
          <Search
            placeholder={t('placeholders:externalId')}
            onSearch={value => handleSetSearchParams({ externalId: value })}
            onChange={({ target: { value } }) => setExternalIdValue(value)}
            value={externalIdValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      handleSetSearchParams,
      hasCustomersRoles,
      customerId,
      descriptionValue,
      externalIdValue,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      handleSetSearchParams({
        page: paginationObj.current,
        linesPerPage: paginationObj.pageSize,
      });
    }
  };

  return (
    <>
      {modalVisible && (
        <RoleChecker role={rolesConstants.LORAWAN_MANAGE_CONTRACTS}>
          <NewContractModal
            visible={modalVisible}
            mutateNewContract={handleMutateContract}
            onClose={() => setModalVisible(false)}
          />
        </RoleChecker>
      )}
      <Table
        title={
          <Filters
            title={t('title')}
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
          />
        }
        columns={columns}
        data={data?.content ?? []}
        loading={!data?.content}
        tableKey="contracts"
        pagination={pagination}
        onChange={handleTableChange}
        extraActions={
          <RoleChecker role={rolesConstants.LORAWAN_MANAGE_CONTRACTS}>
            <Tooltip title={t('buttons.newContract')}>
              <Button
                icon={<PlusCircleOutlined />}
                size={'large'}
                type={'link'}
                onClick={() => setModalVisible(true)}
              />
            </Tooltip>
          </RoleChecker>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Contracts;
