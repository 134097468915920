import React, { memo, useCallback, useState } from 'react';
import { Tooltip, Tag, Button, message } from 'antd';
import { FiLock, FiUnlock } from 'react-icons/fi';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import apiMiddleware from '@src/services/apiMiddleware';
import * as S from './styles';

const RequestCanActivate = ({ requestId, canActivate, mutate }) => {
  const { t } = useTranslation('requests');
  const [loadingPutCanActivate, setLoadingPutCanActivate] = useState(false);

  const colorTag = canActivate ? 'green' : 'red';
  const iconTag = canActivate ? <FiLock /> : <FiUnlock />;
  const tagText = t(`canActivate.${canActivate ? 'enabled' : 'disabled'}`);
  const buttonChangeText = t(
    `canActivate.${canActivate ? 'disable' : 'enable'}`,
  );

  const putCanActivate = useCallback(
    () =>
      apiMiddleware.put(
        `/service-proxy/lorawanVirtualAccounts/${requestId}/activate?canActivate=${!canActivate}`,
      ),
    [canActivate, requestId],
  );

  const handlePutMessage = useCallback(
    type => {
      if (type === 'success') {
        const successMsgType = t(
          `canActivate.${!canActivate ? 'enabled' : 'disabled'}`,
        );

        message.success(
          t('canActivate.msgs.successfully', {
            type: successMsgType,
          }),
        );
      } else {
        const errorMsgType = t(
          `canActivate.${!canActivate ? 'enable' : 'disable'}`,
        );
        message.error(
          t('canActivate.msgs.error', {
            type: errorMsgType?.toLowerCase(),
          }),
        );
      }
    },
    [canActivate, t],
  );

  const handleCanActivateChange = useCallback(async () => {
    try {
      setLoadingPutCanActivate(true);
      await putCanActivate();
      mutate?.(requestId, !canActivate);
      handlePutMessage('success');
    } catch {
      handlePutMessage('error');
    }
    setLoadingPutCanActivate(false);
  }, [canActivate, handlePutMessage, mutate, putCanActivate, requestId]);

  return (
    <S.LockUnlock>
      <Tag color={colorTag}>{tagText}</Tag>
      <Tooltip title={buttonChangeText}>
        <Button
          type="link"
          size="small"
          danger={canActivate}
          onClick={handleCanActivateChange}
          style={{
            color: !canActivate && '#389e0d',
          }}
          disabled={loadingPutCanActivate}
        >
          {loadingPutCanActivate ? <LoadingOutlined /> : iconTag}
        </Button>
      </Tooltip>
    </S.LockUnlock>
  );
};

export default memo(RequestCanActivate);
