import { useContext, useEffect, useRef, useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import apiMiddleware from '@src/services/apiMiddleware';

const useFluxEndpoint = url => {
  const [data, setData] = useState([]);

  const { keycloak } = useContext(KeycloakContext);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (!keycloak.authenticated) {
      return;
    }
    const fullUrl = `${apiMiddleware.defaults.baseURL}${url}`;

    const fetchData = async () => {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      try {
        await fetchEventSource(fullUrl, {
          method: 'GET',
          headers: {
            Accept: 'text/event-stream',
            Authorization: `Bearer ${keycloak.token}`,
          },
          signal: abortController.signal,
          onmessage(event) {
            const parsedData = JSON.parse(event.data);
            setData(dataPoint => [parsedData, ...dataPoint]);
          },
          onclose() {
            console.log('Connection closed by the server');
          },
          onerror(err) {
            throw err;
          },
        });
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.log('Fetch error: ', err);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line consistent-return
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [abortControllerRef, keycloak.authenticated, keycloak.token, url]);

  return data;
};

export default useFluxEndpoint;
