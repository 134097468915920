import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  Descriptions,
  Divider,
  Skeleton,
  Tabs,
  Typography,
  Button,
  Form,
  Select,
  DatePicker,
  message,
} from 'antd';
import { Card } from '@components/ui';
import BackToListing from '@src/components/BackToListing/BackToListing';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Table from '@components/Table';
import useSwr from '@src/hooks/useSwr';
import { firstLetterUpper, formatCurrency } from '@src/utils/formatters';
import moment from 'moment';
import apiMiddleware from '@src/services/apiMiddleware';

const { Item } = Form;
const { Option } = Select;

const SchedulesDetails = () => {
  const { t: translate } = useTranslation('schedules');
  const { id } = useParams();
  const [form] = Form.useForm();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedUF, setSelectedUF] = useState();

  const { data: scheduleDetailsData, mutate } = useSwr(
    `/service-proxy/astro/appointment/${id}`,
  );

  const { data: citiesData } = useSwr(
    selectedUF ? `/service-proxy/astro/cities?uf=${selectedUF}` : null,
  );

  const { data: vehiclesData } = useSwr(
    selectedCustomer
      ? `/service-proxy/astro/vehicles?clientId=${selectedCustomer}`
      : null,
    {
      page: 0,
      per_page: 100,
    },
  );

  const handleSubmitForm = async values => {
    try {
      await apiMiddleware.put(`/service-proxy/astro/appointment/${id}`, {
        data: values.data.format('YYYY-MM-DD'),
        fkVeiculo: Number(values.fk_veiculo),
        periodo: values.periodo,
        status: values.status,
        type: values.type,
      });
      mutate('/service-proxy/astro/appointment');
      message.success(translate('msgs.success-schedule-edit'));
    } catch (error) {
      message.error(translate('msgs.error'));
    }
  };

  const disabledDate = current => {
    const day = current.day();
    const isBeforeToday = current && current < moment().startOf('day');
    return !availableDays?.includes(String(day)) || isBeforeToday;
  };

  useEffect(() => {
    if (citiesData && selectedUF) {
      const findCitie = citiesData?.data?.find(
        item => item.cidade === scheduleDetailsData?.client?.city,
      )?.diasAtendimento;
      setAvailableDays(findCitie);
    }
    if (scheduleDetailsData?.client?.idClient) {
      setSelectedUF(scheduleDetailsData?.client?.state);
      setSelectedCustomer(scheduleDetailsData?.client?.idClient);
    }
    if (scheduleDetailsData?.veiculo && vehiclesData) {
      form.setFieldsValue({
        fk_veiculo: scheduleDetailsData?.veiculo?.idVeiculo,
      });
    }
  }, [scheduleDetailsData, vehiclesData, form, citiesData, selectedUF]);

  const DividerModel = useCallback(
    ({ title }) => (
      <Divider
        style={{
          marginTop: 24,
          marginBottom: 32,
          fontSize: '0.85rem',
          opacity: 0.7,
          textTransform: 'uppercase',
        }}
        orientation="left"
      >
        {title}
      </Divider>
    ),
    [],
  );

  const columnsDefault = useMemo(() => {
    return [
      {
        title: translate('table.product'),
        dataIndex: 'name',
        render: (_, record) => {
          return record?.descriptions?.name;
        },
      },
      {
        title: translate('table.price'),
        dataIndex: 'price',
        render: (_, record) => {
          return record?.descriptions?.price
            ? formatCurrency(Number(record?.descriptions?.price))
            : 'R$ 0,00';
        },
      },
    ];
  }, [translate]);

  const formatObjCheckList = objeto => {
    const chavesNulas = {};
    const chavesObjeto = {};

    for (const chave in objeto) {
      if (objeto[chave] === null) {
        chavesNulas[chave] = null;
      } else if (typeof objeto[chave] === 'object') {
        chavesObjeto[chave] = objeto[chave];
      }
    }

    return {
      general: chavesNulas,
      ...chavesObjeto,
    };
  };

  return (
    <Skeleton loading={!scheduleDetailsData} active>
      <Form
        form={form}
        initialValues={{
          ...scheduleDetailsData,
          data: moment(scheduleDetailsData?.data),
        }}
        onFinish={handleSubmitForm}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col lg={24} sm={24} xs={24}>
            <Card
              title={
                <BackToListing
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography>{translate('scheduleDetails')}</Typography>
                      {scheduleDetailsData?.paymentStatus?.toLowerCase() ===
                        'pending' &&
                        scheduleDetailsData?.paymentLink && (
                          <Button type="primary" style={{ marginLeft: '18px' }}>
                            <a
                              target="_blank"
                              rel="noreferrer noopener"
                              href={scheduleDetailsData?.paymentLink}
                            >
                              {translate('buttons.confirm-payment')}
                            </a>
                          </Button>
                        )}
                    </div>
                  }
                />
              }
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                  <Item
                    name="data"
                    label={translate('table.date')}
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                      disabled={!availableDays}
                    />
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Item
                    name="periodo"
                    label={translate('table.period')}
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select>
                      <Option value="Manhã">
                        {translate('options.morning')}
                      </Option>
                      <Option value="Tarde">
                        {translate('options.afternoon')}
                      </Option>
                    </Select>
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Item
                    name="type"
                    label={translate('table.type')}
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select>
                      <Option value="Interno">
                        {translate('options.inside')}
                      </Option>
                      <Option value="Externo">
                        {translate('options.outside')}
                      </Option>
                    </Select>
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                  <Item
                    name="status"
                    label={translate('table.status')}
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select disabled={true}>
                      <Option value="Pendente">
                        {translate('options.pending')}
                      </Option>
                      <Option value="Em andamento">
                        {translate('options.in_progress')}
                      </Option>
                      <Option value="Cancelado">
                        {translate('options.cancelled')}
                      </Option>
                    </Select>
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={10}>
                  <Item
                    name="fk_veiculo"
                    label={translate('table.vehicle')}
                    rules={[
                      {
                        required: true,
                        message: translate('form.msgs.fillThisField'),
                      },
                    ]}
                  >
                    <Select
                      disabled={!selectedCustomer}
                      style={{ width: '100%' }}
                    >
                      {vehiclesData?.content &&
                        vehiclesData?.content?.map(vehicle => (
                          <Option
                            key={vehicle.idVeiculo}
                            value={vehicle.idVeiculo}
                          >
                            {firstLetterUpper(vehicle.tipoVeiculo || '')}{' '}
                            {vehicle.marca} - {vehicle.modelo} / {vehicle.placa}{' '}
                            -
                          </Option>
                        ))}
                    </Select>
                  </Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                  <Button type="primary" onClick={() => form.submit()}>
                    {translate('buttons.save')}
                  </Button>
                </Col>
              </Row>

              <DividerModel title={translate('table.customers')} />
              <Descriptions
                column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
                style={{ marginTop: 24 }}
              >
                {scheduleDetailsData?.client &&
                  Object.keys(scheduleDetailsData?.client)?.map(
                    item =>
                      item !== 'idClient' &&
                      item !== 'fkCompany' &&
                      item !== 'idCompany' && (
                        <Descriptions.Item
                          key={item}
                          label={translate(`table.${item}`)}
                        >
                          {scheduleDetailsData?.client[item]}
                        </Descriptions.Item>
                      ),
                  )}
              </Descriptions>
            </Card>
          </Col>
        </Row>
        {scheduleDetailsData?.checklist?.map((checklist, index) => (
          <Card title={translate('checklist')} key={index}>
            <Tabs
              items={Object.keys(formatObjCheckList(checklist)).map(
                (key, index) => ({
                  key: `${index}`,
                  label: translate(key),
                  children: (
                    <Descriptions
                      column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
                      style={{ marginTop: 24 }}
                    >
                      {Object.keys(formatObjCheckList(checklist)[key]).map(
                        item => (
                          <Descriptions.Item
                            key={item}
                            label={translate(`table.${item}`)}
                          >
                            {formatObjCheckList(checklist)[key][item] ||
                              'Não informado'}
                          </Descriptions.Item>
                        ),
                      )}
                    </Descriptions>
                  ),
                }),
              )}
              defaultActiveKey="1"
            />
          </Card>
        ))}

        <div style={{ marginTop: 16 }}>
          <Table
            columns={columnsDefault}
            allColumns={columnsDefault}
            title={translate('products')}
            data={scheduleDetailsData?.products || []}
            loading={!scheduleDetailsData?.products}
            tableKey="products"
            pagination={false}
          />
        </div>
      </Form>
    </Skeleton>
  );
};

export default SchedulesDetails;
