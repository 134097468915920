import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`
  .button-previous {
    margin: 0 8px;
  }

  .button-download {
    padding: 0;
    margin-top: 8px;
  }

  .ant-steps {
    margin-bottom: 15px;
  }
`;
