import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const ImportFile = ({ style, onUpload, fileList, handleRemoveFile }) => {
  const [filesList] = useMemo(() => [fileList] || [], [fileList]);
  const { t } = useTranslation('file-uploader');

  const uploaderProps = useMemo(
    () => ({
      name: 'file',
      multiple: false,
      accept: '.pdf',
      fileList: filesList,
      beforeUpload: file => {
        onUpload(file);
        return false;
      },
      onRemove: () => {
        handleRemoveFile();
      },
    }),
    [filesList, handleRemoveFile, onUpload],
  );

  return (
    <Dragger style={style} {...uploaderProps}>
      <p className={'ant-upload-drag-icon'}>
        <InboxOutlined />
      </p>
      <p className={'ant-upload-text'}>{t('uploader-title')}</p>
    </Dragger>
  );
};

export default ImportFile;
