import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;

  .ant-image-img {
    border-radius: 8px;
    border: 1px solid #dbdbdb;
  }

  .banner-img-link {
    cursor: pointer;
    transition: 0.1s;

    :hover {
      transform: scale(1.01);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    }
  }
`;
