import React from 'react';
import { Card, Typography } from 'antd';
import { formatCurrency } from '@src/utils/formatters';
import { useTranslation } from 'react-i18next';
import HireContract from '../HireContract/index';

const HiringContractsCard = ({ contract, mutate }) => {
  const { t: translate } = useTranslation(['sms-send']);

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {contract.type === 'PAY_PER_USE' ? (
            <Typography>{translate(`status-type.${contract.type}`)}</Typography>
          ) : (
            <Typography>
              {translate(`status-type.${contract.type}`)} - {contract.franchise}{' '}
              SMS
            </Typography>
          )}
        </div>
      }
      extra={
        contract.type === 'PAY_PER_USE' ? (
          <Typography>
            {`${formatCurrency(Number(contract.price))} / SMS`}
          </Typography>
        ) : (
          <Typography>{`${formatCurrency(Number(contract.price))}`}</Typography>
        )
      }
      style={{ width: '100%' }}
      key={contract.idProduct}
    >
      <Typography>{contract.description}</Typography>

      <HireContract
        entityToHire={contract.description}
        idToHire={contract.id}
        mutate={mutate}
      />
    </Card>
  );
};

export default HiringContractsCard;
