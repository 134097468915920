import { Input } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.less';

const { Search } = Input;

const SliderRange = ({ format = '%', onChange, value, onClear }) => {
  const { t: translate } = useTranslation('inventory');

  const [valueFrom, setValueFrom] = useState(value && value[0]);
  const [valueTo, setValueTo] = useState(value && value[0]);

  const handleClearFilter = useCallback(() => {
    setValueFrom(undefined);
    setValueTo(undefined);
    onChange({
      valueFrom: undefined,
      valueTo: undefined,
    });
    onClear();
  }, [onChange, onClear]);

  return (
    <div>
      <div className="header-consumption-filter">
        <Search
          placeholder={translate('range-input.from')}
          type="number"
          value={valueFrom}
          onChange={e => {
            if (e.target.value === '' || !e.target.value) {
              handleClearFilter();
            } else {
              setValueFrom(e.target.value);
            }
          }}
          onSearch={() => {
            onChange({ valueFrom, valueTo });
          }}
          addonBefore={format}
          allowClear
          onClear={() => handleClearFilter()}
        />

        <Search
          type="number"
          placeholder={translate('range-input.to')}
          value={valueTo}
          onChange={e => {
            if (e.target.value === '' || !e.target.value) {
              handleClearFilter();
            } else {
              setValueTo(e.target.value);
            }
          }}
          onSearch={() => {
            onChange({ valueFrom, valueTo });
          }}
          allowClear
          addonBefore={format}
        />
      </div>
    </div>
  );
};

export default SliderRange;
