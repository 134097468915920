import { CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CopyButton = ({ onClick }) => {
  const { t } = useTranslation('attributes');
  const [wasCopied, setWasCopied] = useState(false);

  const handleClick = useCallback(
    e => {
      setWasCopied(true);
      setTimeout(() => {
        setWasCopied(false);
      }, 1.5 * 1000);
      onClick?.(e);
    },
    [onClick],
  );

  return (
    <Tooltip title={t(`${wasCopied ? 'copied' : 'copy'}`)}>
      <Button icon={<CopyOutlined />} onClick={handleClick} />
    </Tooltip>
  );
};

export default CopyButton;
