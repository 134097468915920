import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import {
  Button,
  Input,
  Col,
  Select,
  Tabs,
  Modal,
  message,
  DatePicker,
  Tooltip,
} from 'antd';
import { Filters, FiltersCard } from '@components/Filters';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import {
  bytesFormat,
  dateFormat,
  toISOStringWithOffset,
} from '@src/utils/formatters';
import moment from 'moment';
import apiMiddleware from '@src/services/apiMiddleware';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import RequestReport from '@src/components/RequestReport/RequestReport';
import rolesConstants from '@src/utils/rolesConstants';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const AlertsEvents = ({ defaultFilters, tableOnly, isDetailsContracts }) => {
  const { t } = useTranslation(['alerts', 'attributes']);
  const { keycloak } = useContext(KeycloakContext);

  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';
  const isBroker = customerType === 'BROKER';

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [msisdn, setMsisdn] = useState('');
  const [msisdnValue, setMsisdnValue] = useState('');

  const [contractId, setContractId] = useState('');
  const [contractIdValue, setContractIdValue] = useState('');

  const [eventType, setEventType] = useState('');

  const [field, setField] = useState('');

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [showContracted, setShowContracted] = useState(
    isEndCustomer ? 'true' : 'false',
  );
  const [showFilters, setShowFilters] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGINATION.current);
  const [linesPerPage, setLinesPerPage] = useState(DEFAULT_PAGINATION.pageSize);
  const navigate = useNavigate();

  const { data, mutate } = useSwr(
    `/service-proxy/alert-events`,
    {
      page: pagination?.current - 1,
      size: pagination?.pageSize,
      msisdn,
      eventType,
      field,
      dateFrom: dateFrom && toISOStringWithOffset(dateFrom),
      dateTo: dateTo && toISOStringWithOffset(dateTo),
      contractId,
      showContracted: showContracted === 'true',
      ...defaultFilters,
    },
    {
      refreshInterval: 300000,
    },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'msisdn',
        setState: setMsisdn,
        setStateValue: setMsisdnValue,
        inTheFilters: true,
      },

      {
        name: 'contractId',
        setState: setContractId,
        setStateValue: setContractIdValue,
        inTheFilters: true,
      },
      {
        name: 'showContracted',
        setState: setShowContracted,
        inTheFilters: false,
      },
      {
        name: 'eventType',
        setState: setEventType,
        inTheFilters: true,
      },
      {
        name: 'field',
        setState: setField,
        inTheFilters: true,
      },
      {
        name: 'dateFrom',
        setState: setDateFrom,
        inTheFilters: true,
      },
      {
        name: 'dateTo',
        setState: setDateTo,
        inTheFilters: true,
      },
      {
        name: 'page',
        setState: setPage,
        inTheFilters: false,
      },
      {
        name: 'linesPerPage',
        setState: setLinesPerPage,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [linesPerPage]);

  useEffect(() => {
    if (data?.content) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data?.totalElements,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (page && linesPerPage) {
      setPagination(oldPagination => ({
        ...oldPagination,
        current: parseInt(page),
        pageSize: parseInt(linesPerPage),
      }));
    }
  }, [page, linesPerPage]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['page', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const handleDelete = useCallback(
    async id => {
      try {
        await apiMiddleware.delete(`/service-proxy/alert-events/${id}`);
        message.success(t('msgs.alert-event-deleted'));
        mutate();
      } catch {
        message.error(t('msgs.alert-event-deletion-error'));
      }
    },
    [t, mutate],
  );

  const showDelConfirm = useCallback(
    (idToDelete, name) => {
      confirm({
        title: t('titles.delete-modal-event', { name }),
        icon: <ExclamationCircleOutlined />,
        content: t('titles.delete-modal-content-event'),
        centered: true,
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        okButtonProps: { type: 'primary', danger: true },
        onOk: () => handleDelete(idToDelete),
      });
    },
    [handleDelete, t],
  );

  const adminActions = useCallback(
    (text, record) => (
      <Button
        icon={<DeleteOutlined />}
        type={'link'}
        danger
        onClick={() => showDelConfirm(record.id, record.id)}
      />
    ),
    [showDelConfirm],
  );

  const columns = useMemo(
    () => [
      { title: 'ID', dataIndex: 'id', align: 'center' },
      { title: t('table.alertId'), dataIndex: 'alertId', align: 'center' },
      {
        title: t('table.contractId'),
        dataIndex: 'contractId',
        align: 'center',
        render: value =>
          value && (
            <Tooltip title={t('titles.view-contract')}>
              <Button
                style={{
                  margin: 0,
                  padding: 0,
                  height: '100%',
                }}
                type="link"
                onClick={() => navigate(`/financial/contracts/${value}`)}
              >
                {value}
              </Button>
            </Tooltip>
          ),
      },
      {
        title: 'MSISDN',
        dataIndex: 'msisdn',
        align: 'center',
      },
      {
        title: t('table.eventType'),
        dataIndex: 'eventType',
        align: 'center',
        render: value => t(`eventType.${value}`),
      },
      {
        title: t('table.eventValue'),
        dataIndex: 'eventValue',
        align: 'center',
        render: (value, record) => {
          if (record.field === 'consumptionPercent') {
            return `${value.toFixed(1)}%`;
          }

          return bytesFormat(value);
        },
      },
      {
        title: t('table.field'),
        dataIndex: 'field',
        align: 'center',
        render: value => t(`field.${value}`),
      },
      {
        title: t('table.thresholdValue'),
        dataIndex: 'thresholdValue',
        align: 'center',
        render: (value, record) => {
          if (record.field === 'consumptionPercent') {
            return `${value}%`;
          }
          if (
            record.field === 'consumptionTotalAnomaly' ||
            record.field === 'consumptionCurrentDayAnomaly'
          ) {
            return `${value * 100}%`;
          }
          return bytesFormat(value);
        },
      },
      {
        title: t('attributes:name'),
        dataIndex: 'name',
        align: 'center',
      },
      {
        title: t('table.firedAt'),
        dataIndex: 'createdAt',
        align: 'center',
        render: value => value && dateFormat(value),
      },
      {
        title: t('attributes:actions'),
        dataIndex: 'actions',
        align: 'center',
        render: adminActions,
      },
    ],
    [t, adminActions],
  );

  const handleDateChange = useCallback(
    date => {
      const initial = date && date[0].toISOString();
      const end = date && date[1].toISOString();
      handleSetSearchParams({ dateFrom: initial, dateTo: end });
    },
    [handleSetSearchParams],
  );

  const ranges = useMemo(
    () => ({
      [t('attributes:lastAlertDateFrom.lastOneHour')]: [
        moment(new Date()).subtract(1, 'hours'),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastThreeHours')]: [
        moment(new Date()).subtract(3, 'hours'),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastSixHours')]: [
        moment(new Date()).subtract(6, 'hours'),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastTwelveHours')]: [
        moment(new Date()).subtract(12, 'hours'),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastDay')]: [
        moment(new Date()).subtract(1, 'day'),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastWeek')]: [
        moment().subtract(7, 'days'),
        moment(),
      ],
      [t('attributes:lastAlertDateFrom.lastMonth')]: [
        moment().subtract(1, 'months'),
        moment(),
      ],
    }),
    [t],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: !tableOnly,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('MSISDN'),
        item: (
          <Search
            placeholder={t('placeholders:msisdn')}
            onSearch={value => handleSetSearchParams({ msisdn: value })}
            onChange={({ target: { value } }) => setMsisdnValue(value)}
            value={msisdnValue}
            allowClear
          />
        ),
      },
      {
        visible: !tableOnly,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.contractId'),
        item: (
          <Search
            placeholder={t('table.contractId')}
            onSearch={value => handleSetSearchParams({ contractId: value })}
            onChange={({ target: { value } }) => setContractIdValue(value)}
            value={contractIdValue}
            allowClear
          />
        ),
      },
      {
        visible: !tableOnly,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.eventType'),
        item: (
          <Select
            value={eventType}
            onChange={value => handleSetSearchParams({ eventType: value })}
            style={{ width: '100%' }}
            allowClear
          >
            <Option value="CONSUMPTION">{t('eventType.CONSUMPTION')}</Option>
            <Option value="CONTRACT_CONSUMPTION">
              {t('eventType.CONTRACT_CONSUMPTION')}
            </Option>
          </Select>
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.field'),
        item: (
          <Select
            value={field}
            onChange={value => handleSetSearchParams({ field: value })}
            allowClear
            style={{ width: '100%' }}
          >
            <Option value="consumptionTotal">
              {t('field.consumptionTotal')}
            </Option>
            <Option value="consumptionPercent">
              {t('field.consumptionPercent')}
            </Option>

            <Option value="consumptionTotalAnomaly">
              {t('field.consumptionTotalAnomaly')}
            </Option>
            {!isDetailsContracts && (
              <>
                {' '}
                <Option value="consumptionCurrentDayAnomaly">
                  {t('field.consumptionCurrentDayAnomaly')}
                </Option>
                <Option value="consumptionCurrentDay">
                  {t('field.consumptionCurrentDay')}
                </Option>
              </>
            )}
          </Select>
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.firedAt'),
        item: (
          <RangePicker
            showTime
            onChange={handleDateChange}
            rangesDefault={ranges}
            format="DD/MM/YYYY HH:mm"
            defaultValue={
              dateFrom
                ? [
                    moment(dateFrom, 'YYYYMMDD HH:mm:ss'),
                    moment(dateTo, 'YYYYMMDD HH:mm:ss'),
                  ]
                : undefined
            }
            style={{ width: '100%' }}
          />
        ),
      },
    ],
    [
      t,
      handleSetSearchParams,
      msisdnValue,
      dateFrom,
      dateTo,
      handleDateChange,
      field,
      eventType,
      ranges,
      tableOnly,
      contractIdValue,
      isDetailsContracts,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      handleSetSearchParams({
        page: paginationObj.current,
        linesPerPage: paginationObj.pageSize,
      });
    }
  };

  useEffect(() => {
    setMsisdnValue(msisdn);
  }, [msisdn]);

  useEffect(() => {
    setContractIdValue(contractId);
  }, [contractId]);

  return (
    <>
      {!tableOnly && (
        <Tabs
          type="card"
          defaultActiveKey="false"
          activeKey={showContracted}
          onChange={key => {
            handleSetSearchParams({
              showContracted: key,
            });
          }}
          style={{ background: '#ffffff' }}
        >
          {isEndCustomer ? (
            <TabPane key="true" tab={t('visions.true')} />
          ) : isBroker ? (
            <>
              <TabPane key="false" tab={t('visions.false')} />
              <TabPane key="true" tab={t('visions.true')} />
            </>
          ) : (
            <>
              <TabPane key="false" tab={t('visions.false')} />
              <TabPane key="true" tab={t('visions.true')} />
            </>
          )}
        </Tabs>
      )}

      {isDetailsContracts &&
      (!data?.content || data?.content?.length === 0) &&
      !field &&
      !dateFrom &&
      !dateTo ? (
        <> </>
      ) : (
        <Table
          title={
            <Filters
              title={tableOnly ? t('titles.events-alerts') : t('titles.events')}
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          }
          columns={columns}
          data={data?.content}
          loading={!data?.content}
          tableKey="AlertsEvents"
          pagination={pagination}
          onChange={handleTableChange}
          extraActions={
            <>
              <RequestReport
                items={[
                  {
                    role: rolesConstants.REQUEST_REPORT,
                    path: '/service-proxy/alert-events/report',
                    type: 'ALERT_EVENTS',
                    filters: {
                      msisdn,
                      eventType,
                      field,
                      dateFrom,
                      dateTo,
                      contractId,
                      showContracted: showContracted === 'true',
                      ...defaultFilters,
                    },
                    isParams: true,
                  },
                ]}
              />
            </>
          }
          extraFilters={
            showFilters && (
              <FiltersCard>
                {tableFilters?.map(
                  ({ col: { lg, xl, xs }, label, item, visible }) =>
                    visible && (
                      <Col key={label} lg={lg} xl={xl} xs={xs}>
                        {label && (
                          <div>
                            <Label color={'#575962'} htmlFor={label}>
                              {label}
                            </Label>
                          </div>
                        )}
                        {item}
                      </Col>
                    ),
                )}
              </FiltersCard>
            )
          }
        />
      )}
    </>
  );
};

export default AlertsEvents;
