import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalContainer = styled(Modal)`
  max-width: 900px;

  .ant-paragraph {
    width: 50%;
  }
  .ant-divider {
    font-size: 14px;
  }

  .modal-tab > .ant-tabs-nav,
  .modal-edit .ant-tabs-nav {
    display: none;
  }
`;
