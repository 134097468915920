import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Tooltip,
  message,
  Col,
  Space,
  Divider,
  Tag,
  Select,
} from 'antd';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import { PlusCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import apiMiddleware from '@services/apiMiddleware';
import useSearchParams from '@src/hooks/useSearchParams';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import AdminActions from '@src/components/AdminActions/index';
import ScheduleModal from './ScheduleModal/index';
import { useNavigate } from 'react-router-dom';
import {
  firstLetterUpper,
  getDateFromPartiallyInIsoFormat,
} from '@src/utils/formatters';
import useDebounce from '@src/hooks/useDebounce';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30'],
  style: { marginRight: 20 },
};

const Schedules = () => {
  const { t } = useTranslation('schedules');

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [clientId, setClientId] = useState();
  const [clientNameSearch, setClientNameSearch] = useState();

  const navigate = useNavigate();
  const debouncedClientName = useDebounce(clientNameSearch, 1000);

  const filterParams = useMemo(
    () => ({
      clientId,
    }),
    [clientId],
  );

  const { data: creditsData, mutate: mutateCredits } = useSwr(
    '/service-proxy/astro/credit/balance',
    null,
    {
      revalidateOnFocus: true,
    },
  );

  const { data: scheduleData, mutate } = useSwr(
    '/service-proxy/astro/appointments',
    {
      page: pagination.current ? pagination.current - 1 : 0,
      per_page: pagination.pageSize,
      ...filterParams,
    },
  );

  const deleteItem = useCallback(
    idToDelete =>
      apiMiddleware.delete(`/service-proxy/astro/appointment/${idToDelete}`),
    [],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'clientId',
        setState: setClientId,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [clientId]);

  useEffect(() => {
    if (scheduleData) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: scheduleData?.totalElements,
      }));
    }
  }, [scheduleData]);

  const handleMutate = useCallback(
    (type, data) => {
      if (scheduleData?.content) {
        if (type === 'CREATE') {
          mutate('/service-proxy/astro/appointments');
        } else {
          mutate('/service-proxy/astro/appointments');
        }
      }
    },
    [scheduleData, mutate],
  );

  const handleActions = useCallback(
    async (actionType, record) => {
      // actionType = 'UPDATE' || 'DELETE'
      const isUpdate = actionType === 'UPDATE';
      if (isUpdate) {
        navigate(`/schedule/schedules/${record.idAgendamento}`);
      } else {
        try {
          await deleteItem(record.idAgendamento);
          handleMutate('DELETE', { id: record.idAgendamento });
          message.success(t('msgs.success-deleting'));
        } catch {
          message.error(t('msgs.error'));
        }
      }
    },
    [deleteItem, handleMutate, t, navigate],
  );

  const columnsDefault = useMemo(() => {
    return [
      {
        title: t('table.id'),
        dataIndex: 'idAgendamento',
      },
      {
        title: t('table.date'),
        dataIndex: 'data',
        render: value => value && getDateFromPartiallyInIsoFormat(value),
      },
      {
        title: t('table.period'),
        dataIndex: 'periodo',
      },
      {
        title: t('table.type'),
        dataIndex: 'type',
      },
      {
        title: t('table.customers'),
        dataIndex: 'client',
        render: value => {
          return value ? <>{`${value.corporateName}`}</> : '';
        },
      },
      {
        title: t('table.vehicle'),
        dataIndex: 'veiculo',
        render: value => {
          return value ? (
            <Tag>{`${value.modelo} - ${value.marca} / ${value.placa}`}</Tag>
          ) : (
            ''
          );
        },
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        render: value => value && firstLetterUpper(value),
      },
      {
        title: t('table.observation'),
        dataIndex: 'observacao',
      },
      {
        title: t('table.action'),
        dataIndex: 'action',
        render: (value, record) => {
          return (
            <AdminActions
              alertDelete={t('msgs.delete')}
              onEdit={() => handleActions('UPDATE', record)}
              onDelete={() => handleActions('DELETE', record)}
              entityToExclude={`${getDateFromPartiallyInIsoFormat(
                record?.data,
              )} - ${record?.client?.firstName} ${record?.veiculo?.modelo} `}
              detailsIcon
            />
          );
        },
      },
    ];
  }, [t, handleActions]);

  const handleTableChange = useCallback(paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  }, []);

  const onClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleNew = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const { data: customersData } = useSwr(
    `/service-proxy/astro/clients${
      debouncedClientName ? `?corporate_name=${debouncedClientName}` : ''
    }`,
    { page: 0, per_page: 20 },
  );

  const handleClientChange = useCallback(value => {
    setClientNameSearch(value);
  }, []);

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('table.customers'),
        item: (
          <Select
            showSearch
            value={clientId ? Number(clientId) : undefined}
            placeholder={t('table.customers')}
            style={{ width: '100%' }}
            onSelect={value => {
              if (value) {
                handleSetSearchParams({
                  clientId: value,
                });
              } else {
                handleClearParams();
                setClientNameSearch();
              }
            }}
            onClear={() => {
              handleClearParams();
              setClientNameSearch();
            }}
            filterOption={false}
            options={
              customersData?.content.map(client => ({
                label: `${client.corporateName}`,
                value: client.idClient,
              })) || []
            }
            onSearch={handleClientChange}
            notFoundContent={null}
            loading={!customersData?.content}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      clientId,
      customersData,
      handleClientChange,
      handleSetSearchParams,
      handleClearParams,
    ],
  );

  return (
    <>
      <ScheduleModal
        visible={isModalVisible}
        onClose={onClose}
        mutateData={handleMutate}
        creditsData={creditsData}
        mutateCredits={mutateCredits}
      />

      <Table
        columns={columnsDefault}
        allColumns={columnsDefault}
        data={scheduleData?.content || []}
        loading={!scheduleData?.content}
        title={
          <>
            {t('schedules')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        tableKey="schedules"
        pagination={pagination}
        onChange={handleTableChange}
        mainExtraAction={
          <Space>
            <Divider orientation="center" type="vertical" />
            <Tooltip title={t('buyCredits')}>
              <Button
                icon={<ShoppingCartOutlined />}
                onClick={() => navigate(`/schedule/schedules/credits`)}
                type="primary"
              >
                {creditsData?.balance ? creditsData.balance : '0'}{' '}
                {t('haveCredits')}
              </Button>
            </Tooltip>
          </Space>
        }
        extraActions={
          <>
            <Tooltip placement="top" title={t('modal.title.newSchedule')}>
              <Button
                icon={<PlusCircleOutlined />}
                size="large"
                type="link"
                onClick={() => handleNew()}
              />
            </Tooltip>
          </>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Schedules;
