import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import DashboardStats from './DashboardStats';
import DashboardTopTable from './DashboardTopTable';
import ChartActivationDeactivation from './DashboardCharts/ChartActivationDeactivation';
import ChartDevicesByCustomer from './DashboardCharts/ChartDevicesByCustomer';

const Dashboards = () => {
  const { t } = useTranslation('lorawan');
  const { keycloak } = useContext(KeycloakContext);
  const customerType = keycloak.idTokenParsed?.customerType;
  const isSuperBroker = customerType === 'SUPERBROKER';
  const isBroker = ['SUPERBROKER', 'BROKER'].includes(customerType);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <DashboardStats />
      </Row>

      {isBroker && (
        <Row style={{ marginTop: 16 }}>
          <ChartDevicesByCustomer />
        </Row>
      )}

      {isSuperBroker && (
        <Row style={{ marginTop: 16 }}>
          <ChartActivationDeactivation />
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col xl={12} xs={24}>
          <DashboardTopTable
            title={t('devicesWithMoreDownlinks')}
            path="topDownlinks"
          />
        </Col>
        <Col xl={12} xs={24}>
          <DashboardTopTable
            title={t('devicesWithMoreUplinks')}
            path="topUplinks"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboards;
