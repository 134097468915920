import React, { useState, useCallback, useMemo, useContext } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import StepsModal from '@components/StepsModal';
import useSwr from '@hooks/useSwr';
import ContractStep from './ContractStep';
import ConfigStep from './ConfigStep';
import apiMiddleware from '@src/services/apiMiddleware';
import RequestContractForm from './RequestContractForm';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const NewRequestModal = ({
  visible,
  onClose,
  newRequestMutate,
  defaultContract,
}) => {
  const { t } = useTranslation(['requests', 'attributes', 'contracts']);
  const { keycloak } = useContext(KeycloakContext);
  const isAllcom = keycloak.idTokenParsed?.customerType === 'SUPERBROKER';

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [contractId, setContractId] = useState();
  const [configValues, setConfigValues] = useState();
  const [requestContractValues, setRequestContractValues] = useState();

  const isConfirmDisabled =
    !configValues?.name ||
    configValues?.name === '' ||
    (configValues?.loyaltyType === 2 && !configValues?.loyaltyTime);

  const { data: contractsByClient } = useSwr(
    selectedCustomerId && !defaultContract
      ? `/service-proxy/lorawanVirtualPlans?customerId=${selectedCustomerId}`
      : null,
  );

  const createAccount = useCallback(
    payload =>
      apiMiddleware.post('/service-proxy/lorawanVirtualAccounts', payload),
    [],
  );

  const contractDetails = useMemo(
    () =>
      defaultContract ??
      contractsByClient?.content?.find(contract => contract.id === contractId),
    [contractId, contractsByClient?.content, defaultContract],
  );

  const handleRequestContractChange = useCallback(values => {
    setRequestContractValues({
      parentIdMOT: values?.contractMotId,
      parentIdLorawan: values?.contractLorawanId,
      parentIdAccount: values?.requestId,
    });
  }, []);

  const newRequestSteps = useMemo(
    () => [
      {
        title: t(`attributes:${isAllcom ? 'contracts' : 'request'}`),
        doNotDisplay: !!defaultContract,
        disabled: isAllcom
          ? !requestContractValues?.parentIdMOT ||
            !requestContractValues?.parentIdLorawan
          : !requestContractValues?.parentIdAccount,
        content: (
          <RequestContractForm
            contractMotId={requestContractValues?.parentIdMOT}
            contractLorawanId={requestContractValues?.parentIdLorawan}
            requestId={requestContractValues?.parentIdAccount}
            onChange={handleRequestContractChange}
          />
        ),
      },
      {
        title: t('attributes:billingModel'),
        doNotDisplay: !!defaultContract,
        disabled: !contractId,
        content: (
          <ContractStep
            contractsByClient={contractsByClient?.content}
            customerId={selectedCustomerId}
            onCustomerId={customerId => {
              setContractId(undefined);
              setSelectedCustomerId(customerId);
            }}
            contractId={contractId}
            onContractId={setContractId}
          />
        ),
      },
      {
        title: t('modal.stepsTitles.config'),
        content: (
          <ConfigStep
            onChange={setConfigValues}
            contractDetails={contractDetails}
          />
        ),
      },
    ],
    [
      defaultContract,
      isAllcom,
      requestContractValues?.parentIdMOT,
      requestContractValues?.parentIdLorawan,
      requestContractValues?.parentIdAccount,
      handleRequestContractChange,
      contractId,
      contractsByClient?.content,
      selectedCustomerId,
      t,
      contractDetails,
    ],
  );

  const payload = useMemo(() => {
    const { parentIdAccount, parentIdMOT, parentIdLorawan } =
      requestContractValues || {};

    // allcom created request for its customers
    const allcomPayload = { parentIdMOT, parentIdLorawan };
    // request created by allcom son from allcom request
    const childOfAllcomPayload = { parentIdAccount };

    const defaultPayload = {
      ...configValues,
      virtualPlanId: contractDetails?.id,
      ...(parentIdAccount ? childOfAllcomPayload : allcomPayload),
    };

    return defaultPayload;
  }, [configValues, contractDetails?.id, requestContractValues]);

  const handleConfirm = useCallback(async () => {
    try {
      setConfirmLoading(true);

      await createAccount(payload);
      newRequestMutate(payload);
      message.success(t('modal.notifications.success'));
      onClose();
    } catch {
      message.error(t('modal.notifications.error'));
    }

    setConfirmLoading(false);
  }, [t, createAccount, newRequestMutate, onClose, payload]);

  return (
    <StepsModal
      title={t('modal.title')}
      width="700px"
      onConfirm={handleConfirm}
      isOpen={visible}
      allowStepsLine
      steps={newRequestSteps}
      onClose={() => onClose()}
      confirmLoading={confirmLoading}
      confirmDisabled={isConfirmDisabled}
    />
  );
};

export default NewRequestModal;
