import React, { useCallback, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import SearchBoxGoogle from '@components/Maps/SearchBoxGoogle/SearchBoxGoogle';
import MarkerClusterer from '@googlemaps/markerclustererplus';

const CENTER_DEFAULT = {
  lat: -14.235004,
  lng: -51.92528,
};

const ZOOM_DEFAULT = 4;

const PositionMap = ({
  data,
  allowSearch,
  mapHeight,
  mapsZoom,
  setMapsZoom,
  mapsCenter,
  setMapsCenter,
  searchValue,
  setSearchValue,
  clearLocation,
  handleSetSearchParams,
}) => {
  const [mapsProps, setMapsProps] = useState();
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState();

  const handleSetZoom = useCallback(
    value => {
      if (setMapsZoom) {
        setMapsZoom(value);
      } else {
        setZoom(value);
      }
    },
    [setMapsZoom],
  );

  const handleSetCenter = useCallback(
    values => {
      if (setMapsCenter) {
        setMapsCenter(values);
      } else {
        setCenter(values);
      }
    },
    [setMapsCenter],
  );

  const handleGoogleApiLoaded = useCallback(
    (mapProps, map) => {
      map?.setOptions({
        fullscreenControl: false,
      });

      const markers = data?.map(location => {
        const info = `IMSI: ${location.imsi}`;
        const infowindow = new mapProps.InfoWindow({
          content: info,
        });

        const marker = new mapProps.Marker({
          position: {
            lat: location.lat,
            lng: location.lng,
          },
        });

        marker.addListener('click', () => {
          infowindow.open(mapProps, marker);
        });

        return marker;
      });

      const markerCluster = new MarkerClusterer(map, markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      });

      markerCluster?.addListener('clusterclick', cluster => {
        handleSetCenter({
          lat: cluster.getCenter().lat(),
          lng: cluster.getCenter().lng(),
        });
      });

      setMapsProps(mapProps);
    },
    [data, handleSetCenter],
  );

  return (
    <div style={{ height: mapHeight }}>
      <div
        style={{
          height: '100%',
          width: '100%',
          borderRadius: 5,
          position: 'relative',
        }}
      >
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ maps, map }) =>
            handleGoogleApiLoaded(maps, map)
          }
          onClick={({ lat, lng }) => {
            const newCoordinates = {
              lat,
              lng,
            };
            handleSetCenter(newCoordinates);
          }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS || '',
            libraries: ['places', 'visualization'],
          }}
          defaultCenter={CENTER_DEFAULT}
          center={mapsCenter}
          defaultZoom={ZOOM_DEFAULT}
          zoom={mapsZoom}
          onZoomAnimationEnd={handleSetZoom}
        />
        {allowSearch && mapsProps && (
          <SearchBoxGoogle
            googleMaps={mapsProps}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onPlacesChanged={handleSetSearchParams}
            clearLocation={clearLocation}
          />
        )}
      </div>
    </div>
  );
};

export default PositionMap;
