import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Steps, Divider, Form, Alert } from 'antd';

import ActionsItems from '@components/Actions/ActionsItems';

import * as S from './styles';

const { Item } = Form;

const ActionsModal = ({
  isOpen,
  onClose,
  onConfirm,
  onConfirmLoading,
  selectedAction,
  form,
  isUpload,
  devices,
}) => {
  const { t } = useTranslation('device-actions-modal');
  const formValues = Form.useWatch([], form);

  const numberOfDevices = useMemo(
    () => selectedAction?.totalDevices ?? devices?.length,
    [devices?.length, selectedAction?.totalDevices],
  );

  const currentStep = useMemo(
    () =>
      formValues
        ? Object.values(formValues)?.filter(
            value => value || value === 0 || value === false,
          )?.length
        : 0,
    [formValues],
  );

  const handleChangePositionUploadStep = useCallback((steps, newPosition) => {
    const uploadIndex = 0;
    const listItem = steps.splice(uploadIndex, 1)[0];

    steps.splice(newPosition, 0, listItem);
    return steps;
  }, []);

  const isUploadDisabled = useMemo(
    () =>
      selectedAction?.uploadPosition
        ? currentStep < selectedAction?.uploadPosition
        : false,
    [currentStep, selectedAction?.uploadPosition],
  );

  // reset of items that come after what was changed
  const handleItemChange = useCallback(
    changedItem => {
      const newFormValues = { ...form?.getFieldsValue() };
      const keys = Object.keys(newFormValues);
      const changedItemKey = Object.keys(changedItem)[0];
      const changedKeyIndex = keys.indexOf(changedItemKey);

      if (changedKeyIndex >= 0 && changedKeyIndex < keys.length) {
        keys.forEach((key, indexKey) => {
          const preserveStep = selectedAction?.steps?.[indexKey]?.preserveStep;
          if (indexKey > changedKeyIndex && !preserveStep) {
            form?.resetFields([key]);
          }
        });
      }
    },
    [form, selectedAction?.steps],
  );

  const handleUploadRemove = useCallback(
    () => handleItemChange({ uploadedItems: undefined }),
    [handleItemChange],
  );

  const uploadStep = ActionsItems.Common.useUpload(
    selectedAction?.payloadToSend?.action ?? selectedAction?.actionKey,
    form,
    selectedAction?.uploadCustomInfo,
    isUploadDisabled,
    handleUploadRemove,
  );

  const actionSteps = useMemo(() => {
    let steps = selectedAction?.steps ?? [];
    if (isUpload) {
      const stepsWithUpload = [...steps.filter(step => step?.showWhenUpload)];
      stepsWithUpload.unshift(uploadStep);

      steps = selectedAction?.uploadPosition
        ? handleChangePositionUploadStep(
            stepsWithUpload,
            selectedAction?.uploadPosition,
          )
        : stepsWithUpload;
    }
    return steps;
  }, [selectedAction, isUpload, uploadStep, handleChangePositionUploadStep]);

  const handleOnFinish = useCallback(() => {
    form?.validateFields().then(() => {
      onConfirm();
    });
  }, [form, onConfirm]);

  const handleCancel = useCallback(() => {
    form?.resetFields();
    onClose(false);
  }, [form, onClose]);

  const stepsItems = useMemo(
    () =>
      actionSteps?.map(stepItem => {
        const rules = stepItem?.isNotRequired
          ? []
          : [
              {
                required: true,
                message: `* ${t('msgs.required-filed')}`,
              },
            ];

        if (stepItem?.rules) {
          rules.push(...stepItem?.rules);
        }

        if (stepItem?.initialValue && !form?.getFieldValue(stepItem?.name)) {
          form?.setFieldValue(stepItem?.name, stepItem?.initialValue);
        }

        const itemProps = {
          name: stepItem?.name,
          valuePropName: stepItem?.valuePropName,
          rules,
        };

        return {
          ...stepItem,
          description: (
            <div style={{ padding: '8px 0', marginBottom: 16, opacity: 0.8 }}>
              {stepItem?.itemInfo && <div>{stepItem?.itemInfo}</div>}
              <Item {...itemProps}>{stepItem?.content}</Item>
              {stepItem?.showItemMessage && (
                <span className="action-modal-item-error">{`* ${stepItem?.itemMessage}`}</span>
              )}
            </div>
          ),
        };
      }),
    [actionSteps, form, t],
  );

  return (
    <Modal
      title={
        selectedAction?.title ??
        t(`device-actions:labels.${selectedAction?.payloadToSend?.action}`)
      }
      open={isOpen}
      onCancel={handleCancel}
      bodyStyle={{ padding: '24px 40px' }}
      width={selectedAction?.modalWidth}
      footer={false}
      destroyOnClose
    >
      <S.ModalContent>
        {selectedAction?.description && (
          <>
            <div className="action-modal-description-text">
              {selectedAction?.description}
            </div>
            {actionSteps?.length > 0 && <Divider />}
          </>
        )}
        {!isUpload && stepsItems?.length === 0 && (
          <span>{t('msgs.confirm-request')}</span>
        )}
        <Form
          form={form}
          onFinish={handleOnFinish}
          onValuesChange={handleItemChange}
        >
          <Steps
            direction="vertical"
            current={currentStep}
            items={stepsItems}
          />
          {selectedAction?.infoMessage && (
            <Alert
              description={selectedAction?.infoMessage}
              type="warning"
              showIcon
            />
          )}
          <Divider />
          <S.ModalFooter totalDevices={numberOfDevices}>
            {numberOfDevices ? (
              <span className="action-modal-footer-total-devices">
                {t('selected-device', { count: numberOfDevices })}
              </span>
            ) : undefined}
            <Button type="primary" htmlType="submit" loading={onConfirmLoading}>
              {t(onConfirmLoading ? 'sending' : 'confirm')}
            </Button>
          </S.ModalFooter>
        </Form>
      </S.ModalContent>
    </Modal>
  );
};

export default ActionsModal;
