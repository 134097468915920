import React, { useMemo } from 'react';

import { Button, Popover } from 'antd';
import { QuestionOutlined, ExclamationOutlined } from '@ant-design/icons';

import * as S from './styles';

function ButtonHelp({ type, img, description }) {
  const icon = useMemo(() => {
    switch (type) {
      case 'help':
        return <QuestionOutlined />;
      case 'no-data':
        return <ExclamationOutlined />;
      default:
        break;
    }
  }, [type]);

  return (
    <S.Container>
      <Popover
        trigger="click"
        content={
          <S.PopoverContent>
            <p>{description}</p>
            <img className="map-img" src={img} alt="map" />
          </S.PopoverContent>
        }
      >
        <Button icon={icon} />
      </Popover>
    </S.Container>
  );
}

export default ButtonHelp;
