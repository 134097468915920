import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Tooltip, message, Col, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import { PlusCircleOutlined } from '@ant-design/icons';
import apiMiddleware from '@services/apiMiddleware';
import { useNavigate } from 'react-router-dom';
import AdminActions from '@src/components/AdminActions/index';
import CustomerModal from './CustomerModal/index';
import useSearchParams from '@src/hooks/useSearchParams';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import { Label } from '@src/components/ui/index';
import { maskPhone } from '@src/utils/formatters';

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30'],
  style: { marginRight: 20 },
};

const { Search } = Input;

const CustomersSchedules = () => {
  const { t: translate } = useTranslation('schedules');
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customersSelected, setCustomersSelected] = useState();
  const [city, setCity] = useState();
  const [cityValue, setCityValue] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const filterParams = useMemo(
    () => ({
      city,
    }),
    [city],
  );

  const { data: customersData, mutate } = useSwr(
    '/service-proxy/astro/clients',
    {
      page: pagination.current ? pagination.current - 1 : 0,
      per_page: pagination.pageSize,
      ...filterParams,
    },
  );

  const deleteCustomer = useCallback(
    idToDelete =>
      apiMiddleware.delete(`/service-proxy/astro/client/${idToDelete}`),
    [],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'city',
        setState: setCity,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  useEffect(() => {
    setCityValue(city);
  }, [city]);

  useEffect(() => {
    if (customersData) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: customersData?.totalElements,
      }));
    }
  }, [customersData]);

  const handleMutateCustomers = useCallback(
    (type, data) => {
      if (customersData?.content) {
        if (type === 'CREATE') {
          mutate('/service-proxy/astro/clients');
        } else {
          mutate('/service-proxy/astro/clients');
        }
      }
    },
    [customersData, mutate],
  );

  const handleActions = useCallback(
    async (actionType, record) => {
      // actionType = 'UPDATE' || 'DELETE'
      const isUpdate = actionType === 'UPDATE';
      if (isUpdate) {
        navigate(`/schedule/customers/${record.idClient}`);
      } else {
        try {
          await deleteCustomer(record.idClient);
          handleMutateCustomers('DELETE', { id: record.idClient });
          message.success(translate('msgs.success-deleting'));
        } catch {
          message.error(translate('msgs.error'));
        }
      }
    },
    [deleteCustomer, handleMutateCustomers, translate, navigate],
  );

  const columnsDefault = useMemo(() => {
    return [
      {
        title: translate('table.firstName'),
        dataIndex: 'firstName',
      },
      {
        title: translate('table.lastName'),
        dataIndex: 'lastName',
      },
      {
        title: translate('table.corporateName'),
        dataIndex: 'corporateName',
      },
      {
        title: translate('table.fantasyName'),
        dataIndex: 'fantasyName',
      },
      {
        title: translate('table.cpfCnpj'),
        dataIndex: 'cpfCnpj',
      },
      {
        title: translate('table.phoneNumber'),
        dataIndex: 'phoneNumber',
        render: value => value && value.length > 0 && maskPhone(value[0]),
      },
      {
        title: translate('table.wppNumber'),
        dataIndex: 'wppNumber',
        render: value => value && value.length > 0 && maskPhone(value[0]),
      },
      {
        title: translate('table.city'),
        dataIndex: 'city',
      },
      {
        title: translate('table.state'),
        dataIndex: 'state',
      },
      {
        title: translate('table.action'),
        dataIndex: 'action',
        render: (value, record) => {
          return (
            <AdminActions
              alertDelete={translate('alert.delete')}
              onEdit={() => handleActions('UPDATE', record)}
              onDelete={() => handleActions('DELETE', record)}
              entityToExclude={record.firstName}
              detailsIcon
            />
          );
        },
      },
    ];
  }, [translate, handleActions]);

  const handleTableChange = useCallback(paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  }, []);

  const onClose = useCallback(() => {
    setIsModalVisible(false);
    setCustomersSelected(null);
  }, []);

  const handleNewCustomer = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: translate('table.city'),
        item: (
          <Search
            placeholder={translate('table.city')}
            onSearch={value => handleSetSearchParams({ city: value })}
            onChange={({ target: { value } }) => setCityValue(value)}
            value={cityValue}
            allowClear
          />
        ),
      },
    ],
    [handleSetSearchParams, cityValue, translate],
  );

  return (
    <>
      <CustomerModal
        mutateData={handleMutateCustomers}
        onClose={onClose}
        visible={isModalVisible}
        customerData={customersSelected}
      />
      <Table
        columns={columnsDefault}
        allColumns={columnsDefault}
        data={customersData?.content || []}
        loading={!customersData?.content}
        title={
          <>
            {translate('customers')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        tableKey="customers"
        pagination={pagination}
        onChange={handleTableChange}
        extraActions={
          <>
            <Tooltip
              placement="top"
              title={translate('modal.title.newCustomer')}
            >
              <Button
                icon={<PlusCircleOutlined />}
                size="large"
                type="link"
                onClick={() => handleNewCustomer()}
              />
            </Tooltip>
          </>
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default CustomersSchedules;
