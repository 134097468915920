import React from 'react';

import { Radio, Typography, Row, Alert } from 'antd';

import { useTranslation } from 'react-i18next';

// import './styles.less';

import useSwr from '@src/hooks/useSwr';
import { formatCurrency } from '@src/utils/formatters';

const { Paragraph, Text } = Typography;

const ChooseCancellationOrSubstitution = ({
  selectedDevices,
  cancelationType,
  onChange,
}) => {
  const { t: tDevice } = useTranslation('device-actions-modal');
  const onlyMssidnData = selectedDevices?.map(item => item.msisdn);

  const { data: penaltyData } = useSwr(
    '/service-proxy/broker/cancellation/calculate-fee',
    onlyMssidnData,
    {
      method: 'post',
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 18,
      }}
    >
      <Alert
        style={{ padding: 12, marginTop: 18, textAlign: 'justify' }}
        description={tDevice('steps.broker-cancellation.warning-substitution')}
        type="info"
        showIcon
      />

      <Text
        style={{
          textAlign: 'center',
          opacity: 0.6,
        }}
      >
        {tDevice('steps.suspension-withdrawal.informative.total', {
          count: selectedDevices?.length,
        })}
      </Text>
      {cancelationType == 'cancell' && (
        <div style={{ display: 'flex', align: 'center' }}>
          <Text
            style={{
              textAlign: 'center',
              width: '100%',
              alignSelf: 'center',
            }}
          >
            {tDevice('steps.broker-cancellation.fee-value')}
            <Text strong> {formatCurrency(penaltyData?.fee)}</Text>
          </Text>
        </div>
      )}

      <Row gutter={[16, 16]}>
        <Radio.Group
          className="action-lock-unlock"
          value={cancelationType}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
        >
          <Radio.Button value="cancell" className="action-lock-unlock-radio">
            {tDevice('steps.broker-cancellation.cancell-button')}
          </Radio.Button>
          <Radio.Button
            value="substitution"
            className="action-lock-unlock-radio"
          >
            {tDevice('steps.broker-cancellation.substitution-button')}
          </Radio.Button>
        </Radio.Group>
      </Row>
      <Paragraph style={{ textAlign: 'justify' }}>
        {cancelationType === 'cancell'
          ? tDevice('steps.broker-cancellation.time-message-cancell')
          : tDevice('steps.broker-cancellation.time-message-substitution')}
      </Paragraph>
    </div>
  );
};

export default ChooseCancellationOrSubstitution;
