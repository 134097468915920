import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Upload, message } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { useTranslation } from 'react-i18next';
import './styles.less';

const ImageUpload = ({
  onChange,
  dimensions,
  defaultFile,
  allowCut,
  limitSize = 2,
}) => {
  const { t } = useTranslation('main-container');
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (defaultFile) {
      setFileList([defaultFile]);
    } else {
      setFileList([]);
    }
  }, [defaultFile]);

  const onPreview = useCallback(async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }, []);

  const handleOnUpload = useCallback(
    file => {
      const isOnTheLimit = file.size / 1024 / 1024 < limitSize;
      if (!isOnTheLimit) {
        message.error(
          t('image-upload.max-upload-size-error', { value: limitSize }),
        );
      } else {
        onChange(file);
        setFileList([file]);
      }
      return false;
    },
    [limitSize, onChange, t],
  );

  const handleOnChange = useCallback(
    ({ file }) => {
      const wasRemoved = file?.status === 'removed';
      if (wasRemoved) {
        onChange(undefined);
        setFileList([]);
      }
    },
    [onChange],
  );

  const UploadEl = useMemo(
    () => (
      <Upload
        maxCount={1}
        accept=".jpg, .png"
        listType="picture"
        fileList={fileList}
        onPreview={onPreview}
        onChange={handleOnChange}
        beforeUpload={handleOnUpload}
        className="image-upload"
        width="100%"
      >
        {fileList.length < 1 && (
          <div className="image-upload-content">
            <FileImageOutlined className="image-upload-icon" />
            <div>
              <p className="image-upload-title">
                {t('image-upload.click-or-drag')}
              </p>
              <p className="image-upload-subtitle">
                {t('image-upload.support')}
                {dimensions &&
                  t('image-upload.dimensions', {
                    value: `${dimensions?.width}x${dimensions?.height}px`,
                  })}
                &nbsp;
                {!!limitSize &&
                  t('image-upload.max-upload-size', { value: limitSize })}
              </p>
            </div>
          </div>
        )}
      </Upload>
    ),
    [
      dimensions,
      fileList,
      handleOnChange,
      handleOnUpload,
      limitSize,
      onPreview,
      t,
    ],
  );

  return allowCut ? (
    <ImgCrop
      quality={1}
      modalTitle={t('image-upload.crop-modal-title')}
      aspect={dimensions && dimensions?.width / dimensions?.height}
    >
      {UploadEl}
    </ImgCrop>
  ) : (
    UploadEl
  );
};

export default ImageUpload;
